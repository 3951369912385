import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpriteImageComponent } from './sprite-image.component';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SpriteImageComponent
    ],
    exports: [
        SpriteImageComponent
    ]
})

export class SpriteImageModule { }
