import {Pipe, PipeTransform} from "@angular/core";
import {DateTime} from "luxon";
import {HouseShareDatePipe} from "./house-share-date.pipe";
import {DateFormats} from "../models/dates/date-formats";

@Pipe({ name: 'relativeDate' })
export class RelativeDatePipe implements PipeTransform {

    constructor(private houseShareDatePipe: HouseShareDatePipe) {}

    transform(value: any): string {
        if (!value) {
            return '';
        }

        const isoDate = typeof value === 'object' ? value.toISOString() : value;
        const inputDate = DateTime.fromISO(isoDate);
        const now = DateTime.now();

        const daysDiff = Math.floor(now.diff(inputDate, 'days').days);

        if (daysDiff == 0) return 'Today';
        if (daysDiff == 1) return 'Yesterday';
        if (inputDate.weekNumber == now.weekNumber && inputDate <= now) return 'This week';
        if (inputDate.weekNumber == now.weekNumber - 1) return 'Last week';
        if (inputDate.year === now.year) return inputDate.toFormat("ccc dd MMM"); //e.g. Mon 23 Feb
        return this.houseShareDatePipe.transform(value, DateFormats.SHORT);
    }

}
