export enum ModalTypes {
    ADDRESS_FILTER = 0,
    REQUEST_VIEWING = 1,
    HOMES_FILTER = 2,
    PROPERTY_SERVICE_CONFIGURE = 3,
    SEND_MESSAGE_MANAGER = 4,
    ACTION = 5,
    MARKETING_SERVICE = 6,
    REMOVE_ITEM = 7,
    REPORT_MAINTENANCE = 8,
    FORM_NAVIGATE_AWAY_CONFIRM = 9,
    DELETE = 10,
    CONVERSATION_FILTER = 11,
    CONVERSATION_FILE_UPLOADER = 12,
    SUBSCRIPTION_CHECK = 13,
    INFO_BOX = 14,
    EDIT_PROPERTY_IMAGE = 15,
    EDIT_ROOM_IMAGE = 16,
    EDIT_ORGANISATION_IMAGE = 17,
    INFORMATION = 18,
    CONFIRM_ITEM = 19,
    CREATE_CUSTOM_ACTON = 20,
    DOCUMENT_VIEWER = 21,
    RENT_COLLECTION_ORGANISATION_SETTINGS = 22,
    VIEWING = 23,
    MAINTENANCE = 24,
    MAINTENANCE_ADD = 25,
    MAINTENANCE_CATEGORY_UPDATE = 26,
    CONVERSATION_TO_MAINTENANCE = 27,
    TENANCY_NOTE = 28,
    ONBOARDING = 29,
    ERROR= 30,
    SEND_MESSAGE_NON_MANAGER = 31,
    SEND_MESSAGE_FIND_A_HOME = 32,
    SEND_VIEWING_INVITE = 33,
    GENERIC_SUBMIT_MODAL= 34,
    CREATE_CUSTOM_CALENDAR_ACTION = 35,
    RENT_DUE_RECORD = 36,
    NEW_ONBOARDING = 37,
    COHO_CREDIT_TOP_UP = 38,
    RIGHTMOVE_MANAGE = 39,
}
