import {Component, ViewEncapsulation} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ModalTypes} from "../../models/modal/modal-types";
import {ModalModule} from "../modal/modal.module";
import {ModalStates} from "../../models/modal/modal-states";
import {Subject} from "rxjs";
import {ModalState} from "../../models/modal/modal-state";
import {ModalService} from "../../services/modal.service";
import {AccountService} from "../../services/account.service";
import {Router, UrlTree} from "@angular/router";
import {LoaderButtonModule} from "../loader-button/loader-button.module";
import {HttpErrorResponse} from "@angular/common/http";
import {User} from "../../models/user/user";
import {FormMessageModule} from "../form-message/form-message.module";
import {FormMessageTypes} from "../form-message/form-message-types";

export class ErrorModalConfiguration {
    error: HttpErrorResponse
    redirectedTo: RedirectionPages;
}

export enum RedirectionPages {
    MANAGE = 0,
    MESSAGE_CENTER = 1,
    MY_HOME = 2,
    SUPPLIER_DASHBOARD = 3,
    LOGIN = 4,
}

@Component({
    selector: 'error-modal',
    templateUrl: 'error-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CommonModule,
        ModalModule,
        LoaderButtonModule,
        FormMessageModule,
    ]
})

export class ErrorModalComponent {
    modalOpen = false;
    confirguringForm = false;
    error: HttpErrorResponse;
    currentUser: User;
    callbackSubscriber = new Subject<ModalState<ErrorModalConfiguration>>();
    redirectedTo: RedirectionPages;

    constructor(
        private modalService: ModalService,
        private accountService: AccountService,
        private router: Router,
    ){
        this.callbackSubscriber.subscribe(
            config => {
                this.configureForm(config.data)
                if(config.state == ModalStates.CLOSED){
                    this.modalOpen = false;
                } else {
                    this.modalOpen = true;
                }
            }
        );

        accountService.watchCurrentUser()
            .subscribe(user => {
                this.currentUser = user;
            });
    }

    configureForm(data: ErrorModalConfiguration) {
        this.confirguringForm = true;
        this.error = data.error;
        this.redirectedTo = data.redirectedTo;
        this.confirguringForm = false;
    }

    closeModal() {
        this.error = null;
        this.modalService.closeModal(ModalTypes.ERROR);
    }

    logout() {
        this.accountService.logout();
        const loginUrl = this.router.createUrlTree(['/login']);
        window.location.href = loginUrl.toString();
    }

    goToMain(){
        let url: UrlTree;
        if(this.currentUser.isManager){
            url = this.router.createUrlTree(['/manage']);
        } else if(this.currentUser.isTenant || this.currentUser.isOnboarding ||
            (this.currentUser.isViewing && this.currentUser.unclaimedAccount === false)){
            url = this.router.createUrlTree(['/my-home']);
        }
        window.location.href = url.toString();
    }

    refresh(){
        window.location.reload();
    }

    protected readonly ModalTypes = ModalTypes;
    protected readonly RedirectionPages = RedirectionPages;
    protected readonly FormMessageTypes = FormMessageTypes;
}
