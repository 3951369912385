import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';

import { Conversation } from '../models/message/conversation';
import { ConversationsService } from '../services/conversations.service';
import {catchError} from "rxjs/operators";
import {ErrorService} from "../services/error.service";
import {AccountService} from "../services/account.service";
import {RedirectionPages} from "../components/error-modal/error-modal.component";

@Injectable()
export class ConversationResolver implements Resolve<Conversation>
{
    constructor(private conversationsService: ConversationsService,
                private router: Router,
                private errorService: ErrorService,
                private accountService: AccountService) {}


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Conversation> {
        const conversationReference = route.paramMap.get('reference');
        if (!conversationReference) {
            return EMPTY;
        }

        return this.conversationsService.getConversation(conversationReference, true)
            .pipe(catchError(err => {
                const user = this.accountService.getCurrentUser()
                if(user.isManager){
                    this.router.navigate(['/manage/messages']);
                } if(user.isOwner){
                    this.router.navigate(['/owner/messages']);
                } else if(user.isTenant || user.isOnboarding){
                    this.router.navigate(['/my-home/messages']);
                }

                this.errorService.logRoutingError(err, RedirectionPages.MESSAGE_CENTER);
                return EMPTY;
            }));
    }
}
