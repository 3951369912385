import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {AuthenticationInformation} from "../models/tds/authentication-information";
import {AuthenticationInformationPost} from "../models/tds/authentication-information-post";
import {TdsDeposit} from "../models/tds/tds-deposit";
import {TdsBranchUserPost} from "../models/tds/tds-branch-user-post";
import {TdsDepositCreationPostModel} from "../models/tds/tds-deposit-creation-post-model";

@Injectable()
export class TdsService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService) {
    }

    getAuthenticationInformation(organisationReference: string) {
        const url = `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tds/authentication-information`;
        return this.http.get<AuthenticationInformation>(url);
    }

    postAuthenticationInformation(organisationReference: string, model: AuthenticationInformationPost) {
        const url = `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tds/authentication-information`;
        return this.http.post<AuthenticationInformation>(url, model);
    }

    patchAuthenticationInformation(organisationReference: string, model: AuthenticationInformationPost) {
        const url = `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tds/authentication-information`;
        return this.http.patch<AuthenticationInformation>(url, model);
    }

    createDeposit(organisationReference: string, tenancyGuid: string, model: TdsDepositCreationPostModel){
        const url = `${ this.configService.baseUrl }/manage/organisation/${organisationReference}/tds/${tenancyGuid}/create-deposit`;
        return this.http.post<TdsDeposit>(url, model);
    }

    getTdsStatus(organisationReference: string, tenancyGuid: string){
        const url = `${ this.configService.baseUrl }/manage/organisation/${organisationReference}/tds/${tenancyGuid}/status`;
        return this.http.get<TdsDeposit>(url);
    }

    remakePIFile(organisationReference: string, tenancyGuid: string){
        const url = `${ this.configService.baseUrl }/manage/organisation/${organisationReference}/tds/${tenancyGuid}/remake-pi-file`;
        return this.http.get<TdsDeposit>(url);
    }

    markAsPaid(organisationReference: string, tenancyGuid: string){
        const url = `${ this.configService.baseUrl }/manage/organisation/${organisationReference}/tds/${tenancyGuid}/paid`;
        return this.http.patch(url, null);
    }

    cancel(organisationReference: string, tenancyGuid: string){
        const url = `${ this.configService.baseUrl }/manage/organisation/${organisationReference}/tds/${tenancyGuid}/cancel`;
        return this.http.patch(url, null);
    }

    getTdsDeposit(organisationReference: string, tenancyGuid: string){
        const url = `${ this.configService.baseUrl }/manage/organisation/${organisationReference}/tds/${tenancyGuid}`;
        return this.http.get<TdsDeposit>(url);
    }

    createNewBranchUser(organisationReference: string, model: TdsBranchUserPost) {
        const url = `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tds/authentication-information/branch-user`;
        return this.http.patch<AuthenticationInformation>(url, model);
    }

    addLeaflet(organisationReference: string, tenancyGuid: string){
        const url = `${ this.configService.baseUrl }/manage/organisation/${organisationReference}/tds/${tenancyGuid}/add-leaflet`;
        return this.http.get<TdsDeposit>(url);
    }

    getDepositRegistrationDefaults(organisationReference: string, tenancyGuid: string){
        const url = `${ this.configService.baseUrl }/manage/organisation/${organisationReference}/tds/${tenancyGuid}/deposit-creation-defaults`;
        return this.http.get<{depositCreationPostModel: TdsDepositCreationPostModel, auth: AuthenticationInformation}>(url);
    }
}
