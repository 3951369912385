import {Component, ViewEncapsulation} from '@angular/core';
import {FocusOverlayService} from "../../services/focus-overlay.service";

@Component({
    selector: 'focus-overlay',
    templateUrl: './focus-overlay.component.html',
    encapsulation: ViewEncapsulation.None
})
export class FocusOverlayComponent {
    isOpen: boolean = false;

    constructor(focusOverlayService: FocusOverlayService) {
        focusOverlayService.getObservable()
            .subscribe(isOpen => this.isOpen = isOpen);
    }
}
