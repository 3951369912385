export enum FinancialItemIncomeExpenseTypes {
    INCOME = -1,
    NOT_APPLICABLE = 0,
    EXPENSE = 1,
    SETTLEMENT = 2,
    CLIENT_MONEY = 3,
    CLIENT_MONEY_REVERSAL = 9,
    TENANT_FUNDS = 4,
    TENANT_FUNDS_REVERSAL = 8,
    SUPPLIER_COMMISSION = 5,

    INCOME_REVERSAL = -2,
    EXPENSE_REVERSAL = 6,
    SUPPLIER_COMMISSION_REVERSAL = 7,
}

export const FinancialItemIncomeExpenseTypesReverse = {
    "-1": "Income",
    "0": "N/A",
    "1": "Expense",
    "2": "Settlement",
    "3": "Client Money",
    "4": "Tenant Funds",
    "5": "Supplier Commission",
    "-2": "Income Reversal",
    "6": "Expense Reversal",
    "7": "Supplier Com. Reversal",
    "8": "Tenant Funds Reversal",
    "9": "Client Money Reversal",
}


export class IncomeExpense {
    // KEEP SYNCHRONISED WITH FinancialItemIncomeExpenseTypesExtensions in backend
    private static IncomeExpenseTypesArray = [
        FinancialItemIncomeExpenseTypes.INCOME,
        FinancialItemIncomeExpenseTypes.INCOME_REVERSAL,
        FinancialItemIncomeExpenseTypes.EXPENSE_REVERSAL,
        FinancialItemIncomeExpenseTypes.EXPENSE
    ];

    private static SupplierTypesArray =[
        FinancialItemIncomeExpenseTypes.SUPPLIER_COMMISSION,
        FinancialItemIncomeExpenseTypes.SUPPLIER_COMMISSION_REVERSAL,
    ];
    public static IncomeExpenseTypes(inputValue: FinancialItemIncomeExpenseTypes): boolean
    {
        return !!this.IncomeExpenseTypesArray.find((value) => value == inputValue);
    }

    public static SupplierTypes(inputValue: FinancialItemIncomeExpenseTypes): boolean
    {
        return !!this.SupplierTypesArray.find((value) => value == inputValue);
    }

    public static IsMoneyMovement(inputValue: FinancialItemIncomeExpenseTypes, excludeReversal: boolean = false): boolean
    {
        return this.IsExpense(inputValue, excludeReversal) || this.IsIncome(inputValue, excludeReversal);
    }

    public static IsSupplierCommission(inputValue: FinancialItemIncomeExpenseTypes, excludeReversal: boolean = false): boolean
    {
        return inputValue == FinancialItemIncomeExpenseTypes.SUPPLIER_COMMISSION || excludeReversal == false && inputValue == FinancialItemIncomeExpenseTypes.SUPPLIER_COMMISSION_REVERSAL;
    }

    public static IsIncome(inputValue: FinancialItemIncomeExpenseTypes, excludeReversal: boolean = false): boolean
    {
        return inputValue == FinancialItemIncomeExpenseTypes.INCOME || excludeReversal == false && inputValue == FinancialItemIncomeExpenseTypes.INCOME_REVERSAL;
    }

    public static IsIncomeReversal(inputValue: FinancialItemIncomeExpenseTypes): boolean
    {
        return inputValue == FinancialItemIncomeExpenseTypes.INCOME_REVERSAL;
    }

    public static IsExpense(inputValue: FinancialItemIncomeExpenseTypes, excludeReversal: boolean = false): boolean
    {
        return inputValue == FinancialItemIncomeExpenseTypes.EXPENSE || excludeReversal == false && inputValue == FinancialItemIncomeExpenseTypes.EXPENSE_REVERSAL;
    }

    public static IsExpenseReversal(inputValue: FinancialItemIncomeExpenseTypes): boolean
    {
        return inputValue == FinancialItemIncomeExpenseTypes.EXPENSE_REVERSAL;
    }

    public static IsReversal(inputValue: FinancialItemIncomeExpenseTypes): boolean
    {
        return inputValue == FinancialItemIncomeExpenseTypes.EXPENSE_REVERSAL
            || inputValue == FinancialItemIncomeExpenseTypes.INCOME_REVERSAL
            || inputValue == FinancialItemIncomeExpenseTypes.SUPPLIER_COMMISSION_REVERSAL
            || inputValue == FinancialItemIncomeExpenseTypes.TENANT_FUNDS_REVERSAL
            || inputValue == FinancialItemIncomeExpenseTypes.CLIENT_MONEY_REVERSAL;
    }
}
