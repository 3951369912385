import {Component, Input, ViewEncapsulation, OnInit, OnChanges, SimpleChanges} from '@angular/core';

import { FormMessageTypes } from './form-message-types';

@Component({
    selector: 'form-message',
    templateUrl: './form-message.component.html',
    encapsulation: ViewEncapsulation.None
})

export class FormMessageComponent implements OnInit, OnChanges {

    @Input() icon: string | null;
    @Input() type: FormMessageTypes;
    @Input() iconClasses: string = "";
    @Input() classes: string = "";
    @Input() refresh: boolean = false;

    messageType: string;

    constructor() {
    }

    ngOnInit(): void {
        this.updateFromInputs();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.refresh && changes["type"]) {
            this.updateFromInputs();
        }
    }

    updateFromInputs() {
        this.messageType = this.type.toLowerCase();

        if (!this.icon || this.refresh) {
            switch (this.type) {
                case FormMessageTypes.ERROR:
                    this.icon = 'warning';
                    break;
                case FormMessageTypes.NOTIFICATION:
                    this.icon = 'info';
                    break;
                case FormMessageTypes.SUCCESS:
                    this.icon = 'success';
                    break;
                case FormMessageTypes.LIGHT_BULB:
                    this.icon = 'bills';
                    break;
                case FormMessageTypes.LOVE:
                    this.icon = 'love';
                    break;
                case FormMessageTypes.CONFIRM:
                    this.icon = 'success';
                    break;
                case FormMessageTypes.TENANT_NOT_INVITED:
                    this.icon = 'unplugged';
                    this.messageType = "not-invited";
                    break;
                case FormMessageTypes.TENANT_INVITED:
                    this.icon = 'unplugged';
                    this.messageType = "invited";
                    break;
                case FormMessageTypes.TENANT_JOINED:
                    this.icon = 'plug';
                    this.messageType = "joined";
                    break;
            }
        }
    }
}
