import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

import { ModalModule } from '../modal/modal.module';
import { DocumentViewerModalComponent } from './document-viewer-modal.component';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import {PipesModule} from "../../pipes/pipes.module";
import {ErrorResponseSummaryModule} from "../error-response-summary/error-response-summary.module";
import {LoaderButtonModule} from "../loader-button/loader-button.module";
import {DebugInfoModule} from "../debug-info/debug-info.module";

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        NgxDocViewerModule,
        LoadingIndicatorModule,
        PipesModule,
        ErrorResponseSummaryModule,
        LoaderButtonModule,
        DebugInfoModule,
    ],
    declarations: [
        DocumentViewerModalComponent
    ],
    exports: [
        DocumentViewerModalComponent
    ]
})

export class DocumentViewerModalModule {
}
