import {  Subject } from "rxjs";

export class ConfirmConfiguration {
    message: string;
    title: string;
    confirmCallback: Subject<void> = new Subject<void>();
    cancelCallback: Subject<void> = new Subject<void>();
    yesLabel: string = 'Ok';
    noLabel: string = 'Cancel';
}
