// If something is missing from https://coho-design.s3.eu-west-2.amazonaws.com/front-end/latest/icons.html please add it
// Large icons and MaintenanceCategory icons are not there to avoid misusing them

export class IconNames {
    // Interests
    static artAndCrafts = 'icon-art-and-crafts';
    static food = 'icon-food';
    static fitness = 'icon-fitness';
    static games = 'icon-games';
    static movies = 'icon-movies';
    static music = 'icon-music';
    static nightsOut = 'icon-nights-out';
    static parties = 'icon-parties';
    static quietNights = 'icon-quiet-nights';
    static reading = 'icon-reading';
    static sports = 'icon-sports';
    static travelling = 'icon-travelling';
    static videoGames = 'icon-video-games';
    static tv = 'icon-tv';
    static nature = 'icon-nature';
    // Amenities
    static disabledAccess = 'icon-disabled-access';
    static enSuite = 'icon-en-suite';
    static privateBathroom = 'icon-private-bathroom';
    static sink = 'icon-sink';
    static bed = 'icon-bed';
    static drawers = 'icon-drawers';
    static wardrobe = 'icon-wardrobe';
    static desk = 'icon-desk';
    static workspace = 'icon-workspace';
    static kitchenette = 'icon-kitchenette';
    static livingRoom = 'icon-living-room';
    static garage = 'icon-garage';
    static garden = 'icon-garden';
    static noSmoking = 'icon-no-smoking';
    static smoking = 'icon-smoking';
    static parking = 'icon-parking';
    static offRoadParking = 'icon-off-road-parking';
    static parkingPermit = 'icon-parking-permit';
    static pets = 'icon-pets';
    static noPets = 'icon-no-pets';
    static fibre = 'icon-fibre';
    static wifi = 'icon-wifi';
    static wiredInternet = 'icon-wired-internet';
    static tvLicence = 'icon-tv-licence';
    static virginSkyTv = 'icon-virgin-sky-tv';
    static amazonPrimeVideo = 'icon-amazon-prime-video';
    static netflix = 'icon-netflix';
    static disneyPlus = 'icon-disney-plus';
    static singleRoom = 'icon-single-room';
    static doubleRoom = 'icon-double-room';
    static outdoorSeating = 'icon-outdoor-seating';
    static bbq = 'icon-bbq';
    static clothesLine = 'icon-clothes-line';
    static washingMachine = 'icon-washing-machine';
    static tumbleDryer = 'icon-tumble-dryer';
    static dishwasher = 'icon-dishwasher';
    static fridgeFreezer = 'icon-fridge-freezer';
    static vegetablePatch = 'icon-vegetable-patch';
    static minimumStay = 'icon-minimum-stay';
    static maximumStay = 'icon-maximum-stay';
    static bicycle = 'icon-bicycle';
    static download = 'icon-download';
    // Admin
    static user = 'icon-user';
    static users = 'icon-users';
    static creditCard = 'icon-credit-card';
    static settings = 'icon-settings';
    static calendar = 'icon-calendar';
    static clock = 'icon-clock';
    static message = 'icon-message';
    static messageOff = 'icon-message-off';
    static conversation = 'icon-conversation';
    static viewing = 'icon-viewing';
    static document = 'icon-document';
    static report = 'icon-report';
    static images = 'icon-images';
    static house = 'icon-house';
    static area = 'icon-area';
    static description = 'icon-description';
    static bills = 'icon-bills';
    static rent = 'icon-rent';
    static room = 'icon-room';
    static floorplan = 'icon-floorplan';
    static tour = 'icon-tour';
    static video = 'icon-video';
    static task = 'icon-task';
    static plug = 'icon-plug';
    static unplugged = 'icon-unplugged';
    static light = 'icon-light';
    static dark = 'icon-dark';
    static logout = 'icon-logout';
    static actions = 'icon-actions';
    static card = 'icon-card';
    static list = 'icon-list';
    static bell = 'icon-bell';
    static transfer = 'icon-transfer';
    static expense = 'icon-expense';
    static income = 'icon-income';
    static suitcase = 'icon-suitcase';
    static van = 'icon-van';
    static emails = 'icon-emails';
    static phone = 'icon-phone';
    // Actions
    static open = 'icon-open';
    static edit = 'icon-edit';
    static add = 'icon-add';
    static delete = 'icon-delete';
    static reload = 'icon-reload';
    static archive = 'icon-archive';
    static crop = 'icon-crop';
    static rotateLeft = 'icon-rotate-left';
    static rotateRight = 'icon-rotate-right';
    static leftArrow = 'icon-left-arrow';
    static rightArrow = 'icon-right-arrow';
    static downArrow = 'icon-down-arrow';
    static dragHandle = 'icon-drag-handle';
    static filter = 'icon-filter';
    static sort = 'icon-sort';
    static sortUp = 'icon-sort-up';
    static sortNone = 'icon-sort-none';
    static pin = 'icon-pin';
    static arrowRight = 'icon-arrow-right';
    static arrowLeft = 'icon-arrow-left';
    static arrowUp = 'icon-arrow-up';
    static arrowDown = 'icon-arrow-down';
    static option = 'icon-option'
    static signature = 'icon-signature';
    static newWindow = 'icon-new-window';
    static newTab = 'icon-new-tab';
    static copy = 'icon-copy';
    static mail = 'icon-mail';
    static new = 'icon-new';
    static processing = 'icon-processing';
    static file = 'icon-file';
    static attachFile = 'icon-attach-file';
    static send = 'icon-send';
    static more = 'icon-more';
    static lightning = 'icon-lightning';
    // Notifications
    static info = 'icon-info';
    static infoReverse = 'icon-info-reverse';
    static warning = 'icon-warning';
    static success = 'icon-success';
    static tick = 'icon-tick';
    static cross = 'icon-cross';
    static alert = 'icon-alert';
    static silence = 'icon-silence';
    static unsilence = 'icon-unsilence';
    static unread = 'icon-unread';
    static fire = 'icon-fire';
    // Rating
    static sad = 'icon-sad';
    static sadLow = 'icon-sad-low';
    static unhappy = 'icon-unhappy';
    static unhappyLow = 'icon-unhappy-low';
    static happy = 'icon-happy';
    static happyLow = 'icon-happy-low';
    static good = 'icon-good';
    static goodLow = 'icon-good-low';
    static great = 'icon-great';
    static greatLow = 'icon-great-low';
    static star = 'icon-star';
    static happyOutline = 'icon-happy-outline';
    // Steps
    static hourglass = 'icon-hourglass';
    static press = 'icon-press';
    static thumbsUp = 'icon-thumbs-up';
    static thumbsDown = 'icon-thumbs-down';
    static stop = 'icon-stop';
    // User types
    static students = 'icon-students';
    static professional = 'icon-professional';
    static tenantAny = 'icon-tenant-any';
    static female = 'icon-female';
    static male = 'icon-male';
    static mixed = 'icon-mixed';
    static males = 'icon-males';
    static females = 'icon-females';
    static singleGender = 'icon-single-gender';
    static looking = 'icon-looking';
    static landlord = 'icon-landlord';
    static sublettor = 'icon-sublettor';
    static unspecifiedGender = 'icon-unspecified-gender';
    static otherGender = 'icon-other-gender';
    static genderAny = 'icon-gender-any';
    // Services
    static boiler = 'icon-boiler';
    static id = 'icon-id';
    static referenceCheck = 'icon-reference-check';
    static repair = 'icon-repair';
    static contractCreation = 'icon-contract-creation';
    static find = 'icon-find';
    static checkboard = 'icon-checkboard';
    // Social media
    static twitter = 'icon-twitter';
    static facebook = 'icon-facebook';
    static linkedin = 'icon-linkedin';
    static instagram = 'icon-instagram';
    static tiktok = 'icon-tiktok';
    static email = 'icon-email';
    static link = 'icon-link';
    static discord = 'icon-discord';
    static youtube = 'icon-youtube';
    // Payment
    static mastercard = 'icon-mastercard';
    static visa = 'icon-visa';
    static otherCard = 'icon-other-card';
    // Animated
    static rolling = 'icon-rolling';
    static pulse = 'icon-pulse';

}
