import { Injectable } from "@angular/core";
import { RoomSummary } from "../models/manage-room/room-summary";
import { TenancySummary } from "../models/tenancy/tenancy-summary";

@Injectable()
export class OrderByService {
    orderRoomSummariesByRoomName(rooms: RoomSummary[]): RoomSummary[] {
        return rooms.sort((a, b) => {
            const numA = this.extractNumber(a.name);
            const numB = this.extractNumber(b.name);

            if (numA !== numB) {
                return numA - numB;
            }

            return this.toAlphabeticalName(a.name).localeCompare(this.toAlphabeticalName(b.name));
        });
    }

    orderTenancySummariesByRoomName(tenancySummaries: TenancySummary[]): TenancySummary[] {
        return tenancySummaries.sort((a, b) => {
            const numA = this.extractNumber(a.roomName);
            const numB = this.extractNumber(b.roomName);

            if (numA !== numB) {
                return numA - numB;
            }

            return this.toAlphabeticalName(a.roomName).localeCompare(this.toAlphabeticalName(b.roomName));
        });
    }

    orderTenancySummariesByPropertyNameThenRoomName(tenancySummaries: TenancySummary[]): TenancySummary[] {
        return tenancySummaries.sort((a, b) => {
            const propNumA = this.extractNumber(a.propertyName);
            const propNumB = this.extractNumber(b.propertyName);

            if (propNumA !== propNumB) {
                return propNumA - propNumB;
            }

            const propNameComparison = a.propertyName.localeCompare(b.propertyName);
            if (propNameComparison !== 0) {
                return propNameComparison;
            }

            const numA = this.extractNumber(a.roomName);
            const numB = this.extractNumber(b.roomName);

            if (numA !== numB) {
                return numA - numB;
            }

            return this.toAlphabeticalName(a.roomName).localeCompare(this.toAlphabeticalName(b.roomName));
        });
    }

    private extractNumber(roomName: string): number {
        const match = roomName.match(/\d+/);
        return match ? parseInt(match[0], 10) : Number.MIN_SAFE_INTEGER;
    }

    private toAlphabeticalName(name: string): string {
        // Implement the equivalent of `ToAlphabeticalName()` in TypeScript
        return name.replace(/\d+/g, ""); // Example logic (modify as needed)
    }
}
