import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import {TenantFeedbackPost} from "../models/tenant-feedback/tenant-feedback-post";
import {TenantFeedback} from "../models/tenant-feedback/tenant-feedback";
import {TenantFeedbackTypes} from "../models/tenant-feedback/tenant-feedback-types";
import {TenantMonthlyFeedbackPost} from "../models/tenant-feedback/tenant-monthly-feedback-post";
import {MyTenantMonthlyFeedback} from "../models/tenant-feedback/my-tenant-monthly-feedback";
import {TenantFeedbackOverview} from "../models/tenant-feedback/tenant-feedback-overview";


@Injectable()
export class TenantFeedbackService  {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    postTenantMonthlyFeedback(score: number, type: TenantFeedbackTypes): Observable<boolean> {

        let model = new TenantMonthlyFeedbackPost();
        model.score = score;
        model.type = type

        return this.http.post<boolean>(`${this.configService.baseUrl}/my-home/tenant-feedback/monthly`, model);
    }

    postReview(score: number, comments: string): Observable<TenantFeedback> {

        let model = new TenantFeedbackPost();
        model.score = score;
        model.comments = comments;
        model.feedbackType = TenantFeedbackTypes.ORGANISATION_REVIEW;

        return this.http.post<TenantFeedback>(`${this.configService.baseUrl}/my-home/tenant-feedback/review`, model);
    }

    getCurrentTenantMonthlyFeedback(): Observable<MyTenantMonthlyFeedback> {
        return this.http.get<MyTenantMonthlyFeedback>(`${this.configService.baseUrl}/my-home/tenant-feedback/monthly`);
    }

    getTenantReview(): Observable<TenantFeedback> {
        return this.http.get<TenantFeedback>(`${this.configService.baseUrl}/my-home/tenant-feedback/review`);
    }

    getTenantFeedbackOverview(organisationReference: string): Observable<TenantFeedbackOverview> {
        return this.http.get<TenantFeedbackOverview>(`${this.configService.baseUrl}/manage/${organisationReference}/tenant-feedback/overview`);
    }

}
