import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { HealthAndSafetyRecord } from '../models/health-and-safety/health-and-safety-record';
import { HealthAndSafetyRecordPost } from '../models/health-and-safety/health-and-safety-record-post';
import { HealthAndSafetyOverview } from '../models/health-and-safety/health-and-safety-overview';
import { FileService } from "./file.service";
import { HealthAndSafetyRecordType } from '../models/health-and-safety/health-and-safety-record-type';
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class HealthAndSafetyService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string): Observable<HealthAndSafetyOverview> {
        return this.http.get<HealthAndSafetyOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/health-and-safety/overview`);
    }

    getHealthAndSafetyRecordTypes(propertyReference: string): Observable<HealthAndSafetyRecordType[]> {
        return this.http.get<HealthAndSafetyRecordType[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/health-and-safety/types`);
    }

    getPastList(propertyReference: string): Observable<HealthAndSafetyRecord[]> {
        return this.http.get<HealthAndSafetyRecord[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/health-and-safety/past`);
    }

    add(propertyReference: string, model: HealthAndSafetyRecordPost): Observable<HttpEvent<HealthAndSafetyRecord>> {
        return this.fileService.uploadFiles(model.healthAndSafetyRecordFiles)
            .pipe(tap(_ => model.healthAndSafetyRecordFiles = this.fileService.prepareFilePosts(model.healthAndSafetyRecordFiles)),
                mergeMap(_ => this.http.post<HealthAndSafetyRecord>(`${this.configService.baseUrl}/manage/property/${propertyReference}/health-and-safety`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(propertyReference: string, item: HealthAndSafetyRecord): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/health-and-safety/${item.guid}`);
    }

    getAwaitingApprovalRecord(propertyReference: string, recordReference: string): Observable<HealthAndSafetyRecord> {
        return this.http.get<HealthAndSafetyRecord>(`${this.configService.baseUrl}/manage/property/${propertyReference}/health-and-safety/awaiting-approval/${recordReference}`);
    }

    approveRecord(propertyReference: string, recordReference: string): Observable<HealthAndSafetyRecord> {
        return this.http.patch<HealthAndSafetyRecord>(`${this.configService.baseUrl}/manage/property/${propertyReference}/health-and-safety/${recordReference}/approve`, null);
    }

    rejectRecord(propertyReference: string, recordReference: string): Observable<boolean> {
        return this.http.patch<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/health-and-safety/${recordReference}/reject`, null);
    }
}
