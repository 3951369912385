import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { SpriteImageModule } from '../sprite-image/sprite-image.module';
import {COHOFeedbackComponent} from "./coho-feedback.component";
import {MultilineTextboxModule} from "../multiline-textbox/multiline-textbox.module";
import {RadioButtonModule} from "../radio-button/radio-button.module";
import {LoaderButtonModule} from "../loader-button/loader-button.module";
import {LoadingIndicatorModule} from "../loading-indicator/loading-indicator.module";
import {PipesModule} from "../../pipes/pipes.module";
import {ErrorSummaryModule} from "../error-summary/error-summary.module";

@NgModule({
    imports: [
        CommonModule,
        SvgIconModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MultilineTextboxModule,
        RadioButtonModule,
        SpriteImageModule,
        LoaderButtonModule,
        LoadingIndicatorModule,
        PipesModule,
        ErrorSummaryModule
    ],
    declarations: [
        COHOFeedbackComponent
    ],
    exports: [
        COHOFeedbackComponent
    ]
})

export class COHOFeedbackModule { }
