import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
    selector: 'loading-indicator',
    templateUrl: './loading-indicator.component.html',
    encapsulation: ViewEncapsulation.None
})

export class LoadingIndicatorComponent {

    @Input() message: string = "Loading...";
    @Input() small: boolean = false;
    @Input() tiny: boolean = false;
    @Input() inline: boolean = false;
    @Input() colour: string = "icon--darkorange";
    @Input() cssClasses: string = null;

    constructor() {

    }
}
