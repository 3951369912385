export enum ActivityTypes {
    LOGIN = 0,
    MANAGE_ORGANISATION = 1,
    MANAGE_PROPERTY = 2,
    MANAGE_ROOM = 3,
    MY_HOME = 4,
    LOGIN_TOKEN = 5,
    PANEL_OPENED = 6,
    ONBOARDING_STARTED = 7,
    ONBOARDING_COMPLETED = 8,
    ONBOARDING_WORKFLOW_ADDED = 9,
}

export const ActivityTypesReverse = {
    0: 'LOGIN',
    1: 'MANAGE_ORGANISATION',
    2: 'MANAGE_PROPERTY',
    3: 'MANAGE_ROOM',
    4: 'MY_HOME',
    5: 'LOGIN_TOKEN',
    6: 'PANEL_OPENED',
    7: 'ONBOARDING_STARTED',
    8: 'ONBOARDING_COMPLETED',
    9: 'ONBOARDING_WORKFLOW_ADDED',
}
