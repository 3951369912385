export enum LoginResponseStatus {
    ERROR = 0,
    SUCCESS = 1,
    INCORRECT_USER_PASSWORD = 3,
    LOCKED = 4,
    FAILED_VERIFICATION = 5,
    EMAIL_EXISTS = 6,
    INVITATION_LINK_USED = 7,
    MAGIC_LINK_INVALID = 8,
    MAGIC_LINK_EXPIRED = 9,
}
