import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LoaderButtonModule } from '../loader-button/loader-button.module';
import { ModalModule } from '../modal/modal.module';
import { ConfirmModalComponent } from './confirm-modal.component';
import { FormMessageModule } from '../form-message/form-message.module';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        LoaderButtonModule,
        RouterModule,
        FormMessageModule,
        LoaderButtonModule

    ],
    declarations: [
        ConfirmModalComponent
    ],
    exports: [
        ConfirmModalComponent
    ]
})

export class ConfirmModalModule {
}
