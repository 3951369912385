import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormMessageComponent } from './form-message.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

@NgModule({
    imports: [
        CommonModule,
        SvgIconModule
    ],
    declarations: [
        FormMessageComponent
    ],
    exports: [
        FormMessageComponent
    ]
})

export class FormMessageModule {
}
