import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {mergeMap, Observable} from 'rxjs';

import { ConfigService } from './config.service';
import { DepositManagementOverview } from '../models/deposit-management/deposit-management-overview';
import { DepositManagement } from '../models/deposit-management/deposit-management';
import { DepositManagementPost } from '../models/deposit-management/deposit-management-post';
import { DepositProtectionScheme } from '../models/deposit-management/deposit-protection-scheme';
import { FileService } from "./file.service";
import {MyDepositManagement} from "../models/deposit-management/my-deposit-management";
import {DepositManagementSignaturePost} from "../models/deposit-management/deposit-management-signature-post";
import {tap} from "rxjs/operators";


@Injectable()
export class DepositManagementService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string = null): Observable<DepositManagementOverview> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.get<DepositManagementOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/deposit-management/overview`, {params});
    }

    getAvailableDepositProtectionSchemes(organisationReference: string, propertyReference: string, roomReference: string): Observable<DepositProtectionScheme[]> {
        return this.http.get<DepositProtectionScheme[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/deposit-management/available-deposit-protection-schemes`);
    }

    getItem(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string = null): Observable<DepositManagement> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.get<DepositManagement>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/deposit-management`, {params});
    }

    getTenantFriendly(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string = null): Observable<MyDepositManagement> {
        const params = new HttpParams().set('tenancyGuid', tenancyGuid);
        return this.http.get<MyDepositManagement>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/deposit-management/tenant-friendly`, {params});
    }

    getForTenant(propertyReference: string, roomReference: string, tenancyGuid: string = null): Observable<MyDepositManagement> {
        const params = new HttpParams().set('tenancyGuid', tenancyGuid);
        return this.http.get<MyDepositManagement>(`${this.configService.baseUrl}/my-home/deposit-management/property/${propertyReference}/room/${roomReference}`, {params});
    }

    add(organisationReference: string, propertyReference: string, roomReference: string, model: DepositManagementPost, tenancyGuid: string = null): Observable<HttpEvent<DepositManagement>> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.fileService.uploadFiles(model.privateFiles)
            .pipe(tap(_ => model.privateFiles = this.fileService.prepareFilePosts(model.privateFiles)),
                mergeMap(_ => this.fileService.uploadFiles(model.publicFiles)),
                tap(_ => model.publicFiles = this.fileService.prepareFilePosts(model.publicFiles)),
                mergeMap(_ => this.http.post<DepositManagement>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/deposit-management`, model, {
                    params: params, reportProgress: true, observe: 'events'
                })));
    }

    remove(organisationReference: string, propertyReference: string, roomReference: string, tenancyReference: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyReference}/deposit-management`);
    }

    addSignatureRequirement(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<DepositManagement> {
        const params = new HttpParams().set('tenancyGuid', tenancyGuid);
        return this.http.patch<DepositManagement>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/deposit-management/add-require-signature`, null, {params});
    }

    alertTenantsRequireSignature(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<DepositManagement> {
        const params = new HttpParams().set('tenancyGuid', tenancyGuid);
        return this.http.post<DepositManagement>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/deposit-management/alert-tenants-require-signature`, null, {params});
    }

    removeSignatureRequirement(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<DepositManagement> {
        const params = new HttpParams().set('tenancyGuid', tenancyGuid);
        return this.http.patch<DepositManagement>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/deposit-management/remove-require-signature`, null, {params});
    }

    submitApplicantSignature(tenancyGuid: string, model: DepositManagementSignaturePost): Observable<MyDepositManagement> {
        return this.http.patch<MyDepositManagement>(`${this.configService.baseUrl}/my-home/compliancy/${tenancyGuid}/sign-and-acknowledge`, model);
    }

    submitApplicantSignatureFromCompliancy(compliancyItemGuid: string, model: DepositManagementSignaturePost): Observable<MyDepositManagement> {
        return this.http.patch<MyDepositManagement>(`${this.configService.baseUrl}/my-home/compliancy/${compliancyItemGuid}/sign-and-acknowledge-from-compliancy`, model);
    }

    managerAcceptedSignature(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<DepositManagement> {
        const params = new HttpParams().set('tenancyGuid', tenancyGuid);
        return this.http.patch<DepositManagement>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/deposit-management/manager-accepted-signature`, null, {params});
    }

    getPast(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string = null){
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/deposit-management/past`
        return this.http.get<DepositManagement[]>(url);
    }
}
