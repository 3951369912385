import {Component, ViewEncapsulation} from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {SvgIconModule} from "../../../components/svg-icon/svg-icon.module";
import { CopyrightTextComponent } from "../../../components/copyright-text/copyright-text.component";

@Component({
    selector: 'site-footer-my-viewings',
    templateUrl: './site-footer-my-viewings.component.html',
    standalone: true, imports: [CommonModule, SvgIconModule, RouterModule, CopyrightTextComponent,],
    encapsulation: ViewEncapsulation.None
})

export class SiteFooterMyViewingsComponent {
    isLoggedIn: boolean = false;
    staticWebUrl: string;

    constructor(
        private configService: ConfigService,
    ) {
        this.staticWebUrl = this.configService.staticWebUrl;
    }

}
