

// Buildings be definition and decision only support 1 level of nesting. So there are only 2 types of buildings in the hierarchy. Further restrictions
// apply to simplify coding and reasoning
//   * Buildings that contain 0 or more buildings & properties
//   * Buildings that contain 0 or properties
//   * A building is either a TOP_LEVEL (has no parent) or it is a LEAF_LEVEL (has a parent and has no children)
// NOTICE: The `Tier` does not give you any indication as to whether the building has any properties linked nor any buildings linked. However it does help you to
//     understand whether a building is linkable by other buildings. Buildings are always linkable by properties.
export enum BuildingTier {
    TOP_LEVEL = 1,
    LEAF_LEVEL = 2,
}
