import { Component } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, NavigationStart, NavigationCancel, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Settings } from 'luxon';
import {combineLatest, of} from 'rxjs';
import Chart from 'chart.js';

import { LayoutType } from './models/layout-type/layout-type';
import { LayoutService } from './services/layout.service';
import { LoaderService } from './services/loader.service';
import { AccountService } from './services/account.service';
import { AnalyticsService } from './services/analytics.service';
import { TitleService } from './services/title.service';
import { ModalService } from './services/modal.service';
import { AppUpdateService } from './services/app-update.service';
import { MobileAppService } from './services/mobile-app.service';
import { DeviceService } from './services/device.service';
import { AffiliateService } from './services/affiliate.service';
import { ReferralService } from './services/referral.service';
import { TrackingService } from './services/tracking.service';
import {AuthenticationToken} from "./models/account/authentication-token";
import {ErrorModalConfiguration} from "./components/error-modal/error-modal.component";
import {ErrorService} from "./services/error.service";
import {ModalTypes} from "./models/modal/modal-types";
import {HttpErrorResponse} from "@angular/common/http";
import {OrganisationNavigationLayoutTypes} from "./models/layout-type/organisation-navigation-layout-types";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    title = 'house-share';

    layout: LayoutType;
    LayoutType = LayoutType;
    isApp: boolean = false;
    isPhone: boolean = false;

    organisationNavigationLayoutMinimised: boolean = false;

    organisationNavigationLayoutType: OrganisationNavigationLayoutTypes;
    OrganisationNavigationLayoutTypes = OrganisationNavigationLayoutTypes;

    // AppUpdateService is in the constructor to ensure it is instantiated, it is not used in this component
    constructor(
        titleService: TitleService,
        layoutService: LayoutService,
        loaderService: LoaderService,
        protected router: Router,
        mobileAppService: MobileAppService,
        private affiliateService: AffiliateService,
        deviceService: DeviceService,
        activatedRoute: ActivatedRoute,
        private accountService: AccountService,
        analyticsService: AnalyticsService,
        private modalService: ModalService,
        ignore: AppUpdateService,
        private location: Location,
        private referralService: ReferralService,
        trackingService: TrackingService,
        private errorService: ErrorService) {

        Settings.defaultZone = 'Europe/London';
        Chart.defaults.global.defaultFontFamily = "Mulish";

        layoutService.layout.subscribe(item => this.layout = item);

        accountService.watchCurrentUser()
            .subscribe(user => {
                if (user && user.person) {
                    analyticsService.setUser(user.person.reference, user.isManager);
                } else {
                    analyticsService.setUser(null, false);
                }
            });

        this.organisationNavigationLayoutType = layoutService.getOrganisationNavigationLayoutType();

        layoutService.watchOrganisationNavigationLayoutType()
            .subscribe(type => this.organisationNavigationLayoutType = type);

        this.organisationNavigationLayoutMinimised = layoutService.getOrganisationManageNavigationLayoutMinimised();

        layoutService.watchOrganisationNavigationLayoutMinimised()
            .subscribe(minimised => this.organisationNavigationLayoutMinimised = minimised);

        layoutService.watchOrganisationManageFocusMode()
            .subscribe(focusMode => {
                if (focusMode) {
                    document.body.classList.add('coho__focus');
                }
                 else {
                    document.body.classList.remove('coho__focus');
                }
            });

        router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => combineLatest(route.data, route.queryParamMap))
            )
            .subscribe((event) => {
                const data = event[0];
                const params = event[1];

                if (data.layout) {
                    layoutService.setLayout(data.layout);
                } else {
                    layoutService.setLayout(LayoutType.STANDARD);
                }

                modalService.closeModals();

                this.checkReferralCodes(params);
                mobileAppService.history(window.location.href);
            });

        router.events
            .pipe(
                map(event => {
                    return {event: event, route: activatedRoute};
                }),
                map((event) => {
                    while (event.route.firstChild) {
                        event.route = event.route.firstChild;
                    }
                    return event;
                }),
                filter(event => event.route.outlet === 'primary'),
                mergeMap(event => combineLatest(of(event.event), event.route.paramMap))
            )
            .subscribe(data => {
                const event = data[0];
                const route = data[1];
                if (event instanceof NavigationStart) {
                    loaderService.enable();

                    titleService.removeTags();

                    this.checkIfUserRegistrationComplete(event);
                } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                    loaderService.disable();
                }
            });

        this.isApp = mobileAppService.isMobileApp();
        this.isPhone = deviceService.isMobile();
    }

    ngAfterViewInit(){
        this.errorService.hasErrored.subscribe(hasError => {
            if (hasError) {
                const error = this.errorService.getLastCaughtRoutingError();
                if(error){
                    const config: ErrorModalConfiguration = {
                        error: error.error,
                        redirectedTo: error.redirected,
                    }
                    this.modalService.openModal(ModalTypes.ERROR, config);
                    this.errorService.resetErrorService();
                    this.errorService.deleteLastCaughtError('routing');
                }
            }
        });
    }

    checkIfUserRegistrationComplete(event: NavigationStart) {
        const allowedUrls = ['/logout', '/forms-playground', '/facebook-leads-upload',
            '/management', '/find-a-home', '/articles/management', '/management/support',
            '/management/faqs', '/legal/terms-and-conditions', '/legal/privacy-policy',
            '/legal/terms-of-use', '/legal/acceptable-use', '/demo/login'];
        if (!allowedUrls.find(url => url === event.url)) {
            let user = this.accountService.getCurrentUser();
            if (user) {
                if (!user.emailConfirmed && !user.unclaimedAccount) {
                    if (!event.url.startsWith('/register/verify')) {
                        this.router.navigate(['/register', 'verify']);
                        return;
                    }
                } else if (!user.registrationJourneyCompleted && !user.unclaimedAccount) {
                    if (!event.url.startsWith('/register/about')) {
                        this.router.navigate(['/register', 'about']);
                        return;
                    }
                }
            }
        }
    }

    private checkReferralCodes(params: ParamMap) {
        let affiliateRef = params.get('ra');
        if (affiliateRef) {
            this.affiliateService.setAffiliateCode(affiliateRef);
        }

        let referralRef = params.get('rf');
        if (referralRef) {
            this.referralService.setReferralCode(referralRef);
        }

        let newParams: string = '';
        params.keys
            .filter(k => ['rf'].indexOf(k) === -1 && ['ra'].indexOf(k) === -1)
            .forEach(k => {
                newParams += `&${ k }=${ params.get(k) }`;
            });

        let baseUrl = this.router.url.split('?')[0];
        if (newParams.length) {
            newParams = `?${ newParams.substr(1) }`;
        }

        this.location.replaceState(`${ baseUrl }${ newParams }`);
    }
}
