import { Component, ViewEncapsulation, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AccountService } from '../../services/account.service';
import { DOCUMENT } from '@angular/common';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user/user';
import { UserNotifications } from '../../models/user/user-notifications';
import { Address } from '../../models/address/address';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import { LayoutType } from '../../models/layout-type/layout-type';
import { UserPatch } from '../../models/user/user-patch';
import { DropdownOption } from '../dropdown/dropdown-option';
import { UntypedFormBuilder} from '@angular/forms';
import { ConfigService } from '../../services/config.service';
import { WebsocketNotificationService } from '../../services/websocket-notifications.service';
import { ArticleService } from '../../services/article.service';
import { ArticleSummary } from '../../models/article/article-summary';

@Component({
    selector: 'site-header',
    templateUrl: './site-header.component.html',
    encapsulation: ViewEncapsulation.None,
    host: {
        '(document:click)': 'onClick($event)'
    }
})

export class SiteHeaderComponent implements OnInit, OnDestroy {

    @ViewChild('userMenuDropdown') userMenuDropdown: ElementRef;
    @ViewChild('announceDropdown') announceDropdown: ElementRef;

    loginStackCanPop: boolean = false;
    debugEnabled: boolean = false;
    isLoggedIn: boolean = false;
    mainMenuOpen: boolean = false;
    userMenuOpen: boolean = false;
    announceMenuOpen: boolean = false;
    currentUser: User;
    profileImage: string;
    profileImageAlt: string;
    userNotifications: UserNotifications;
    mainCities: Address[];
    conversationPage: boolean = false;
    articleType: string;
    articleCount: number;
    articles: ArticleSummary[];
    showAnnouncements: boolean = false;

    liveWebUrl: string;
    demo: boolean = false;
    demoDetailsLoaded: boolean = false;
    demoInfoExpanded: boolean = false;
    demoUserOptions: DropdownOption[] = [];
    tenancyGuid : string;

    demoForm = this.formBuilder.group({
        demoUser: [null]
    });

    private stopRequestingWebsocketNotifications = new Subject();

    constructor(
        private accountService: AccountService,
        private userService: UserService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private layoutService: LayoutService,
        private websocketNotificationService: WebsocketNotificationService,
        private formBuilder: UntypedFormBuilder,
        public configService: ConfigService,
        private articlesService: ArticleService,
        private route: ActivatedRoute) {

        accountService.watchCurrentUser()
            .subscribe(user => {
                this.currentUser = user;
                this.loginStackCanPop = this.accountService.loginStackCanPop();

                if (user && user.person) {
                    this.profileImage = user.person.image.thumbnail;
                    this.profileImageAlt = `${ user.person.forename } ${ user.person.surname }`;
                } else {
                    this.profileImage = null;
                    this.profileImageAlt = null;
                }
            });

        this.layoutService.layout
            .subscribe(data => {
                this.conversationPage = data === LayoutType.CONVERSATION;
            });

        this.layoutService.watchPanelOpenState()
            .subscribe(open => {
                this.mainMenuOpen = open;
            });

        this.liveWebUrl = this.configService.liveWebUrl;

        if (this.configService.isDemo) {
            this.demo = true;

            this.accountService.getDemoDetails()
                .subscribe(data => {
                    this.demoUserOptions = [];

                    for (let user of data.users) {
                        this.demoUserOptions.push({value: user.guid, label: user.name});
                    }

                    this.demoDetailsLoaded = true;
                });
        }
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.demoForm.controls['demoUser'].valueChanges
                .subscribe(value => {
                    this.router.navigate(['/demo', 'switch'], {queryParams: {guid: value}});

                });
        });

        this.websocketNotificationService.newNotificationsAlertReceived
            .pipe(takeUntil(this.stopRequestingWebsocketNotifications))
            .subscribe((data: boolean) => {
                if (data) {
                    this.getNewUserNotifications();
                }
            });

        this.websocketNotificationService.newMyHomeNotificationsAlertReceived
            .pipe(takeUntil(this.stopRequestingWebsocketNotifications))
            .subscribe((data: boolean) => {
                if (data) {
                    this.getNewUserNotifications();
                }
            });

        this.websocketNotificationService.articleReadReceived
            .pipe(takeUntil(this.stopRequestingWebsocketNotifications))
            .subscribe((articleReference: string) => {
                this.articles = this.articles.filter(m => m.reference != articleReference);
                this.articleCount = this.articles.length;
            });


        this.userNotifications = new UserNotifications();

        this.accountService.watchIsLoggedIn()
            .subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn;
                this.loginStackCanPop = this.accountService.loginStackCanPop();

                if (this.currentUser != null && this.currentUser.emailConfirmed && this.currentUser.registrationJourneyCompleted) {
                    this.articleType = this.currentUser.isManager ? 'management' : 'tenant';

                    // Delay loading announcements and otp header notifications to allow other more important requests to go first
                    setTimeout(x => {
                        this.getNewUserNotifications();
                        this.getAnnouncements()}, 2000);
                }
            });

       // this.mainCities = this.lookupService.getMainCities();
        this.loginStackCanPop = this.accountService.loginStackCanPop();
        this.debugEnabled = this.accountService.loginHasDebugEnabled();

        this.route.queryParams.subscribe(params => {
            this.tenancyGuid = params['tenancy'] && this.currentUser.isTenant ? params['tenancy'] : '';
        });
    }

    accountSettings() {
        this.router.navigate(['/account']);
    }

    logoutAs() {
        this.router.navigate(['/manage']).then(_ => {
            this.accountService.adminLogoutAsUser();
        }).catch(err => {
            console.error('Navigation error:', err);
        });
    }

    logout() {
        this.accountService.logout();
        const loginUrl = this.router.createUrlTree(['/login']);
        window.location.href = loginUrl.toString();
    }

    toggleDebug() {
        this.accountService.loginToggleDebug();
        window.location.reload();
    }

    toggleUserMenu(open?: boolean) {
        if (open === false) {
            this.userMenuOpen = false;
        } else if (open === true) {
            this.userMenuOpen = true;
            this.announceMenuOpen = false;
        } else {
            if (this.userMenuOpen) {
                setTimeout(() => this.userMenuOpen = false);
            } else {
                setTimeout(() => this.userMenuOpen = true);
                setTimeout(() => this.announceMenuOpen = false);
            }
        }
    }

    toggleAnnounceMenu(open?: boolean) {
        if (open === false) {
            this.announceMenuOpen = false;
        } else if (open === true) {
            this.announceMenuOpen = true;
            this.userMenuOpen = false;
        } else {
            if (this.announceMenuOpen) {
                setTimeout(() => this.announceMenuOpen = false);
            } else {
                setTimeout(() => this.announceMenuOpen = true);
                setTimeout(() => this.userMenuOpen = false);
            }
        }
    }

    toggleMainMenu() {
        if (this.mainMenuOpen) {
            const mainMenu = document.getElementById('MainMenu') as HTMLElement;

            if (mainMenu && mainMenu.classList.contains('open')) {
                mainMenu.classList.remove('open');
            }

            this.mainMenuOpen = false;
        } else {
            const mainMenu = document.getElementById('MainMenu') as HTMLElement;

            if (mainMenu && !mainMenu.classList.contains('open')) {
                mainMenu.classList.add('open');
            }

            this.mainMenuOpen = true;
        }

    }

    closeMainMenu() {
        this.mainMenuOpen = false;
    }

    onClick(event: Event): void {
        if (this.userMenuOpen && this.userMenuDropdown && this.userMenuDropdown.nativeElement) {
            if (!this.userMenuDropdown.nativeElement.contains(event.target)) {
                this.userMenuOpen = false;
            }
        }

        if (this.announceMenuOpen && this.announceDropdown && this.announceDropdown.nativeElement) {
            if (!this.announceDropdown.nativeElement.contains(event.target)) {
                this.announceMenuOpen = false;
            }
        }
    }

    getNewUserNotifications() {
        this.userService.userNotifications()
            .subscribe(data => {
                console.log('user notifications data', data);
                this.userNotifications = data
            });
    }

    ngOnDestroy() {
        this.stopRequestingWebsocketNotifications.next(true);
    }

    toggleDarkMode(darkModeOn: boolean) {
        const model = new UserPatch();
        model.darkMode = darkModeOn;

        this.userService.patchUser(model)
            .subscribe();
    }

    toggleDemoShowMore(show: boolean) {
        this.demoInfoExpanded = show;
    }

    private getAnnouncements() {
        this.articlesService.getUnreadAnnouncements(this.articleType)
            .subscribe(data => {
                this.articleCount = data.count;
                this.articles = data.articles;
                if (this.articleCount > 0) {
                    this.showAnnouncements = true;
                } else {
                    this.showAnnouncements = false;
                }
            });
    }

    articleMarkRead(article: ArticleSummary) {
        this.articlesService.markAnnouncementRead(this.articleType, article.reference)
            .subscribe(data => {
                this.articleCount = data.count;
                this.articles = data.articles;
                if (this.articleCount > 0) {
                    this.showAnnouncements = true;
                } else {
                    this.showAnnouncements = false;
                }
            });
    }

    loginAs() {
        if (this.accountService.loginStackCanPop()) {
            this.accountService.adminLogoutAsUser(false);
        }
        const urlTree = this.router.createUrlTree(['/admin', 'login']);
        window.location.href = urlTree.toString();
    }

    goToMainPage(){
        if(this.currentUser?.isManager){
            this.router.navigate(['/manage']);
        } else if(this.currentUser?.isTenant ||
            (this.currentUser?.isOnboarding && this.currentUser?.unclaimedAccount === false) ||
            (this.currentUser?.isViewing && this.currentUser?.unclaimedAccount === false)){
            this.router.navigate(['/my-home']);
        } else if(this.currentUser?.isViewing && this.currentUser?.unclaimedAccount === true){
            this.router.navigate(['my-viewings', 'messages']);
        } else if(this.currentUser?.isOwner){
            this.router.navigate(['/owner']);
        } else {
            console.error('No redirection set for this case', this.currentUser)
        }
    }

    openActionPanel(){
        this.router.navigate(['/manage'], { fragment: 'actions'});
    }

    isManageRoute(): boolean {
        return this.router.url.startsWith('/manage');
    }

    isMyHomeRoute(): boolean {
        return this.router.url.startsWith('/my-home');
    }
}
