import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {mergeMap, Observable} from 'rxjs';

import { ConfigService } from './config.service';
import { FileService } from "./file.service";
import { InfoBoxPost } from '../models/info-box/info-box-post';
import { InfoBox } from '../models/info-box/info-box';
import { PropertyInfoBoxOverview } from '../models/info-box/property-info-box-overview';
import { OrganisationInfoBoxOverview } from '../models/info-box/organisation-info-box-overview';
import { JsonPatch } from "../models/json-patch/json-patch";
import { RoomInfoBoxOverview } from '../models/info-box/room-info-box-overview';
import {MyHomeInfoBoxOverview} from "../models/info-box/my-home-info-box-overview";
import {map} from "rxjs/operators";

@Injectable()
export class InfoBoxService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOrganisationOverview(organisationReference: string, privateInformation: boolean): Observable<OrganisationInfoBoxOverview> {
        let params = new HttpParams();
        if (privateInformation) {
            params = params.set('privateInformation', "true");
        }

        return this.http.get<OrganisationInfoBoxOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/info-boxes/overview`, { params });
    }

    getPropertyOverview(organisationReference: string, propertyReference: string, privateInformation: boolean): Observable<PropertyInfoBoxOverview> {
        let params = new HttpParams();
        if (privateInformation) {
            params = params.set('privateInformation', "true");
        }

        return this.http.get<PropertyInfoBoxOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/info-boxes/property/${propertyReference}/overview`, { params });
    }

    getAnonymousInfoBox(organisationReference: string, propertyPublicReference: string, propertyPrivateReference: string): Observable<MyHomeInfoBoxOverview> {
        let params = new HttpParams();
        params = params.set('propertyPrivateReference', propertyPrivateReference);


        return this.http.get<MyHomeInfoBoxOverview>(`${this.configService.baseUrl}/welcome-pack/organisation/${organisationReference}/${propertyPublicReference}`, { params });
    }

    getRoomOverview(organisationReference: string, propertyReference: string, roomReference: string, privateInformation: boolean): Observable<RoomInfoBoxOverview> {
        let params = new HttpParams();
        if (privateInformation) {
            params = params.set('privateInformation', "true");
        }

        return this.http.get<RoomInfoBoxOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/info-boxes/property/${propertyReference}/room/${roomReference}/overview`, { params });
    }

    getMaintenceCategoryInfoBoxes(organisationReference: string, maintenanceCategoryGuid: string): Observable<InfoBox[]> {
        return this.http.get<InfoBox[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/info-boxes/maintenance/${maintenanceCategoryGuid}`);
    }

    getTenantMaintenceCategoryInfoBoxes(maintenanceCategoryGuid: string): Observable<InfoBox[]> {
        return this.http.get<InfoBox[]>(`${this.configService.baseUrl}/my-home/info-boxes/maintenance/${maintenanceCategoryGuid}`);
    }

    getOrganisationMaintenceCategoryInfoBoxes(organisationReference: string, maintenanceCategoryGuid: string): Observable<InfoBox[]> {
        return this.http.get<InfoBox[]>(`${this.configService.baseUrl}/organisation/${organisationReference}/maintenance/info-boxes/${maintenanceCategoryGuid}`);
    }

    add(organisationReference: string, model: InfoBoxPost): Observable<HttpEvent<InfoBox>> {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/info-boxes`;

        return this.fileService.uploadFiles(model.files)
            .pipe(map(_ => model.files = this.fileService.prepareFilePosts(model.files)),
                mergeMap(_ => this.http.post<InfoBox>(url, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    update(organisationReference: string, guid: string, model: JsonPatch[]): Observable<HttpEvent<InfoBox>> {
        return this.http.patch<InfoBox>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/info-boxes/${guid}`, model, {
            reportProgress: true, observe: 'events'
        });
    }

    remove(organisationReference: string, guid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/info-boxes/${guid}`);
    }

    showHide(organisationReference: string, propertyReference: string, roomReference: string, guid: string, show: boolean): Observable<InfoBox> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/info-boxes/`;

        if (propertyReference) {
            url += `property/${propertyReference}/`;
        }

        if (roomReference) {
            url += `room/${roomReference}/`;
        }

        url += `${guid}/`;

        if (show) {
            url += `show`;
        } else {
            url += `hide`;
        }

        return this.http.post<InfoBox>(url, null);
    }
}
