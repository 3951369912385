import {Injectable} from '@angular/core';
import {LayoutType} from '../models/layout-type/layout-type';
import {BehaviorSubject, Observable} from 'rxjs';
import {OrganisationNavigationLayoutTypes} from "../models/layout-type/organisation-navigation-layout-types";
import { NavigationLevels } from "../pages/manage/components/organisation-navigation/navigation-levels";

@Injectable()
export class LayoutService {

    private _layout = new BehaviorSubject<LayoutType>(LayoutType.EMPTY);
    layout = this._layout.asObservable();


    readonly ORGANISATION_NAVIGATION_LAYOUT_TYPE = 'ORGANISATION_NAVIGATION_LAYOUT_TYPE';
    readonly ORGANISATION_NAVIGATION_LAYOUT_MINIMISED = 'ORGANISATION_NAVIGATION_LAYOUT_MINIMISED';
    readonly ORGANISATION_NAVIGATION_LAYOUT_WIDTH_LARGE = 'ORGANISATION_NAVIGATION_LAYOUT_WIDTH_LARGE';
    readonly ORGANISATION_NAVIGATION_LAYOUT_WIDTH_MEDIUM = 'ORGANISATION_NAVIGATION_LAYOUT_WIDTH_MEDIUM';
    readonly ORGANISATION_MANAGE_FOCUS_MODE = 'ORGANISATION_MANAGE_FOCUS_MODE';
    readonly ORGANISATION_NAVIGATION_ELEMENT = 'ORGANISATION_NAVIGATION_ELEMENT';

    private currentOrganisationNavigationDisplayTypeSubject = new BehaviorSubject<OrganisationNavigationLayoutTypes>(null);
    private currentOrganisationNavigationDisplayMinimisedSubject = new BehaviorSubject<boolean>(null);
    private currentOrganisationNavigationDisplayWidthLargeSubject = new BehaviorSubject<number>(null);
    private currentOrganisationNavigationDisplayWidthMediumSubject = new BehaviorSubject<number>(null);
    private currentOrganisationFocusModeSubject = new BehaviorSubject<boolean>(false);
    private selectedElementSubject = new BehaviorSubject<string | null>(this.getInitialSelectedElement());
    private panelOpenState = new BehaviorSubject<boolean>(false);

    OrganisationNavigationLayoutTypes = OrganisationNavigationLayoutTypes;

    constructor() {
        this.currentOrganisationFocusModeSubject = new BehaviorSubject<boolean>(this.getOrganisationManageFocusMode());
        this.currentOrganisationNavigationDisplayMinimisedSubject = new BehaviorSubject<boolean>(this.getOrganisationManageNavigationLayoutMinimised());
    }

    setLayout(layoutType: LayoutType) {
        this._layout.next(layoutType);
    }

    getOrganisationNavigationLayoutType() {
        const typeString = localStorage.getItem(this.ORGANISATION_NAVIGATION_LAYOUT_TYPE);

        let type = OrganisationNavigationLayoutTypes.RIGHT;

        if (typeString != null) {
            type = parseInt(typeString);

            if (this.currentOrganisationNavigationDisplayTypeSubject?.value == null) {
                this.currentOrganisationNavigationDisplayTypeSubject.next(type);
            }
        }
        else {
            localStorage.setItem(this.ORGANISATION_NAVIGATION_LAYOUT_TYPE, type.toString());
        }

        return type;
    }

    setOrganisationNavigationLayoutType(type: OrganisationNavigationLayoutTypes) {
        localStorage.setItem(this.ORGANISATION_NAVIGATION_LAYOUT_TYPE, type.toString());
        this.currentOrganisationNavigationDisplayTypeSubject.next(type);
    }

    watchOrganisationNavigationLayoutType(): Observable<OrganisationNavigationLayoutTypes> {
        return this.currentOrganisationNavigationDisplayTypeSubject;
    }

    getOrganisationManageNavigationLayoutMinimised() {
        const minimisedString = localStorage.getItem(this.ORGANISATION_NAVIGATION_LAYOUT_MINIMISED);

        let minimised: boolean = false;

        if (minimisedString != null) {
            minimised = JSON.parse(minimisedString);

            if (this.currentOrganisationNavigationDisplayMinimisedSubject?.value == null) {
                this.currentOrganisationNavigationDisplayMinimisedSubject.next(minimised);
            }
        }
        else {
            localStorage.setItem(this.ORGANISATION_NAVIGATION_LAYOUT_MINIMISED, minimised.toString());
        }

        return minimised;
    }

    setOrganisationNavigationLayoutMinimised(minimised: boolean) {
        localStorage.setItem(this.ORGANISATION_NAVIGATION_LAYOUT_MINIMISED, minimised.toString());
        this.currentOrganisationNavigationDisplayMinimisedSubject.next(minimised);
    }

    watchOrganisationNavigationLayoutMinimised(): Observable<boolean> {
        return this.currentOrganisationNavigationDisplayMinimisedSubject;
    }

    getOrganisationManageFocusMode() {
        const focusModeString = localStorage.getItem(this.ORGANISATION_MANAGE_FOCUS_MODE);

        let focusMode = false;

        if (focusModeString != null) {
            focusMode = focusModeString == 'true';
        }
        else {
            localStorage.setItem(this.ORGANISATION_MANAGE_FOCUS_MODE, focusMode.toString());
        }

        return focusMode;
    }

    setOrganisationManageFocusMode(focusMode: boolean) {
        localStorage.setItem(this.ORGANISATION_MANAGE_FOCUS_MODE, focusMode.toString());
        this.currentOrganisationFocusModeSubject.next(focusMode);
    }

    watchOrganisationManageFocusMode(): Observable<boolean> {
        return this.currentOrganisationFocusModeSubject;
    }

    getOrganisationNavigationLayoutWidth(large: boolean): number {
        let width = "0";

        if (large) {
            width = localStorage.getItem(this.ORGANISATION_NAVIGATION_LAYOUT_WIDTH_LARGE);
        }
        else {
            width =  localStorage.getItem(this.ORGANISATION_NAVIGATION_LAYOUT_WIDTH_MEDIUM);
        }


        if (width != null) {

            if (large) {
                if (this.currentOrganisationNavigationDisplayWidthLargeSubject?.value == null) {
                    this.currentOrganisationNavigationDisplayWidthLargeSubject.next(+width);
                }
            }
            else {
                if (this.currentOrganisationNavigationDisplayWidthMediumSubject?.value == null) {
                    this.currentOrganisationNavigationDisplayWidthMediumSubject.next(+width);
                }
            }

        }
        else {

            if (large) {
                localStorage.setItem(this.ORGANISATION_NAVIGATION_LAYOUT_WIDTH_LARGE, "50");
            }
            else {
                localStorage.setItem(this.ORGANISATION_NAVIGATION_LAYOUT_WIDTH_MEDIUM, "30");
            }
        }

        return +width;
    }

    setOrganisationNavigationLayoutWidth(large: boolean, width: number) {
        if (large) {
            localStorage.setItem(this.ORGANISATION_NAVIGATION_LAYOUT_WIDTH_LARGE, width.toString());
            this.currentOrganisationNavigationDisplayWidthLargeSubject.next(width);
        }
        else {
            localStorage.setItem(this.ORGANISATION_NAVIGATION_LAYOUT_WIDTH_MEDIUM, width.toString());
            this.currentOrganisationNavigationDisplayWidthMediumSubject.next(width);
        }
    }

    watchOrganisationNavigationLayoutWidthLarge(): Observable<number> {
        return this.currentOrganisationNavigationDisplayWidthLargeSubject;
    }

    watchOrganisationNavigationLayoutWidthMedium(): Observable<number> {
        return this.currentOrganisationNavigationDisplayWidthMediumSubject;
    }

    getPanelOpenState(): boolean {
        return this.panelOpenState.value;
    }

    setPanelOpenState(open: boolean) {
        this.panelOpenState.next(open);
    }

    watchPanelOpenState(): Observable<boolean> {
        return this.panelOpenState;
    }

    private getInitialSelectedElement(): string | null {
        return localStorage.getItem(this.ORGANISATION_NAVIGATION_ELEMENT);
    }

    getSelectedElement(): string | null {
        return this.selectedElementSubject.value;
    }

    setSelectedElement(level: NavigationLevels, element: string): void {
        // Normalize the value to avoid redundant prefixes
        const normalizedElement = typeof element === 'string' && element.startsWith(`${level}-`) ? element : `${level}-${element}`;
        localStorage.setItem(this.ORGANISATION_NAVIGATION_ELEMENT, normalizedElement);
        this.selectedElementSubject.next(normalizedElement);
    }

    getSelectedElementForLevel(level: string): string | null {
        const value = this.getSelectedElement();
        console.log(`getSelectedElementForLevel: ${value}`);
        if (value && value.startsWith(`${level}-`)) {
            return value.split(`${level}-`)[1];
        }
        return null;
    }

    watchSelectedElement(): Observable<string | null> {
        return this.selectedElementSubject.asObservable();
    }
}
