import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VabooPropertyInstruction } from '../models/vaboo/vaboo-property-instruction';

import { ConfigService } from './config.service';
import {VabooOrganisationSynchronisation} from "../models/vaboo/vaboo-organisation-synchronisation";



@Injectable()
export class VabooService  {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getPropertyInstructions(organisationReference: string) {
        return this.http.get<{instructions: VabooPropertyInstruction[]; synchronisation: VabooOrganisationSynchronisation; activeTenants: number; inactiveTenants:number;}>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/vaboo/properties-instructed`);
    }

    enableProperty(organisationReference: string, propertyReference: string){
        return this.http.post<VabooPropertyInstruction>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/vaboo/property/${propertyReference}/enable/true`, {});
    }

    disableProperty(organisationReference: string, propertyReference: string){
        return this.http.post<VabooPropertyInstruction>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/vaboo/property/${propertyReference}/enable/false`, {});
    }

    enableOrganisation(organisationReference: string){
        return this.http.post<VabooOrganisationSynchronisation>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/vaboo/enable/true`, {});
    }

    disableOrganisation(organisationReference: string){
        return this.http.post<VabooOrganisationSynchronisation>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/vaboo/enable/false`, {});
    }
}
