export class JsonPatch {
  public op: string;
  public path: string;
  public value: string;

  constructor(op: string, path: string, value?: any) {
    this.op = op;
    this.path = path;
    this.value = value;
  }
}
