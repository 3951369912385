import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";


@Pipe({name: 'escape', pure: true})
export class EscapePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(plainText: string) {
        var plainTextAsEncodedHtmlString = new Option(plainText).innerHTML;

        return this.sanitizer.sanitize(SecurityContext.HTML,this.sanitizer.bypassSecurityTrustHtml(plainTextAsEncodedHtmlString));
    }
}
