import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SiteFooterStaticComponent } from './site-footer-static.component';
import { SpriteImageModule } from '../sprite-image/sprite-image.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import {CopyrightTextComponent} from "../copyright-text/copyright-text.component";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SpriteImageModule,
        SvgIconModule,
        CopyrightTextComponent
    ],
  declarations: [
    SiteFooterStaticComponent
  ],
  exports: [
    SiteFooterStaticComponent
  ]
})

export class SiteFooterStaticModule { }
