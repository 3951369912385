import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {OrganisationUser} from '../models/organisation/organisation-user';
import {OrganisationUserPost} from '../models/organisation/organisation-user-post';
import {OrganisationUserPatch} from '../models/organisation/organisation-user-patch';
import {OrganisationUsersOverview} from '../models/organisation/organisation-users-overview';

import {ConfigService} from './config.service';
import {DataCacheSubject} from "../helpers/data-cache-subject";
import {DropdownOption} from "../components/dropdown/dropdown-option";
import {
    OrganisationPersonFilterDefaultPost,
    OrganisationPersonFilterPresets, Panels
} from "../models/organisation/organisation-person";
import {UserAssignment} from "../models/user/user-assignment";
import {Role} from "../models/authorisation/role";
import {ManageOrganisationService} from "./manage-organisation.service";
import {AccountService} from "./account.service";
import {UserActionAssignmentOverride} from "../models/user/user-action-assignment-override";
import {Guid} from "../models/guid/guid";
import {UserActionAssignmentOverridePostPatch} from "../models/user/user-action-assignment-override-post-patch";

@Injectable()
export class ManageOrganisationUsersService {

    private  _rolesCache : DataCacheSubject<Role[]> = null;
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private manageOrganisationService: ManageOrganisationService,
        private accountService: AccountService) {
    }

    getOverview(organisationReference: string): Observable<OrganisationUsersOverview> {
        return this.http.get<OrganisationUsersOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/overview`);
    }

    // Use cached version
    private getUsers(organisationReference: string): Observable <OrganisationUser[]> {
        return this.http.get<OrganisationUser[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users`);
    }

    add(organisationReference: string, organisationUser: OrganisationUserPost): Observable<OrganisationUser> {
        return this.http.post<OrganisationUser>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users`, organisationUser);
    }

    patch(organisationReference: string, guid: string, model: OrganisationUserPatch): Observable<OrganisationUser> {
        return this.http.patch<OrganisationUser>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/${guid}`, model);
    }

    delete(organisationReference: string, guid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/${guid}`);
    }

    getUserAssignments(organisationReference: string): Observable<UserAssignment> {
        return this.http.get<UserAssignment>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/user-assignments`);
    }

    setUserAssignments(organisationReference: string, model: UserAssignment): Observable<UserAssignment> {
        return this.http.post<UserAssignment>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/user-assignments`, model);
    }

    deleteUserActionOverride(organisationReference: string, guid: Guid): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/user-action-assignment-overrides/${guid}`);
    }

    getUserActionAssignmentOverrides(organisationReference: string): Observable<UserActionAssignmentOverride[]> {
        return this.http.get<UserActionAssignmentOverride[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/user-action-assignment-overrides`);
    }

    postUserActionAssignmentOverride(organisationReference: string, model: UserActionAssignmentOverridePostPatch): Observable<UserActionAssignmentOverride> {
        return this.http.post<UserActionAssignmentOverride>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/user-action-assignment-overrides`, model);
    }

    patchUserActionAssignmentOverride(organisationReference: string, actionAssignmentOverrideGuid: string, model: UserActionAssignmentOverridePostPatch): Observable<UserActionAssignmentOverride> {
        return this.http.patch<UserActionAssignmentOverride>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/user-action-assignment-overrides/${actionAssignmentOverrideGuid}`, model);
    }

    //----------------------------------------------------//
    // Cached variations of the calls
    //----------------------------------------------------//
    private  _usersCache : DataCacheSubject<OrganisationUser[]> = null;
    public getUsersCached(organisationReference: string): Observable <OrganisationUser[]> {
        if(organisationReference === undefined) organisationReference = this.manageOrganisationService.getOrganisationReference();
        const constraints = {'organisationReference': organisationReference};

        // Create the cache if it does not exist (this does not trigger the event yet)
        if (this._usersCache == null) this._usersCache = new DataCacheSubject<OrganisationUser[]>(x => {return this.getUsers(organisationReference)}, constraints);

        return this._usersCache.cached(constraints);
    }

    public clearUsersCache(): void {
        this._usersCache = null;
    }

    public getOrganisationPersonsDropdownCached(organisationReference: string, includePresets : boolean = false): Observable<DropdownOption[]> {
        const subject = new ReplaySubject<DropdownOption[]>();

        // Make our cached call and return a dropdown list
        this.getUsersCached(organisationReference).subscribe(
            users => {
                const dropdowns = users.map(m => new DropdownOption(m.forename + ' ' + m.surname + (m.currentUser ? ' (You)' : ''), m.guid, 'persons'));
                if (includePresets) {
                    dropdowns.unshift(new DropdownOption('Mine & Unassigned', OrganisationPersonFilterPresets.MINE_AND_UNASSIGNED, 'presets'));
                    dropdowns.unshift(new DropdownOption('Mine', OrganisationPersonFilterPresets.MINE, 'presets'));
                    dropdowns.unshift(new DropdownOption('Unassigned', OrganisationPersonFilterPresets.UNASSIGNED, 'presets'));
                    dropdowns.unshift(new DropdownOption('All', OrganisationPersonFilterPresets.ALL, 'presets'));
                }

                subject.next(dropdowns);
                subject.complete();
            },
            error => {
                subject.error(error)
                subject.complete();
            }
        );

        return subject;
    }

    public getAvailableRoles(organisationReference: string) : Observable<Role[]>
    {
        const constraints = {'organisationReference': organisationReference};
        if (this._rolesCache == null) {
            this._rolesCache = new DataCacheSubject<Role[]>(x => {
                return this.http.get<Role[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/available-roles`);
            }, constraints);
        }
        return this._rolesCache.cached(constraints);
    }

    getAllDefaultFilterValueFromLocalStorage(){
        const orgPersonGuid = this.accountService.getCurrentUserPersonReference()
        const storageKey = 'defaultOrgPersonFilter_' + orgPersonGuid;
        const storedData = localStorage.getItem(storageKey);
        return storedData ? JSON.parse(storedData) : {};
    }

    setDefaultFilterValueForPanelToLocalStorage(model: OrganisationPersonFilterDefaultPost){
        const orgPersonGuid = this.accountService.getCurrentUserPersonReference()
        const storageKey = 'defaultOrgPersonFilter_' + orgPersonGuid;
        let settings = this.getAllDefaultFilterValueFromLocalStorage();
        settings[model.panel] = { default: model.default };
        localStorage.setItem(storageKey, JSON.stringify(settings));
    }

    getDefaultFilterValueForPanelFromLocalStorage(panel: Panels): OrganisationPersonFilterPresets | null {
        const settings = this.getAllDefaultFilterValueFromLocalStorage();
        return settings[panel]?.default || null;
    }

    getDeletedUsers(organisationReference: string): Observable<OrganisationUser[]> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/users/deleted`;
        return this.http.get<OrganisationUser[]>(url);
    }

}

