import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { Article } from '../models/article/article';
import { UnreadArticles } from '../models/article/unread-articles';

@Injectable()
export class ArticleService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getArticles(page: number): Observable<Article[]> {
        let params = new HttpParams();
        if (page) {
            params = params.set('page', page.toString());
        }

        return this.http.get<Article[]>(`${ this.configService.baseUrl }/articles`, {params});
    }

    getArticlesByCategory(categoryReference: string, page: number): Observable<Article[]> {
        let params = new HttpParams();
        if (page) {
            params = params.set('page', page.toString());
        }

        return this.http.get<Article[]>(`${ this.configService.baseUrl }/articles/${ categoryReference }`, {params});
    }

    getArticle(categoryReference: string, reference: string): Observable<Article> {
        return this.http.get<Article>(`${ this.configService.baseUrl }/articles/${ categoryReference }/${ reference }`);
    }

    getUnreadAnnouncements(categoryReference: string) : Observable<UnreadArticles> {
        return this.http.get<UnreadArticles>(`${ this.configService.baseUrl }/articles/announcements/${ categoryReference }`);
    }

    markAnnouncementRead(categoryReference: string, reference: string) : Observable<UnreadArticles> {
        return this.http.post<UnreadArticles>(`${ this.configService.baseUrl }/articles/announcements/${ categoryReference }/${ reference }/read`, null);
    }
}
