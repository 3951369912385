import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MultilineTextboxComponent } from "./multiline-textbox.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode'}),
        FormsModule
    ],
    declarations: [
        MultilineTextboxComponent
    ],
    exports: [
        MultilineTextboxComponent
    ]
})

export class MultilineTextboxModule {
}
