<modal
    [type]="ModalTypes.ERROR"
    [closeOnBlur]="false"
    [callback]="callbackSubscriber"
    (onClose)="closeModal()">

    <ng-container modal-header>We're sorry, an error occured</ng-container>

    <ng-container modal-body>
        <p *ngIf="redirectedTo && modalOpen">
            We already redirected you
            <ng-container *ngIf="redirectedTo === RedirectionPages.MANAGE"> to the main manage page</ng-container>
            <ng-container *ngIf="redirectedTo === RedirectionPages.MESSAGE_CENTER"> to the message center page</ng-container>
            <ng-container *ngIf="redirectedTo === RedirectionPages.MY_HOME"> to the main my home page</ng-container>
            <ng-container *ngIf="redirectedTo === RedirectionPages.SUPPLIER_DASHBOARD"> to the dashboard page</ng-container>
            <ng-container *ngIf="redirectedTo === RedirectionPages.LOGIN"> to the login page</ng-container>
            so you can safely close this.
        </p>
        <p>{{ redirectedTo ? 'Otherwise, you' : 'You'}} can try refreshing the page if needed, or use the options below.</p>

        <form-message *ngIf="error?.error" [type]="FormMessageTypes.ERROR">{{error.error}}</form-message>
    </ng-container>

    <ng-container modal-footer *ngIf="!redirectedTo && modalOpen">
        <div class="button__group">
            <div class="button__group-item">
                <loader-button [cssClasses]="'button--secondary'" (click)="refresh()">Refresh</loader-button>
            </div>
            <div class="button__group-item"
                 *ngIf="currentUser && (currentUser.isManager ||
                        currentUser.isTenant ||
                        currentUser.isOnboarding ||
                        (currentUser.isViewing && currentUser.unclaimedAccount === false))">
                <loader-button [cssClasses]="'button--secondary'" (click)="goToMain()">Go to main page</loader-button>
            </div>
            <div class="button__group-item">
                <loader-button [cssClasses]="'button--ghost'" (click)="logout()">Logout</loader-button>
            </div>
        </div>
    </ng-container>
</modal>
