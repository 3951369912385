import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { JsonPatch } from '../models/json-patch/json-patch';
import { RoomDetails } from '../models/manage-room/room-details';
import { ManageRoomOverview } from '../models/manage-room/manage-room-overview';
import { InviteTenantPost } from '../models/manage-property/invite-tenant-post';
import { TenantOverview } from '../models/tenancy/tenant-overview';
import { AddRoom } from '../models/manage/add-room';
import { TenancySummary } from '../models/tenancy/tenancy-summary';
import { TenantPatch } from '../models/tenancy/tenant-patch';
import { RentPaymentDayPatch } from '../models/tenancy/rent-payment-day-patch';
import { TenancyPatch } from '../models/tenancy/tenancy-patch';
import { RoomSummary } from '../models/manage-room/room-summary';
import { TenancyInvitePost } from '../models/tenancy/tenancy-invite-post';
import { RoomAvailabilityPost } from '../models/manage-room/room-availability-post';
import { Image } from '../models/image/image';
import { TenancyPost } from '../models/tenancy/tenancy-post';
import { RentChangePatch } from '../models/tenancy/rent-change-patch';
import { FinanceTenancy } from '../models/accounting/finance-tenancy';
import {GuarantorDetailPost} from "../models/tenancy/guarantor-detail-post";
import {GuarantorDetail} from "../models/tenancy/guarantor-detail";
import {TenantPost} from "../models/tenancy/tenant-post";
import {RemoveTenantPost} from "../models/tenancy/remove-tenant-post";
import {TenantSummary} from "../models/tenancy/tenant-summary";
import {TenancyTenantTypes} from "../models/tenancy/tenancy-tenant-types";

@Injectable()
export class ManageRoomService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getManageRoomOverviewByMaintenanceGuid(maintenanceGuid: string): Observable<ManageRoomOverview> {
        return this.http.get<ManageRoomOverview>(`${ this.configService.baseUrl }/manage/room/overview/by-maintenance-guid/${ maintenanceGuid }`);
    }

    getManageRoomOverview(propertyReference: string, roomReference: string, tenancyGuid: string = null, tenancyTenantGuid: string = null): Observable<ManageRoomOverview> {
        let params = new HttpParams();
        if (tenancyGuid && tenancyGuid !== 'null') params = params.set('tenancyGuid', tenancyGuid);
        if (tenancyTenantGuid && tenancyTenantGuid !== 'null') params = params.set('tenancyTenantGuid', tenancyTenantGuid);

        return this.http.get<ManageRoomOverview>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/overview`, {params});
    }

    patchRoom(propertyReference: string, roomReference: string, body: JsonPatch[]): Observable<HttpEvent<RoomDetails>> {
        return this.http.patch<RoomDetails>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }`, body, {
            reportProgress: true, observe: 'events'
        });
    }

    postTenancy(organisationReference: string, propertyReference: string, roomReference: string, model: TenancyPost): Observable<TenancySummary> {
        return this.http.post<TenancySummary>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy`, model);
    }

    inviteTenantToCOHO(propertyReference: string, roomReference: string, guid: string, model: InviteTenantPost): Observable<TenantOverview> {
        return this.http.post<TenantOverview>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/invite-tenant`, model);
    }

    postRoomAvailableFrom(propertyReference: string, roomReference: string, model: RoomAvailabilityPost): Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/room-availability`, model);
    }

    inviteToTenancy(propertyReference: string, roomReference: string, model: TenancyInvitePost): Observable<TenancySummary> {
        return this.http.post<TenancySummary>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/invite-to-tenancy`, model);
    }

    //archiveAction(propertyReference: string, roomReference: string, actionReference: string): Observable<boolean> {
    //    return this.http.post<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/actions/${actionReference}/archive`, null);
    //}

    postRoom(propertyReference: string, body: AddRoom): Observable<RoomDetails> {
        return this.http.post<RoomDetails>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room`, body);
    }

    postTenant(organisationReference, propertyReference: string, roomReference: string, tenancyGuid: string, body: TenantPost): Observable<TenancySummary> {
        return this.http.post<TenancySummary>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/property/${ propertyReference }/room/${roomReference}/tenancy/${tenancyGuid}/add-tenant-to-tenancy`, body);
    }
    getTenantOverview(propertyReference: string, roomReference: string): Observable<TenantOverview> {
        return this.http.get<TenantOverview>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/tenant-overview`);
    }

    getTenancySummary(propertyReference: string, roomReference: string, tenancyGuid: string = null): Observable<TenancySummary> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.get<TenancySummary>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/tenancy/summary`, {params});
    }

    getPastTenancies(propertyReference: string, roomReference: string): Observable<TenancySummary[]> {
        return this.http.get<TenancySummary[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/past-tenancies`);
    }

    updateTenantDetails(propertyReference: string, roomReference: string, guid: string, model: TenantPatch): Observable<TenantOverview> {
        return this.http.patch<TenantOverview>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/tenant/${ guid }`, model);
    }

    updateTenantConvert(organisationReference: string, propertyReference: string, roomReference: string, guid: string, type: TenancyTenantTypes): Observable<TenantSummary> {
        let params = new HttpParams();
        if (type) {
            params = params.set('type', type);
        }

        return this.http.patch<TenantSummary>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenant/${ guid }/convert`, null, {params});
    }

    updateGuarantorDetails(propertyReference: string, roomReference: string, guid: string, model: GuarantorDetailPost) {
        const url = `${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/tenancy/${ guid }/guarantor`;
        return this.http.patch<GuarantorDetail>(url, model);
    }

    deleteGuarantorDetails(propertyReference: string, roomReference: string, guid: string) {
        const url = `${ this.configService.baseUrl }/manage/property/${propertyReference}/room/${roomReference}/tenancy/${guid}/guarantor`
        return this.http.delete<boolean>(url);
    }

    updateTenancyDetails(propertyReference: string, roomReference: string, guid: string, model: TenancyPatch): Observable<TenancySummary> {
        return this.http.patch<TenancySummary>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/tenancy/${ guid }`, model);
    }

    updateTenancyRentDay(propertyReference: string, roomReference: string, guid: string, model: RentPaymentDayPatch): Observable<TenancySummary> {
        return this.http.patch<TenancySummary>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/tenancy/${ guid }/rent-payment-day`, model);
    }

    updateTenancyRentCollectionTrackingFromDate(organisationReference: string, propertyReference: string, roomReference: string, guid: string, newTrackingFromDate: string): Observable<boolean> {
        let params = new HttpParams();
        if (newTrackingFromDate) {
            params = params.set('newTrackingFromDate', newTrackingFromDate);
        }

        return this.http.patch<boolean>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/property/${ propertyReference }/room/${ roomReference }/tenancy/${ guid }/rent-collection-tracking-from`, null, {params});
    }

    updateTenancyRentAmount(organisationReference: string, propertyReference: string, roomReference: string, guid: string, model: RentChangePatch): Observable<TenancySummary> {
        return this.http.patch<TenancySummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${guid}/rent-change`, model);
    }

    removeTenancyRentChange(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<TenancySummary> {
        return this.http.patch<TenancySummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${guid}/remove-rent-change`, {});
    }

    deactivateRoom(propertyReference: string, roomReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/deactivate`, null);
    }

    activateRoom(propertyReference: string, roomReference: string, manuallyAccept: boolean, alwaysAccept: boolean = false): Observable<RoomSummary> {
        let params = new HttpParams();
        if (manuallyAccept) {
            params = params.set('manuallyAccept', 'true');
        }
        if (alwaysAccept) {
            params = params.set('alwaysAccept', 'true');
        }

        return this.http.post<RoomSummary>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/activate`, null, {params});
    }

    deleteRoom(propertyReference: string, roomReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/delete`, null);
    }

    getImages(propertyReference: string, roomReference: string): Observable<Image[]> {
        return this.http.get<Image[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/images`);
    }

    tenancies(propertyReference: string, roomReference: string, includePast: boolean = false): Observable<TenancySummary[]> {
        let params = new HttpParams();
        if (includePast) {
            params = params.set('includePast', 'true');
        }

        return this.http.get<TenancySummary[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/tenancies`, {params});
    }

    financeTenancies(propertyReference: string, roomReference: string): Observable<FinanceTenancy[]> {
        return this.http.get<FinanceTenancy[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/finance-tenancies` );
    }

    endTenancy(propertyReference: string, roomReference: string, model: RemoveTenantPost, tenancyGuid): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/end-tenancy`, model);
    }

    restoreTenancy(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string = null): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/restore-tenancy`, null);
    }

    deleteTenancy(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string = null): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/delete-tenancy`, null);
    }

    getRoomSummary(organisationReference: string, propertyReference: string, roomReference: string,){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}`
        return this.http.get<RoomSummary>(url)
    }

    disconnectUserFromTenancyTenant(organisationReference: string, tenancyGuid: string, tenancyTenantGuid: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancies/${tenancyGuid}/tenancy-tenants/${tenancyTenantGuid}/disconnect`;
        return this.http.post(url, null);
    }
}

