import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PagedResponse } from './paged-response';
import { HttpResponse } from '@angular/common/http';

export function toPagedResponse<T>() {
    return <T>(source: Observable<HttpResponse<T>>) => {
        return source.pipe(
            map((value: HttpResponse<T>) => {
                value.headers.keys();
                const response = new PagedResponse<T>();

                response.page = parseInt(value.headers.get('x-pagination-page'));
                response.pageCount = parseInt(value.headers.get('x-pagination-page-count'));
                response.pageSize = parseInt(value.headers.get('x-pagination-page-size'));
                response.rowCount = parseInt(value.headers.get('x-pagination-row-count'));
                response.totalRowCount = parseInt(value.headers.get('x-pagination-total-row-count'));
                response.results = value.body;

                return response;
            }));
    };
}

/*
x-pagination-page: 1
x-pagination-page-count: 3
x-pagination-page-size: 5
x-pagination-row-count: 5
x-pagination-total-row-count: 14
*/
