import {mergeMap, Observable} from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { ConfigService } from "./config.service";
import {BaseEntityStatuses} from "../models/base-entity-statuses";
import {ActionProgressionTemplatePost} from "../models/action-progression/action-progression-template-post";
import {ActionProgressionTemplate} from "../models/action-progression/action-progression-template";
import {ActionProgressionStepPostPatch} from "../models/action-progression/action-progression-step-post-patch";
import {ActionProgressionSummary} from "../models/action-progression/action-progression-summary";
import {ActionProgressionTemplatePatch} from "../models/action-progression/action-progression-template-patch";
import { ActionTypes } from "../models/action/action-types";
import {ActionSnoozePostDateTimeOnly} from "../models/action/action-snooze-post";
import {tap} from "rxjs/operators";
import {FileService} from "./file.service";

@Injectable()
export class ActionService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    dismissRecommendation(actionReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/recommendation/${actionReference}/dismiss`, null);
    }

    // Region - Progression Templates
    getActionProgressionTemplates(organisationReference: string, showOnlyArchived: boolean = false) {
        let params = new HttpParams();
        params = params.set('showOnlyArchived', showOnlyArchived ? "true" : "false");
        return this.http.get<ActionProgressionTemplate[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/progression-templates`, { params: params });
    }

    getActionProgressionTemplatesAsSelectables(organisationReference: string, limitToActionType: ActionTypes = null) {
        let params = new HttpParams();
        if (limitToActionType) params = params.set('limitToActionType', limitToActionType.toString());
        return this.http.get<{
            templatesLookup: {[p: string]: string},
            defaultTemplate: string | null,
            mandatoryTemplate: string | null,
        }>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/progression-templates/as-selectables`, { params: params });
    }

    getActionProgressionTemplatesForAction(organisationReference: string, actionReference: string) {
        let params = new HttpParams();
        params = params.set('actionReference', actionReference);
        return this.http.get<ActionProgressionTemplate[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/progression-templates`, { params: params });
    }

    addActionProgressionTemplate(organisationReference: string, model: ActionProgressionTemplatePost) {
        return this.fileService.uploadFiles(model.instructionFiles)
            .pipe(tap(_ => model.instructionFiles = this.fileService.prepareFilePosts(model.instructionFiles)),
                mergeMap(_ => this.http.post<ActionProgressionTemplate>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/progression-template`, model)));
    }

    editActionProgressionTemplate(organisationReference: string, model: ActionProgressionTemplatePatch) {
        return this.fileService.uploadFiles(model.instructionFiles)
            .pipe(tap(_ => model.instructionFiles = this.fileService.prepareFilePosts(model.instructionFiles)),
                mergeMap(_ => this.http.patch<ActionProgressionTemplate>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/progression-template/${ model.guid }`, model)));
    }

    deleteActionProgressionTemplate(organisationReference: string, templateGuid: string) {
        return this.http.post<true>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/progression-template/${templateGuid}/set-entity-status/${BaseEntityStatuses.DELETED}`, {});
    }

    archiveActionProgressionTemplate(organisationReference: string, templateGuid: string) {
        return this.http.post<true>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/progression-template/${templateGuid}/set-entity-status/${BaseEntityStatuses.ACTIVE_AND_ARCHIVED}`, {});
    }

    unarchiveActionProgressionTemplate(organisationReference: string, templateGuid: string) {
        return this.http.post<true>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/progression-template/${templateGuid}/set-entity-status/${BaseEntityStatuses.ACTIVE_NOT_ARCHIVED}`, {});
    }

    applyActionProgressionTemplate(organisationReference: string, actionReference: string, templateGuid: string) {
        return this.http.post<ActionProgressionSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/progression-template/${templateGuid}/apply-to-action/${actionReference}`, {});
    }


    // Region - Progressions
    getActionProgression(organisationReference: string, actionReference: string) {
        return this.http.get<ActionProgressionSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/${actionReference}/get-progression`, {});
    }

    addActionProgressionStep(organisationReference: string, actionReference: string, model: ActionProgressionStepPostPatch) {
        return this.http.post<ActionProgressionSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/${actionReference}/progression/add-step`, model);
    }

    unassignActionProgressionStep(organisationReference: string, actionReference: string, stepGuid: string) {
        return this.http.post<ActionProgressionSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/${actionReference}/progression/${stepGuid}/unassign-step/from-user`, {});
    }

    assignActionProgressionStep(organisationReference: string, actionReference: string, stepGuid: string, organisationPersonGuid: string) {
        return this.http.post<ActionProgressionSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/${actionReference}/progression/${stepGuid}/assign-step/to-user/${organisationPersonGuid}`, {});
    }

    completeActionProgressionStep(organisationReference: string, actionReference: string, stepGuid: string) {
        return this.http.post<ActionProgressionSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/${actionReference}/progression/${stepGuid}/complete-step`, {});
    }

    uncompleteActionProgressionStep(organisationReference: string, actionReference: string, stepGuid: string) {
        return this.http.post<ActionProgressionSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/${actionReference}/progression/${stepGuid}/uncomplete-step`, {});
    }


    deleteActionProgressionStep(organisationReference: string, actionReference: string, stepGuid: string) {
        return this.http.delete<ActionProgressionSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/action/${actionReference}/progression/${stepGuid}/delete-step`, {});
    }

    snoozeAction(organisationReference: string, actionReference: string, model: ActionSnoozePostDateTimeOnly) {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/${actionReference}/snooze`
        return this.http.patch(url, model);
    }

    unSnoozeAction(organisationReference: string, actionReference: string) {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/${actionReference}/un-snooze`
        return this.http.patch(url, null);
    }
}
