import {HttpClient, HttpEvent, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { OnboardingPost } from '../models/onboarding/onboarding-post';
import { Onboarding } from '../models/onboarding/onboarding';

import { ConfigService } from './config.service';
import { OnboardingSummary } from '../models/onboarding/onboarding-summary';
import { OnboardingOverview } from '../models/onboarding/onboarding-overview';
import { OnboardingPatch } from '../models/onboarding/onboarding-patch';
import { InviteTenantPost } from '../models/manage-property/invite-tenant-post';
import {PropertyFilterPresets} from "../models/manage-property/property-details";
import {OrganisationPersonFilterPresets} from "../models/organisation/organisation-person";
import {AccountService} from "./account.service";
import {TenantPost} from "../models/tenancy/tenant-post";
import {FileService} from "./file.service";
import {OnboardingCancelPost} from "../models/onboarding/onboarding-cancel-post";
import {mergeMap, tap} from "rxjs/operators";
import {OnboardingHoldingFeePost} from "../models/onboarding/onboarding-holding-fee-post";
import {OnboardingApplicationFormPost} from "../models/onboarding/onboarding-application-form-post";
import {
    OnboardingApplicationFormReviewFeedback
} from "../models/onboarding/onboarding-application-form-review-feedback.interface";
import {OnboardingApplicationFormCompleted} from "../models/onboarding/onboarding-application-form-completed.interface";
import {OnboardingPatchFollowUpDateInterface} from "../models/onboarding/onboarding-patch-follow-up-date.interface";
import {OnboardingPatchMovingDetails} from "../models/onboarding/onboarding-patch-move-in-details.interface";
import {OnboardingAssignmentPatch} from "../models/onboarding/onboarding-assignment-patch";
import {ApplicationFormSettingsPatchModel} from "../models/application-form/application-form-settings-patch-model";

@Injectable()
export class OnboardingService {

    indicateHoldingFeeMarkPaid = new BehaviorSubject<boolean>(false);
    indicateHoldingFeeMarkReceived = new BehaviorSubject<boolean>(false);
    indicateHoldingFeeSetHoldingFee = new BehaviorSubject<boolean>(false);
    indicateApplicationFormSendApplicationForm = new BehaviorSubject<boolean>(false);
    indicateApplicationFormCompleteApplicationForm = new BehaviorSubject<boolean>(false);
    indicateApplicationFormReviewApplicationForm = new BehaviorSubject<boolean>(false);
    indicateBeginApplicantReferencing = new BehaviorSubject<boolean>(false);
    indicateBeginGuarantorReferencing = new BehaviorSubject<boolean>(false);
    indicateProvideApplicantReferencingResults = new BehaviorSubject<boolean>(false);
    indicateProvideGuarantorReferencingResults = new BehaviorSubject<boolean>(false);
    indicateSkipReferencing = new BehaviorSubject<boolean>(false);
    indicateSkipGuarantor = new BehaviorSubject<boolean>(false);
    indicateProvideGuarantorReferencingInformation = new BehaviorSubject<boolean>(false);
    indicateMoniesMarkPaid = new BehaviorSubject<boolean>(false);
    indicateMoniesMarkReceived = new BehaviorSubject<boolean>(false);
    indicateMoniesSetMonies = new BehaviorSubject<boolean>(false);

    indicateGenerateRentSchedule = new BehaviorSubject<boolean>(false);

    indicateCreateTenancyAgreement = new BehaviorSubject<boolean>(false);

    indicateSignTenancyAgreement = new BehaviorSubject<boolean>(false);

    indicateApproveTenancyAgreement = new BehaviorSubject<boolean>(false);

    indicateFinishOnboarding = new BehaviorSubject<boolean>(false);
    indicateSetFollowUpDate = new BehaviorSubject<boolean>(false);
    indicateSetMoveInDayDetails = new BehaviorSubject<boolean>(false);

    onChangeTenancyDetails = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private configService: ConfigService, private accountService: AccountService, private fileService: FileService) {
    }

    getOverview(organisationReference: string, propertyReferences: string[] | null = null, organisationPersonReferences: string[] | null = null, includeUnassigned: boolean = true): Observable<OnboardingOverview> {
        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        if (includeUnassigned) params = params.append('includeUnassigned', true);
        if (organisationPersonReferences) {
            if (organisationPersonReferences.length) organisationPersonReferences.forEach(reference => params = params.append('organisationPersons', reference));
            else params = params.append('organisationPersons', OrganisationPersonFilterPresets.NONE);
        }

        return this.http.get<OnboardingOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/onboarding/overview`, { params: params });
    }

    getOverviewAsCsvUrl(organisationReference: string, propertyReferences: string[] | null = null, organisationPersonReferences: string[] | null = null, includeUnassigned: boolean = true): string {
        let params = new HttpParams();


        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        if (includeUnassigned) params = params.append('includeUnassigned', true);
        if (organisationPersonReferences) {
            if (organisationPersonReferences.length) organisationPersonReferences.forEach(reference => params = params.append('organisationPersons', reference));
            else params = params.append('organisationPersons', OrganisationPersonFilterPresets.NONE);
        }

        params = params.set('access_token', this.accountService.accessToken.bearerToken);

        return `${this.configService.baseUrl}/manage/organisation/${organisationReference}/onboarding/overview/as-csv/true?${params.toString()}`
    }

    getSummary(organisationReference: string, propertyReferences: string[] | null = null): Observable<OnboardingSummary> {
        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        return this.http.get<OnboardingSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/onboarding/summary`, { params: params });
    }

    getOnboarding(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<Onboarding> {
        return this.http.get<Onboarding>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/onboarding/${guid}`);
    }

    getOnboardingByGuid(guid: string): Observable<Onboarding> {
        return this.http.get<Onboarding>(`${this.configService.baseUrl}/manage/onboarding/onboarding/${guid}`);
    }

    cancelOnboarding(organisationReference: string, model: OnboardingCancelPost): Observable<Onboarding> {
        return this.http.post<Onboarding>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/cancel-onboarding`, model);
    }

    restoreOnboarding(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<Onboarding> {
        return this.http.post<Onboarding>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/onboarding/${guid}/restore`, null);
    }
    getMyOnboarding(guid: string): Observable<Onboarding> {
        return this.http.get<Onboarding>(`${this.configService.baseUrl}/my-home/onboarding/${guid}`);
    }

    add(organisationReference: string, propertyReference: string, roomReference: string, model: OnboardingPost): Observable<HttpEvent<Onboarding>> {
        let obs = of([]);
        if (model.onboardingApplicationForm?.newApplicationForm?.files) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.onboardingApplicationForm.newApplicationForm.files)),
                tap(_ => model.onboardingApplicationForm.newApplicationForm.files = this.fileService.prepareFilePosts(model.onboardingApplicationForm.newApplicationForm.files)));
        }
        if (model.onboardingHoldingFee?.newOrganisationAdditionalTerms?.files) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.onboardingHoldingFee.newOrganisationAdditionalTerms.files)),
                tap(_ => model.onboardingHoldingFee.newOrganisationAdditionalTerms.files = this.fileService.prepareFilePosts(model.onboardingHoldingFee.newOrganisationAdditionalTerms.files)));
        }

        return obs.pipe(mergeMap(_ => this.http.post<Onboarding>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/onboarding`, model, {
            reportProgress: true, observe: 'events'
        })));
    }

    applicantPatchOnboarding(guid: string, model: OnboardingPatch) {
        let obs = of([]);

        if (model.onboardingReferencingApplicationFiles) {
            obs = obs.pipe(mergeMap(_ => this.fileService.uploadFiles(model.onboardingReferencingApplicationFiles)),
                tap(_ => model.onboardingReferencingApplicationFiles = this.fileService.prepareFilePosts(model.onboardingReferencingApplicationFiles)));
        }

        const url = `${this.configService.baseUrl}/my-home/onboarding/${guid}`

        return obs.pipe(mergeMap(_ => this.http.patch<Onboarding>(url, model)));
    }

    managerPatchOnboarding(organisationReference: string, propertyReference: string, roomReference: string, guid: string, model: OnboardingPatch): Observable<HttpEvent<Onboarding>> {
        let obs = of([]);
        if (model.onboardingReferencingApplicantResultFiles) {
            obs = obs.pipe(mergeMap(_ => this.fileService.uploadFiles(model.onboardingReferencingApplicantResultFiles)),
                tap(_ => model.onboardingReferencingApplicantResultFiles = this.fileService.prepareFilePosts(model.onboardingReferencingApplicantResultFiles)));
        }

        if (model.onboardingReferencingGuarantorResultFiles) {
            obs = obs.pipe(mergeMap(_ => this.fileService.uploadFiles(model.onboardingReferencingGuarantorResultFiles)),
                tap(_ => model.onboardingReferencingGuarantorResultFiles = this.fileService.prepareFilePosts(model.onboardingReferencingGuarantorResultFiles)));
        }

        return obs
            .pipe(mergeMap(_ => this.http.patch<Onboarding>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/onboarding/${guid}`, model, {
            reportProgress: true, observe: 'events'
        })));
    }

    managerPatchTenant(organisationReference: string, propertyReference: string, roomReference: string, guid: string, model: TenantPost): Observable<Onboarding> {
        return this.http.patch<Onboarding>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/onboarding/${guid}/patch-tenant`, model);
    }

    createOnboardingReferencing(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<Onboarding> {
        return this.http.post<Onboarding>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/onboarding/${guid}/referencing`, null);
    }

    createOnboardingTenancyAgreement(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<Onboarding> {
        return this.http.post<Onboarding>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/onboarding/${guid}/tenancy-agreement`, null);
    }

    createOnboardingPayMoney(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<Onboarding> {
        return this.http.post<Onboarding>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/onboarding/${guid}/pay-money`, null);
    }

    createOnboardingFollowUp(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<Onboarding> {
        return this.http.post<Onboarding>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/onboarding/${guid}/follow-up`, null);
    }

    inviteTenantToCOHO(guid: string, model: InviteTenantPost) {
        return this.http.patch(`${this.configService.baseUrl}/manage/onboardings/${guid}/resend-invite`, model);
    }

    watchIndicateHoldingFeeMarkPaid(): Observable<boolean> {
        return this.indicateHoldingFeeMarkPaid.asObservable();
    }

    watchIndicateHoldingFeeMarkReceived(): Observable<boolean> {
        return this.indicateHoldingFeeMarkReceived.asObservable();
    }

    watchIndicateHoldingFeeSetHoldingFee(): Observable<boolean> {
        return this.indicateHoldingFeeSetHoldingFee.asObservable();
    }

    watchIndicateApplicationFormSendApplicationForm(): Observable<boolean> {
        return this.indicateApplicationFormSendApplicationForm.asObservable();
    }

    watchIndicateApplicationFormCompleteApplicationForm(): Observable<boolean> {
        return this.indicateApplicationFormCompleteApplicationForm.asObservable();
    }

    watchIndicateApplicationFormReviewApplicationForm(): Observable<boolean> {
        return this.indicateApplicationFormReviewApplicationForm.asObservable();
    }

    watchIndicateBeginApplicantReferencing(): Observable<boolean> {
        return this.indicateBeginApplicantReferencing.asObservable();
    }

    watchIndicateBeginGuarantorReferencing(): Observable<boolean> {
        return this.indicateBeginGuarantorReferencing.asObservable();
    }

    watchIndicateProvideApplicantReferencingResults(): Observable<boolean> {
        return this.indicateProvideApplicantReferencingResults.asObservable();
    }

    watchIndicateProvideGuarantorReferencingInformation(): Observable<boolean> {
        return this.indicateProvideGuarantorReferencingInformation.asObservable();
    }

    watchIndicateGenerateRentSchedule(): Observable<boolean> {
        return this.indicateGenerateRentSchedule.asObservable();
    }

    watchIndicateCreateTenancyAgreement(): Observable<boolean> {
        return this.indicateCreateTenancyAgreement.asObservable();
    }

    watchIndicateSignTenancyAgreement(): Observable<boolean> {
        return this.indicateSignTenancyAgreement.asObservable();
    }

    watchIndicateApproveTenancyAgreement(): Observable<boolean> {
        return this.indicateApproveTenancyAgreement.asObservable();
    }

    watchIndicateMoniesMarkPaid(): Observable<boolean> {
        return this.indicateMoniesMarkPaid.asObservable();
    }

    watchIndicateMoniesMarkReceived(): Observable<boolean> {
        return this.indicateMoniesMarkReceived.asObservable();
    }

    watchIndicateMoniesSetMonies(): Observable<boolean> {
        return this.indicateMoniesSetMonies.asObservable();
    }

    watchIndicateFinishOnboarding(): Observable<boolean> {
        return this.indicateFinishOnboarding.asObservable();
    }

    watchIndicateSetMoveInDayDetails(): Observable<boolean> {
        return this.indicateSetMoveInDayDetails.asObservable();
    }

    watchIndicateSetFollowUpDate(): Observable<boolean> {
        return this.indicateSetFollowUpDate.asObservable();
    }

    changeTenancyDetails() {
        this.onChangeTenancyDetails.next(true);
    }

    requireHoldingFeeStep(onboardingGuid: string, model: OnboardingHoldingFeePost){
        let obs = of([]);
        if (model.newOrganisationAdditionalTerms?.files) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.newOrganisationAdditionalTerms.files)),
                tap(_ => model.newOrganisationAdditionalTerms.files = this.fileService.prepareFilePosts(model.newOrganisationAdditionalTerms.files)));
        }

        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/holding-fee/require-step`
        return obs.pipe(mergeMap(_ => this.http.patch<Onboarding>(url, model)));
    }

    skipHoldingFeeStep(onboardingGuid: string){
        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/holding-fee/skip-step`
        return this.http.patch<Onboarding>(url, null);
    }

    undoHoldingFeePaymentDeclaration(onboardingGuid: string){
        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/holding-fee/undo-payment-declaration`
        return this.http.patch<Onboarding>(url, null);
    }

    keepHoldingFee(onboardingGuid: string){
        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/holding-fee/keep`
        return this.http.patch<Onboarding>(url, null);
    }

    returnHoldingFee(onboardingGuid: string){
        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/holding-fee/return`
        return this.http.patch<Onboarding>(url, null);
    }

    requireApplicationFormStep(onboardingGuid: string, model: OnboardingApplicationFormPost){
        let obs = of([]);

        if (model.newApplicationForm?.files) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.newApplicationForm.files)),
                tap(_ => model.newApplicationForm.files = this.fileService.prepareFilePosts(model.newApplicationForm.files)));
        }

        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/application-forms/require-step`
        return obs.pipe(mergeMap(_ => this.http.patch<Onboarding>(url, model)));
    }

    skipApplicationFormStep(onboardingGuid: string){
        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/application-forms/skip-step`
        return this.http.patch<Onboarding>(url, null);
    }

    completeApplicationFormStep(onboardingGuid: string){
        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/application-forms/complete-step`
        return this.http.patch<Onboarding>(url, null);
    }

    applicationFormCompleted(onboardingGuid: string, model: OnboardingApplicationFormCompleted) {
        let obs = of([]);

        if (model.completedApplicationForm.identification) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.identification)),
                tap(_ => model.completedApplicationForm.identification = this.fileService.prepareFilePosts(model.completedApplicationForm.identification)));
        }
        if (model.completedApplicationForm.bankStatements) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.bankStatements)),
                tap(_ => model.completedApplicationForm.bankStatements = this.fileService.prepareFilePosts(model.completedApplicationForm.bankStatements)));
        }
        if (model.completedApplicationForm.currentEmployerEmploymentContract) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.currentEmployerEmploymentContract)),
                tap(_ => model.completedApplicationForm.currentEmployerEmploymentContract = this.fileService.prepareFilePosts(model.completedApplicationForm.currentEmployerEmploymentContract)));
        }
        if (model.completedApplicationForm.currentEmployerCompanyAccounts) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.currentEmployerCompanyAccounts)),
                tap(_ => model.completedApplicationForm.currentEmployerCompanyAccounts = this.fileService.prepareFilePosts(model.completedApplicationForm.currentEmployerCompanyAccounts)));
        }
        if (model.completedApplicationForm.completedFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.completedFiles)),
                tap(_ => model.completedApplicationForm.completedFiles = this.fileService.prepareFilePosts(model.completedApplicationForm.completedFiles)));
        }
        if (model.completedApplicationForm.rightToRentFiles) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.rightToRentFiles)),
                tap(_ => model.completedApplicationForm.rightToRentFiles = this.fileService.prepareFilePosts(model.completedApplicationForm.rightToRentFiles)));
        }

        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/application-forms/completed`

        return obs.pipe(mergeMap(_ => this.http.patch<Onboarding>(url, model)));
    }

    applicationFormFeedback(onboardingGuid: string, model: OnboardingApplicationFormReviewFeedback){
        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/application-forms/feedback`
        return this.http.patch<Onboarding>(url, model);
    }

    patchFollowUpDate(onboardingGuid: string, model: OnboardingPatchFollowUpDateInterface){
        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/follow-up/date`
        return this.http.patch<Onboarding>(url, model);
    }

    patchMoveInDetails(onboardingGuid: string, model: OnboardingPatchMovingDetails){
        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/follow-up/move-in-details`
        return this.http.patch<Onboarding>(url, model);
    }

    createConversationIfMissing(organisationReference: string, onboardingGuid: string){
        const url = `${this.configService.baseUrl}/manage/${organisationReference}/onboardings/${onboardingGuid}/create-conversation`
        return this.http.post<Onboarding>(url, null);
    }

    assign(organisationReference: string, onboardingGuid: string, model: OnboardingAssignmentPatch){
        const url = `${this.configService.baseUrl}/manage/${organisationReference}/onboardings/${onboardingGuid}/assign`
        return this.http.patch<Onboarding>(url, model);
    }

    patchApplicationFormSettings(onboardingGuid: string, tenancyTenantGuid: string, model: ApplicationFormSettingsPatchModel){
        const url = `${this.configService.baseUrl}/manage/onboardings/${onboardingGuid}/application-forms/${tenancyTenantGuid}/settings`
        return this.http.patch(url, model);
    }
}
