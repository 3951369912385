import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import { ConfigService } from './config.service';
import { Conversation } from '../models/message/conversation';
import { ConversationContextTypes } from '../models/message/conversation-context-types';
import { Conversations } from '../models/message/conversations';
import { Message } from '../models/message/message';
import { MessagePost } from '../models/message/message-post';
import { PagedResponse } from '../models/http/paged-response';
import { toPagedResponse } from '../models/http/to-paged-response';
import { ConversationPostManager } from '../models/message/conversation-post-manager';
import { FileService } from "./file.service";
import {ConversationPostTenant} from "../models/message/conversation-post-tenant";
import {ConversationTemplateType} from "../models/organisation/organisation-conversation-template-type";
import {OrganisationConversationTemplate} from "../models/organisation/organisation-conversation-template";
import {OrganisationConversationTemplatePost} from "../models/organisation/organisation-conversation-template-post";
import {OrganisationConversationTemplatePatch} from "../models/organisation/organisation-conversation-template-patch";
import {ManageOrganisationService} from "./manage-organisation.service";
import {ConversationComposeEmailPost} from "../models/message/conversation-compose-email-post";
import {ConversationPreview} from "../models/message/conversation-preview";
import {GetConversationsOrigin} from "../models/message/get-conversations-origin";
import {ConversationAssignedOrgPersonFilter} from "../models/message/conversation-assigned-org-person-filter";
import {AccountService} from "./account.service";
import {File as HouseShareFile} from "../models/file/file";
import {FilePost} from "../models/file/file-post";
import {mergeMap, tap} from "rxjs/operators";
import {EnrichedConversationUser} from "../models/message/enriched-conversation-user";

@Injectable()
export class ConversationsService {

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private manageOrganisationService: ManageOrganisationService,
        private fileService: FileService,
        private accountService: AccountService) {
    }

    getConversations(origin: GetConversationsOrigin, contextType: ConversationContextTypes = null, propertyReference: string = null, archived: boolean = false, unreadOnly: boolean = false, massMessages: boolean = false, beforeTime: string = null, count: number = 15, includeEmail: boolean = false, includeSms: boolean = false, searchTerm: string = null, includeAllMedium: boolean = false, conversationAssignedOrgFilter: ConversationAssignedOrgPersonFilter = null): Observable<Conversations> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('count', count);
        httpParams = httpParams.set('origin', origin);
        httpParams = httpParams.set('archived', archived);
        httpParams = httpParams.set('unreadOnly', unreadOnly);
        httpParams = httpParams.set('massOnly', massMessages);
        httpParams = httpParams.set('emailOnly', includeEmail);
        httpParams = httpParams.set('smsOnly', includeSms);
        httpParams = httpParams.set('allMedium', includeAllMedium);

        if (contextType) httpParams = httpParams.set('contextType', contextType.toString());
        if (propertyReference) httpParams = httpParams.set('propertyReference', propertyReference);
        if (beforeTime) httpParams = httpParams.set('beforeTime', beforeTime); //used when loading more than the first load
        if (searchTerm) httpParams = httpParams.set('searchTerm', searchTerm);

        console.log('conversationAssignedOrgFilter', conversationAssignedOrgFilter)
        if(conversationAssignedOrgFilter != null) {
            httpParams = httpParams.set('conversationAssignedOrgFilter', conversationAssignedOrgFilter);
        } else {
            if(origin == GetConversationsOrigin.Manage){
                const defaultAssignmentFilter = this.getCachedDefaultAssignmentFilterForMessageCenter();
                console.log('defaultAssignmentFilter', defaultAssignmentFilter)
                if(defaultAssignmentFilter != null){
                    httpParams = httpParams.set('conversationAssignedOrgFilter', defaultAssignmentFilter);
                }
            }
        }

        const url = this.getBaseUrl();
        return this.http.get<Conversations>(url, {params: httpParams});
    }

    getConversation(reference: string, updateRead: boolean): Observable<Conversation> {
        let params = new HttpParams();
        if (!updateRead) {
            params = params.set('updateRead', 'false');
        }

        let url = this.getBaseUrl();
        url += `/${ reference }`;

        return this.http.get<Conversation>(url, {params});
    }

    getEnrichedConversationUsers(reference: string): Observable<EnrichedConversationUser[]> {
        const url = `${ this.configService.baseUrl }/conversations/${ reference }/enriched-conversation-user`;
        return this.http.get<EnrichedConversationUser[]>(url);
    }

    sendMessage(reference: string, body: MessagePost): Observable<HttpEvent<Message>> {
        let url = this.getBaseUrl();
        url += `/${ reference }`;

        return this.fileService.uploadFiles(body.files)
            .pipe(tap(_ => body.files = this.fileService.prepareFilePosts(body.files)),
                mergeMap(_ => this.http.post<Message>(url, body, {
                    reportProgress: true, observe: 'events'
                })));
    }

    getMessages(reference: string, page: number): Observable<PagedResponse<Message[]>> {
        let httpParams = new HttpParams();
        if (page) {
            httpParams = httpParams.append('page', page.toString());
        }

        let url = this.getBaseUrl();
        url += `/${ reference }/messages`;

        return this.http.get<Message[]>(url, {params: httpParams, observe: 'response'})
            .pipe(toPagedResponse<Message[]>());
    }

    updateReadDate(reference: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/conversations/${ reference }/read`, null);
    }

    archiveSelected(references: string[], archive: boolean): Observable<boolean> {
        const url = `${ this.configService.baseUrl }/conversations/archive`
        let params = new HttpParams();
        params = params.set('archive', archive);
        references.forEach((reference) => {
            params = params.append('references', reference);
        })
        return this.http.post<boolean>(url, null, {params});
    }

    readSelected(references: string[]): Observable<boolean> {
        const url = `${ this.configService.baseUrl }/conversations/read`
        let params = new HttpParams();
        references.forEach((reference) => {
            params = params.append('references', reference);
        })
        return this.http.post<boolean>(url, null, {params});
    }

    archiveConversation(reference: string, archive: boolean) {
        let params = new HttpParams();
        params = params.append('archive', archive);
        const url = `${ this.configService.baseUrl }/conversations/${ reference }/archive`;
        return this.http.patch<boolean>(url, null, {params});
    }

    deleteConversation(reference: string): Observable<Conversation> {
        return this.http.delete<Conversation>(`${ this.configService.baseUrl }/conversations/${ reference }/delete`);
    }

    private getBaseUrl(): string {
        return `${ this.configService.baseUrl }/conversations`;
    }

    sendEmailAsManager(organisationReference: string, commit: boolean, model: ConversationComposeEmailPost) {
        let fileUploaderObs: Observable<(HouseShareFile | FilePost)[]>;
        if (commit) {
            fileUploaderObs = this.fileService.uploadFiles(model.attachments)
                .pipe(tap(_ => model.attachments = this.fileService.prepareFilePosts(model.attachments)));
        } else {
            fileUploaderObs = of([]);
        }

        return fileUploaderObs.pipe(
            mergeMap(_ => this.http.post<{
                conversations: Conversation[];
                replacements: { [p: string]: string },
            }>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/conversations/post-email/commit/${commit}`, model)
        ));
    }

    startConversationManager(organisationReference: string, commit: boolean, body: ConversationPostManager) {
        let fileUploaderObs: Observable<(HouseShareFile | FilePost)[]>;
        if (commit) {
            fileUploaderObs = this.fileService.uploadFiles(body.files)
                .pipe(tap(_ => body.files = this.fileService.prepareFilePosts(body.files)));
        } else {
            fileUploaderObs = of([]);
            body.files = [];
        }
        return fileUploaderObs
            .pipe(mergeMap(_ => this.http.post<{
                isPreview: boolean,
                createdConversationList: ConversationPreview[],
                previewConversationList: Conversation[],
                replacementsDictionary: { [p: string]: string },
            }>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/conversations/post-conversation/commit/${commit}`, body)));
    }

    startConversationTenant(body: ConversationPostTenant) {
        return this.fileService.uploadFiles(body.files)
            .pipe(tap(_ => body.files = this.fileService.prepareFilePosts(body.files)),
                mergeMap(_ => this.http.post<Conversation>(`${this.configService.baseUrl}/conversations/post-tenant`, body)));
    }

    markUnread(reference: string) {
        return this.http.patch<any>(`${ this.configService.baseUrl }/conversations/${reference}/unread`, null);
    }

    toggleMute(reference: string) {
        return this.http.patch<any>(`${ this.configService.baseUrl }/conversations/${reference}/toggle-mute`, null);
    }

    addConversationUser(conversationReference: string, tenancyTenantGuid: string){
        const url = `${ this.configService.baseUrl }/conversations/${conversationReference}/conversation-users/${tenancyTenantGuid}`
        return this.http.post<Conversation>(url, null);
    }

    getConversationTemplates(type: ConversationTemplateType = ConversationTemplateType.CONVERSATION_STARTER) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/conversations/conversation-templates`
        let params = new HttpParams();
        params = params.append('type', type);
        return this.http.get<OrganisationConversationTemplate[]>(url, {params});
    }

    removeConversationTemplate(guid: string): Observable<boolean> {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/conversations/conversation-templates/${guid}`
        return this.http.delete<boolean>(url);
    }

    addConversationTemplate(model: OrganisationConversationTemplatePost) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/conversations/conversation-templates`

        return this.fileService.uploadFiles(model.files)
            .pipe(tap(_ => model.files = this.fileService.prepareFilePosts(model.files)),
                mergeMap(_ => this.http.post<OrganisationConversationTemplate>(url, model)));
    }

    patchConversationTemplate(guid: string, model: OrganisationConversationTemplatePatch) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/conversations/conversation-templates/${guid}`
        return this.http.patch<OrganisationConversationTemplate>(url, model);
    }

    switchAllowReplies(conversationReference: string, allowReplies: boolean){
        const url = `${this.configService.baseUrl}/conversations/${conversationReference}/allow-replies`
        let params = new HttpParams();
        params = params.append('allowReplies', allowReplies);
        return this.http.patch<Conversation>(url, null,{params});
    }

    disableConversationUser(conversationReference: string, personReference: string){
        const url = `${this.configService.baseUrl}/conversations/${conversationReference}/conversation-users/${personReference}/disable`
        return this.http.patch<Conversation>(url, null);
    }

    deleteMessage(conversationReference: string, messageGuid: string){
        const url = `${this.configService.baseUrl}/conversations/${conversationReference}/messages/${messageGuid}`
        return this.http.delete<boolean>(url);
    }

    assignOrganisationPerson(conversationReference: string, assign: boolean, organisationPersonGuid?: string){
        const url = `${this.configService.baseUrl}/conversations/${conversationReference}/assign-organisation-person`

        let params = new HttpParams();
        params = params.append('assign', assign);
        if(organisationPersonGuid) params = params.append('organisationPersonGuid', organisationPersonGuid)

        return this.http.patch<Conversation>(url, null, {params});
    }

    cacheDefaultAssignmentFilterForMessageCenter(assignmentType: ConversationAssignedOrgPersonFilter){
        const orgPersonGuid = this.accountService.getCurrentUserPersonReference()
        const storageKey = 'messageCenterOrgPersonFilter_ForOrgPersonGuid_' + orgPersonGuid;
        const assignmentTypeString = JSON.stringify(assignmentType);
        localStorage.setItem(storageKey, assignmentTypeString);
    }

    getCachedDefaultAssignmentFilterForMessageCenter(): ConversationAssignedOrgPersonFilter | null{
        const orgPersonGuid = this.accountService.getCurrentUserPersonReference()
        const storageKey = 'messageCenterOrgPersonFilter_ForOrgPersonGuid_' + orgPersonGuid;
        const storedData = localStorage.getItem(storageKey);
        return storedData ? JSON.parse(storedData) : null;
    }
}
