<div class="form__message form__message--{{messageType}} {{classes}}">
    <div class="form__message-icon">
        <svg-icon role="img" class="icon {{iconClasses}}" [name]="'icon-' + icon"></svg-icon>
    </div>
    <div class="form__message-text">

        <ng-content></ng-content>

    </div>
</div>
