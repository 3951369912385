<modal [type]="ModalTypes.DOCUMENT_VIEWER" [closeOnBlur]="false" [callback]="callbackSubscriber" (onClose)="closeModal()" [fullScreen]="true" [largeWidth]="true">
    <ng-container modal-header>
        {{file ? file.title : 'Document viewer'}}
    </ng-container>
    <ng-container modal-body>
        <p *ngIf="file?.type === FileTypes.DOCUMENT && (file?.extensionType.toLowerCase() === 'zip' || file?.extensionType.toLowerCase() === 'csv')">
            Document viewing not supported, please download.
        </p>

        <ngx-doc-viewer
            class="document_viewer"
            *ngIf="file?.type === FileTypes.DOCUMENT &&
                   file?.extensionType.toLowerCase() !== 'pdf' &&
                   file?.extensionType.toLowerCase() !== 'zip' &&
                   file?.extensionType.toLowerCase() !== 'csv'"
            [url]="url"
            viewer="office"
            disableContent="popout-hide">
        </ngx-doc-viewer>

        <div *ngIf="file?.type === FileTypes.DOCUMENT && file?.extensionType.toLowerCase() === 'pdf'" #pdfViewer class="document_viewer"></div>

        <img class="document-viewer-modal-img" [src]="url" *ngIf="file?.type === FileTypes.IMAGE"/>

        <video class="document-viewer-modal-video" *ngIf="file?.type === FileTypes.VIDEO && videoSignedUrl" controls >
            <source [src]="videoSignedUrl">
        </video>

        <loading-indicator *ngIf="getSignedUrl.loading"></loading-indicator>
        <error-response-summary [responseErrors]="getSignedUrl.responseErrors"></error-response-summary>

        <debug-info [jsonPrint]="file"></debug-info>
    </ng-container>

    <ng-container modal-footer>
        <div class="button__group" *ngIf="file">
            <div class="button__group-item">
                <loader-button (click)="downloadFile()">Download</loader-button>
            </div>
        </div>
    </ng-container>
</modal>
