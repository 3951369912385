import {Injectable} from "@angular/core";
import {MobileAppService} from "./mobile-app.service";
import {AccountService} from "./account.service";
import {ActivatedRoute} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {ConfigService} from "./config.service";

@Injectable()
export class WindowService {
    constructor(private mobileAppService: MobileAppService, private accountService: AccountService, private activatedRoute: ActivatedRoute,
                private configService: ConfigService) {
    }

    openSelf(url: string, addAuthTokens: boolean = false)
    {
        if (addAuthTokens) url = this.urlWithAuthTokens(url);

        window.location.href = url;
    }

    open(url: string, addAuthTokens: boolean = false, forceNewWindow?: boolean) : Window | null {
        if (addAuthTokens) url = this.urlWithAuthTokens(url);

        if(forceNewWindow || !this.mobileAppService.isMobileApp()){
            return window.open(url);
        } else {
            window.location.href = url;
        }
    }

    urlWithAuthTokens(url: string) {
        let urlObj;
        try {
            urlObj = new URL(`${url}`);
        } catch {
            urlObj = new URL(`${this.configService.webUrl}${url}`);
        }
        let newParams = new HttpParams();
        if (urlObj.searchParams) {
            urlObj.searchParams.forEach((value, key) => {
                newParams = newParams.append(key, value);
            });
        }

        if (this.accountService.isLoggedIn()) {
            newParams = newParams.append('bearerToken', this.accountService.accessToken.bearerToken);
            newParams = newParams.append('refreshToken', this.accountService.accessToken.refreshToken);
            newParams = newParams.append('email', this.accountService.accessToken.email);
        }

        if (newParams) {
            url = `${urlObj.pathname}?${newParams.toString()}`;
        }

        return url;
    }

    hardReloadCurrentPage() {
        window.location.reload();
    }
}
