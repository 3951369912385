<header
    class="supplier__header supplier__header--claim"
    [class.supplier__header--logged-in]="isLoggedIn"
    [class.supplier__header--logged-out]="!isLoggedIn">
    <div class="container">
        <div class="header__navigation">
            <div class="header__logo">
                <a [routerLink]="['/supplier']" class="header__logo">
                    <sprite-image reference="coho-logo" defaultImage="https://d19qeljo1i8r7y.cloudfront.net/web-images/logos/logo.png" alt="COHO logo"></sprite-image>
                </a>
            </div>
        </div>
        <div class="header__options">
            <a *ngIf="subPage" [routerLink]="['/supplier']" class="button button--transparent button--small button--nomargin">
                View dashboard
            </a>

            <div>
                <h4>Hello {{currentUser?.person?.fullname}}</h4>
            </div>

            <div class="dropdown" [class.open]="userMenuOpen" #userMenuDropdown>
                <button class="header__options-button" (click)="toggleUserMenu()">
                    <div class="profile__image header__profile-image" (click)="toggleUserMenu()">
                        <img [lazyLoad]="currentUser?.person?.image.url" [alt]="currentUser?.person?.fullname" defaultImage="https://d19qeljo1i8r7y.cloudfront.net/images/placeholder/user-other.png"/>
                    </div>
                </button>

                <ul class="dropdown__menu dropdown__menu--right" (click)="toggleUserMenu(false)">
                    <li>
                        <span class="dropdown__menu-title">
                            <svg-icon role="img" class="icon icon-user icon--" name="icon-user"></svg-icon>Account
                        </span>
                        <ul>
                            <li *ngIf="!currentUser.unclaimedAccount">
                                <a [routerLink]="['/supplier/account']" class="dropdown__menu-item" (click)="toggleUserMenu(false)">
                                    <svg-icon role="img" class="icon icon-settings icon--" name="icon-settings"></svg-icon>
                                    Settings
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" (click)="logout()" class="dropdown__menu-item">
                                    <svg-icon role="img" class="icon icon-logout icon--" name="icon-logout"></svg-icon>
                                    Log out
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</header>
