import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
    name: 'messageDate',
    pure: true
})
export class MessageDatePipe implements PipeTransform {
    transform(value: any): string {
        const val = moment(value).local();
        let dateFormat = '';
        let messageDate = '';

        if (val.isSame(moment(), 'day')) {
            dateFormat = "HH:mm";
            messageDate = "Today at " + val.format(dateFormat)
        }
        else if (val.isSameOrAfter(moment().subtract(1, 'day'))) {
            dateFormat = "HH:mm";
            messageDate = "Yesterday at " + val.format(dateFormat)
        }
        else if (val.isSameOrAfter(moment().subtract(1, 'years'))) {
            dateFormat = "DD MMM";
            messageDate = val.format(dateFormat) + " at " + val.format("HH:mm")
        }
        else {
            dateFormat = "DD MMM YYYY"
            messageDate = val.format(dateFormat)
        }

        return messageDate;
    }
}
