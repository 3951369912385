<div class="loader {{this.cssClasses}}" [class.loader--small]="small" [class.loader--tiny]="tiny" *ngIf="!inline">
    <div class="loading__text" *ngIf="message">
        {{message}}
    </div>
    <div class="loading__dots">
        <div class="loader__dot"></div>
        <div class="loader__dot"></div>
        <div class="loader__dot" *ngIf="!tiny"></div>
        <div class="loader__dot" *ngIf="!small && !tiny"></div>
        <div class="loader__dot" *ngIf="!small && !tiny"></div>
    </div>
</div>
<div class="loader__inline {{this.cssClasses}}" *ngIf="inline">
    <div class="loader__icon">
        <svg role="img" class="icon icon-rolling {{colour}}"><use href="#icon-rolling"></use></svg>
    </div>
    <div class="loader__text">
        {{message}}
    </div>
</div>
