import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import {BaseEntityTypes} from "../models/base-entity-types";
import {DataCacheSubject} from "../helpers/data-cache-subject";
import {TagPipe} from "../pipes/tag.pipe";
import * as _ from 'lodash';
import {DropdownOption} from "../components/dropdown/dropdown-option";


@Injectable()
export class ManageTagService {
    constructor(private http: HttpClient, private configService: ConfigService, private tagPipe: TagPipe) {
    }



    // Returns a list of tagStrings that are releveant to this specific object type (if supplied) and those inuse (if supplied)
    getTagOptions(organisationReference: string, type: BaseEntityTypes | null, includeUnused: boolean = false): Observable<string[]> {
        let params = new HttpParams();
        params = params.set('includeUnused', includeUnused  ? 'true' : 'false');
        params = params.set('limitToType', type  ? type : null);

        return this.http.get<string[]>(`${ this.configService.baseUrl }/tags/organisation/${ organisationReference }/tag-links/options`, {params});
    }

    // Returns a list of tagStrings relevant to a specific object
    getTagLinks(organisationReference: string, type: BaseEntityTypes, objectReferenceOrGuid: string ): Observable<string[]> {
        return this.http.get<string[]>(`${ this.configService.baseUrl }/tags/organisation/${ organisationReference }/tag-links/${ type }/identifier/${ objectReferenceOrGuid }/get-links`);
    }

    // Adds 1 or more tagStrings to a specific object
    addTagLinks(organisationReference: string, type: BaseEntityTypes, objectReferenceOrGuid: string, tagStrings: string[]): Observable<string[]> {
        this.clearCache();

        return this.http.post<string[]>(`${ this.configService.baseUrl }/tags/organisation/${ organisationReference }/tag-links/${ type }/identifier/${ objectReferenceOrGuid }/add-links`, tagStrings);
    }


    // Remove 1 or more tagStrings from a specific object
    RemoveTagLinks(organisationReference: string, type: BaseEntityTypes, objectReferenceOrGuid: string, tagStrings: string[]): Observable<{ rowsModified: number }> {
        this.clearCache();

        return this.http.post<{ rowsModified: number }>(`${ this.configService.baseUrl }/tags/organisation/${ organisationReference }/tag-links/${ type }/identifier/${ objectReferenceOrGuid }/remove-links`, tagStrings);
    }


    // Returns a list of Base Entity Identifiers that have the tags attached. The list will be either 'references' or 'guids' depending on what BaseEntityType
    getEntityReferencesWhereTag(organisationReference: string, type: BaseEntityTypes, tagStrings: string[]): Observable<string[]> {
        let params = new HttpParams();
        params = params.set('tagStrings', tagStrings.join(','));

        return this.http.get<string[]>(`${ this.configService.baseUrl }/tags/organisation/${ organisationReference }/tag-links/${ type }/tag-strings-to-property-reference`, {params});
    }



    //----------------------------------------------------//
    // Cached variations of the calls
    //----------------------------------------------------//
    private  _tagOptionsCache : { [p: string]: DataCacheSubject<string[]>} = {};
    private  _tagOptionLinksCache : { [p: string]: DataCacheSubject<string[]>} = {};

    clearCache() {
        this._tagOptionsCache = {};
        this._tagOptionLinksCache = {};
    }

    getTagOptionsCached(organisationReference: string, type: BaseEntityTypes | null): Observable<string[]>  {
        const key = type.toString();
        this._tagOptionsCache[key] = this._tagOptionsCache[key] || new DataCacheSubject<string[]>(x => {return this.getTagOptions(organisationReference, type, false)});

        return this._tagOptionsCache[key].cached();
    }


    // HELPER
    prepareTagOptions(tags: string[]) : {
        tags: string[];
        nice: string[];
        translation: { [p: string]: string },
        options: DropdownOption[],
    } {
        const tagsSorted = [...tags].sort();
        const tagsNice = tagsSorted.map(tag => this.tagPipe.transform(tag));
        const tagsTranslation = _.zipObject(tagsNice, tagsSorted);
        const dropdownOptions = tagsSorted.map(tag => new DropdownOption(this.tagPipe.transform(tag), tag));

        return {
            tags: tagsSorted,
            nice: tagsNice,
            translation: tagsTranslation,
            options: dropdownOptions,
        }
    }
}
