import {Pipe, PipeTransform} from '@angular/core';
import {AsyncPipe} from "@angular/common";

import {PermissionResources} from "../models/authorisation/permission-resources";
import {AuthorisationService} from "../services/authorisation.service";
import {PermissionAccessActions} from "../models/authorisation/permission-access-actions";

@Pipe({
    name: 'authorisation',
    pure: true
})
export class AuthorisationPipe implements PipeTransform {
    constructor(private authorisationService: AuthorisationService) {
    }

    transform(resource: PermissionResources, accessAction: PermissionAccessActions): boolean {
        return this.authorisationService.check(resource, accessAction);
    }
}
