import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalComponent } from './modal.component';
import {FocusOverlayModule} from "../focus-overlay/focus-overlay.module";
import {LoaderButtonModule} from "../loader-button/loader-button.module";

@NgModule({
    imports: [
        CommonModule,
        FocusOverlayModule,
        LoaderButtonModule
    ],
  declarations: [
      ModalComponent
  ],
  exports: [
      ModalComponent
  ]
})

export class ModalModule { }
