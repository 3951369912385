import {Component, ViewEncapsulation} from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ConfigService } from 'src/app/services/config.service';
import { MobileAppService } from 'src/app/services/mobile-app.service';

@Component({
  selector: 'site-footer-supplier',
  templateUrl: './site-footer-supplier.component.html',
    encapsulation: ViewEncapsulation.None
})

export class SiteFooterSupplierComponent {
    isLoggedIn: boolean = false;
    showAppFooter: boolean = true;
    showLegal: boolean = false;
    staticWebUrl: string;

    constructor(
        private mobileAppService: MobileAppService,
        private configService: ConfigService,
        private accountService: AccountService
        ) {
        this.staticWebUrl = this.configService.staticWebUrl;
        // if (configService.isDev) this.showAppFooter = false;
    }

    ngOnInit() {
        // if (this.mobileAppService.isMobileApp()) {
        //         this.showAppFooter = false;
        // }
        // this.accountService.watchIsLoggedIn().subscribe(isLoggedIn => {
        //         this.isLoggedIn = isLoggedIn;
        // })
    }
}
