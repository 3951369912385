import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';

import { from, Observable } from 'rxjs';

import { AccountService } from './account.service';
import { UserService } from './user.service';

@Injectable()
export class OwnerAuthGuard implements CanActivate, CanActivateChild {

    constructor(private accountService: AccountService, private _router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkIsOwner(state);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkIsOwner(state);
    }

    private checkIsOwner(state: RouterStateSnapshot): boolean | Observable<boolean> {
        const isLoggedIn = this.accountService.isLoggedIn();
        if (!isLoggedIn) {
            return from(this._router.navigate(['/login'], { queryParams: { redirect_to: state.url } }))
                .pipe(map(() => false));
        }

        const user = this.accountService.getCurrentUser();
        if (user && !user.isOwner && user.isManager) {
            return from(this._router.navigate(['/manage']))
                .pipe(map(() => false));
        }
        else if (user && !user.isOwner && user.isSupplier) {
            return from(this._router.navigate(['/supplier']))
                .pipe(map(() => false));
        }
        else if (user && !user.isOwner && (user.isTenant || user.isOnboarding)) {
            return from(this._router.navigate(['/my-home']))
                .pipe(map(() => false));
        }
        else if (user && !user.isOwner) {
            return from(this._router.navigate(['/find-a-home']))
                .pipe(map(() => false));
        }

        return isLoggedIn;
    }
}
