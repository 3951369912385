import {Component, ElementRef, HostListener, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { User } from 'src/app/models/user/user';
import { UserNotifications } from 'src/app/models/user/user-notifications';
import { AccountService } from 'src/app/services/account.service';
import {filter} from "rxjs/operators";
@Component({
    selector: 'site-header-supplier',
    templateUrl: './site-header-supplier.component.html',
    encapsulation: ViewEncapsulation.None
})

export class SiteHeaderSupplierComponent {

    @ViewChild('userMenuDropdown') userMenuDropdown: ElementRef;

    currentUser: User;
    isLoggedIn: boolean = false;
    userMenuOpen: boolean = false;
    userNotifications: UserNotifications;
    subPage: boolean = false;

    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent): void {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (!clickedInside && this.userMenuOpen) {
            this.userMenuOpen = false;
        }
    }

    constructor(
        private accountService: AccountService,
        private router: Router,
        private elementRef: ElementRef
        ) {

        this.subPage = this.router.url !== '/supplier';

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(_ => this.subPage = this.router.url !== '/supplier');

        accountService.watchCurrentUser().subscribe(user => {
            this.currentUser = user;
        });

        this.accountService.watchIsLoggedIn().subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });
    }

    toggleUserMenu(open?: boolean) {
        if (open === false) {
            this.userMenuOpen = false;
        } else if (open === true) {
            this.userMenuOpen = true;
        } else {
            if (this.userMenuOpen) {
                setTimeout(() => this.userMenuOpen = false);
            } else {
                setTimeout(() => this.userMenuOpen = true);
            }
        }
    }

    logout() {
        this.accountService.logout();
        const loginUrl = this.router.createUrlTree(['/login']);
        window.location.href = loginUrl.toString();
    }
}
