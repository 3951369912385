<modal [type]="ModalTypes.REMOVE_ITEM" [closeOnBlur]="false" [callback]="callbackSubscriber">
    <ng-container modal-header>Remove {{itemType}}</ng-container>
    <ng-container modal-body>

        <div class="form">
            <h3 class="form__label form__label--large">Are you sure?</h3>
            <form-message [type]="FormMessageTypes.ERROR">
                Are you sure you want to remove
                <ng-container *ngIf="itemTitle != null && itemTitle.length">{{itemTitle}}</ng-container>
                <ng-container *ngIf="itemTitle == null || itemTitle.length == 0">this {{itemType}}</ng-container>? <strong>This cannot be undone</strong>.
            </form-message>
        </div>

    </ng-container>
    <ng-container modal-footer>
        <div class="form__grid-element">
            <div class="button__group">
                <div class="button__group-item">
                    <loader-button (click)="confirmRemoveItem()" [loading]="loading">Yes</loader-button>
                </div>
                <div class="button__group-item">
                    <button class="button button--ghost" type="button" (click)="closeModal()">No</button>
                </div>
            </div>
        </div>
    </ng-container>
</modal>
