import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { ConfigService } from './config.service';
import { TenancyAgreementPost } from '../models/tenancy-agreement/tenancy-agreement-post';
import { TenancyAgreement } from '../models/tenancy-agreement/tenancy-agreement';
import { FileService } from "./file.service";
import { TenancyAgreementOverview } from '../models/tenancy-agreement/tenancy-agreement-overview';
import { TenancyAgreementConfirmPost } from '../models/tenancy-agreement/tenancy-agreement-confirm-post';
import { TenancyContractReceipt } from '../models/tenancy-agreement/tenancy-contract-receipt';
import { GuarantorTenancyAgreement } from '../models/tenancy-agreement/guarantor-tenancy-agreement';
import { TenanciesOrganisationOverview } from '../models/tenancy-agreement/tenancies-organisation-overview';
import { TenanciesOrganisationSummary } from '../models/tenancy-agreement/tenancies-organisation-summary';
import { TenancyStatuses } from '../models/tenancy/tenancy-statuses';
import { TenancySummary } from '../models/tenancy/tenancy-summary';
import moment from 'moment';
import { AccountService } from './account.service';
import { TenancyRoomMovePost } from '../models/tenancy/tenancy-room-move-post';
import { OrganisationTenancyAgreement } from '../models/tenancy-agreement/organisation-tenancy-agreement';
import { OrganisationTenancyAgreementPost } from '../models/tenancy-agreement/organisation-tenancy-agreement-post';
import { TenancyAgreementPreview } from '../models/tenancy-agreement/tenancy-agreement-preview';
import {TenancyAgreementGuarantorSignedPost} from "../models/tenancy-agreement/tenancy-agreement-guarantor-signed-post";
import {TenancyAgreementSummary} from "../models/tenancy/tenancy-agreement-summary";
import {PropertyFilterPresets} from "../models/manage-property/property-details";
import {FilePost} from "../models/file/file-post";
import {map, mergeMap, tap} from "rxjs/operators";


@Injectable()
export class TenancyAgreementService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService, private accountService: AccountService) {
    }

    getOverview(propertyReference: string, roomReference: string, guid: string): Observable<TenancyAgreementOverview> {
        return this.http.get<TenancyAgreementOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${guid}/tenancy-agreement/overview`);
    }

    add(propertyReference: string, roomReference: string, guid: string, model: TenancyAgreementPost): Observable<HttpEvent<TenancyAgreement>> {
        return this.fileService.uploadFiles(model.tenancyAgreementFiles)
            .pipe(tap(_ => model.tenancyAgreementFiles = this.fileService.prepareFilePosts(model.tenancyAgreementFiles)),
                mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementSupportingFiles)),
                tap(_ => model.tenancyAgreementSupportingFiles = this.fileService.prepareFilePosts(model.tenancyAgreementSupportingFiles)),
                mergeMap(_ => this.fileService.uploadFiles(model.guarantorFiles)),
                tap(_ => model.guarantorFiles = this.fileService.prepareFilePosts(model.guarantorFiles)),
                mergeMap(_ => this.http.post<TenancyAgreement>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${guid}/tenancy-agreement`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    preview(propertyReference: string, roomReference: string, guid: string, model: TenancyAgreementPost): Observable<HttpEvent<TenancyAgreementPreview>> {
        return this.fileService.uploadFiles(model.tenancyAgreementFiles)
            .pipe(tap(_ => model.tenancyAgreementFiles = this.fileService.prepareFilePosts(model.tenancyAgreementFiles)),
                mergeMap(_ => this.fileService.uploadFiles(model.tenancyAgreementSupportingFiles)),
                tap(_ => model.tenancyAgreementSupportingFiles = this.fileService.prepareFilePosts(model.tenancyAgreementSupportingFiles)),
                mergeMap(_ => this.fileService.uploadFiles(model.guarantorFiles)),
                tap(_ => model.guarantorFiles = this.fileService.prepareFilePosts(model.guarantorFiles)),
                mergeMap(_ => this.http.post<TenancyAgreementPreview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${guid}/tenancy-agreement/preview`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    get(propertyReference: string, roomReference: string, guid: string, tenancyAgreementGuid: string): Observable<TenancyAgreement> {
        return this.http.get<TenancyAgreement>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${guid}/tenancy-agreement/${tenancyAgreementGuid}`);
    }

    getProposed(propertyReference: string, roomReference: string, tenancyGuid: string, tenancyAgreementGuid: string): Observable<TenancyAgreement> {
        return this.http.get<TenancyAgreement>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/tenancy-agreement/${tenancyAgreementGuid}/proposed`);
    }

    confirm(propertyReference: string, roomReference: string, tenancyGuid: string, tenancyAgreementGuid: string, model: TenancyAgreementConfirmPost): Observable<HttpEvent<void>> {
        return this.fileService.uploadFiles(model.tenancyAgreementSignedFiles)
            .pipe(tap(_ => model.tenancyAgreementSignedFiles = this.fileService.prepareFilePosts(model.tenancyAgreementSignedFiles)),
                mergeMap(_ => this.http.post<void>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/tenancy-agreement/${tenancyAgreementGuid}/confirm`, model, {
            reportProgress: true, observe: 'events'
        })));
    }

    adminRegenerateSignedContracts(propertyReference: string, roomReference: string, tenancyGuid: string, tenancyAgreementGuid: string): any {
        return this.http.get<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/tenancy-agreement/${tenancyAgreementGuid}/admin-regenerate-signed-documents`);
    }

    cancelProposedTenancyAgreement(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, tenancyAgreementGuid: string): Observable<void> {
        return this.http.post<void>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/tenancy-agreement/${tenancyAgreementGuid}/cancel`, null);
    }

    deleteTenancyAgreement(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, tenancyAgreementGuid: string): Observable<void> {
        return this.http.post<void>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/tenancy-agreement/${tenancyAgreementGuid}/cancel`, null);
    }


    addFilesToTenancyAgreement(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, tenancyAgreementGuid: string, tenancyFiles: FilePost[], supportingFiles: FilePost[], guarantorFiles: FilePost[]) {
        return this.fileService.uploadFiles(tenancyFiles)
            .pipe(
                mergeMap(_ => this.fileService.uploadFiles(supportingFiles)),
                mergeMap(_ => this.fileService.uploadFiles(guarantorFiles)),
                map(_ => {
                    return {
                        tenancyAgreementFiles: this.fileService.prepareFilePosts(tenancyFiles),
                        tenancyAgreementSupportingFiles: this.fileService.prepareFilePosts(supportingFiles),
                        guarantorFiles: this.fileService.prepareFilePosts(guarantorFiles),
                    }
                }),
                mergeMap(data => this.http.post<TenancyAgreement>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/tenancy-agreement/${tenancyAgreementGuid}/attach-additional-files`, data, {
                    reportProgress: true, observe: 'events'
                })));
    }


    getTenancyContractReceipt(guid: string): Observable<TenancyContractReceipt> {
        return this.http.get<TenancyContractReceipt>(`${this.configService.baseUrl}/tenancy-agreement/receipt/${guid}`);
    }

    getGuarantorTenancyAgreementByGuid(guid: string): Observable<GuarantorTenancyAgreement> {
        return this.http.get<GuarantorTenancyAgreement>(`${this.configService.baseUrl}/guarantor/${guid}/tenancy-agreement`);
    }

    getGuarantorTenancyAgreementByInviteReference(inviteReference: string, mobileNumber: string): Observable<GuarantorTenancyAgreement> {
        let params = new HttpParams();
        params = params.set('inviteReference', inviteReference);
        params = params.set('mobileNumber', mobileNumber);

        return this.http.get<GuarantorTenancyAgreement>(`${this.configService.baseUrl}/guarantor/identify`, { params: params });
    }

    guarantorSignTenancyAgreement(guid: string, model: TenancyAgreementGuarantorSignedPost): Observable<HttpEvent<GuarantorTenancyAgreement>> {
        return this.fileService.uploadFiles(model.signedFiles)
            .pipe(tap(_ => model.signedFiles = this.fileService.prepareFilePosts(model.signedFiles)),
                mergeMap(_ => this.http.post<GuarantorTenancyAgreement>(`${this.configService.baseUrl}/guarantor/${guid}/sign`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    getTenanciesOverview(organisationReference: string, propertyReferences: string[] | null = null): Observable<TenanciesOrganisationOverview> {

        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        return this.http.get<TenanciesOrganisationOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancies/overview`, { params: params });
    }

    getTenanciesAsCsvUrl(organisationReference: string, propertyReferences: string[] | null = null, pastTenancies: boolean = false): string {
        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        params = params.append('pastTenancies', pastTenancies ? "true" : "false");
        params = params.append('access_token', this.accountService.accessToken.bearerToken);

        return`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancies/overview/as-csv/true?${params.toString()}`;
    }

    getTenanciesOrganisationSummary(organisationReference: string): Observable<TenanciesOrganisationSummary> {
        return this.http.get<TenanciesOrganisationSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancies/summary`);
    }

    getTenancySummaries(organisationReference: string, status: TenancyStatuses, moveInDateFrom: Date, moveInDateTo: Date, fixedTermEndDateFrom: Date, fixedTermEndDateTo: Date): Observable<TenancySummary[]> {

        let params = new HttpParams();
        if (status != null) {
            params = params.set('status', status);
        }

        if (moveInDateFrom != null) {
            params = params.set("moveInDateFrom", moment(moveInDateFrom).utc().toISOString());
        }

        if (moveInDateTo != null) {
            params = params.set("moveInDateTo", moment(moveInDateTo).utc().toISOString());
        }

        if (fixedTermEndDateFrom != null) {
            params = params.set("fixedTermEndDateFrom", moment(fixedTermEndDateFrom).utc().toISOString());
        }

        if (fixedTermEndDateTo != null) {
            params = params.set("fixedTermEndDateTo", moment(fixedTermEndDateTo).utc().toISOString());
        }

        return this.http.get<TenancySummary[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancies`, { params: params });
    }

    moveRoom(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, model: TenancyRoomMovePost): Observable<TenancySummary> {
        return this.http.post<TenancySummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/move-room`, model);
    }

    getOrganisationTenancyAgreements(organisationReference: string, propertyReference: string = "", includeAll: boolean = false): Observable<OrganisationTenancyAgreement[]> {
        let params = new HttpParams();

        if (propertyReference != null && propertyReference.length) {
            params = params.append('propertyReference', propertyReference);
        }

        if (includeAll) {
            params = params.append('includeAll', 'true');
        }

        return this.http.get<OrganisationTenancyAgreement[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancy-agreement-templates`, { params: params });
    }

    getOrganisationTenancyAgreementMailMergeSampleTags(organisationReference: string) {
        return this.http.get<{
            sampleDictionary :{[p: string]: string};
            draftDictionary :{[p: string]: string};
        }>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancy-agreement-templates/sample-mailmerge-tags`);
    }

    addOrganisationTenancyAgreement(organisationReference: string, model: OrganisationTenancyAgreementPost): Observable<HttpEvent<OrganisationTenancyAgreement>> {
        return this.fileService.uploadFiles(model.tenancyAgreementFiles)
            .pipe(tap(_ => model.tenancyAgreementFiles = this.fileService.prepareFilePosts(model.tenancyAgreementFiles)),
                mergeMap(_ => this.fileService.uploadFiles(model.supportingFiles)),
                tap(_ => model.supportingFiles = this.fileService.prepareFilePosts(model.supportingFiles)),
                mergeMap(_ => this.fileService.uploadFiles(model.guarantorFiles)),
                tap(_ => model.guarantorFiles = this.fileService.prepareFilePosts(model.guarantorFiles)),
                mergeMap(_ => this.http.post<OrganisationTenancyAgreement>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancy-agreement-templates/add-new`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    deleteOrganisationTenancyAgreement(organisationReference: string, guid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancy-agreement-templates/${guid}`);
    }

    getAgreementsInProgressForExistingTenancies(organisationReference: string): Observable<TenancyAgreementSummary[]> {
        return this.http.get<TenancyAgreementSummary[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancy-agreements/in-progress`);
    }

    remove(organisationReference: string, propertyReference: string, item: TenancyAgreement): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/tenancy-agreement/${item.guid}`);
    }
}
