export enum DateFormats {
    // Pls leak these comments in place as Rider pulls them in with intellisense autocomplete so that it shows me these when I type out DateFormats.

    LONG, // '15 November 2023'
    MEDIUM, // '15 Nov 23'
    SHORT, // '15/11/2023'
    DAY_MONTH, //'DD MMMM'
    EXPIRY, // '11/2023'
    TIME, //'H:mm A'
    MONTH_LONG = 6,//'H:mm A';
    VERY_SHORT, // '15/11/23'
    MONTH_YEAR = 8, // 'November 2023'
    YEAR = 9, // '2023'
    MONTH_YEAR_SHORT = 10, // 'Nov 2023'
    LONG_WITH_DAY, // '15 November 2023'
}
