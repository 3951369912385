import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor as AngularHttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AccountService} from "../services/account.service";

@Injectable()
export class HttpInterceptor implements AngularHttpInterceptor {

    constructor(private accountService: AccountService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.headers.has('Content-Type')) {
            request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
        }

        if (this.accountService.loginStackCanPop()) {
            request = request.clone({headers: request.headers.set('login-stack', 'true')});
        }

        return next.handle(request);
    }
}
