import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import DOMPurify from 'dompurify';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }
    transform(html) {
        const sanitisedHtml = DOMPurify.sanitize(html);
        return this.sanitizer.bypassSecurityTrustHtml(sanitisedHtml);
    }
}
