import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import {SiteFooterSupplierComponent} from "./site-footer-supplier.component";
import {CopyrightTextComponent} from "../copyright-text/copyright-text.component";

@NgModule({
    imports: [
        CommonModule,
        SvgIconModule,
        RouterModule,
        CopyrightTextComponent,
    ],
    declarations: [
        SiteFooterSupplierComponent
    ],
    exports: [
        SiteFooterSupplierComponent
    ]
})

export class SiteFooterSupplierModule { }
