import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorSummaryComponent } from './error-summary.component';
import { FriendlyDescriptionPipe } from './friendly-description.pipe';
import { NgObjectPipesModule } from 'ngx-pipes';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

@NgModule({
    imports: [
        CommonModule,
        SvgIconModule,
        NgObjectPipesModule,
    ],
    declarations: [
        FriendlyDescriptionPipe,
        ErrorSummaryComponent
    ],
    exports: [
        ErrorSummaryComponent
    ]
})
export class ErrorSummaryModule {
}
