import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'houseShareCurrency',
    pure: true
})
export class HouseShareCurrencyPipe implements PipeTransform {

    constructor() {
    }

    transform(value: number, forceDecimals: boolean = false, round: boolean = false, abs: boolean = false) {

        if (value != null) {

            let currencyString = '';
            let valueString: string = '';
            let hasDecimalPoints = false;

            // Force positive value if Abs is requested
            if (abs && value < 0) value = -value;

            if (round) {
                valueString = Math.round(value).toString();
            } else {
                hasDecimalPoints = Number(value) % 1 !== 0;
                valueString = Number(value).toFixed(hasDecimalPoints ? 2 : 0);
            }

            currencyString = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: (+valueString % 1 != 0 ? 2 : 0), maximumFractionDigits: (hasDecimalPoints ? 2 : 0) }).format(+valueString);

            if ((currencyString.substring(currencyString.length - 3) == '.00' || currencyString.substring(currencyString.length - 3) == ',00') && forceDecimals != true) {
                currencyString = currencyString.substring(0, currencyString.length - 3);
            }

            if (forceDecimals == true && !currencyString.includes('.')) {
                currencyString = currencyString + ".00";
            }

            return currencyString;
        }

        return value;

    }
}
