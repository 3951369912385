import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { ActionOverview } from '../models/action/action-overview';
import { NotificationSummary } from '../models/manage-organisation/notification-summary';
import { ActionSnoozePostFull } from '../models/action/action-snooze-post';
import { SupplierDashboardOverview } from '../models/supplier/supplier-dashboard-overview';
import { SupplierSummary } from '../models/supplier/supplier-summary';
import {SupplierSearchResult} from "../models/supplier/supplier-search-result";

@Injectable()
export class SupplierService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getSupplierDashboardOverview(): Observable<SupplierDashboardOverview> {
        return this.http.get<SupplierDashboardOverview>(`${this.configService.baseUrl}/supplier/overview`);
    }

    getActionsOverview(): Observable<ActionOverview> {
        return this.http.get<ActionOverview>(`${this.configService.baseUrl}/supplier/actions/overview`);
    }

    getNotifications(): Observable<NotificationSummary> {
        return this.http.get<NotificationSummary>(`${this.configService.baseUrl}/supplier/notifications`);
    }

    snoozeAction(actionReference: string, days: number, months: number, date: string = null): Observable<boolean> {
        let snoozeModel = new ActionSnoozePostFull();
        snoozeModel.date = date;
        snoozeModel.days = days;
        snoozeModel.months = months;

        return this.http.post<boolean>(`${this.configService.baseUrl}/supplier/actions/${actionReference}/snooze`, snoozeModel);
    }

    completeAction(actionReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/supplier/actions/${actionReference}/complete`, null);
    }

    search(query: string): Observable<SupplierSearchResult> {
        let encodedQuery = encodeURIComponent(query);
        const url = `${this.configService.baseUrl}/supplier/search-issue`
        return this.http.get<SupplierSearchResult>(url, { params: { query: encodedQuery } });
    }
}

