<ng-container *ngIf="layout === LayoutType.CONVERSATION || layout === LayoutType.SUPPLIER_CONVERSATION">
    <div class="conversation__page" [class.is-app]="isApp" [class.is-phone]="isPhone">
        <div class="site__content">
            <site-header *ngIf="layout === LayoutType.CONVERSATION"></site-header>
            <site-header-supplier *ngIf="layout === LayoutType.SUPPLIER_CONVERSATION"></site-header-supplier>
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="layout === LayoutType.ACCOUNT_LANDING || layout === LayoutType.REGISTRATION || layout == LayoutType.MARKETING || layout == LayoutType.STATIC">
    <div [class.site__content]="layout == LayoutType.STATIC" 
         [class.account__page]="layout == LayoutType.ACCOUNT_LANDING"
         [class.register__page]="layout == LayoutType.REGISTRATION"
         [class.is-app]="isApp" id="top">

         <site-header-static *ngIf="layout == LayoutType.STATIC"></site-header-static>

        <router-outlet></router-outlet>
    </div>
</ng-container>

<ng-container *ngIf="layout !== LayoutType.CONVERSATION && layout !== LayoutType.SUPPLIER_CONVERSATION && layout !== LayoutType.ACCOUNT_LANDING && layout !== LayoutType.REGISTRATION && layout !== LayoutType.MARKETING && layout !== LayoutType.STATIC">
    <div [class.site__content]="layout !== LayoutType.ACCOUNT_LANDING"
         [class.empty__page]="layout == LayoutType.EMPTY"
         [class.news__page]="layout == LayoutType.NEWS"
         [class.landing__page]="layout == LayoutType.LANDING || layout == LayoutType.DEMO"
         [class.demo__page]="layout == LayoutType.DEMO"
         [class.magic__page]="layout == LayoutType.MAGIC"
         [class.wide-nav]="layout == LayoutType.MANAGE"
         [class.wide-nav--right]="layout == LayoutType.MANAGE && organisationNavigationLayoutType == OrganisationNavigationLayoutTypes.RIGHT"
         [class.wide-nav--minimised]="layout == LayoutType.MANAGE && organisationNavigationLayoutMinimised"
         [class.is-app]="isApp" id="top">

        <site-header *ngIf="layout == LayoutType.STANDARD || layout == LayoutType.MANAGE || layout == LayoutType.NEWS || layout == LayoutType.MAGIC"></site-header>
        <site-header-static *ngIf="layout == LayoutType.STATIC"></site-header-static>
        <site-header-supplier *ngIf="layout == LayoutType.SUPPLIER"></site-header-supplier>
        <div id="firstContainer">
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-container>

<site-footer
    *ngIf="layout == LayoutType.STANDARD || layout == LayoutType.NEWS || layout == LayoutType.MAGIC || layout == LayoutType.MANAGE"
    [class.magic__page]="layout == LayoutType.MAGIC"
    [class.is-app]="isApp"
    [class.wide-nav]="layout == LayoutType.MANAGE"
    [class.wide-nav--right]="layout == LayoutType.MANAGE && organisationNavigationLayoutType == OrganisationNavigationLayoutTypes.RIGHT"
    [class.wide-nav--minimised]="layout == LayoutType.MANAGE && organisationNavigationLayoutMinimised"
    id="siteFooter">
</site-footer>

<site-footer-static *ngIf="layout == LayoutType.STATIC" [class.static__page]="layout == LayoutType.STATIC"></site-footer-static>

<site-footer-supplier *ngIf="layout == LayoutType.SUPPLIER"></site-footer-supplier>
<site-footer-my-viewings *ngIf="layout == LayoutType.VIEWINGS"></site-footer-my-viewings>

<cookie-consent-modal></cookie-consent-modal>
<form-navigate-away-confirm></form-navigate-away-confirm>
<remove-item-modal></remove-item-modal>
<confirm-modal></confirm-modal>
<document-viewer-modal></document-viewer-modal>
<error-modal></error-modal>
<toast></toast>
