import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { HomesBrowse } from '../models/homes/homes-browse';
import { Landing } from '../models/homes/landing';
import { Address } from '../models/address/address';
import { RoomSearchProfile } from '../models/homes/room-search-profile';
import { WidgetRoom } from '../models/property/widget-room';
import { WidgetHome } from '../models/property/widget-home';
import { WidgetHomeOverview } from '../models/property/widget-home-overview';
import { WidgetRoomOverview } from '../models/property/widget-room-overview';
import {OrganisationHomesBrowse} from "../models/organisation-homes/organisation-homes-browse";

@Injectable()
export class HomesService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getHomes(locationReference: string, area: string, roomSearchProfile: RoomSearchProfile, page: number): Observable<HomesBrowse> {
        let params = new HttpParams();
        if (roomSearchProfile) {
            if (roomSearchProfile.roomSearch) {
                if (roomSearchProfile.roomSearch.guid) {
                    params = params.set("guid", roomSearchProfile.roomSearch.guid);
                }
            }

            if (roomSearchProfile.includeAll == true) {
                params = params.set("includeAll", "true");
            }
        }

        if (area) {
            params = params.set("area", area.toString());
        }

        if (page) {
            params = params.set("page", page.toString());
        }

        if (locationReference) {
            return this.http.get<HomesBrowse>(`${this.configService.baseUrl}/homes/${locationReference}`, { params });
        }
        else {
            return this.http.get<HomesBrowse>(`${this.configService.baseUrl}/homes`, { params });
        }

    }

    getLanding(): Observable<Landing> {
        return this.http.get<Landing>(`${this.configService.baseUrl}/homes/landing`);
    }

    getBrowse(): Observable<Address[]> {
        return this.http.get<Address[]>(`${this.configService.baseUrl}/homes/browse`);
    }

    getWidgetHomes(organisationReference: string, majorAreaReference: string, availableOnly: boolean, securityToken: string, page: number): Observable<WidgetHomeOverview> {
        let params = new HttpParams();

        params = params.set("securityToken", securityToken);

        if (majorAreaReference != null) {
            params = params.set("majorAreaReference", majorAreaReference);
        }

        if (availableOnly == true) {
            params = params.set("availableOnly", "true");
        }

        if (page != null) {
            params = params.set("page", page.toString());
        }

        return this.http.get<WidgetHomeOverview>(`${this.configService.baseUrl}/widget/homes/organisation/${organisationReference}/homes`, { params });
    }

    getWidgetRooms(organisationReference: string, majorAreaReference: string, availableOnly: boolean, securityToken: string, page: number): Observable<WidgetRoomOverview> {
        let params = new HttpParams();
        params = params.set("securityToken", securityToken);

        if (majorAreaReference != null) {
            params = params.set("majorAreaReference", majorAreaReference);
        }

        if (availableOnly == true) {
            params = params.set("availableOnly", "true");
        }

        if (page != null) {
            params = params.set("page", page.toString());
        }

        return this.http.get<WidgetRoomOverview>(`${this.configService.baseUrl}/widget/homes/organisation/${organisationReference}/rooms`, { params });
    }

    getOrganisationHomes(organisationReference: string, majorAreaReference: string = null, availableOnly: boolean = null, page: number = null): Observable<OrganisationHomesBrowse> {
        let params = new HttpParams();

        if (majorAreaReference != null) {
            params = params.set("majorAreaReference", majorAreaReference);
        }

        if (availableOnly == true) {
            params = params.set("availableOnly", "true");
        }

        if (page != null) {
            params = params.set("page", page.toString());
        }

        return this.http.get<OrganisationHomesBrowse>(`${this.configService.baseUrl}/homes/organisation/${organisationReference}/homes`, { params });
    }
}
