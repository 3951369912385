import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initials',
    pure: true
})
export class InitialsPipe implements PipeTransform {
    transform(value: { forename: string; surname: string; } | null, fallback: string = '', fullInitials: boolean = true): string {
        if (!value || typeof value.forename != 'string' || typeof value.surname != 'string') return fallback;
        if (!value.forename && !value.surname) return fallback;

        const forenames = value.forename.split(/[\s\n]+/g);
        const surnames = value.surname.split(/[\s\n]+/g);

        // Basic request
        if (!fullInitials) return forenames[0].substring(0,1) + surnames[0].substring(0,1);

        // More complex request
        const initials: string[] = [];

        forenames.forEach(part => initials.push(part.substring(0,1)));
        surnames.forEach(part => initials.push(part.substring(0,1)));

        return initials.join('');
    }
}
