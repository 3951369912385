import { Injectable } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';

const referralRefKey: string = 'R_REF';

@Injectable()
export class ReferralService {

    constructor(cookieService: CookieService) {
        const cookie = cookieService.get(referralRefKey);
        if (cookie) {
            this.setReferralCode(cookie);
        }
    }

    getReferralCode(): string {
        const code = localStorage.getItem(referralRefKey);
        return code;
    }

    setReferralCode(code: string): void {
        localStorage.setItem(referralRefKey, code);
    }
}
