import {HttpClient, HttpEvent, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { FileService } from "./file.service";
import { PropertyOwnershipOverview } from '../models/property-owner/property-ownership-overview';
import { PropertyOwnership } from '../models/property-owner/property-ownership';
import { PropertyOwnershipPost } from '../models/property-owner/property-ownership-post';
import { JsonPatch } from '../models/json-patch/json-patch';
import { PropertyOwner } from '../models/property-owner/property-owner';
import { PropertySummary } from '../models/manage-property/property-summary';
import {
    OrganisationChildFinancialCompany,
    OrganisationChildFinancialCompanyPatch
} from '../models/property-owner/organisation-child-financial-company';
import { PropertyOwnerPatch } from '../models/property-owner/property-owner-patch';
import {B} from "@fullcalendar/core/internal-common";
import {ManageOrganisationService} from "./manage-organisation.service";
import {BaseEntityStatuses} from "../models/base-entity-statuses";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class PropertyOwnershipService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService, private manageOrganisationService: ManageOrganisationService) {
    }

    getOverview(organisationReference: string, propertyReference: string): Observable<PropertyOwnershipOverview> {
        return this.http.get<PropertyOwnershipOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/ownership/overview`);
    }

    addPropertyOwnership(organisationReference: string, propertyReference: string, model: PropertyOwnershipPost): Observable<HttpEvent<PropertyOwnership>> {
        return this.fileService.uploadFiles(model.files)
            .pipe(tap(_ => model.files = this.fileService.prepareFilePosts(model.files)),
                mergeMap(_ => this.http.post<PropertyOwnership>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/ownership`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    updatePropertyOwnership(organisationReference: string, propertyReference: string, model: JsonPatch[]): Observable<HttpEvent<PropertyOwnership>> {

        return this.http.patch<PropertyOwnership>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/ownership`, model, {
            reportProgress: true, observe: 'events'
        });
    }

    addPropertyOwner(organisationReference: string, propertyReference, model: JsonPatch[]): Observable<HttpEvent<PropertyOwnership>> {

        return this.http.patch<PropertyOwnership>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/ownership`, model, {
            reportProgress: true, observe: 'events'
        });
    }

    updatePropertyOwner(organisationReference: string, propertyOwnerReference: string, model: JsonPatch[]): Observable<HttpEvent<PropertyOwner>> {

        return this.http.patch<PropertyOwner>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property-owners/${propertyOwnerReference}`, model, {
            reportProgress: true, observe: 'events'
        });
    }

    patchPropertyOwner(organisationReference: string, propertyOwnerReference: string, model: PropertyOwnerPatch): Observable<PropertyOwner> {

        return this.http.patch<PropertyOwner>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property-owners/${propertyOwnerReference}/patch`, model);
    }

    getOwners(organisationReference: string): Observable<PropertyOwner[]> {
        return this.http.get<PropertyOwner[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property-owners`);
    }

    getOrganisationChildFinancialCompanies(organisationReference: string): Observable<OrganisationChildFinancialCompany[]> {
        return this.http.get<OrganisationChildFinancialCompany[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/child-financial-companies`);
    }

    updateChildFinancialCompany(model: OrganisationChildFinancialCompanyPatch){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/child-financial-companies/${model.guid}`
        return this.http.patch<OrganisationChildFinancialCompany>(url, model);
    }

    getOwnersProperties(organisationReference: string, ownerReference: string, includeRooms: boolean = false, includeOwnershipDetails: boolean = false): Observable<PropertySummary[]> {
        let params = new HttpParams();
        if (includeRooms) {
            params = params.set('includeRooms', 'true');
        }
        if (includeOwnershipDetails) {
            params = params.set('includeOwnershipDetails', 'true');
        }


        return this.http.get<PropertySummary[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property-owners/${ownerReference}/property`, { params });
    }

    setPropertyOwnerEntityStatus(organisationReference: string, propertyOwnerGuid: string, entityStatus: BaseEntityStatuses){
        return this.http.patch<void>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property-owners/${propertyOwnerGuid}/set-entity-status/${entityStatus}`, null);
    }

    createConversationWithManager(organisationReference: string, propertyOwnerGuid: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property-owners/${propertyOwnerGuid}/create-conversation-with-manager`
        return this.http.post<PropertyOwner>(url, null);
    }
}
