import { Pipe, PipeTransform, SecurityContext, VERSION } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import * as _ from 'lodash';


@Pipe({
    name: 'nl2br',
    pure: true
})
export class Nl2BrPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    // Usage of this pipe must be like this --> <p [innerHTML]="message.message | nl2br"></p>
    transform(rawString: string): SafeHtml {
        if (typeof rawString !== 'string') {
            return rawString;
        }

        // First sanitize & escape all HTML characters as the incoming string is meant to be pure plaintext.
        let htmlString = _.escape(rawString);

        // Then add html newlines <br/>
        const htmlStringWithBrs = htmlString.replace(/(?:\r\n|\r|\n)/g, '<br />');
        const safeHtml= this.sanitizer.sanitize(SecurityContext.HTML, htmlStringWithBrs);


        return safeHtml;
    }

}
