import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountService } from './account.service';

@Injectable()
export class UnauthorisedOrUnverifiedAuthGuard implements CanActivate, CanActivateChild {

    constructor(private accountService: AccountService, private _router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkIsAuthorised();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkIsAuthorised();
    }

    private checkIsAuthorised(): boolean | Observable<boolean> {
        const user = this.accountService.getCurrentUser();
        if (user && user.emailConfirmed) {
            return from(this._router.navigate(['/']))
                .pipe(
                    map(() => false)
                );
        }

        return true;
    }
}
