<button
    (click)="submit()"
    type="{{this.type}}"
    class="button {{this.cssClasses}}"
    [ngClass]="{
        'button--icon': this.icon,
        'button--loading': this.loading,
        'button--icon-only': this.iconOnly,
        }"
    [disabled]="this.disabled || this.loading"
    [attr.tabindex]="tabIndex != null ? tabIndex : tabIndex">

    <svg-icon
        *ngIf="this.icon && !this.loading"
        [name]="iconName"
        [color]="getIconClass()">
    </svg-icon>

    <ng-content *ngIf="!this.loading || (this.loading && preserveText)"></ng-content>

    <ng-container *ngIf="this.loading && !preserveText">{{loadingText ?? 'Loading...'}}</ng-container>
</button>

<div *ngIf="hoverName && iconOnly && !expand" class="hover_name">
    <p>{{hoverName}}</p>
</div>

<div *ngIf="expandable && expand"
     [ngClass]="{
        'menu': true,
        'menu--left': expandDirection === 'left',
        'menu--down': expandDirection === 'down',
        'menu--up': expandDirection === 'up',
        'menu--right': expandDirection === 'right'
      }">
    <ul class="menu-options">
        <li *ngIf="expandAction1?.condition === true" (click)="expandActionClick(1)">
            <svg-icon *ngIf="this.iconNameExpandAction1" name="{{this.iconNameExpandAction1}}" class="icon"></svg-icon>
            {{ expandAction1.name }}
        </li>
        <li *ngIf="expandAction2?.condition === true" (click)="expandActionClick(2)">
            <svg-icon *ngIf="this.iconNameExpandAction2" name="{{this.iconNameExpandAction2}}" class="icon"></svg-icon>
            {{ expandAction2.name }}
        </li>
        <li *ngIf="expandAction3?.condition === true" (click)="expandActionClick(3)">
            <svg-icon *ngIf="this.iconNameExpandAction3" name="{{this.iconNameExpandAction3}}" class="icon"></svg-icon>
            {{ expandAction3.name }}
        </li>
        <li *ngIf="expandAction4?.condition === true" (click)="expandActionClick(4)">
            <svg-icon *ngIf="this.iconNameExpandAction4" name="{{this.iconNameExpandAction4}}" class="icon"></svg-icon>
            {{ expandAction4.name }}
        </li>
        <li *ngIf="expandAction5?.condition === true" (click)="expandActionClick(5)">
            <svg-icon *ngIf="this.iconNameExpandAction5" name="{{this.iconNameExpandAction5}}" class="icon"></svg-icon>
            {{ expandAction5.name }}
        </li>
    </ul>
</div>
