<div class="page__section page__section--highlight page__section--slim" *ngIf="showAppFooter">
    <div class="container">
        <div class="grid__layout">

            <div class="row">
                <div class="col">
                    <p class="u-text-align--center u-text--large u-margin-bottom--10">COHO also works great on your smaller screen</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="button__group button__group--centered">
                        <div class="button__group--item">
                            <a href="https://apps.apple.com/app/id1560659217" target="_blank" class="button button--icon-only button--cancel button--small button--app">
                                <sprite-image reference="apple-app-store" defaultImage="" alt="Download on the app store"></sprite-image>
                            </a>
                        </div>
                        <div class="button__group--item">
                            <a href="https://play.google.com/store/apps/details?id=com.houseshare.mobile" target="_blank" class="button button--icon-only button--cancel button--small button--app">
                                <sprite-image reference="google-play" defaultImage="" alt="Get it on Google Play"></sprite-image>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col col__md--3">
                <ul class="footer__links">
                    <li>
                        <a href="{{staticWebUrl}}app/find-a-home/">Find a home</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}what-we-do/hmo-management-software/">What we do</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}features/">Features</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}pricing/">Pricing</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}faqs/">FAQs</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}instant-demo/">Instant demo</a>
                    </li>

                </ul>
            </div>
            <div class="col col__md--3">
                <ul class="footer__links">
                    <li>
                        <a href="{{staticWebUrl}}latest-news/">Latest news</a>
                    </li>
                </ul>
                <ul class="footer__links">
                    <li>
                        <a href="{{staticWebUrl}}future-og-hmos/">The Future of HMOs</a>
                    </li>
                </ul>
            </div>
            <div class="col col__md--3">
                <ul class="footer__links">
                    <li>
                        <a href="{{staticWebUrl}}support/">Support using COHO</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}training/">COHO Training</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}information-for-tenants/">Information for tenants</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}contact/">Contact us</a>
                    </li>
                </ul>
            </div>
            <div class="col col__md--3">
                <ul class="footer__links">
                    <li>
                        <a href="{{staticWebUrl}}legal/website-terms-of-use/">Website Terms of Use</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}legal/privacy-policy/">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}legal/acceptable-use-policy/">Acceptable Use Policy</a>
                    </li>
                    <li>
                        <a href="{{staticWebUrl}}legal/terms-and-conditions/">Terms and Conditions</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <p class="copyright"><copyright-text></copyright-text></p>
                <ul class="social-links">
                    <li class="fb"><a href="https://www.facebook.com/cohomanage/" target="_blank"><svg-icon class="icon icon--light2-grey" name="icon-facebook"></svg-icon></a></li>
                    <li class="li"><a href="https://www.linkedin.com/company/cohohomes" target="_blank"><svg-icon class="icon icon--light2-grey" name="icon-linkedin"></svg-icon></a>
                </ul>
            </div>
        </div>

    </div>
</div>
