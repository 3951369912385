import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { RoomPreview } from '../models/property/room-preview';
import { Home } from '../models/property/home';
import {ViewingHoursAndInfoInterface} from "../models/tenant-find/viewing-hour.interface";


@Injectable()
export class HomeService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getHome(homeReference: string, roomReference: string = null): Observable<Home> {
        var params = "";
        if (roomReference != null) {
            params = "?roomReference=" + roomReference;
        }

        return this.http.get<Home>(`${this.configService.baseUrl}/home/${homeReference}${params}`);
    }

    getSimilar(homeReference: string, roomReference: string): Observable<RoomPreview[]> {
        if (roomReference) {
            return this.http.get<RoomPreview[]>(`${this.configService.baseUrl}/home/${homeReference}/${roomReference}/similar`);
        }
        else {
            return this.http.get<RoomPreview[]>(`${this.configService.baseUrl}/home/${homeReference}/similar`);
        }

    }

    sendMessage(homePublicReference: string, roomReference: string, message: string): Observable<string> {
        return this.http.post(`${this.configService.baseUrl}/home/send-message`,
            {
                propertyPublicReference: homePublicReference,
                roomReference: roomReference,
                message: message
            },
            {
                responseType: 'text'
            });
    }

    getViewingHoursAndInfo(propertyPublicReference: string){
        const url = `${this.configService.baseUrl}/home/${propertyPublicReference}/viewing-hours-and-info`
        return this.http.get<ViewingHoursAndInfoInterface>(url);
    }
}
