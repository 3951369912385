import {HttpClient, HttpEvent, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import * as _ from 'lodash';
import {ConfigService} from './config.service';
import {ManageOrganisationOverview} from '../models/manage-property/manage-organisation-overview';
import {PropertySummary} from '../models/manage-property/property-summary';
import {OrganisationSummary} from '../models/organisation/organisation-summary';
import {OrganisationSearchResult} from '../models/organisation/organisation-search-result';
import {JsonPatch} from '../models/json-patch/json-patch';
import {OrganisationDetails} from '../models/organisation/organisation-details';
import {OnboardingPaymentDetails} from '../models/organisation/onboarding-payment-details';
import {OnboardingMoveInDayDetails} from '../models/organisation/onboarding-move-in-day-details';
import {NotificationSummary} from '../models/manage-organisation/notification-summary';
import {ActionOverview} from '../models/action/action-overview';
import {ActionTypes} from '../models/action/action-types';
import {ActionCategory} from '../models/action/action-category';
import {AccountService} from './account.service';
import {InvitePropertyOwnerPost} from '../models/manage-organisation/invite-property-owner-post';
import {PropertyOwner} from '../models/property-owner/property-owner';
import {CustomActionPost} from '../models/action/custom-action-post';
import {Action} from '../models/action/action';
import {AssignActionPost} from '../models/action/assign-action-post';
import {OrganisationHoldingFeeAdditionalTerms} from '../models/organisation/organisation-holding-fee-additional-terms';
import {DropdownOption} from "../components/dropdown/dropdown-option";
import {DataCacheSubject} from "../helpers/data-cache-subject";
import {OrganisationFeatures} from '../models/organisation/organisation-features';
import {WidgetSecurityCode} from "../models/manage-organisation/widget-security-code";
import {ActionStatusPatch} from "../models/action/action-status-patch";
import {CreditControlTenancyArrears} from "../models/rent-collection/credit-control-tenancy-arrears";
import {TenantSummary} from "../models/tenancy/tenant-summary";
import {OrganisationInformationInterface} from "../models/organisation/organisation-information.interface";
import {OrganisationInformationPostInterface} from "../models/organisation/organisation-information-post.interface";
import { OrganisationSettingsPatch } from '../models/organisation/organisation-settings-patch';
import {
    ViewingHoursAndInfoInterface,
    WeeklyViewingHoursPostInterface
} from "../models/tenant-find/viewing-hour.interface";
import {
    OrganisationPersonCalendarAuthInterface
} from "../models/organisation/organisation-person-calendar-auth.interface";
import { CalendarPost } from '../models/calendar/calendar-post';
import {ActionNotificationInfo} from "../models/action/action-notification-info";
import {CustomActionEditPatch} from "../models/action/custom-action-edit-patch";
import {PropertiesMix} from "../models/organisation/properties-mix.enum";


@Injectable()
export class ManageOrganisationService {
    organisationReference: string | null;
    private organisationFeatures: OrganisationFeatures;
    private  _organisationNotificationsCache : { [p: string]: DataCacheSubject<NotificationSummary>} = {};

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private accountService: AccountService) {

        this.accountService.watchCurrentUser()
            .subscribe(user => {
                this.organisationFeatures = null
                this.setOrganisationReference(null);
            });
    }

    setOrganisationReference(organisationReference: string | null){
        this.organisationReference = organisationReference;
    }

    getOrganisationReference(): string | null {
        return this.organisationReference;
    }

    getOrganisationReferenceWithFallback(): Observable<string | null> {
        // console.log(this.organisationReference); // Remove

        // We have an organisation reference, so return an observable that gives the value once subscribed
        if (this.organisationReference) {
            const obs = new Subject<string | null>();

            // On next tick run observable giving function caller time to subscribe to the observable
            setTimeout(_ => {
                obs.next(this.organisationReference);
                obs.complete();
            });

            return obs;
        }
        else
        {
            // console.log("Fetching organisation reference from API"); // Remove
            const obs = new Subject<string | null>();

            const request = this.http.get<{ organisationReference: string | null; }>(`${this.configService.baseUrl}/manage/organisation/get-reference`);
            request.subscribe(response => {
                // console.log("Retrieved from API:", response); // Remove

                if (response.organisationReference) {
                    this.organisationReference = response.organisationReference;
                    obs.next(this.organisationReference);
                    obs.complete();
                }
            }, error => {
                console.log("Retrieved error from API:", error); // Remove
                obs.error(error);
                obs.complete();
            })

            return obs;
        }
    }

    getOrganisationSummary(organisationReference: string): Observable<OrganisationSummary> {
        if (organisationReference != null && organisationReference.length) {
            return this.http.get<OrganisationSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}`);
        } else {
            return this.http.get<OrganisationSummary>(`${this.configService.baseUrl}/manage/organisation`);
        }
    }

    getManageOrganisationOverview(organisationReference: string = null): Observable<ManageOrganisationOverview> {
        if (organisationReference != null && organisationReference.length) {
            return this.http.get<ManageOrganisationOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/overview`);
        } else {
            return this.http.get<ManageOrganisationOverview>(`${this.configService.baseUrl}/manage/organisation/overview`);
        }
    }

    getPropertySummaries(organisationReference: string, params: { includeOverviewData?: boolean; includePropertyOwnership?: boolean; } = {}) {
        let httpParams = new HttpParams();
        httpParams.appendAll(params);

        return this.http.get<PropertySummary[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property`, {params});
    }

    getPropertySummariesByOwner(organisationReference: string, propertyOwnerReference: string): Observable<PropertySummary[]> {

        let params = new HttpParams();
        if (propertyOwnerReference) {
            params = params.set('propertyOwnerReference', propertyOwnerReference);
        }

        return this.http.get<PropertySummary[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property`, {params});
    }

    getOrganisationInformation(organisationReference: string): Observable <OrganisationInformationInterface>{
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-info`
        return this.http.get<OrganisationInformationInterface>(url);
    }

    addOrganisationInformation(organisationReference: string, model: OrganisationInformationPostInterface): Observable <OrganisationInformationInterface>{
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-info`
        return this.http.post<OrganisationInformationInterface>(url, model);
    }

    updateOrganisationInformation(organisationReference: string, model: OrganisationInformationPostInterface): Observable <OrganisationInformationInterface>{
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-info`
        return this.http.patch<OrganisationInformationInterface>(url, model);
    }

    getAvailablePropertySummaries(organisationReference: string = null): Observable<PropertySummary[]> {
        let params = new HttpParams();
        if (organisationReference) {
            params = params.set('organisationReference', organisationReference);
        }

        return this.http.get<PropertySummary[]>(`${this.configService.baseUrl}/manage/property/available`, {params});
    }

    getActionsOverview(organisationReference: string, includeCompleted: boolean = false, orgPersonDefaultFilter?: string): Observable<ActionOverview> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/overview`
        let params = new HttpParams();
        if (includeCompleted) params = params.set('includeCompleted', includeCompleted);
        if (orgPersonDefaultFilter) params = params.set("orgPersonDefaultFilter", orgPersonDefaultFilter);
        return this.http.get<ActionOverview>(url, {params});
    }

    getActionCategories(organisationReference: string, propertyReference: string, roomReference: string, actionCategoryReference: string, actionType: ActionTypes, organisationPersons: string[] | null = null, includeUnassigned = false, showSnoozed: boolean = null, includePropertyCompletion: boolean = false): Observable<ActionCategory[]> {
        let params = new HttpParams();

        if (propertyReference != null) params = params.append('propertyReference', propertyReference);
        if (roomReference != null) params = params.append('roomReference', roomReference);
        if (actionCategoryReference != null) params = params.append('actionCategoryReference', actionCategoryReference);
        if (actionType != null) params = params.append('actionType', actionType);
        if (organisationPersons) {
            if (organisationPersons.length == 0) params = params.append('organisationPersons', 'no-match');
            else organisationPersons.forEach(m => params = params.append('organisationPersons', m));
        }
        if (includeUnassigned) params = params.append('includeUnassigned', "true");
        if (showSnoozed != null) params = params.append('showSnoozed', showSnoozed);
        if (includePropertyCompletion) params = params.append('includePropertyCompletion', "true");

        return this.http.get<ActionCategory[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/action-categories-with-actions`, {params});
    }

    private getAllActionCategories(organisationReference: string): Observable<ActionCategory[]> {
        return this.http.get<ActionCategory[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/action-categories`);
    }

    getNotifications(organisationReference: string): Observable<NotificationSummary> {
        const constraints = {'organisationReference': organisationReference};

        // Create the cache if it does not exist (this does not trigger the event yet)
        if (!this._organisationNotificationsCache[organisationReference]) {
            this._organisationNotificationsCache[organisationReference] = new DataCacheSubject<NotificationSummary>(x => {
                return this.http.get<NotificationSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/notifications`);
            }, constraints, 2);
        }

        return this._organisationNotificationsCache[organisationReference].cached(constraints);
    }

    organisationSearch(organisationReference: string, query: string): Observable<OrganisationSearchResult> {
        return this.http.get<OrganisationSearchResult>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/search/${query}`);
    }

    patchOrganisation(organisationReference: string, body: JsonPatch[]): Observable<HttpEvent<OrganisationDetails>> {
        return this.http.patch<OrganisationDetails>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}`, body, {
            reportProgress: true, observe: 'events'
        });
    }

    getOnboardingPaymentDetails(organisationReference: string): Observable<OnboardingPaymentDetails[]> {
        return this.http.get<OnboardingPaymentDetails[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/onboarding-payment-details`);
    }

    getOnboardingMoveInDayDetails(organisationReference: string): Observable<OnboardingMoveInDayDetails[]> {
        return this.http.get<OnboardingMoveInDayDetails[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/onboarding-move-in-day-details`);
    }

    generateActionCSV(organisationReference: string, propertyReferences: string[] | null, roomReference: string, actionCategoryReference: string, actionType: ActionTypes = null,  organisationPersons: string[] | null = null, includeUnassigned = false, showSnoozed: boolean = false, onlyCompleted: boolean = false): string {
        let params = new HttpParams();

        if (propertyReferences) propertyReferences.forEach(propertyReference => params = params.append('propertyReferences', propertyReference));
        if (roomReference) params = params.append('roomReference', roomReference);
        if (actionCategoryReference) params = params.append('actionCategoryReference', actionCategoryReference);
        if (actionType) params = params.append('actionType', actionType);
        if (organisationPersons) {
            if (organisationPersons.length == 0) params = params.append('organisationPersons', 'no-match');
            else organisationPersons.forEach(m => params = params.append('organisationPersons', m));
        }
        if (includeUnassigned) params = params.append('includeUnassigned', "true");
        if (showSnoozed) params = params.append('showSnoozed', "true");
        if (onlyCompleted) params = params.append('onlyCompleted', "true");

        params = params.append('access_token', this.accountService.accessToken.bearerToken);

        return `${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/csv?${params.toString()}`;
    }

    inviteAllTenants(organisationReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/invite-all-tenants`, null);
    }

    invitePropertyOwnerToCOHO(organisationReference: string, guid: string, model: InvitePropertyOwnerPost): Observable<PropertyOwner> {

        return this.http.post<PropertyOwner>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property-owners/invite`, model);
    }

    getPropertyOwner(organisationReference: string, guid: string): Observable<PropertyOwner> {
        return this.http.get<PropertyOwner>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property-owners/${guid}`);
    }

    completeAction(organisationReference: string, actionReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/${actionReference}/complete`, null);
    }

    removePaymentDetails(organisationReference: string, guid: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/remove-onboarding-payment-details/${guid}`, null);
    }

    addCustomAction(organisationReference: string, model: CustomActionPost): Observable<Action> {
        return this.http.post<Action>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions`, model);
    }

    patchCustomAction(organisationReference: string, actionReference: string, model: CustomActionEditPatch): Observable<Action> {
        return this.http.patch<Action>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/${actionReference}/edit`, model);
    }
    assignAction(organisationReference: string, actionReference: string, assignTo: string): Observable<Action> {
        let model = new AssignActionPost();
        model.assignTo = assignTo;

        return this.http.post<Action>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/${actionReference}/assign`, model);
    }

    getAction(organisationReference: string, actionReference: string) : Observable<Action>{
        return this.http.get<Action>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/${actionReference}`);
    }

    rerunActionNotificationProcess(organisationReference: string, actionReference: string): Observable<ActionNotificationInfo> {
        return this.http.post<ActionNotificationInfo>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/${actionReference}/rerun-action-notification-process`, null);
    }

    runIterationOfActionNotificationJob(organisationReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/run-iteration-of-action-notification-job`, null);
    }

    getActionNotificationInfo(organisationReference: string, actionReference: string): Observable<ActionNotificationInfo> {
        return this.http.get<ActionNotificationInfo>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/${actionReference}/action-notification-info`);
    }

    patchActionStatus(organisationReference: string, actionReference: string, model: ActionStatusPatch): Observable<Action> {
        return this.http.patch<Action>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/actions/${actionReference}`, model);
    }

    getOnboardingHoldingFeeAdditionalTerms(organisationReference: string): Observable<OrganisationHoldingFeeAdditionalTerms[]> {
        return this.http.get<OrganisationHoldingFeeAdditionalTerms[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/holding-fee-additional-terms`);
    }

    removeOrganisationHoldingFeeAdditionalTerms(organisationReference: string, guid: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/remove-holding-fee-additional-terms/${guid}`, null);
    }

    getOrganisationFeatures(): Observable<OrganisationFeatures> {
        if (this.organisationFeatures) {
            return of(this.organisationFeatures);
        }

        return this.http.get<OrganisationFeatures>(`${this.configService.baseUrl}/manage/organisation/features`)
            .pipe(tap(data => this.organisationFeatures = data));
    }

    getOrganisationWidgetSecurityCode(organisationReference: string, availableOnly: boolean): Observable<WidgetSecurityCode> {

        let params = new HttpParams();

        params = params.append('availableOnly', availableOnly);

        return this.http.get<WidgetSecurityCode>(`${this.configService.baseUrl}/widget/homes/organisation/${organisationReference}/security-token`, {params});
    }

    getAllTenantSummaries(includePast: boolean = true, includeFuture: boolean = true, includeProspective: boolean = true, propertyReferences: string[] = []): Observable<TenantSummary[]> {
        let params = new HttpParams();
        params = params.append('includePast', includePast);
        params = params.append('includeFuture', includeFuture);
        params = params.append('includeProspective', includeProspective);

        // Append each property reference as a separate parameter
        for (let ref of propertyReferences) {
            params = params.append('propertyReferences', ref);
        }

        const url = `${ this.configService.baseUrl }/manage/organisation/${this.organisationReference}/all-tenant-summaries`
        return this.http.get<TenantSummary[]>(url, {params});
    }


    //----------------------------------------------------//
    // Cached variations of the calls
    //----------------------------------------------------//

    private  _propertySummariesCache : DataCacheSubject<PropertySummary[]> = null;
    getPropertySummariesCached(organisationReference: string | null = null, includeDisabled = true): Observable<PropertySummary[]> {
        if (organisationReference && organisationReference != this.organisationReference) throw new Error("Organisation reference mismatch on getPropertySummariesCached");

        const constraints = {'organisationReference': this.organisationReference};

        // Create the cache if it does not exist (this does not trigger the event yet)
        if (this._propertySummariesCache == null) {
            this._propertySummariesCache = new DataCacheSubject<PropertySummary[]>(() => {
                return this.getPropertySummaries(this.organisationReference, {includePropertyOwnership: true})
            }, constraints);
        }

        return this._propertySummariesCache
            .cached(constraints)
            .pipe(
                map(properties=> properties.filter(property => property.deactivated == false || includeDisabled == true))
            );
    }

    clearPropertySummariesCached() {
        this._propertySummariesCache = null;
    }

    getOrganisationPropertiesDropdownCached(organisationReference: string, includeAllPreset : boolean = false) : Observable<DropdownOption[]> {
        // Use a ReplaySubject because the Cached call could return instantly due to the result being cached. A ReplaySubject keeps the result and replays it
        // to any subscribers
        const subject = new ReplaySubject<DropdownOption[]>();

        // Make our cached call
        this.getPropertySummariesCached(this.organisationReference).subscribe(
            summaries => {
                const dropdowns = summaries.map(m => new DropdownOption(m.name, m.reference, m.rentalType))
                if (includeAllPreset) dropdowns.unshift(new DropdownOption("All Properties", "preset:all", 'presets'));

                subject.next(dropdowns);
                subject.complete();
            },
            error => {
                subject.error(error)
                subject.complete();
            }
        );

        return subject;
    }

    private  _allActionCategoriesCache : DataCacheSubject<ActionCategory[]> = null;
    getAllActionCategoriesCached(organisationReference : string)  : Observable<ActionCategory[]> {
        const constraints = {'organisationReference': organisationReference};

        // Create the cache if it does not exist (this does not trigger the event yet)
        if (this._allActionCategoriesCache == null) this._allActionCategoriesCache = new DataCacheSubject<ActionCategory[]>(x => {return this.getAllActionCategories(organisationReference)}, constraints);

        return this._allActionCategoriesCache.cached(constraints);
    }

    // PROBABLY TO MOVE OUT TO A SORT-SERVICE
    // KEY Requirement:
    //    * You need to pass-through the sorted Properties
    //    * OrderId is 0 based. So the maxId + 1 is the number of records stored

    private _propertyReferenceToSortOrderId : {} | null = null;
    private _propertyReferenceToSortOrderIdMax : number | null = null;
    setupPropertySortOrder(sortedProperties: PropertySummary[]) {
        if (!sortedProperties && this._propertyReferenceToSortOrderId != null) {
            console.log("No sorted properties passed in, fix this. Fortunately I am able to run as someone else was kind enough to pass them in...");
            return;
        }

        if (!sortedProperties) {
            console.log("No sorted properties passed in");
            this._propertyReferenceToSortOrderId ||= {};
            this._propertyReferenceToSortOrderIdMax = 0;

            return;
        }

        // Sorting not set up, set-up and cache
        if (this._propertyReferenceToSortOrderId == null || Object.keys(this._propertyReferenceToSortOrderId).length != sortedProperties.length) {
            this._propertyReferenceToSortOrderId = [];

            let i : number;
            for (i = 0;i < sortedProperties.length;i++) {
                this._propertyReferenceToSortOrderId[sortedProperties[i].reference] = i;
            }

            this._propertyReferenceToSortOrderIdMax = i;
        }
    }

    getPropertySortOrderId(propertyReference: string) : number {
        // Key value found for sort orderId number? Return
        if (this._propertyReferenceToSortOrderId[propertyReference] !== undefined) return this._propertyReferenceToSortOrderId[propertyReference];

        // Okay, this property is missing (how?). Dunno. Just return max+1
        console.log("Property reference not found in sorted lookup", propertyReference);

        return this._propertyReferenceToSortOrderIdMax + 1;
    }

    sortInPlace(tenancyArrears: CreditControlTenancyArrears[], sortedProperties: PropertySummary[]): CreditControlTenancyArrears[] {
        this.setupPropertySortOrder(sortedProperties);

        return _.orderBy(tenancyArrears, [
            (row) => this.getPropertySortOrderId(row.propertySummary.reference),
            (row) => row.tenancySummary?.roomName,
            (row) => row.tenancySummary?.tenantName,
        ], [
            'asc',
            'asc',
            'asc',
        ]);
    }

    patchOrganisationSettings(organisationReference: string, model: OrganisationSettingsPatch){
        return this.http.patch(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/settings`, model);
    }

    getViewingHoursAndInfo(organisationReference: string, isManager: boolean = false) {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/viewing-hours-and-info`
        let params = new HttpParams();
        params = params.append('isManager', isManager);

        return this.http.get<ViewingHoursAndInfoInterface>(url, {params});
    }

    updateViewingHoursAndInfo(model: WeeklyViewingHoursPostInterface) {
        const organisationReference = this.organisationReference;
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/viewing-hours-and-info`
        return this.http.patch<ViewingHoursAndInfoInterface>(url, model);
    }

    getICalAuthsCreatedByOrgPersonId(organisationPersonGuid: string) {
        const organisationReference = this.organisationReference;
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/ical-urlstrings`
        let params = new HttpParams();
        params = params.append('organisationPersonGuid', organisationPersonGuid);
        return this.http.get<OrganisationPersonCalendarAuthInterface[]>(url, { params });
    }

    generateOrganisationPersonCalendarAuthentication(model: CalendarPost) {
        const organisationReference = this.organisationReference;
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/calendar-auth`
        return this.http.post<OrganisationPersonCalendarAuthInterface>(url, model);
    }

    revokeOrganisationPersonCalendarAuthentication(authGuid: string, revoke: boolean){
        const organisationReference = this.organisationReference;
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/calendar-auth/${authGuid}`
        let params = new HttpParams();
        params = params.append('revoke', revoke);
        return this.http.patch<OrganisationPersonCalendarAuthInterface>(url, null, {params});
    }

    deleteOrganisationPersonCalendarAuthentication(authGuid: string){
        const organisationReference = this.organisationReference;
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/calendar-auth/delete/${authGuid}`
        return this.http.patch<OrganisationPersonCalendarAuthInterface>(url, null);
    }

    createOrUpdateAdvancedRentOrganisationContact(organisationReference: string, organisationPersonGuid: string){
        console.log('createOrUpdateAdvancedRentOrganisationContact');
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/advanced-rent-organisation-contact/${organisationPersonGuid}`
        return this.http.patch<OrganisationInformationInterface>(url, null);
    }

    getPropertiesMix(organisationReference: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/properties-mix`
        return this.http.get<{ propertiesMix: PropertiesMix }>(url);
    }
}

