import { Injectable } from '@angular/core';
import {forkJoin, fromEvent, Observable, of} from 'rxjs';
import {HttpClient, HttpEvent, HttpEventType, HttpParams} from '@angular/common/http';
import {map, mergeMap, tap} from 'rxjs/operators';
import { AbstractControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { NgxCsvParser } from 'ngx-csv-parser';

import { ConfigService } from './config.service';
import { FilePost } from '../models/file/file-post';
import { UtilitiesService } from './utilities.service';
import { HouseShareFile } from '../models/file/file';
import { FileTypes } from '../models/file/file-types';
import { Image } from '../models/image/image';
import { JsonPatch } from '../models/json-patch/json-patch';
import { read, utils } from 'xlsx';
import {Guid} from "../models/guid/guid";
import {PresignedUploadUrl} from "../models/file/presigned-upload-url";

@Injectable()
export class FileService {
    constructor(private http: HttpClient, private configService: ConfigService, private utilitiesService: UtilitiesService, private ngxCsvParser: NgxCsvParser) {
    }

    getFileUrl(reference: string): Observable<string> {
        return this.http.get<string>(`${ this.configService.baseUrl }/file/request/${ reference }`, {responseType: 'text' as 'json'})
            .pipe(
                map(response => `${ this.configService.baseUrl }/file/download/${ response }`)
            );
    }

    downloadFile(fileUrl: string) {
        return this.http.get(`${ fileUrl }`, {
            reportProgress: true,
            observe: 'events',
            responseType: 'blob'
        });
    }

    filesFormArrayChanged(formArray: UntypedFormArray, files: HouseShareFile[]) {
        const formArrayFiles = formArray.controls.map(control => control.value as HouseShareFile);
        const changes = this.getFileArrayChanges(formArrayFiles, files);
        changes.inserted.forEach(file => {
            const clonedFile = this.utilitiesService.clone(file);
            const ctrl = new UntypedFormControl(clonedFile);
            ctrl.markAsDirty();
            formArray.push(ctrl);
            formArray.markAsDirty();
        });

        changes.updated.forEach(file => {
            const control = formArray.controls.find(c => (c.value as HouseShareFile).reference === file.reference);
            control.setValue(file);
            control.markAsDirty({onlySelf: true});
        });

        changes.deleted.forEach(file => {
            if (file.isNew) {
                const control = formArray.controls.find(c => (c.value as HouseShareFile).reference === file.reference);
                formArray.removeAt(formArray.controls.indexOf(control));
            }
        });
    }

    private getFileArrayChanges(arr1: HouseShareFile[], arr2: HouseShareFile[]): { inserted: HouseShareFile[]; deleted: HouseShareFile[]; updated: HouseShareFile[] } {
        const self = this;
        const keys1 = {};
        const keys2 = {};

        const inserted = [];
        const updated = [];
        const deleted = [];

        arr1.forEach(item => {
            keys1[item.reference] = item;
        });

        arr2.forEach(item => {
            keys2[item.reference] = item;
        });

        arr1.forEach((item, index) => {
            const obj = keys2[item.reference];
            if (!obj) {
                item.deleted = true;
                deleted.push(item);
            } else {
                const newIndex = arr2.indexOf(obj);

                const clonedItem = this.utilitiesService.clone<HouseShareFile>(item);
                const clonedObj = this.utilitiesService.clone<HouseShareFile>(obj);

                delete clonedItem.order;
                delete clonedObj.order;

                if (!self.utilitiesService.equals(clonedObj, clonedItem)) {
                    obj.isMoved = false;
                    updated.push(obj);
                } else if (index !== newIndex) {
                    obj.order = newIndex;
                    obj.isMoved = true;
                    updated.push(obj);
                }
            }
        });

        arr2.forEach((item, index) => {
            if (!keys1[item.reference]) {
                item.order = index;
                inserted.push(item);
            }
        });

        return {
            inserted: inserted,
            updated: updated,
            deleted: deleted
        };
    }

    prepareFilePosts(files: HouseShareFile[] | FilePost[] | null): FilePost[] {
        if (!files || files.length == 0) {
            return [] as FilePost[];
        }

        return files.map(file => this.prepareFilePost(file));
    }

    prepareFilePost(file: HouseShareFile | FilePost): FilePost {
        if (!file) {
            return null;
        }

        //FilePost is basically a clean-up version of HouseShareFile, so we delete some keys and that makes the transformation work
        const clonedFilePost = this.utilitiesService.clone<FilePost>(file);
        const keysToDelete = ['url', 'invalid', 'isNew', 'isMoved', 'extension', 'extensionType', 'public', 'downloading', 'assumedType', 'dataString'];

        keysToDelete.forEach(key => {
            if (clonedFilePost[key]) {
                delete clonedFilePost[key];
            }
        });

        return clonedFilePost;
    }

    convertImagesToFiles(images: Image[]) {
        return images.map(image => {
            const file = new HouseShareFile();
            file.reference = image.reference;
            file.filename = image.title;
            file.url = image.url;
            file.order = image.order;
            file.originalFilename = image.originalFilename;
            file.type = FileTypes.IMAGE;
            file.public = true;
            return file;
        });
    }

    convertToFiles(files: HouseShareFile[] | FilePost[]) {
        return files.map(f => {
            const file = new HouseShareFile();
            file.reference = f.reference;
            file.filename = f.title;
            file.url = f.url;
            file.order = f.order;
            file.title = f.title;
            file.type = f.type;
            file.public = f.public;
            file.originalFilename = f.originalFilename;
            file.thumbnail = f.thumbnail;
            file.existingFile = true;
            file.existingFileName = f.title;
            return file;
        });
    }

    getChanges(model: JsonPatch[], control: UntypedFormArray, path: string, key: string) {
        const files = control.value as HouseShareFile[];
        const newFiles = [];
        for (const file of files) {
            const patchedFile = this.prepareFilePost(file);
            newFiles.push(patchedFile);
        }
        model.push(new JsonPatch('replace', `${ path }/${ key }`, newFiles));
    }

    processHttpEvent(event: HttpEvent<any>): number {
        if (event.type === HttpEventType.UploadProgress) {
            return Math.round(100 * event.loaded / event.total);
        }
    }

    parseCsv(fileData): any[][] {
        fileData = fileData.split(',')[1];
        fileData = decodeURIComponent(Array.prototype.map.call(atob(fileData), (c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""))
        return this.ngxCsvParser.csvStringToArray(fileData, ',');
    }

    parseXls(dataString: string) : any[][] {
        dataString = dataString.split(',')[1];
        const buf = window.Buffer.from(dataString, 'base64');
        const book = read(buf, { type: 'buffer' });
        const sheet = book.Sheets[book.SheetNames[0]];
        const result = utils.sheet_to_csv(sheet, { FS: ',' });
        return this.ngxCsvParser.csvStringToArray(result, ',');
    }

    getSignedVideoUrl(reference: string) {
        const url = `${this.configService.baseUrl}/file/get-signed-video-url/${reference}`
        return this.http.get<{ signedUrl: string }>(url);
    }

    convertBlobToFile(file: File): Observable<HouseShareFile> {
        const houseShareFile = new HouseShareFile();
        houseShareFile.contentType = file.type;
        houseShareFile.reference = Guid.newGuid();
        houseShareFile.filename = file.name;
        houseShareFile.originalFilename = file.name;
        houseShareFile.isNew = true;
        houseShareFile.size = file.size;

        const fileExtension = this.getFileExtension(file.name);
        houseShareFile.extension = fileExtension;

        const dataStringReader = new FileReader();
        const dataStringEvent = fromEvent(dataStringReader, 'load');

        dataStringReader.readAsDataURL(file);

        return dataStringEvent
            .pipe(tap(_  => {
                    houseShareFile.dataString = dataStringReader.result as string;
                    houseShareFile.type = houseShareFile.assumedType;
                }),
                map(() => houseShareFile));
    }

    private getFileExtension(filename: string): string {
        const dotIndex = filename.lastIndexOf('.');
        if (dotIndex !== -1) {
            return "." + filename.substr(dotIndex + 1).toLowerCase();

        }
        return '';
    }

    private getPresignedUploadUrl(filename: string, fileType: string) {
        const params = new HttpParams()
            .set('fileType', fileType)
            .set('filename', filename);

        return this.http.get<PresignedUploadUrl>(`${this.configService.baseUrl}/file/presigned-upload-url?${params.toString()}`);
    }

    uploadFile(file: HouseShareFile | FilePost) : Observable<HouseShareFile | FilePost> {
        if (!file || file.key || !file.dataString || file.existingFile || !(file as HouseShareFile).isNew) {
            return of(file);
        }

        const dataString = file.dataString.split(',')[1];
        const blob = this.base64ToBlob(dataString, file.contentType);

        return this.getPresignedUploadUrl(file.filename, file.contentType)
            .pipe(mergeMap(url => {
                const headers = {
                    'Content-Type': file.contentType
                };
                return this.http.put(url.presignedUrl, blob, {headers, responseType: 'text', reportProgress: true})
                    .pipe(
                        tap(_ => file.key = url.key),
                        map(_ => file));
            }));
    }

    uploadFiles(files: HouseShareFile[] | FilePost[]) : Observable<(HouseShareFile | FilePost)[]> {
        if (!files) {
            return of([]);
        }

        if (files.length === 0) {
            return of(files);
        }

        return forkJoin(files.map(file => this.uploadFile(file)));
    }

    private base64ToBlob(base64: string, contentType: string = '', sliceSize: number = 512): Blob {
        const byteCharacters = atob(base64);
        const byteArrays: Uint8Array[] = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }
}
