import { SupplierInvoiceStatuses } from "../supplier/maintenance-invoice-statuses";
import { SupplierInvoiceTypes } from "../supplier/maintenance-invoice-types";
import {File} from "../file/file";
import {FinancialItem} from "../finance/financial-item";
import { OrganisationSupplier } from "../organisation-supplier/organisation-supplier";

export class SupplierInvoice {
    guid: string;

    amount: number;
    amountTax: number;
    amountTaxPercentage: number;
    totalAmount: number;

    invoiceNumber?: string;
    invoiceDate?: Date;

    payByDate?: Date;
    type: SupplierInvoiceTypes;
    status: SupplierInvoiceStatuses;

    files: File[];
    financialItems?: FinancialItem[];
    organisationSupplier?: OrganisationSupplier;
}
