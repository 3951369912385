import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';

import { Dropdown } from '../models/dropdown/dropdown';
import { DomService } from './dom.service';

@Injectable()
export class DropdownService {

    private callbacks: Map<string, Dropdown> = new Map<string, Dropdown>();

    constructor(domService: DomService) {
        fromEvent(document, 'click')
            .subscribe((event: MouseEvent) => {
                this.callbacks.forEach((dropdown: Dropdown, key: string) => {
                    if (!dropdown.component) {
                        this.removeCallback(dropdown);
                        return;
                    }

                    if (!domService.isDescendant(dropdown.component.nativeElement, event.target) && ((<HTMLInputElement>event.target).attributes["data-target"] == null || (<HTMLInputElement>event.target).attributes["data-target"].value != dropdown.component.nativeElement.id)) {
                        dropdown.callback();
                    }
                });
            });
    }

    addCallback(dropdown: Dropdown): void {
        if (!dropdown || !dropdown.component) {
            return;
        }

        this.callbacks.set(dropdown.key, dropdown);
    }

    removeCallback(dropdown: Dropdown): void {
        if (!dropdown || dropdown.component) {
            return;
        }

        this.callbacks.delete(dropdown.key);
    }
}
