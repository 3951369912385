import {Component} from "@angular/core";
import {DateTime} from "luxon";

@Component({
    selector: "copyright-text",
    template: `
        <span>&copy; 2020-{{currentYear}} Company For Life Ltd.</span>
    `,
    standalone: true,
})
export class CopyrightTextComponent {
    currentYear = DateTime.now().year
}
