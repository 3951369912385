import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';

@Injectable()
export class ImageService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getImageData(url: string): Observable<string> {
        return this.http.get(`${this.configService.baseUrl}/image/image-data/${encodeURIComponent(url)}`, { responseType: 'text' });
    }
}
