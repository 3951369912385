import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map} from 'rxjs/operators';

import { AccountService } from './account.service';


@Injectable()
export class RegistrationAuthGuard implements CanActivate, CanActivateChild {

    constructor(private accountService: AccountService, private _router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkIsUnauthorised(route);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkIsUnauthorised(childRoute);
    }

    private checkIsUnauthorised(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
        const user = this.accountService.getCurrentUser();
        if (user) {
            let prom = null;

            let type = route.queryParamMap.get('type');
            let guid = route.queryParamMap.get('guid');

            if (type != null && type != "") {
                prom = this._router.navigate(['/i/t/accept'], { queryParams: { type: type, guid: guid } });
            }
            else {
                if (user.isManager) {
                    prom = this._router.navigate(['/manage']);
                } else if (user.isTenant) {
                    prom = this._router.navigate(['/my-home']);
                } else if (user.isOnboarding) {
                    prom = this._router.navigate(['/my-home']);
                } else if (user.isViewing && user.unclaimedAccount === true) {
                    prom = this._router.navigate(['/my-viewings']);
                } else if (user.isViewing && user.unclaimedAccount === false) {
                    prom = this._router.navigate(['/my-home']);
                } else {
                    prom = this._router.navigate(['/find-a-home']);
                }
            }

            return from(prom)
                .pipe(
                    map(() => false)
                );
        }
        return true;
    }
}
