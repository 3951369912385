import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, of} from "rxjs";

import {PermissionResources} from "../models/authorisation/permission-resources";
import {PermissionAccessActions} from "../models/authorisation/permission-access-actions";
import {ConfigService} from "./config.service";
import {Permission} from "../models/authorisation/permission";
import {tap} from "rxjs/operators";
import {AccountService} from "./account.service";

@Injectable()
export class AuthorisationService {

    private lastOrganisationReference: string;
    private permissions: Permission[];
    constructor(private http: HttpClient, private configService: ConfigService, private accountService: AccountService) {
        this.accountService.watchCurrentUser()
            .subscribe(user => this.permissions = null);
    }

    getPermissions(organisationReference: string | null): Observable<Permission[]> {
        if (this.permissions) {
            return of(this.permissions);
        }

        let params = new HttpParams();
        if (organisationReference) {
            params = params.set('organisationReference', organisationReference);
        }

        return this.http.get<Permission[]>(`${this.configService.baseUrl}/authorisation/permissions`, { params })
            .pipe(tap(permissions => this.permissions = permissions));
    }

    getResourceAccess(organisationReference: string, resource: PermissionResources): Observable<PermissionAccessActions> {
        let params = new HttpParams();
        if (organisationReference) {
            params = params.set('organisationReference', organisationReference);
        }

        return this.http.get<PermissionAccessActions>(`${this.configService.baseUrl}/authorisation/resource-permissions/${resource}`, { params });
    }

    canWrite(resource: PermissionResources) : boolean {
        return this.check(resource, PermissionAccessActions.WRITE);
    }

    canRead(resource: PermissionResources) : boolean {
        return this.check(resource, PermissionAccessActions.READ);
    }

    check(resource: PermissionResources, action: PermissionAccessActions): boolean {
        if (!this.permissions) {
            if (this.configService.isDev) console.log("Permissions not loaded so defaulting to false = Permission not alloed for action " + action + " on resource " + resource);
            return false;
        }

        const fullAccess = this.permissions.find(p => p.resource === PermissionResources.FULL_ACCESS && p.accessAction === PermissionAccessActions.FULL);
        if (fullAccess) {
            return true;
        }

        const permission = this.permissions.find(p => p.resource === resource);
        if (permission.accessAction == PermissionAccessActions.NONE) {
            return false;
        }

        if (action == PermissionAccessActions.WRITE) {
            return permission.accessAction === PermissionAccessActions.FULL || permission.accessAction === PermissionAccessActions.WRITE;
        }

        if (action === PermissionAccessActions.READ) {
            return permission.accessAction === PermissionAccessActions.FULL || permission.accessAction === PermissionAccessActions.WRITE|| permission.accessAction == PermissionAccessActions.READ;
        }

        return false;
    }
}
