import { Component, ViewChild, ViewEncapsulation } from '@angular/core';

import { ModalTypes } from "../../models/modal/modal-types";
import { ModalService } from "../../services/modal.service";
import { ModalComponent } from "../modal/modal.component";
import { FormNavigateAwayConfirmation } from "../../pages/manage/add-property/form-navigate-away-confirmation";

@Component({
    selector: 'form-navigate-away-confirm',
    templateUrl: './form-navigate-away-confirm.component.html',
    encapsulation: ViewEncapsulation.None
})
export class FormNavigateAwayConfirmComponent {
    ModalTypes = ModalTypes;

    @ViewChild('modal', {static: true}) modal: ModalComponent;

    constructor(private modalService: ModalService) {
    }

    confirmNavigateAway() {
        this.navigateAway(true);
    }

    cancelNavigateAway() {
        this.navigateAway(false);
    }

    private navigateAway(value: boolean) {
        const modalState = this.modalService.modals.get(ModalTypes.FORM_NAVIGATE_AWAY_CONFIRM);
        (modalState.data as FormNavigateAwayConfirmation).navigateAway = value;
        this.modalService.closeModal(ModalTypes.FORM_NAVIGATE_AWAY_CONFIRM);
    }
}
