import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ConfigService } from "./config.service";
import { CalendarEntry } from "../models/calendar/calendar-entry";

@Injectable()
export class CalendarService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getViewingEntries(organisationReference: string): Observable<CalendarEntry[]> {
        return this.http.get<CalendarEntry[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/calendar/property-viewings`);
    }

    getMaintenanceEntries(organisationReference: string): Observable<CalendarEntry[]> {
        return this.http.get<CalendarEntry[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/calendar/maintenance-issues`);
    }

    getActionEntries(organisationReference: string): Observable<CalendarEntry[]> {
        return this.http.get<CalendarEntry[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/calendar/actions`);
    }

    getTenancyEntries(organisationReference: string): Observable<CalendarEntry[]> {
        return this.http.get<CalendarEntry[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/calendar/tenancies`);
    }
}
