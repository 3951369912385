import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'friendlyDescription'})
export class FriendlyDescriptionPipe implements PipeTransform {
    transform(value: { [error: string]: any }, key: string): string {
        if (!value) {
            return '';
        }

        const err = Object.keys(value).pop();

        let isOrAre = 'is';
        if (key.endsWith('s') && key != 'Address' && key != 'clientCostType' && key != 'Status') {
            isOrAre = 'are';
        }

        switch (err) {
            case 'email':
                return ' should be a valid email address';
            case 'required':
            case 'requiredTrue':
                return ` ${ isOrAre } required`;
            case 'saved':
                return ` ${ isOrAre } not saved`;
            case 'invalid-format':
                return ' format is invalid';
            case 'file-empty':
                return ' file size is zero, please check the file and upload it again';
            case 'atLeastOneRequired':
                return ' at least one is required';
            case 'stringAlreadyExists':
                return ' is already in use';
            case 'dateBefore':
                return ` should be before end date`;
            case 'dateAfter':
                return ` should be before start date`;
            case 'min':
                return ` should be a minimum of ${value.min?.min} characters`;
            case 'maxlength':
                return ` is too long (our limit is ${value.maxlength?.requiredLength} characters)`;
            case 'patternWithMessage':
                return value.patternWithMessage;
            case 'alwaysInvalid':
                return value.alwaysInvalid;
            case 'options':
                return value.options || ' does not have a valid option selected.';
            case 'dropdownOptions':
                return value.dropdownOptions || ' does not have a valid option selected.';
            case 'timeRequired':
                return `need a time to be set. Format needs to be HH:mm`;
            case 'invalidViewingTime':
                return ` date-time combination doesn't meet the criterias.` ;
            case 'invalidViewingDay':
                return ` this date is not available as a viewing day.` ;
            case 'timeInPast':
                return ` need a time in the future.` ;
            case 'invalidEmailList':
                return ` should be a valid email address or a list of valid email addresses separated by a comma. Eg. "john@doe.com, jane@doe.com"`;
            case 'emailFromOrgUser':
                return ` cannot be the email address of one of the organisation members.`;
            case 'invalidFormat':
                return ` format is invalid.`;
            case 'holdingFeeTooHigh':
                return ` should not exceed one week's rent`;
            case 'filesSumTooHigh':
                return `${value.filesSumTooHigh}`;
        }
    }
}
