import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SvgIconModule } from '../svg-icon/svg-icon.module';
import {SiteHeaderSupplierComponent} from "./site-header-supplier.component";
import {SpriteImageModule} from "../sprite-image/sprite-image.module";
import {LazyLoadImageModule} from "ng-lazyload-image";

@NgModule({
    imports: [
        CommonModule,
        SvgIconModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        SpriteImageModule,
        LazyLoadImageModule
    ],
    declarations: [
        SiteHeaderSupplierComponent
    ],
    exports: [
        SiteHeaderSupplierComponent
    ]
})

export class SiteHeaderSupplierModule { }
