import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { ConfirmConfiguration } from '../../models/modal/confirm-configuration';
import { ModalTypes } from '../../models/modal/modal-types';
import { ModalService } from '../../services/modal.service';
import {assertNever} from "../../helpers/assert-never";

type ActionConfig = {name?: string; condition?: boolean; requiresConfirmation?: boolean; confirmText?: string; confirmTitle?: string; yesLabel?: string; noLabel?: string; };

@Component({
    selector: 'loader-button',
    templateUrl: './loader-button.component.html',
    encapsulation: ViewEncapsulation.None
})

export class LoaderButtonComponent {

    @Input() type: string = 'button';
    @Input() loadingText: string = 'Loading...';
    @Input() loading: boolean = false;
    @Input() disabled: boolean = false;
    @Input() icon: boolean = false;
    @Input() iconOnly: boolean = false;
    @Input() cssClasses: string = null;
    @Input() iconName: string = null;
    @Input() iconColor: 'cyan' | 'green' | 'dark-green' | 'pink' | 'magenta' | 'orange' | 'grey' | 'light-grey' | 'very-light-grey' | 'light2-grey' | 'teal' | 'white' | 'warning' | 'violet' | 'dark-teal' | 'dark-magenta' | 'black' = null;
    @Input() preserveText: boolean = false;
    @Input() tabIndex: number;
    @Input() requireConfirmation: boolean = false;
    @Input() confirmText: string = "Are you sure?";
    @Input() confirmTitle: string = "";
    @Input() yesLabel: string = "Ok";
    @Input() noLabel: string = "Cancel";
    @Input() hoverName: string = null; //if the button is iconOnly and there is a hovername, it shows to give a hint before the person clicks


    //Expandable
    @Input() expandAction1: ActionConfig = null;
    @Input() expandAction2: ActionConfig = null;
    @Input() expandAction3: ActionConfig = null;
    @Input() expandAction4: ActionConfig = null;
    @Input() expandAction5: ActionConfig = null;
    @Input() expandable: boolean = false;
    @Input() expand: boolean = null;
    @Input() expandDirection: 'left' | 'down' | 'up' | 'right';
    @Input() iconNameExpandAction1: string;
    @Input() iconNameExpandAction2: string;
    @Input() iconNameExpandAction3: string;
    @Input() iconNameExpandAction4: string;
    @Input() iconNameExpandAction5: string;

    @Output() onSubmit = new EventEmitter<void>();
    @Output() onAction1Click = new EventEmitter<void>();
    @Output() onAction2Click = new EventEmitter<void>();
    @Output() onAction3Click = new EventEmitter<void>();
    @Output() onAction4Click = new EventEmitter<void>();
    @Output() onAction5Click = new EventEmitter<void>();

    constructor(private modalService: ModalService) {
    }

    expandActionClick(number: 1 | 2 | 3 | 4 | 5) {
        let emitter: EventEmitter<void> = null;
        let config: ActionConfig = null;

        switch (number) {
            case 1:
                emitter = this.onAction1Click;
                config = this.expandAction1;
                break;
            case 2:
                emitter = this.onAction2Click;
                config = this.expandAction2;
                break;
            case 3:
                emitter = this.onAction3Click;
                config = this.expandAction3;
                break;
            case 4:
                emitter = this.onAction4Click;
                config = this.expandAction4;
                break;
            case 5:
                emitter = this.onAction5Click;
                config = this.expandAction5;
                break;
            default:
                return assertNever(number);
        }

        // No confirmation required
        if (!config.requiresConfirmation) {
            emitter.emit();
            return;
        }

        // Manage confirmation
        let configuration = new ConfirmConfiguration();
        configuration.message = config.confirmText || this.confirmText;
        configuration.title = config.confirmTitle || this.confirmTitle;
        configuration.yesLabel = config.yesLabel || this.yesLabel;
        configuration.noLabel = config.noLabel || this.noLabel;
        configuration.confirmCallback.subscribe(() => {
            emitter.emit();
            this.modalService.closeModal(ModalTypes.CONFIRM_ITEM);
        });

        this.modalService.openModal(ModalTypes.CONFIRM_ITEM, configuration);
    }

    submit() {
        if (this.expandable) {
            if (!this.expand) {
                this.expand = true;
                setTimeout(_ => document.addEventListener('click', (event) => this.expand = false, {once: true, passive: true}), 150);
            } else {
                this.expand = false;
            }
        }
        else if (this.requireConfirmation) {
            this.showConfirm();
        }
        else {
            this.confirm();
        }
    }

    ngOnInit(){
        if (this.cssClasses && this.cssClasses.includes('button--icon-only')) {
            this.iconOnly = true;
        }
        if(this.iconOnly){
            //Prevent ugly looking loading
            this.preserveText = true
        }
    }

    showConfirm() {
        let configuration = new ConfirmConfiguration();
        configuration.message = this.confirmText;
        configuration.title = this.confirmTitle;
        configuration.yesLabel = this.yesLabel;
        configuration.noLabel = this.noLabel;
        configuration.confirmCallback
            .subscribe(() => this.confirm());

        this.modalService.openModal(ModalTypes.CONFIRM_ITEM, configuration);
    }

    confirm() {
        this.modalService.closeModal(ModalTypes.CONFIRM_ITEM);
        this.onSubmit.emit();
    }

    getIconClass() {
        let classes = 'icon';
        if (this.iconColor) {
            classes += ' icon--' + this.iconColor;
        } else {
            if(this.cssClasses){
                const classArray = this.cssClasses.split(' ');
                if (classArray.includes('button--transparent') || classArray.includes('button--ghost') || classArray.includes('button--transparent-full') || classArray.includes('button--cancel')){
                    if(this.disabled){
                        classes += ' icon--light1-grey'
                    } else {
                        classes += ' icon--black'
                    }
                } else {
                    classes += ' icon--white'
                }
            } else {
                classes += ' icon--white'
            }
        }
        return classes;
    }
}
