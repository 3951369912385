import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { BuildingInsurance } from '../models/building-insurance/building-insurance';
import { BuildingInsurancePost } from '../models/building-insurance/building-insurance-post';
import { BuildingInsuranceOverview } from '../models/building-insurance/building-insurance-overview';
import { FileService } from "./file.service";
import {PropertyServicesSummary} from "../models/property-services/property-services-summary.interface";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class BuildingInsuranceService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string): Observable<BuildingInsuranceOverview> {
        return this.http.get<BuildingInsuranceOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/building-insurance/overview`);
    }

    getPastList(propertyReference: string): Observable<BuildingInsurance[]> {
        return this.http.get<BuildingInsurance[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/building-insurance/past`);
    }

    add(propertyReference: string, model: BuildingInsurancePost): Observable<HttpEvent<BuildingInsurance>> {
        return this.fileService.uploadFiles(model.buildingInsuranceFiles)
            .pipe(tap(_ => model.buildingInsuranceFiles = this.fileService.prepareFilePosts(model.buildingInsuranceFiles)),
                mergeMap(_ => this.http.post<BuildingInsurance>(`${this.configService.baseUrl}/manage/property/${propertyReference}/building-insurance`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(propertyReference: string, item: BuildingInsurance): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/building-insurance/${item.guid}`);
    }

    getSummary(propertyReference: string) {
        return this.http.delete<PropertyServicesSummary>(`${this.configService.baseUrl}/manage/property/${propertyReference}/building-insurance/summary`);
    }
}
