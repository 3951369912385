import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { Interest } from '../models/interest/interest';
import { RegistrationLookupLists } from '../models/lookup/registration-lookup-lists';
import { RightToRentIdentityDocumentType } from '../models/right-to-rent/right-to-rent-identity-document-type';
import { Amenities } from '../models/amenity/amenities';
import { Address } from '../models/address/address';
import { ContactPost } from '../models/lookup/contact-post';
import { SpriteImage } from '../models/image/sprite-image';
import { Bank } from '../models/finance/bank';
import {UnreadArticles} from "../models/article/unread-articles";
import {OrganisationProfile} from "../models/organisation/organisation-profile";
import {ActionTypes} from "../models/action/action-types";
import {ActionType} from "../models/action/action-type";
import {SubscriptionPlanOptions} from "../models/accounting/subscription-plans-options";

const spriteStorageName: string = 'SPRITE_IMAGES';
const spriteStorageVersion: string = '1';

@Injectable()
export class LookupService {

    interests: Interest[] = [];
    mainCities: Address[] = [];
    spriteImages: SpriteImage[];

    constructor(private httpClient: HttpClient, private config: ConfigService) {
    }

    getInterests(): Observable<Interest[]> {
        return this.httpClient.get<Interest[]>(`${this.config.baseUrl}/interests`);
    }

    getRightToRentIdentityDocumentType(): Observable<RightToRentIdentityDocumentType[]> {
        return this.httpClient.get<RightToRentIdentityDocumentType[]>(`${this.config.baseUrl}/right-to-rent-identity-document-types`);
    }

    //getMainCities(): Address[] {
    //    return this.mainCities;

    //}

    //setMainCities(mainCities: Address[]): void {
    //    this.mainCities = mainCities;
    //}

    getInterest(reference: string): Interest {
        let interests = this.interests.filter(x => x.reference === reference);
        if (interests.length) {
            return interests[0];
        }

        return null;
    }

    getRegistrationLookups(ar: string = null): Observable<RegistrationLookupLists> {
        let params = new HttpParams();

        if (ar != null) {
            params = params.set("ar", ar.toString());
        }

        return this.httpClient.get<RegistrationLookupLists>(`${this.config.baseUrl}/lookup/registration`, {params});
    }

    getAmenities(): Observable<Amenities> {
        return this.httpClient.get<Amenities>(`${this.config.baseUrl}/amenities`);
    }

    getManagerWorkflowActionTypes(): Observable<ActionType[]> {
        return this.httpClient.get<ActionType[]>(`${this.config.baseUrl}/action-types/workflow/manager`);
    }

    postContact(model: ContactPost): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.config.baseUrl}/contact`, model);
    }

    postSupport(model: ContactPost): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.config.baseUrl}/support`, model);
    }

    postRecommendationResponse(organisationReference: string, campaign: string): Observable<boolean> {

        return this.httpClient.post<boolean>(`${this.config.baseUrl}/campaign/${organisationReference}/${campaign}`, null);
    }


    requestDemo(model: ContactPost): Observable<string> {
        return this.httpClient.post<string>(`${this.config.baseUrl}/demo`, model);
    }

    getOrganisationProfile(organistionReference: string): Observable<OrganisationProfile> {
        return this.httpClient.get<OrganisationProfile>(`${this.config.baseUrl}/lookup/organisations/${organistionReference}`);
    }

    getSubscriptionPlans(): Observable<SubscriptionPlanOptions> {
        return this.httpClient.get<SubscriptionPlanOptions>(`${this.config.baseUrl}/lookup/subscription-plans`);
    }

    getSpriteImage(reference: string): SpriteImage {
        if (this.spriteImages == null) {

            if (localStorage.getItem(spriteStorageName + "-VERSION") == spriteStorageVersion) {
                this.spriteImages = JSON.parse(localStorage.getItem(spriteStorageName));
            }

            if (this.spriteImages == null) {
                this.spriteImages = [];
            }
        }

        let image = this.spriteImages.find(x => x.r === this.getSpriteShortReference(reference));
        return image;
    }

    // Subject handling is used here so that we only initiate a single HTTP request even if multiple callers hit the 'loadSpriteImage' before the first result has come back
    _loadSpriteImageSubjects: {[p: string]: Subject<boolean>} = {};
    loadSpriteImage(reference: string): Subject<boolean> {
        const fileUrl = this.getSpriteFileByReference(reference);

        if (!this._loadSpriteImageSubjects[fileUrl]) {
            // We haven't created our subject (and hence not initialised the first HTTP request)
            // Create our subject which will emit the response, initiate the HTTP request and return a new observable from the subject
            this._loadSpriteImageSubjects[fileUrl] = new Subject<boolean>();
            this.httpClient.get<any>(fileUrl).subscribe(response => {
                // Set the sprite images for this file and now inform all listeners they can load it from the cache store
                this.setSpriteImages(response.i, fileUrl);


                this._loadSpriteImageSubjects[fileUrl].next(true);
                this._loadSpriteImageSubjects[fileUrl].complete();
            });

            return this._loadSpriteImageSubjects[fileUrl];
        }

        // We have the sprite, return it immediately via a behaviour subject
        if (this._loadSpriteImageSubjects[fileUrl].closed) {
            return new BehaviorSubject(true);
        }

        // We are loading the sprite
        return this._loadSpriteImageSubjects[fileUrl];
    }

    getSpriteFileByReference(reference): string {
        switch (reference) {

            case "performance":
            case "actions":
            case "block":
            case "boiler-servicing":
            case "builder":
            case "building-insurance":
            case "calendar":
            case "cleaning":
            case "credit-control":
            case "service-default":
            case "deposit-management":
            case "electrical-installation-condition-report":
            case "energy-performance-certificate":
            case "feedback":
            case "finances":
            case "fire-safety":
            case "gardening":
            case "gas-safety-certificate":
            case "group":
            case "how-to-rent-guide":
            case "health-and-safety":
            case "history":
            case "hmo-licence":
            case "household-bills":
            case "housemates":
            case "inspection":
            case "insurance":
            case "inventory":
            case "invoice":
            case "manager-info-panel":
            case "messaging":
            case "mortgage-renewal":
            case "occupancy":
            case "onboarding":
            case "past-tenancies":
            case "performance":
            case "portable-appliance-test":
            case "profile":
            case "property":
            case "property-access":
            case "property-compliancy":
            case "property-details":
            case "property-marketing":
            case "property-ownership":
            case "property-owners":
            case "property-photography":
            case "reference-check":
            case "recurring-items":
            case "rent-collection":
            case "rent-guarantee-insurance":
            case "rent-review":
            case "repairs":
            case "rewards":
            case "right-to-rent":
            case "room":
            case "room-details":
            case "services":
            case "streetmap":
            case "suppliers":
            case "team":
            case "tenancy-agreement":
            case "tenancy-summary":
            case "tenant-find":
            case "tenant-info":
            case "tenant-info-panel":
            case "tenant-referencing":
            case "tenant-rewards":
            case "transactions":
            case "viewing":
                return "https://coho-public.s3.eu-west-2.amazonaws.com/sprites/services.json";
            case "arts-and-crafts":
            case "fitness":
            case "food":
            case "games":
            case "movies":
            case "music":
            case "nature":
            case "nights-out":
            case "parties":
            case "quiet-nights":
            case "reading":
            case "sports":
            case "travelling":
            case "tv":
            case "video-games":
                return "https://coho-public.s3.eu-west-2.amazonaws.com/sprites/interests.json";
            case "user-0":
            case "user-1":
            case "user-2":
            case "user-3":
            case "user-4":
            case "user-5":
            case "user-6":
            case "user-7":
            case "user-8":
            case "user-9":
                return "https://coho-public.s3.eu-west-2.amazonaws.com/sprites/users.json";
            case "nav-help-product-guide":
            case "nav-help-troubleshooting":
            case "nav-help-tutorials":
            case "nav-help-support-ticket":
            case "nav-org-actions":
            case "nav-org-calendar":
            case "nav-org-performance":
            case "nav-org-properties":
            case "nav-org-compliance":
            case "nav-org-inspection":
            case "nav-property-inspection":
            case "nav-org-property-access":
            case "nav-property-property-access":
            case "nav-org-welcome-pack":
            case "nav-org-misc-info":
            case "nav-org-maintenance":
            case "nav-org-suppliers":
            case "nav-org-rent":
            case "nav-org-bookkeeping":
            case "nav-org-transactions":
            case "nav-org-rent-review":
            case "nav-org-property-owners":
            case "nav-org-recurring-items":
            case "nav-org-tenant-find":
            case "nav-org-onboarding":
            case "nav-org-occupancy":
            case "nav-org-feedback":
            case "nav-org-account":
            case "nav-org-users":
            case "nav-org-history":
            case "nav-org-credit-control":
            case "nav-org-client-accounting":
            case "nav-property-rooms":
            case "nav-property-property-ownership":
            case "nav-property-hmo-licence":
            case "nav-property-gas-safety-certificate":
            case "nav-property-fire-safety":
            case "nav-property-health-and-safety":
            case "nav-property-energy-performance-certificate":
            case "nav-property-electrical-installation-condition-report":
            case "nav-property-boiler-servicing":
            case "nav-property-portable-appliance-test":
            case "nav-property-building-insurance":
            case "nav-property-mortgage-renewal":
            case "nav-property-marketing":
            case "nav-property-details":
            case "nav-property-inventory-builder":
            case "nav-property-services":
            case "nav-room-tenancy-summary":
            case "nav-room-tenancy-agreement":
            case "nav-room-tenant-info":
            case "nav-room-rtr":
            case "nav-room-deposit":
            case "nav-room-inventory":
            case "nav-room-details":
            case "nav-room-past":
                return "https://coho-public.s3.eu-west-2.amazonaws.com/sprites/nav.json";
            default:
                return "https://coho-public.s3.eu-west-2.amazonaws.com/sprites/default.json";
                //return "https://d19qeljo1i8r7y.cloudfront.net/sprites/default.json";
        }
    }

    getSpriteShortReference(longReference): string {
        switch (longReference) {

            case "performance":
                return "p";
            case "actions":
                return "a";
            case "block":
                return "bl";
            case "boiler-servicing":
                return "bs";
            case "builder":
                return "invb";
            case "building-insurance":
                return "bi";
            case "calendar":
                return "ca";
            case "cleaning":
                return "c";
            case "credit-control":
                return "cc";
            case "client-accounting":
                return "cla";
            case "service-default":
                return "d";
            case "deposit-management":
                return "dm";
            case "electrical-installation-condition-report":
                return "eicr";
            case "energy-performance-certificate":
                return "epc";
            case "finances":
                return "f";
            case "feedback":
                return "fb";
            case "fire-safety":
                return "fs";
            case "gardening":
                return "g";
            case "gas-safety-certificate":
                return "gsc";
            case "group":
                return "gr";
            case "health-and-safety":
                return "hs";
            case "history":
                return "hi";
            case "hmo-licence":
                return "hl";
            case "household-bills":
                return "hb";
            case "housemates":
                return "hm";
            case "inspection":
                return "insp";
            case "insurance":
                return "i";
            case "inventory":
                return "inv";
            case "invoice":
                return "invoice";
            case "manager-info-panel":
                return "mip";
            case "messaging":
                return "m";
            case "mortgage-renewal":
                return "mr";
            case "occupancy":
                return "occ";
            case "onboarding":
                return "o";
            case "performance":
                return "p";
            case "past-tenancies":
                return "past";
            case "portable-appliance-test":
                return "pat";
            case "profile":
                return "profile";
            case "property":
                return "property";
            case "property-access":
                return "pa";
            case "property-compliancy":
                return "pc";
            case "property-details":
                return "pd";
            case "property-marketing":
                return "pm";
            case "property-ownership":
                return "po";
            case "property-owners":
                return "pos";
            case "property-photography":
                return "pp";
            case "reference-check":
                return "ref";
            case "recurring-items":
                return "ri";
            case "rent-collection":
                return "rent";
            case "rent-guarantee-insurance":
                return "rg";
            case "rent-review":
                return "rr";
            case "repairs":
                return "r";
            case "rewards":
                return "reward";
            case "right-to-rent":
                return "rtr";
            case "room":
                return "room";
            case "room-details":
                return "rd";
            case "streetmap":
                return "sm";
            case "services":
                return "se";
            case "suppliers":
                return "s";
            case "team":
                return "t";
            case "tenancy-agreement":
                return "ta";
            case "tenancy-summary":
                return "tsu";
            case "tenant-find":
                return "tf";
            case "tenant-info":
                return "ti";    
            case "tenant-info-panel":
                return "tip";
            case "tenant-referencing":
                return "tr";
            case "tenant-rewards":
                return "trw";
            case "transactions":
                return "ts";
            case "viewing":
                return "v";
            case "user-0":
                return "0";
            case "user-1":
                return "1";
            case "user-2":
                return "2";
            case "user-3":
                return "3";
            case "user-4":
                return "4";
            case "user-5":
                return "5";
            case "user-6":
                return "6";
            case "user-7":
                return "7";
            case "user-8":
                return "8";
            case "user-9":
                return "9";
            case "nav-help-product-guide":
                return "nhpg";
            case "nav-help-troubleshooting":
                return "nhtr";
            case "nav-help-tutorials":
                return "nhtu";
            case "nav-help-support-ticket":
                return "nhst";
            case "nav-org-actions":
                return "noac";
            case "nav-org-calendar":
                return "noca";
            case "nav-org-performance":
                return "nope";
            case "nav-org-properties":
                return "nopr";
            case "nav-org-compliance":
                return "noco";
            case "nav-org-inspection":
            case "nav-property-inspection":
                return "noin";
            case "nav-org-property-access":
            case "nav-property-property-access":
                return "nopa";
            case "nav-org-welcome-pack":
                return "nowp";
            case "nav-org-misc-info":
                return "nomx";
            case "nav-org-maintenance":
                return "noma";
            case "nav-org-suppliers":
                return "nosu";
            case "nav-org-rent":
                return "norc";
            case "nav-org-bookkeeping":
                return "nobk";
            case "nav-org-transactions":
                return "nobt";
            case "nav-org-rent-review":
                return "norr";
            case "nav-org-property-owners":
                return "nopo";
            case "nav-org-recurring-items":
                return "nori";
            case "nav-org-tenant-find":
                return "notf";
            case "nav-org-onboarding":
                return "noon";
            case "nav-org-occupancy":
                return "nooc";
            case "nav-org-feedback":
                return "nofe";
            case "nav-org-account":
                return "noya";
            case "nav-org-users":
                return "nous";
            case "nav-org-history":
                return "noal";
            case "nav-org-credit-control":
                return "nocc";
            case "nav-org-client-accounting":
                return "nocl";
            case "nav-property-rooms":
                return "npro";
            case "nav-property-property-ownership":
                return "nppx";
            case "nav-property-hmo-licence":
                return "nphl";
            case "nav-property-gas-safety-certificate":
                return "npgs";
            case "nav-property-fire-safety":
                return "npfs";
            case "nav-property-health-and-safety":
                return "nphs";
            case "nav-property-energy-performance-certificate":
                return "npen";
            case "nav-property-electrical-installation-condition-report":
                return "npel";
            case "nav-property-boiler-servicing":
                return "npbs";
            case "nav-property-portable-appliance-test":
                return "nppo";
            case "nav-property-building-insurance":
                return "npbi";
            case "nav-property-mortgage-renewal":
                return "npmr";
            case "nav-property-marketing":
                return "nppm";
            case "nav-property-details":
                return "nppd";
            case "nav-property-inventory-builder":
                return "npib";
            case "nav-property-services":
                return "npse";
            case "nav-room-tenancy-summary":
                return "nrts";
            case "nav-room-tenancy-agreement":
                return "nrta";
            case "nav-room-tenancy-summary":
                return "nrta";
            case "nav-room-tenant-info":
                return "nrti";
            case "nav-room-rtr":
                return "nrri";
            case "nav-room-deposit":
                return "nrdm";
            case "nav-room-inventory":
                return "nrin";
            case "nav-room-details":
                return "nrrd";
            case "nav-room-past":
                return "nrpt";
            case "arts-and-crafts":
                return "art";
            case "fitness":
                return "fit";
            case "food":
                return "food";
            case "games":
                return "game";
            case "movies":
                return "movie";
            case "music":
                return "music";
            case "nature":
                return "nature";
            case "nights-out":
                return "out";
            case "parties":
                return "party";
            case "quiet-nights":
                return "quiet";
            case "reading":
                return "read";
            case "sports":
                return "sport";
            case "travelling":
                return "travel";
            case "tv":
                return "tv";
            case "video-games":
                return "video";
            case "landlord":
                return "l";
            case "male":
                return "mg";
            case "female":
                return "fg";
            case "other":
                return "og";
            case "unspecified":
                return "ug";
            case "females":
                return "fe";
            case "males":
                return "ma";
            case "mixed":
                return "mix";
            case "commercial":
                return "co";
            case "mixed-use":
                return "mu";
            case "parking":
                return "park";
            case "retail":
                return "re";
            case "any-tenants":
                return "at";
            case "professional":
                return "pt";
            case "students":
                return "st";
            case "social-housing":
                return "sh";
            case "placeholder-property":
                return "placep";
            case "placeholder-property-add":
                return "placepa";
            case "placeholder-room":
                return "placer";
            case "placeholder-room-add":
                return "placera";
            case "placeholder-org":
                return "placeo";
            case "placeholder-org-add":
                return "placeoa";
            case "placeholder-user":  //Defunct
                return "placeu";
            case "placeholder-video":
                return "placev";
            case "document":
                return "file-doc";
            case "file-pdf":
                return "pdf";
            case "file-image":
                return "img";
            case "file-video":
                return "vid";
            case "file-word":
                return "word";
            case "apple-app-store":
                return "apple";
            case "google-play":
                return "google";
            case "coho-logo":
                return "coho";
            case "coho-logo-blue":
                return "cohoblue";
            case "coho-logo-report-black":
                return "cohoblack";
            case "how-to-rent-guide":
                return "h2rg";
            case "support-ticket":
                return "sut";
            case "tutorial":
                return "tu";
            case "workflow":
                return "wf";
            default:
                return longReference;
        }

    }

    setSpriteImages(spriteImages: SpriteImage[], reference: string): SpriteImage {

        for (let spriteImage of spriteImages) {
            let existingImage = this.spriteImages.find(x => x.r == this.getSpriteShortReference(spriteImage.r));

            if (existingImage != null) {
                existingImage.d = spriteImage.d;
            }
            else {
                this.spriteImages.push(spriteImage);
            }
        }

        localStorage.setItem(spriteStorageName, JSON.stringify(this.spriteImages));
        localStorage.setItem(spriteStorageName + "-VERSION", spriteStorageVersion);

        let existingImage = this.spriteImages.find(x => x.r == this.getSpriteShortReference(reference));
        return existingImage;
    }

    dismissKnowledgebaseItem(reference: string) : Observable<boolean> {
        const url = `${this.config.baseUrl}/knowledgebase/${reference}/dismiss`
        return this.httpClient.post<boolean>(url, null);
    }
}
