import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import moment from 'moment';

import {ConfigService} from './config.service';
import {FileService} from './file.service';
import {TransactionItemPost} from '../models/finance/transaction-item-post';
import {TransactionItem} from '../models/finance/transaction-item';
import {TransactionItemImport} from '../models/finance/transaction-item-import';
import {TransactionOverview} from '../models/finance/transaction-overview';
import {TransactionItemPatch} from '../models/finance/transaction-item-patch';
import {TransactionMatchSummary} from '../models/finance/transaction-match-summary';
import {ImportTransactionItems} from '../models/finance/import-transaction-items';
import {RentMatch} from "../models/finance/rent-match";
import {TransactionItemFilterStatuses} from "../models/finance/transaction-item-filter-statuses";
import {BankAccount} from '@stripe/stripe-js';
import {Bank} from '../models/finance/bank';
import {TransactionRentMatchRule} from "../models/finance/transaction-rent-match-rule";
import {BaseEntityStatuses} from "../models/base-entity-statuses";
import {RentRuleMatchBehaviour} from "../models/finance/rent-rule-match-behaviour";
import {TransactionRentMatchRulePostPatch} from "../models/finance/transaction-rent-match-rule-post-patch";

@Injectable()
export class TransactionService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getRentMatches(organisationReference: string, lastTransactionReference: string = ""): Observable<RentMatch[]> {
        let params = new HttpParams();

        if (lastTransactionReference != null) {
            params = params.set('lastTransactionReference', lastTransactionReference);
        }

        return this.http.get<RentMatch[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/rent-matches`, { params });
    }

    getTransactionMatchSummary(organisationReference: string): Observable<TransactionMatchSummary> {
        return this.http.get<TransactionMatchSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/rent-match-summary`);
    }

    getOrganisationOverview(organisationReference: string, startDate: Date= null, endDate: Date = null, description: string = null,
                            transactionStatus:TransactionItemFilterStatuses = null, accountsFilterCsl:string = null, amountFrom: number = null,
                            amountTo: number = null): Observable<TransactionOverview> {
        let params = new HttpParams();

        if (startDate != null) {
            params = params.set('startDate', moment(startDate).toISOString());
        } else {
            params.set('startDate', null);
        }

        if (endDate != null) {
            params = params.set('endDate', moment(endDate).toISOString());
        } else {
            params.set('endDate', null);
        }

        params = params.set('description', description ?? "");
        params = params.set('transactionFilterStatusValue', transactionStatus ?? TransactionItemFilterStatuses.ALL);
        if (accountsFilterCsl) params = params.set('accountsFilterCsl', accountsFilterCsl);
        params = params.set('amountFrom', amountFrom ?? "");
        params = params.set('amountTo', amountTo ?? "");

        return this.http.get<TransactionOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/overview`, { params });
    }

    getTransactionItems(organisationReference: string, startDate: Date = null, endDate: Date = null, description: string = null, amountFrom: number,
                        amountTo: number, transactionFilterStatus: TransactionItemFilterStatuses, accountsFilterCsl: string): Observable<TransactionItem[]> {
        let params = new HttpParams();
        if (startDate != null) {
            params = params.set('startDate', moment(startDate).toISOString());
        }

        if (endDate != null) {
            params = params.set('endDate', moment(endDate).toISOString());
        }

        if (description != null) {
            params = params.set('description', description);
        }

        if (amountFrom != null) {
            params = params.set('amountFrom', amountFrom);
        }

        if (amountTo != null) {
            params = params.set('amountTo', amountTo);
        }

        if (accountsFilterCsl) params = params.set('accountsFilterCsl', accountsFilterCsl);

        if (transactionFilterStatus != null) {
            params = params.set('transactionFilterStatus', transactionFilterStatus);
        }

        params = params.set('includeOrganisationItems', true);

        return this.http.get<TransactionItem[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/transaction-item`, { params });
    }

    getTransactionItem(organisationReference: string, guid: string) {
        return this.http.get<TransactionItem>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/transaction-item/${guid}`);
    }

    patchTransactionItem(organisationReference: string, guid: string, model: TransactionItemPatch): Observable<TransactionItem> {

        return this.http.patch<TransactionItem>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/transaction-item/${guid}`, model);
    }

    deleteTransactionItem(organisationReference: string, guid: string): Observable<number> {

        return this.http.delete<number>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/transaction-item/${guid}`);
    }

    /*importTransactionItems(organisationReference: string, model: TransactionItemImport) : Observable<{ transactions:TransactionItem[], result: object}> {
        return this.http.post<{ transactions:TransactionItem[], result: object}>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/finance/import-transaction-items`, model);
    }*/

    addTransactionItem(organisationReference: string, model: TransactionItemPost) : Observable<TransactionItem> {
        return this.http.post<TransactionItem>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/transaction/transaction-item`, model);
    }

    importTransactionItems(organisationReference: string, model: TransactionItemImport) : Observable<ImportTransactionItems> {
        return this.http.post<ImportTransactionItems>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/transaction/import-transaction-items`, model);
    }

    getBanksAndAccounts(organisationReference: string) : Observable<Bank[]> {
        return this.http.get<any>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/transaction/banks-and-accounts`);
    }

    setTransactionIgnored(organisationReference: string, transactionGuid: string, ignored: boolean) {
        return this.http.post<{ isExcluded: boolean }>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/transaction-item/${transactionGuid}/set-ignored`, ignored);
    }

    setTransactionsNotRent(organisationReference: string, transactionGuid: string[]) : Observable<void> {
        return this.http.post<void>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/toggle-is-rent`, transactionGuid);
    }

    refreshAutoMatches(organisationReference: string) : Observable<void> {
        return this.http.post<void>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/transaction/refresh-auto-matches`, null);
    }

    retryAutoMatch(organisationReference: string, transactionGuids: string[]) {
        return this.http.post<void>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/transaction/retry-auto-matching`, transactionGuids);
    }

    syncTransactions(organisationReference: string, waitForSuccess: boolean = false) {
        let params = new HttpParams();
        params = params.set('waitForSuccess', waitForSuccess ? 'true' : 'false');

        return this.http.post<{saves: number}>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/transaction/sync?${ params.toString() }`, null);
    }

    syncTransactionsOneAccount(organisationReference: string, accountGuid: string) {
        return this.http.post<{saves: number}>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/transaction/sync/${accountGuid}`, null);
    }

    getRentMatchRules(organisationReference: string){
        return this.http.get<TransactionRentMatchRule[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/rent-match-rules`);
    }

    setRentMatchRuleStatus(organisationReference: string, ruleGuid: string, entityStatus: BaseEntityStatuses){
        return this.http.patch<TransactionRentMatchRule>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/rent-match-rules/${ruleGuid}/set-entity-status/${entityStatus}`, null)
    }

    updateRentMatchRule(organisationReference: string, ruleGuid: string, model: TransactionRentMatchRulePostPatch) {
        return this.http.patch<TransactionRentMatchRule>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/transaction/rent-match-rules/${ruleGuid}`, model)
    }

}
