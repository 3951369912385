export enum LayoutType {
    EMPTY = -1,
    STANDARD = 0,
    REGISTRATION = 1,
    LANDING = 2,
    ACCOUNT_LANDING = 3,
    MARKETING = 4,
    NEWS = 5,
    CONVERSATION = 6,
    FEATURE = 7,
    HOWTO = 8,
    MAGIC = 9,
    PARTNERS = 10,
    STATIC = 11,
    DEMO = 12,
    SUPPLIER = 13,
    SUPPLIER_CONVERSATION = 14,
    VIEWINGS = 15,
    MANAGE = 16
}
