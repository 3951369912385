import { Injectable } from '@angular/core';
import {ActivatedRoute, Resolve} from '@angular/router';

import { Observable } from 'rxjs';
import { Amenities } from '../models/amenity/amenities';
import { ManageOrganisationService } from '../services/manage-organisation.service';
import { OrganisationFeatures } from '../models/organisation/organisation-features';
import {Permission} from "../models/authorisation/permission";
import {AuthorisationService} from "../services/authorisation.service";

@Injectable()
export class AuthorisationResolver implements Resolve<Permission[]> {

    constructor(private authorisationService: AuthorisationService, private manageOrganisationService: ManageOrganisationService) { }

    resolve(): Observable<Permission[]> {
        return this.authorisationService.getPermissions(this.manageOrganisationService.getOrganisationReference());
    }
}
