import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { InvestmentProperties } from '../models/investment-property/investment-properties';
import { Observable } from 'rxjs';
import { InvestmentProperty } from '../models/investment-property/investment-property';
import { InvestmentPropertyInterestPost } from '../models/investment-property/investment-property-interest-post';

@Injectable()
export class InvestmentPropertyService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getInvestmentProperties(locationReference: string, maximumBudget: number, page: number): Observable<InvestmentProperties> {
        let params = new HttpParams();
       
        if (maximumBudget) {
            params = params.set("maximumBudger", maximumBudget.toString());
        }

        if (page) {
            params = params.set("page", page.toString());
        }

        if (locationReference) {
            return this.http.get<InvestmentProperties>(`${this.configService.baseUrl}/investment-property/${locationReference}`, { params });
        }
        else {
            return this.http.get<InvestmentProperties>(`${this.configService.baseUrl}/investment-property`, { params });
        }
    }

    getInvestmentProperty(investmentPropertyReference: string): Observable<InvestmentProperty> {
        return this.http.get<InvestmentProperty>(`${this.configService.baseUrl}/investment-property/${investmentPropertyReference}`);
    }

    registerInterest(investmentPropertyReference: string, model: InvestmentPropertyInterestPost): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/investment-property/${investmentPropertyReference}/register-interest`, model);
    }

    getSimilar(investmentPropertyReference: string) : Observable<InvestmentProperty[]> {
        return this.http.get<InvestmentProperty[]>(`${this.configService.baseUrl}/investment-property/${investmentPropertyReference}/similar`);
    }
}
