import {ToastModule as PrimeNgToastModule} from "primeng/toast";
import {ToastComponent} from "./toast.component";
import {NgModule} from "@angular/core";
import {MessageService} from "primeng/api";
import {ButtonModule} from "primeng/button";

@NgModule({
    imports: [
        PrimeNgToastModule,
        ButtonModule
    ],
    declarations: [
        ToastComponent
    ],
    exports: [
        ToastComponent
    ],
    providers: [
        MessageService
    ]
})

export class ToastModule {
}
