export enum TenantFeedbackTypes
{
    MONTHLY_EXPERIENCE_OVERALL = 1,
    ONBOARDING = 2,
    ORGANISATION_REVIEW = 3,
    MAINTENANCE = 4,
    MONTHLY_EXPERIENCE_HOUSE_TIDINESS = 5,
    MONTHLY_EXPERIENCE_HOUSE_VIBE = 6,
    MONTHLY_EXPERIENCE_SERVICE = 7,
}
