<ng-container *ngIf="enabled">
    <div class="debug debug-info-button-open" *ngIf="showExpandButton && !expanded">
        <a href="javascript:void(0);" class="toggle__trigger" (click)="toggleExpand()">Show Debug</a>
    </div>
    <div *ngIf="expanded" class="debug debug-info" [id]="domId">
        <h4 *ngIf="title">{{ title }}</h4>

        <ng-content></ng-content>
        <pre *ngIf="jsonPrint">{{ jsonPrint | json }}</pre>

        <hr *ngIf="showExpandButton && expanded" />
        <div class="debug-info-button-close" *ngIf="showExpandButton && expanded">
            <a *ngIf="expanded" href="javascript:void(0);" class="toggle__trigger z" (click)="toggleExpand()">Collapse</a>
        </div>
    </div>
</ng-container>

