import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({ name: 'tagCapitalise' })
export class TagPipe implements PipeTransform {
    private splitRegex = new RegExp("[\\-_:+]");

    // If you make changes here you need to reflect them in the backend in the TagManager->TagCapitalise
    transform(value: string): string {
        // Replace '-' with spaces and capitalise the tag with PascalCase (split on the special characters)
        if (!value) return "";
        if (typeof(value) != "string") return "";

        value = value.toLowerCase().replace(/-/gi, "-|");
        value = value.replace(/_/gi, "_|");
        value = value.replace(/:/gi, ":|");
        value = value.replace(/\\+/gi, "+|");
        const parts = value.split("|");
        return parts.filter(word => !!word).map(word => word[0].toUpperCase() + word.substring(1)).join("").replace(/-/gi, ' ');
    }
}
