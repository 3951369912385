import {Subject, Subscriber} from 'rxjs';

import { ModalTypes } from './modal-types';
import { ModalStates } from './modal-states';


export class ModalState<T> {
    modal: ModalTypes | string;
    state: ModalStates;
    callback?: Subscriber<T> | Subject<T>;
    data?: T;
    reference: string;

    constructor(modal: ModalTypes | string, state: ModalStates, callback?: Subscriber<T> | Subject<T>, data?: T, reference?: string) {
        this.data = data;
        this.state = state;
        this.callback = callback;
        this.modal = modal;
        this.reference = reference;
    }
}
