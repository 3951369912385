import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import {COHOFeedbackQuestion} from "../models/coho-feedback/coho-feedback-question";
import {COHOFeedbackPost} from "../models/coho-feedback/coho-feedback-post";

@Injectable()
export class COHOFeedbackService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getQuestion() {
        return this.http.get<COHOFeedbackQuestion|null>(`${ this.configService.baseUrl }/coho-feedback/question`);
    }

    postFeedback(model: COHOFeedbackPost): Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/coho-feedback`, model);
    }
}
