import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable()
export class PageSectionStylesService {
    constructor(@Inject(DOCUMENT) private document) {
    }

    addClasses() {
        setTimeout(() => {
            const elements = this.document.getElementsByClassName('page__section');
            for (let i = 0; i < elements.length; i++) {
                const element = elements.item(i);

                if (element.classList.contains('page__section--alt')) {
                    element.classList.remove('page__section--alt');
                }

                if (i % 2 === 1) {
                    element.classList.add('page__section--alt');
                }
            }
        });
    }
}
