import { Injectable } from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import { AccountService } from './account.service';
import { LazyLoaderService } from "./lazy-loader.service";

@Injectable()
export class AppLoaderService {

    constructor(private accountService: AccountService, private lazyLoaderService: LazyLoaderService) {
    }

    load(): Observable<any> {
        this.lazyLoaderService.loadGTagManager();
        this.lazyLoaderService.loadSvgs();
        return this.accountService.setup();
    }

    setup(): Promise<any> {
        return this.load().toPromise();
    }
}
