import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { AccountService } from './account.service';
import { OwnerStatement } from '../models/finance/owner-statement/owner-statement';
import { RentalStatement } from '../models/report/rental-statement/rental-statement';
import { ProfitabilityReport } from '../models/report/profitability-report/profitability-report';
import {MoneyTypes} from "../models/rent-collection/money-types";
import {Guid} from "../models/guid/guid";
import { MaintenanceJobReport } from '../models/maintenance/maintenance-job-report';
import {MaintenanceIssueShareLinkVisibilityFields} from "../models/maintenance/maintenance-issue-share-link";

@Injectable()
export class ReportService {
    constructor(private http: HttpClient, private configService: ConfigService, private accountService: AccountService) {
    }

    rentalStatement(organisationReference: string, startDate: string, endDate: string, includePrepayments: boolean, includeBadDebt: boolean, showPastAssignments: boolean, propertyReferences: string[], roomReferences: string[], tenancyGuid: string, moneyTypes: MoneyTypes[]): Observable<RentalStatement> {
        let params = this.getRentalStatementParams(startDate, endDate, includePrepayments, includeBadDebt, showPastAssignments, propertyReferences, roomReferences, tenancyGuid, moneyTypes);

        return this.http.get<RentalStatement>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rental-statement`, { params });
    }

    rentalStatementCSVUrl(organisationReference: string, startDate: string, endDate: string, includePrepayments: boolean, includeBadDebt: boolean, showPastAssignments: boolean, propertyReferences: string[], roomReferences: string[], tenancyGuid: string, moneyTypes: MoneyTypes[]): string {
        let params = this.getRentalStatementParams(startDate, endDate, includePrepayments, includeBadDebt, showPastAssignments, propertyReferences, roomReferences, tenancyGuid, moneyTypes,true);

        return `${this.configService.baseUrl}/manage/organisation/${organisationReference}/rental-statement/csv?${params.toString()}`;
    }

    private getRentalStatementParams(startDate: string, endDate: string, includePrepayments: boolean, includeBadDebt: boolean, showPastAssignments: boolean, propertyReferences: string[], roomReferences: string[], tenancyGuid: string, moneyTypes: MoneyTypes[], addBearerToken: boolean = false) {
        let params = new HttpParams();

        if (startDate) {
            params = params.append('startDate', startDate);
        }

        if (endDate) {
            params = params.append('endDate', endDate);
        }

        if (includePrepayments === true) {
            params = params.append('includePrepayments', includePrepayments);
        }

        if (includeBadDebt === true) {
            params = params.append('includeBadDebt', includeBadDebt);
        }

        if (showPastAssignments === true) {
            params = params.append('showPastAssignments', showPastAssignments);
        }

        if (propertyReferences) {
            propertyReferences.forEach(ref => params = params.append('propertyReferences', ref));
        }

        if (roomReferences) {
            roomReferences.forEach(ref => params = params.append('roomReferences', ref));
        }

        if (tenancyGuid) {
            params = params.append('tenancyGuid', tenancyGuid);
        }

        if (moneyTypes != null) {
            moneyTypes.forEach(moneyType => params = params.append('moneyTypes', moneyType));
        }

        if (addBearerToken === true) {
            params = params.append('access_token', this.accountService.accessToken.bearerToken);
        }

        return params;
    }

    maintenanceJobReport(organisationReference: string, maintenanceGuid: Guid, postModel: MaintenanceIssueShareLinkVisibilityFields): Observable<MaintenanceJobReport> {
        return this.http.post<MaintenanceJobReport>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/maintenance/${maintenanceGuid}/job-report`, postModel);
    }

    private getMaintenanceJobReportParams(includeAccessDetails: boolean, includeImages: boolean, includeInternalNotes: boolean, includeTenantConversation: boolean) {
        let params = new HttpParams();

        if (includeAccessDetails == true) {
            params = params.append('includeAccessDetails', includeAccessDetails);
        }

        if (includeImages == true) {
            params = params.append('includeImages', includeImages);
        }

        if (includeInternalNotes == true) {
            params = params.append('includeInternalNotes', includeInternalNotes);
        }

        if (includeTenantConversation == true) {
            params = params.append('includeTenantConversation', includeTenantConversation);
        }

        params = params.append('access_token', this.accountService.accessToken.bearerToken);

        return params;
    }

    getOwnerStatement(organisationReference: string, reference: string): Observable<OwnerStatement> {
        if (organisationReference != null && organisationReference.length) {
            return this.http.get<OwnerStatement>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/report/owner-statement/${ reference }`);
        } else {
            return this.http.get<OwnerStatement>(`${ this.configService.baseUrl }/manage/report/owner-statement/${ reference }`);
        }
    }

    getProfitabilityReport(organisationReference: string, reference: string): Observable<ProfitabilityReport> {
        return this.http.get<ProfitabilityReport>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/finance/profitability-report/${ reference }`);
    }
}

