<div class="form__message form__message--error" *ngIf="errors && (hasErrors() || invalidDateTime())">
    <div class="form__message-icon">
        <svg-icon class="icon" name="icon-warning"></svg-icon>
    </div>
    <div class="form__message-text">
        <div class="u-margin-bottom--5" *ngFor="let error of errors; let i = index">
            <ng-container *ngFor="let key of error | keys">

                <ng-container *ngIf="error[key].errors && hasErrors()">
                    <p>{{key}} {{error[key].errors | friendlyDescription:key}} <svg-icon class="icon" name="icon-find" (click)="goToControl(i, key)" *ngIf="false && controlExists(i, key)"></svg-icon></p>
                </ng-container>

                <ng-container *ngIf="error[key].value?.invalid && invalidDateTime()">
                    <p>{{key}} - Reason: {{error[key].value.invalid.reason}}, Explanation: {{error[key].value.invalid.explanation}}</p>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
