import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import { services } from './services/services';
import { resolvers } from './resolvers/resolvers';
import { AppComponent } from './app.component';
import { AppRouting as AppRoutingModule } from './app.routing';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { AppLoaderService } from './services/app-loader.service';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { HttpInterceptor } from './interceptors/http.interceptor';
import { SiteHeaderModule } from './components/site-header/site-header.module';
import { SiteFooterModule } from './components/site-footer/site-footer.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieConsentModalModule } from "./components/cookie-consent-modal/cookie-consent-modal.module";
import { FormNavigateAwayConfirmModule } from "./components/form-navigate-away-confirm/form-navigate-away-confirm.module";
import { RemoveItemModalModule } from "./components/remove-item-modal/remove-item-modal.module";
import { MobileAppHttpInterceptor } from './interceptors/mobile-app-http.interceptor';
import { ConfirmModalModule } from './components/confirm-modal/confirm-modal.module';
import { environment } from '../environments/environment';
import { SiteHeaderStaticModule } from './components/site-header-static/site-header-static.module';
import { SiteFooterStaticModule } from './components/site-footer-static/site-footer-static.module';
import { DocumentViewerModalModule } from './components/document-viewer-modal/document-viewer-modal.module';
import {DebugInfoModule} from "./components/debug-info/debug-info.module";
import {SiteHeaderSupplierModule} from "./components/site-header-supplier/site-header-supplier.module";
import {SiteFooterSupplierModule} from "./components/site-footer-supplier/site-footer-supplier.module";
import {
    SiteFooterMyViewingsComponent
} from "./pages/my-viewings/site-footer-my-viewings/site-footer-my-viewings.component";
import {ErrorModalComponent} from "./components/error-modal/error-modal.component";
import {RetryInterceptor} from "./interceptors/retry.interceptor";
import {ToastModule} from "./components/toast/toast.module";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        SiteHeaderModule,
        SiteHeaderStaticModule,
        SiteHeaderSupplierModule,
        SiteFooterModule,
        SiteFooterSupplierModule,
        SiteFooterStaticModule,
        CookieConsentModalModule,
        FormNavigateAwayConfirmModule,
        RemoveItemModalModule,
        ConfirmModalModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: 'serviceWorker' in navigator && (environment.production || environment.labs)}),
        DocumentViewerModalModule,
        DebugInfoModule,
        ErrorModalComponent,
        SiteFooterMyViewingsComponent,
        ToastModule
    ],
    providers: [
        ...services,
        ...resolvers,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MobileAppHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RetryInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            deps: [AppLoaderService],
            useFactory: (appLoader: AppLoaderService) => () => appLoader.setup(),
            multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
