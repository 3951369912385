import { ConversationDatePipe } from "./conversation-date.pipe";
import { MessageDatePipe } from "./message-date.pipe";
import { HouseShareDatePipe } from "./house-share-date.pipe";
import { SafePipe } from "./safe.pipe";
import { NgModule } from "@angular/core";
import { HouseShareCurrencyPipe } from './currency.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { MustEndInFullStopPipe } from './must-end-in-full-stop.pipe';
import {TagPipe} from "./tag.pipe";
import {PluralityPipe} from "./plurality.pipe";
import { Nl2BrPipe } from "./nl2br.pipe";
import {AuthorisationPipe} from "./authorisation.pipe";
import {InitialsPipe} from "./initials.pipe";
import {NoAuthorisationPipe} from "./no-authorisation.pipe";
import {JoinAndPipe} from "./join-and.pipe";
import {HouseShareCostPipe} from "./house-share-cost.pipe";
import {HouseShareBracketsWrap} from "./house-share-brackets-wrap.pipe";
import {RemoveEmptyPipe} from "./remove-empty.pipe";
import {DayOfMonthPipe} from "./day-of-month.pipe";
import {HouseShareNamePipe} from "./house-share-name.pipe";
import {LinkifyPipe} from "./linkify.pipe";
import {EscapePipe} from "./escape.pipe";
import {RelativeDatePipe} from "./relative-date.pipe";
import {DaysAgoPipe} from "./days-overdue.pipe";


const pipes = [
    ConversationDatePipe,
    MessageDatePipe,
    HouseShareDatePipe,
    PluralityPipe,
    HouseShareCurrencyPipe,
    SafePipe,
    DaysAgoPipe,
    SafeHtmlPipe,
    MustEndInFullStopPipe,
    TagPipe,
    Nl2BrPipe,
    AuthorisationPipe,
    NoAuthorisationPipe,
    JoinAndPipe,
    RemoveEmptyPipe,
    InitialsPipe,
    HouseShareCostPipe,
    HouseShareNamePipe,
    HouseShareBracketsWrap,
    EscapePipe,
    DayOfMonthPipe,
    LinkifyPipe,
    RelativeDatePipe
];

@NgModule({
    declarations: [
        ...pipes
    ],
    exports: [
        ...pipes
    ],
    providers: [
        ...pipes
    ]
})
export class PipesModule {
}
