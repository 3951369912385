import {Injectable} from '@angular/core';

import {SwUpdate} from '@angular/service-worker';

import {ModalService} from './modal.service';
import {ConfirmConfiguration} from '../models/modal/confirm-configuration';
import {ModalTypes} from '../models/modal/modal-types';
import {environment} from '../../environments/environment';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {from} from 'rxjs';

@Injectable()
export class AppUpdateService {
    updateAvailable: boolean = false;
    externalPage: boolean = false;

    constructor(private swUpdate: SwUpdate, modalService: ModalService, private router: Router) {
        if ((!environment.production && !environment.labs) || !swUpdate.isEnabled) {
            return;
        }

        router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.externalPage = event.url.indexOf('/embed/') > -1;

                if (this.updateAvailable) {
                    from(swUpdate.activateUpdate())
                        .subscribe(_ => {
                            document.location.reload();
                        });
                }
            });

        const configuration = new ConfirmConfiguration();
        configuration.message = '<p>A new update has been released for COHO.  Would you like to reload your browser to enable the update? (Please note that any unsaved data may be lost).</p>' +
            '<p>If you are busy, you should carry on and simply refresh your browser when it is convenient.</p>';
        configuration.title = 'A new update has been released';
        configuration.yesLabel = 'Refresh';
        configuration.noLabel = 'Do it later';
        configuration.confirmCallback
            .subscribe(() => {
                from(swUpdate.activateUpdate())
                    .subscribe(_ => {
                        document.location.reload();
                    });
            });

        this.checkForUpdate();

        swUpdate.versionUpdates
            .pipe(filter(m => m.type === 'VERSION_READY'))
            .subscribe(() => {
                this.updateAvailable = true;

                if (!this.externalPage) {
                    setTimeout(() => modalService.openModal(ModalTypes.CONFIRM_ITEM, configuration), 10 * 60 * 1000);
                }
            });
    }

    private checkForUpdate() {
        this.swUpdate.checkForUpdate()
            .then(() => {
                setTimeout(() => this.checkForUpdate(), 60 * 1000);
            });
    }
}
