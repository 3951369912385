import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { MyHomeOverview } from '../models/my-home/my-home-overview';
import {ActionSnoozePostFull} from '../models/action/action-snooze-post';
import { MyHomeNotifications } from '../models/my-home/my-home-notifications';
import { MyHomeSummary } from '../models/my-home/my-home-summary';
import { TenantAction } from '../models/action/tenant-action';
import { Housemate } from '../models/user/housemate';
import { HealthAndSafetyRecordType } from '../models/health-and-safety/health-and-safety-record-type';
import { FireSafetyRecordType } from '../models/fire-safety/fire-safety-record-type';
import { HealthAndSafetyRecordPost } from '../models/health-and-safety/health-and-safety-record-post';
import { FireSafetyRecordPost } from '../models/fire-safety/fire-safety-record-post';
import { MySearchOverview } from '../models/my-home/my-search-overview';
import { TenancySummary } from '../models/tenancy/tenancy-summary';
import { TenancyAcceptancePost } from '../models/tenancy/tenancy-acceptance-post';
import { TenancyContentsInsurancePost } from '../models/tenancy/tenancy-contents-insurance-post';
import { MyFutureTenancySummary } from '../models/my-home/my-future-tenancy-summary';
import { TenancyAgreementSignedPost } from '../models/tenancy-agreement/tenancy-agreement-signed-post';
import { MyProposedTenancyAgreement } from '../models/my-home/my-proposed-tenancy-agreement';
import { Viewing } from '../models/tenant-find/viewing';
import { TenancyAgreement } from '../models/tenancy-agreement/tenancy-agreement';
import { MyTenancySummary } from '../models/my-home/my-tenancy-summary';
import { TenancyContentsInsuranceStatuses } from '../models/tenancy/tenancy-contents-insurance-statuses';
import {RightToRent} from "../models/right-to-rent/right-to-rent";
import {FileService} from "./file.service";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class MyHomeService  {

    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getMyHomeOverview(tenancyGuid: string = null): Observable<MyHomeOverview> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.get<MyHomeOverview>(`${this.configService.baseUrl}/my-home/overview`, { params });
    }

    getMySearchOverview(): Observable<MySearchOverview> {
        return this.http.get<MySearchOverview>(`${this.configService.baseUrl}/my-home/my-search/overview`);
    }

    getMyHomeSummary(tenancyGuid?: string): Observable<MyHomeSummary> {
        const url = `${this.configService.baseUrl}/my-home/summary`;
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set("tenancyGuid", tenancyGuid);
        }
        return this.http.get<MyHomeSummary>(url, {params});
    }

    getMyHousemateProfile(): Observable<Housemate> {
        return this.http.get<Housemate>(`${this.configService.baseUrl}/my-home/profile`);
    }

    getMyHousemates(propertyReference?: string): Observable<Housemate[]> {
        const url = `${this.configService.baseUrl}/my-home/housemates`;
        let params = new HttpParams();
        if (propertyReference) {
            params = params.set("propertyReference", propertyReference);
        }
        return this.http.get<Housemate[]>(url, {params});
    }

    getFireSafetyRecordType(reference: string): Observable<FireSafetyRecordType> {
        return this.http.get<FireSafetyRecordType>(`${this.configService.baseUrl}/my-home/fire-safety/get-record-type/${reference}`);
    }

    getHealthAndSafetyRecordType(reference: string): Observable<HealthAndSafetyRecordType> {
        return this.http.get<HealthAndSafetyRecordType>(`${this.configService.baseUrl}/my-home/health-and-safety/get-record-type/${reference}`);
    }

    getTenancyAgreement(guid: string): Observable<TenancyAgreement> {
        return this.http.get<TenancyAgreement>(`${this.configService.baseUrl}/my-home/tenancy-agreement/${guid}`);
    }

    addHealthAndSafetyRecord(model: HealthAndSafetyRecordPost): Observable<HttpEvent<boolean>> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/my-home/health-and-safety/add-record`, model, {
            reportProgress: true, observe: 'events'
        });
    }

    addFireSafetyRecord(model: FireSafetyRecordPost): Observable<HttpEvent<boolean>> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/my-home/fire-safety/add-record`, model, {
            reportProgress: true, observe: 'events'
        });
    }

    signTenancyAgreement(guid: string, model: TenancyAgreementSignedPost): Observable<HttpEvent<MyProposedTenancyAgreement>> {
        return this.fileService.uploadFiles(model.tenantSignedFiles)
            .pipe(tap(_ => model.tenantSignedFiles = this.fileService.prepareFilePosts(model.tenantSignedFiles)),
                mergeMap(_ => this.http.post<MyProposedTenancyAgreement>(`${this.configService.baseUrl}/my-home/tenancy-agreement/${guid}/sign`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    getMyProposedTenancyAgreement(guid: string): Observable<MyProposedTenancyAgreement> {
        return this.http.get<MyProposedTenancyAgreement>(`${this.configService.baseUrl}/my-home/tenancy-agreement/${guid}/proposed`);
    }

    getActions(): Observable<TenantAction[]> {
        return this.http.get<TenantAction[]>(`${this.configService.baseUrl}/my-home/actions`);
    }

    //archiveAction(actionReference: string): Observable<boolean> {
    //    return this.http.post<boolean>(`${this.configService.baseUrl}/my-home/actions/${actionReference}/archive`, null);
    //}

    snoozeAction(actionReference: string, days: number, months: number, date: string = null): Observable<boolean> {
        let snoozeModel = new ActionSnoozePostFull();
        snoozeModel.date = date;
        snoozeModel.days = days;
        snoozeModel.months = months;

        return this.http.post<boolean>(`${this.configService.baseUrl}/my-home/actions/${actionReference}/snooze`, snoozeModel);
    }

    completeAction(actionReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/my-home/actions/${actionReference}/complete`, null);
    }

    getMyHomeNotifications(): Observable<MyHomeNotifications> {
        return this.http.get<MyHomeNotifications>(`${this.configService.baseUrl}/my-home/notifications`);
    }

    tenancyAcceptance(tenancyGuid: string, acceptance: boolean): Observable<TenancySummary> {
        let model = new TenancyAcceptancePost();
        model.accept = acceptance;

        return this.http.post<TenancySummary>(`${this.configService.baseUrl}/my-home/tenancy/future/${tenancyGuid}/accept`, model);
    }

    getFutureTenancy(tenancyGuid: string): Observable<MyFutureTenancySummary> {
        return this.http.get<MyFutureTenancySummary>(`${this.configService.baseUrl}/my-home/tenancy/future/${tenancyGuid}`);
    }

    getMyTenancy(tenancyGuid: string): Observable<MyTenancySummary> {

        return this.http.get<MyTenancySummary>(`${this.configService.baseUrl}/my-home/tenancy/${tenancyGuid}/summary`);
    }

    contentsInsuranceLog(tenancyGuid: string): Observable<MyTenancySummary> {
        let model = new TenancyContentsInsurancePost();
        model.opened = true;

        return this.http.post<MyTenancySummary>(`${this.configService.baseUrl}/my-home/tenancy/${tenancyGuid}/contents-insurance`, model);
    }

    contentsInsuranceUpdate(tenancyGuid: string, status: TenancyContentsInsuranceStatuses): Observable<MyTenancySummary> {
        let model = new TenancyContentsInsurancePost();
        model.status = status;

        return this.http.post<MyTenancySummary>(`${this.configService.baseUrl}/my-home/tenancy/${tenancyGuid}/contents-insurance`, model);
    }

    getMyLatestRightToRents(tenancyGuid: string, tenancyTenantGuid: string = null) {
        let params = new HttpParams();
        if (tenancyTenantGuid) params = params.set('tenancyTenantGuid', tenancyTenantGuid);

        // Returns a lookup of the right to rent by TenancyTenantGuid
        return this.http.get<[{
            tenancyTenantGuid: string,
            latestRightToRent: RightToRent,
        }]>(`${this.configService.baseUrl}/my-home/right-to-rent/${tenancyGuid}/latest`, { params });
    }
}

