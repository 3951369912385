import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { HMOLicence } from '../models/hmo-licence/hmo-licence';
import { HMOLicencePost } from '../models/hmo-licence/hmo-licence-post';
import { HMOLicenceOverview } from '../models/hmo-licence/hmo-licence-overview';
import { FileService } from "./file.service";
import { OwnerHMOLicenceOverview } from '../models/hmo-licence/owner-hmo-licence-overview';
import {PropertyServicesSummary} from "../models/property-services/property-services-summary.interface";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class HMOLicenceService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string): Observable<HMOLicenceOverview> {
        return this.http.get<HMOLicenceOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/hmo-licences/overview`);
    }

    getList(propertyReference: string): Observable<HMOLicence[]> {
        return this.http.get<HMOLicence[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/hmo-licences`);
    }

    getOwnerOverview(propertyReference: string): Observable<OwnerHMOLicenceOverview> {
        return this.http.get<OwnerHMOLicenceOverview>(`${this.configService.baseUrl}/owner/property/${propertyReference}/hmo-licences/overview`);
    }

    getOwnerList(propertyReference: string): Observable<HMOLicence[]> {
        return this.http.get<HMOLicence[]>(`${this.configService.baseUrl}/owner/property/${propertyReference}/hmo-licences`);
    }

    add(propertyReference: string, model: HMOLicencePost): Observable<HttpEvent<HMOLicence>> {
        return this.fileService.uploadFiles(model.hmoLicenceFiles)
            .pipe(tap(_ => model.hmoLicenceFiles = this.fileService.prepareFilePosts(model.hmoLicenceFiles)),
                mergeMap(_ => this.http.post<HMOLicence>(`${this.configService.baseUrl}/manage/property/${propertyReference}/hmo-licences`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(propertyReference: string, item: HMOLicence): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/hmo-licences/${item.guid}`);
    }

    getOwnerHMOLicence(propertyReference: string, guid: string): Observable<HMOLicence> {
        return this.http.get<HMOLicence>(`${this.configService.baseUrl}/owner/property/${propertyReference}/hmo-licences/${guid}`);
    }

    getSummary(propertyReference: string) {
        return this.http.get<PropertyServicesSummary>(`${this.configService.baseUrl}/manage/property/${propertyReference}/hmo-licences/summary`);
    }
}
