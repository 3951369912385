import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SiteHeaderComponent } from './site-header.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { SpriteImageModule } from '../sprite-image/sprite-image.module';
import {COHOFeedbackModule} from "../coho-feedback/coho-feedback.module";

@NgModule({
    imports: [
        CommonModule,
        SvgIconModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownModule,
        SpriteImageModule,
        COHOFeedbackModule
    ],
    declarations: [
        SiteHeaderComponent
    ],
    exports: [
        SiteHeaderComponent
    ]
})

export class SiteHeaderModule { }
