import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { OrganisationSupplier } from '../models/organisation-supplier/organisation-supplier';
import { OrganisationSupplierPost } from '../models/organisation-supplier/organisation-supplier-post';
import { OrganisationSupplierPatch } from '../models/organisation-supplier/organisation-supplier-patch';
import { OrganisationSuppliersOverview } from '../models/organisation-supplier/organisation-suppliers-overview';

import { ConfigService } from './config.service';
import { TradeSpecialism } from '../models/supplier/trade-specialism';
import {SupplierPerson} from "../models/organisation-supplier/supplier.person";
import {SupplierPersonPatchInterface} from "../models/organisation-supplier/supplier-person-patch.interface";
import {ManageOrganisationService} from "./manage-organisation.service";
import {SuppliersImport} from "../models/supplier/suppliers-import";
import {AccountService} from "./account.service";

@Injectable()
export class OrganisationSuppliersService {
    constructor(private http: HttpClient, private configService: ConfigService, private manageOrganisationService: ManageOrganisationService, private accountService: AccountService) {
    }

    getOverview(organisationReference: string, archived: boolean) {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/suppliers/overview`;
        let params = new HttpParams();
        params = params.set('archived', archived);
        return this.http.get<OrganisationSuppliersOverview>(url, {params});
    }

    getOrganisationSuppliers(organisationReference: string, archived: boolean = false): Observable<OrganisationSupplier[]> {
        let params = new HttpParams();
        params = params.set('archived', archived);
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/suppliers`;
        return this.http.get<OrganisationSupplier[]>(url, {params});
    }

    getSupplierPersons(organisationSupplierGuid: string): Observable<SupplierPerson[]> {
        const url = `${this.configService.baseUrl}/organisation-supplier/${organisationSupplierGuid}/supplier-persons`
        return this.http.get<SupplierPerson[]>(url);
    }

    deleteSupplierPerson(organisationSupplierGuid: string, supplierPersonGuid: string): Observable<SupplierPerson[]> {
        const url = `${this.configService.baseUrl}/organisation-supplier/${organisationSupplierGuid}/supplier-persons/${supplierPersonGuid}`
        return this.http.delete<SupplierPerson[]>(url);
    }

    updateSupplierPerson(organisationSupplierGuid: string, supplierPersonGuid: string, model: SupplierPersonPatchInterface): Observable<SupplierPerson[]> {
        const url = `${this.configService.baseUrl}/organisation-supplier/${organisationSupplierGuid}/supplier-persons/${supplierPersonGuid}`
        return this.http.patch<SupplierPerson[]>(url, model);
    }

    getTradeSpecialisms(organisationReference: string): Observable<TradeSpecialism[]> {
        return this.http.get<TradeSpecialism[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/suppliers/trade-specialisms`);
    }

    add(organisationReference: string, model: OrganisationSupplierPost): Observable<OrganisationSupplier> {
        return this.http.post<OrganisationSupplier>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/suppliers`, model);
    }

    patch(organisationReference: string, guid: string, model: OrganisationSupplierPatch): Observable<OrganisationSupplier> {
        return this.http.patch<OrganisationSupplier>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/suppliers/${guid}`, model);
    }

    archive(guid: string, archive: boolean = true){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/suppliers/${guid}/archive`
        let params = new HttpParams();
        params = params.set('archive', archive);

        return this.http.patch<OrganisationSupplier>(url, null, {params});
    }
    restore(guid: string, archive: boolean = false){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/suppliers/${guid}/archive`
        let params = new HttpParams();
        params = params.set('archive', archive);

        return this.http.patch<OrganisationSupplier>(url, null, {params});
    }

    // delete(organisationReference: string, guid: string): Observable<boolean> {
    //     return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/suppliers/${guid}`);
    // }

    importSuppliers(model: SuppliersImport): Observable<OrganisationSupplier[]> {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        return this.http.post<OrganisationSupplier[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/suppliers/import`, model);
    }

    getSuppliersExportCSV(): string {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        let params = new HttpParams();
        params = params.append('access_token', this.accountService.accessToken.bearerToken);

        return `${this.configService.baseUrl}/manage/organisation/${organisationReference}/suppliers/export?${params.toString()}`;
    }
}

