import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import {Observable, of} from 'rxjs';
import { Amenities } from '../models/amenity/amenities';
import { ManageOrganisationService } from '../services/manage-organisation.service';
import { OrganisationFeatures } from '../models/organisation/organisation-features';
import {catchError} from "rxjs/operators";

@Injectable()
export class ManageOrganisationFeaturesResolver implements Resolve<OrganisationFeatures> {

    constructor(private organisationService: ManageOrganisationService) { }

    resolve(): Observable<OrganisationFeatures> {
        return this.organisationService.getOrganisationFeatures()
            .pipe(catchError((error) => of(null)));
    }
}
