<p-toast key="dialog"></p-toast>

<p-toast position="bottom-center" key="confirm" (onClose)="onReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="p-grid p-fluid">
                <button type="button" pButton (click)="onConfirm()" label="Ok" class="p-button-error"></button>
            </div>
        </div>
    </ng-template>
</p-toast>
