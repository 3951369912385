import {ApplicationRef, EventEmitter, Inject, Injectable, Optional, SkipSelf} from '@angular/core'

import {DOCUMENT} from "@angular/common";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable()
export class FocusOverlayService {
    overlayListeners: BehaviorSubject <boolean>;

    constructor(@Optional() @SkipSelf() parentModule: FocusOverlayService/*, @Inject(DOCUMENT) private document: Document, private applicationRef: ApplicationRef*/) {
        if (parentModule) {
            this.overlayListeners = parentModule.overlayListeners;
        } else {
            this.overlayListeners = new BehaviorSubject<boolean>(false);
        }
    }

    setOpen(): void {
        this.overlayListeners.next(true);
    }

    setClosed(): void {
        this.overlayListeners.next(false);
    }

    getObservable(): Observable<boolean> {
        return this.overlayListeners.asObservable();
    }
}
