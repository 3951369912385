import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {ManageOrganisationService} from "./manage-organisation.service";
import { ApplicationFormTypes } from "../models/application-form/application-form-types";
import {
    SpacesForApplicationFormOverviewInterface
} from "../models/application-form/spaces-for-org-application-form-overview.interface";
import {OrganisationApplicationForm} from "../models/application-form/organisation-application-form";
import {
    NewOnboardingApplicationFormPostInterface
} from "../models/application-form/new-organisation-application-form-post.interface";
import {FileService} from "./file.service";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class ApplicationFormService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private manageOrganisationService: ManageOrganisationService,
        private fileService: FileService) {
    }

    getOrganisationApplicationForms(type?: ApplicationFormTypes) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-application-forms`
        let params = new HttpParams();
        if (type != null) params = params.append('type', type);

        return this.http.get<OrganisationApplicationForm[]>(url, {params});
    }

    postOrganisationApplicationForm(model: NewOnboardingApplicationFormPostInterface){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-application-forms`

        return this.fileService.uploadFiles(model.files)
            .pipe(tap(_ => model.files = this.fileService.prepareFilePosts(model.files)),
                mergeMap(_ => this.http.post<OrganisationApplicationForm>(url, model)));
    }

    archiveOrganisationApplicationForm(guid: string){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-application-forms/${guid}/archive`
        return this.http.patch<boolean>(url, null);
    }

    getOrganisationApplicationForm(guid: string){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-application-forms/${guid}`
        return this.http.get<OrganisationApplicationForm>(url);
    }

    getSpacesForMapping(guid: string, type: ApplicationFormTypes){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-application-forms/${guid}/spaces-available-for-mapping`
        let params = new HttpParams();
        params = params.append('type', type);

        return this.http.get<SpacesForApplicationFormOverviewInterface>(url, {params});
    }

    updateOrganisationMapping(guid: string, type: ApplicationFormTypes, replace: boolean){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-application-forms/${guid}/update-organisation-mapping`
        let params = new HttpParams();
        params = params.append('type', type);
        params = params.append('replace', replace);

        return this.http.patch(url, null, {params});
    }

    deleteOrganisationMapping(guid: string, type: ApplicationFormTypes){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-application-forms/${guid}/delete-organisation-mapping`
        let params = new HttpParams();
        params = params.append('type', type);
        return this.http.patch(url, null, {params});
    }

    attachOrganisationApplicationFormToProperty(guid: string, type: ApplicationFormTypes, propertyReference: string, attach: boolean){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/organisation-application-forms/${guid}/attach-to-property`
        let params = new HttpParams();
        params = params.append('type', type);
        params = params.append('propertyReference', propertyReference);
        params = params.append('attach', attach);
        return this.http.patch(url, null, {params});
    }

    //This method is used both from find-a-home and manage
    getSpaceDefaultApplicationForm(propertyReference: string, publicReference: boolean, type: ApplicationFormTypes){
        const url = `${this.configService.baseUrl}/default-form-for-space/${propertyReference}`
        let params = new HttpParams();
        params = params.append('publicReference', publicReference);
        params = params.append('type', type);
        return this.http.get<OrganisationApplicationForm>(url, {params});
    }
}
