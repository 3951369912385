export enum FinancialItemCarriedOutByTypes {
    INTERNAL = 1,
    EXTERNAL = 2
}

export const FinancialItemCarriedOutByTypesReverse = {
    "1": "Internal",
    "2": "External",
}

