import { PropertySummary } from '../manage-property/property-summary';
import { RoomSummary } from '../manage-room/room-summary';
import { TenancySummary } from '../tenancy/tenancy-summary';
import { FinancialItemCategory } from './financial-item-category';
import {FinancialItemIncomeExpenseTypes, IncomeExpense} from './financial-item-income-expense-types';
import { FinancialItemPaidStatuses } from './financial-item-paid-statuses';
import { FinancialItemParentCategories } from './financial-item-parent-categories';
import { FinancialItemSettlementPartyFinancialItem } from './financial-item-settlement-party-financial-item';
import { FinancialItemSettlementStatuses } from './financial-item-settlement-statuses';
import { FinancialItemClientCostTypes } from './financial-item-client-cost-types';
import { FinancialItemInvoice } from './financial-item-invoice';
import {FinancialItemCarriedOutByTypes} from "./financial-item-carried-out-by-types";
import {DateString8601} from "../primitive-types";

export class FinancialItem {
    guid: string;
    splitGroupGuid: string;
    splitFactor: number;


    invoiceDate: Date;
    invoiceDueDate: Date;
    invoiceNumber: string;
    fullyPaidDate: Date;
    deleted: DateString8601;
    paidStatus: FinancialItemPaidStatuses;

    rawAmount: number;
    rawAmountTax: number;
    rawTaxPercentage: number;
    rawTotalAmount: number;

    organisationAmount: number;
    organisationAmountTax: number;
    organisationTotalAmount: number;
    organisationSettlementStatus: FinancialItemSettlementStatuses;

    organisationTotalAmountSettled: number | null;
    organisationTotalAmountSettleable: number | null;
    organisationTotalAmountsError: string | null;

    clientAmount: number;
    clientAmountTax: number;
    clientTotalAmount: number;
    clientSettlementStatus: FinancialItemSettlementStatuses;

    aggregateSettlementStatus: FinancialItemSettlementStatuses;

    formAmount: number;
    formAmountTax: number;
    formTaxPercentage: number;
    formTotalAmount: number;

    formOrganisationAmount: number;
    formOrganisationAmountTax: number;
    formOrganisationTaxPercentage: number;
    formOrganisationTotalAmount: number;

    formClientAmount: number;
    formClientAmountTax: number;
    formClientTaxPercentage: number;
    formClientTotalAmount: number;

    clientTotalAmountSettled: number | null;
    clientTotalAmountSettleable: number | null;
    clientTotalAmountsError: string | null;

    description: string;
    notes: string;
    parentCategory: FinancialItemParentCategories;
    category: FinancialItemCategory;

    showOnSettlement: boolean;
    showSettlement: boolean;
    organisationType: FinancialItemIncomeExpenseTypes;
    clientType: FinancialItemIncomeExpenseTypes;
    clientCostType: FinancialItemClientCostTypes;

    propertySummary: PropertySummary;
    roomSummary: RoomSummary;
    tenancySummary: TenancySummary;

    settlementPartyFinancialItems: FinancialItemSettlementPartyFinancialItem[];

    parentFinancialItem: FinancialItem;
    childFinancialItems: FinancialItem[];
    hasRepeatingItem: boolean;
    carriedOutBy: FinancialItemCarriedOutByTypes;

    financialItemInvoice: FinancialItemInvoice;

    amountPaid: number;

    editLocked: boolean;
}

export class FinancialItemUtil {
    public static IsReversal(financialItem: FinancialItem) {
        return IncomeExpense.IsReversal(financialItem.organisationType) || IncomeExpense.IsReversal(financialItem.clientType);
    }
}
