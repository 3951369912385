import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { UpdateMoveOutPost } from '../models/move-out/update-move-out-post';
import { MoveOut } from '../models/move-out/move-out';


@Injectable()
export class MoveOutService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    updateMoveOut(tenancyReference: string, moveOutPost: UpdateMoveOutPost): Observable<MoveOut> {
        return this.http.post<MoveOut>(`${this.configService.baseUrl}/tenancy/${tenancyReference}/move-out`, moveOutPost);
    }
}
