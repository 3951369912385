import { Pipe, PipeTransform } from '@angular/core';
// luxon .local
import { DateTime } from 'luxon';
import { DateFormats } from "../models/dates/date-formats";

@Pipe({ name: 'houseShareDate' })
export class HouseShareDatePipe implements PipeTransform {
    transform(value: any, format: DateFormats = DateFormats.MEDIUM, includeTime: boolean = false, includeSeconds: boolean = false): string {
        if (!value) {
            return '';
        }

        const isoDate = typeof value === 'object' ? value.toISOString() : value;
        const val = DateTime.fromISO(isoDate);
        let dateFormat = '';

        switch (format) {
            case DateFormats.LONG:
                dateFormat = 'DDD'; // '15 November 2023'
                break;
            case DateFormats.MEDIUM:
                dateFormat = 'DD'; // '15 Nov 23'
                break;
            case DateFormats.SHORT:
                dateFormat = 'dd/MM/yyyy'; // '15/11/2023'
                break;
            case DateFormats.VERY_SHORT:
                dateFormat = 'dd/MM/yy'; // '15/11/23'
                break;
            case DateFormats.DAY_MONTH:
                dateFormat = 'dd MMM'; //'DD MMMM';
                break;
            case DateFormats.EXPIRY:
                dateFormat = 'MM/yyyy'; // '11/2023'
                break;
            case DateFormats.TIME:
                dateFormat = 't'; //'H:mm A';
                break;
            case DateFormats.MONTH_LONG:
                dateFormat = 'MMMM';//'H:mm A';
                break;
            case DateFormats.MONTH_YEAR:
                dateFormat = 'MMMM yyyy';//'November 2023';
                break;
            case DateFormats.MONTH_YEAR_SHORT:
                dateFormat = 'MMM yyyy';//'Nov 2023';
                break;
            case DateFormats.YEAR:
                dateFormat = 'yyyy';//'2023';
                break;
            case DateFormats.LONG_WITH_DAY:
                dateFormat = 'DDDD';//'H:mm A';
                break;
        }

        if (includeTime) {
            if (val.hour === 0 && val.minute === 0){
                dateFormat += ''
            } else {
                if(format == DateFormats.LONG){
                    dateFormat += " 'at' HH:mm"; // '15 November 2023 at 12:43'
                } else {
                    dateFormat += ' HH:mm';
                }
            }

            if (includeSeconds) {
                if (val.second === 0){
                    dateFormat += ''
                } else {
                    dateFormat += ':ss';
                }
            }
        }

        return val.toFormat(dateFormat);
    }
}
