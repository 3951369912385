import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {RentDueRecord} from "../models/rent-collection/rent-due-record";
import {GocardlessExportPost} from "../models/gocardless/gocardless-export-post.interface";
import {GoCardlessExport} from "../models/gocardless/gocardless-export.interface";
import {TenancySummary} from "../models/tenancy/tenancy-summary";
import {GoCardlessTenancyPatch} from "../models/gocardless/gocardless-tenancy-patch";
import {GoCardlessTenancy} from "../models/gocardless/gocardless-tenancy";
import {GoCardlessCustomer} from "../models/gocardless/go-cardless-customer";
import {SetGoCardlessAccessTokenResponse} from "../models/gocardless/set-gc-access-token-response";
import {GoCardlessRentDueRecord} from "../models/gocardless/go-cardless-rent-due-record";
import {OrganisationRentCollectionSettings} from "../models/rent-collection/organisation-rent-collection-settings";
import {GoCardlessMandate} from "../models/gocardless/gocardless-mandate.interface";
import {GoCardlessSubscription} from "../models/gocardless/go-cardless-subscription.interface";

@Injectable()
export class GoCardlessService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getRentsForExport(organisationReference: string, fromDate: string, toDate: string){
        const url = `${this.configService.baseUrl}/${organisationReference}/gocardless/rent-due-records-for-export`
        let params = new HttpParams();
        params = params.set("fromDate", fromDate);
        params = params.set("toDate", toDate);
        return this.http.get<RentDueRecord[]>(url, {params});
    }

    generateExport(organisationReference: string, model: GocardlessExportPost){
        const url = `${this.configService.baseUrl}/${organisationReference}/gocardless/export`
        if(model.generateCsv){
            return this.http.post(url, model, {responseType: 'blob'});
        } else {
            return this.http.post(url, model);
        }
    }

    getExports(organisationReference: string, tenancyGuid: string){
        const url = `${this.configService.baseUrl}/${organisationReference}/gocardless/exports`
        let params = new HttpParams();
        if(tenancyGuid) params = params.set("tenancyGuid", tenancyGuid);
        return this.http.get<GoCardlessExport[]>(url, {params});
    }

    getExport(organisationReference: string, exportGuid: string){
        const url = `${this.configService.baseUrl}/${organisationReference}/gocardless/exports/${exportGuid}`
        return this.http.get<GoCardlessExport>(url);
    }

    downloadExportCsv(organisationReference: string, exportGuid: string){
        const url = `${this.configService.baseUrl}/${organisationReference}/gocardless/exports/${exportGuid}/csv`
        return this.http.get(url, { responseType: 'blob' });
    }

    getTenanciesToMatch(organisationReference: string, propertyReferences: string[]){
        const url = `${this.configService.baseUrl}/${organisationReference}/gocardless/tenancies-to-match`
        let params = new HttpParams();
        if (propertyReferences?.length) propertyReferences.forEach(p => params = params.append('propertyReferences', p));
        return this.http.get<TenancySummary[]>(url, {params});
    }

    updateGoCardlessTenancy(organisationReference: string, tenancyGuid: string, model: GoCardlessTenancyPatch) {
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/tenancy/${tenancyGuid}`
        return this.http.patch<GoCardlessTenancy>(url, model);
    }

    createMandateBillingRequestsFromExport(organisationReference: string, exportGuid: string) {
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/exports/${exportGuid}/mandate-billing-requests`
        return this.http.post<GoCardlessExport>(url, null);
    }

    getCustomersForMatch(organisationReference: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/customers-for-match`
        return this.http.get<GoCardlessCustomer[]>(url);
    }

    setAccessTokenWithOAuthCode(organisationReference: string, code: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/set-access-token/${code}`
        return this.http.get<SetGoCardlessAccessTokenResponse>(url);
    }

    checkVerificationStatus(organisationReference: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/check-verification-status`
        return this.http.get<boolean>(url);
    }

    sendBillingRequestLink(organisationReference: string, recordGuid: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/gocardless-rent-due-records/${recordGuid}/send-mandate-billing-requests-notification`
        return this.http.post<boolean>(url, null);
    }

    retryPayment(organisationReference: string, recordGuid: string, retryOn: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/gocardless-rent-due-records/${recordGuid}/retry-payment`
        return this.http.post<GoCardlessRentDueRecord>(url, null, {
            params: {
                retryOn: retryOn
            }
        });
    }

    generateInstantPayment(organisationReference: string, rentDueRecordGuid: string, isHoldingFee: boolean){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/instant-payment/${rentDueRecordGuid}`
        let params = new HttpParams()
        params = params.set("isHoldingFee", isHoldingFee);
        return this.http.post<GoCardlessRentDueRecord>(url, null, {params});
    }

    newTransactionImport(organisationReference: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/new-transaction-import`
        return this.http.post(url, null);
    }

    getLatestStatus(organisationReference: string, recordGuid: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/gocardless-rent-due-records/${recordGuid}/status`
        return this.http.get<GoCardlessRentDueRecord>(url);
    }

    generateBillingRequestForOnboardingPayMonies(organisationReference: string, onboardingGuid: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/onboardings/${onboardingGuid}/pay-monies-billing-request`
        return this.http.post<GoCardlessRentDueRecord[]>(url, null);
    }

    getTenanciesWithoutMandate(organisationReference: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/tenancies-without-mandate`
        return this.http.get<TenancySummary[]>(url);
    }

    generateMandateForTenancies(organisationReference: string, tenanciesGuids: string[]){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/generate-mandate-for-tenancies`
        let params = new HttpParams();
        tenanciesGuids.forEach(m => params = params.append('tenanciesGuids', m));
        return this.http.post(url, null, {params});
    }

    deleteAccountInfo(organisationReference: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/delete-account-info`
        return this.http.patch<OrganisationRentCollectionSettings>(url, null);
    }

    getMandate(organisationReference: string, mandateId: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/mandates/${mandateId}`
        return this.http.get<GoCardlessMandate>(url);
    }

    cancelSusbcription(organisationReference: string, subscriptionId: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/subscriptions/${subscriptionId}/cancel`
        return this.http.patch(url, null);
    }

    getMandateSubscriptions(organisationReference: string, mandateId: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/mandates/${mandateId}/subscriptions`
        return this.http.get<GoCardlessSubscription[]>(url);
    }

    newChargeDate(organisationReference: string, recordGuid: string, chargeDate: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/gocardless-rent-due-records/${recordGuid}/new-charge-date`
        return this.http.post<GoCardlessRentDueRecord>(url, null, {
            params: {
                chargeDate: chargeDate
            }
        });
    }

    cancelBillingRequest(organisationReference: string, recordGuid: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/gocardless-rent-due-records/${recordGuid}/cancel-billing-request`
        return this.http.post(url, null);
    }

    cancelPayment(organisationReference: string, recordGuid: string){
        const url = `${ this.configService.baseUrl }/${organisationReference}/gocardless/gocardless-rent-due-records/${recordGuid}/cancel-payment`
        return this.http.post(url, null);
    }
}
