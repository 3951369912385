import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'nameStatic', pure: true})
export class HouseShareNamePipe implements PipeTransform {
    constructor() {
    }
    transform(input: { forename: string, surname: string, company?: string}, fallback = null) {
        if (input.company) {
            return input.company + ' (' + input.forename + ' ' + input.surname + ')';
        } else {
            return input.forename + ' ' + input.surname;
        }
    }
}
