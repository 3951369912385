import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { LoaderService } from "../../services/loader.service";
import { UserService } from "../../services/user.service";

import { AccountService } from "../../services/account.service";
import { UserPatch } from '../../models/user/user-patch';
import { MobileAppService } from '../../services/mobile-app.service';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, map, mergeMap} from "rxjs/operators";
import {combineLatest} from "rxjs";

const cookiesAcceptedKey = 'COOKIES_ACCEPTED';

@Component({
    selector: 'cookie-consent-modal',
    templateUrl: './cookie-consent-modal.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CookieConsentModalComponent implements OnInit {
    showDialog: boolean = false;
    externalPage: boolean = false;

    set cookiesAccepted(value: boolean) {
        localStorage.setItem(cookiesAcceptedKey, value ? 'true' : 'false');
    }

    get cookiesAccepted(): boolean {
        try {
            return JSON.parse(localStorage.getItem(cookiesAcceptedKey));
        } catch {
            return false;
        }
    }

    constructor(private loaderService: LoaderService, private userService: UserService, private accountService: AccountService, private mobileAppService: MobileAppService, private router: Router) {

        router.events.subscribe((event:  NavigationEnd) => {
            if (event.type == 1) {
                this.externalPage = event.url.indexOf('/widget/') > -1;
            }
        });
    }

    ngOnInit() {
        if (this.mobileAppService.isMobileApp()) {
            return;
        }

        this.accountService.watchCurrentUser()
            .subscribe(user => {
            const userAcceptedCookieWarning = user ? user.acceptedCookieWarning : false;

            if (userAcceptedCookieWarning) {
                this.cookiesAccepted = userAcceptedCookieWarning;
            }

            if (!this.cookiesAccepted) {
                const sub = this.loaderService.hidden
                    .subscribe(() => {
                        this.showDialog = true;
                        sub.unsubscribe();
                    });
            }
            else {
                this.showDialog = false;
            }

            if (user && user.emailConfirmed && !userAcceptedCookieWarning && this.cookiesAccepted) {
                this.acceptCookieWarning();
            }
        });
    }

    acceptClick() {
        this.acceptCookieWarning();
    }

    private acceptCookieWarning() {
        if (!this.accountService.getCurrentUser()) {
            this.cookiesAccepted = true;
            this.showDialog = false;

            return;
        }

        let userPatch: UserPatch = new UserPatch();
        userPatch.acceptedCookieWarning = true;
        this.userService.patchUser(userPatch)
            .subscribe(() => {
                this.cookiesAccepted = true;
                this.showDialog = false;
            });
    }
}
