import {ApplicationRef, Inject, Injectable, Optional, SkipSelf} from '@angular/core'

import {ModalStates} from '../models/modal/modal-states';
import {ModalState} from '../models/modal/modal-state';
import {ModalTypes} from '../models/modal/modal-types';
import {DOCUMENT} from "@angular/common";
import {FocusOverlayService} from "./focus-overlay.service";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class ModalService {
    modals: Map<ModalTypes | string, ModalState<any>>;
    private scrollPosition: number;

    modalOpened = new BehaviorSubject<boolean>(false);

    constructor(
        @Optional() @SkipSelf() parentModule: ModalService,
        @Inject(DOCUMENT) private document: Document,
        private applicationRef: ApplicationRef,
        private focusOverlayService: FocusOverlayService) {
        if (parentModule) {
            this.modals = parentModule.modals;
        } else {
            this.modals = new Map<ModalTypes, ModalState<any>>();
        }
    }

    addModal(state: ModalState<any>): void {
        this.modals.set(state.modal, state);
    }

    removeModal(modal: ModalTypes | string): void {
        this.modals.delete(modal);
    }

    hasModal(modal: ModalTypes | string): boolean {
        return this.modals.has(modal);
    }

    isModalOpen(modal: ModalTypes | string): boolean {
        return this.modals.get(modal).state == ModalStates.OPEN;
    }

    openModal(modal: ModalTypes | string, data?: any) {
        this.scrollPosition = window.scrollY;
        this.setModalState(modal, ModalStates.OPEN, data);
        this.document.querySelector('body').style.overflow = 'hidden';
        this.modalOpened.next(true);
        return this.modals.get(modal);
    }

    closeModal(modal: ModalTypes | string, scrollTop: boolean = false): void {
        this.setModalState(modal, ModalStates.CLOSED);
        this.document.querySelector('body').style.overflow = null;
        this.focusOverlayService.setClosed();

        this.modalOpened.next(false);

        if (scrollTop) {
            window.scrollTo(0, this.scrollPosition);
        }
    }

    closeModals(): void {
        this.document.querySelector('body').style.overflow = null;
    }

    private setModalState(modal: ModalTypes | string, state: ModalStates, data?: any): void {
        let m = this.modals.get(modal);
        if (!m) {
            return;
        }

        m.state = state;

        if (data) {
            m.data = data;
        }

        if (m.callback) {
            m.callback.next(m);
        }

        this.applicationRef.tick();
    }
}
