import { Component, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

import { ModalTypes } from '../../models/modal/modal-types';
import { ModalService } from '../../services/modal.service';
import { ModalState } from "../../models/modal/modal-state";
import { FormMessageTypes } from '../form-message/form-message-types';
import { ConfirmConfiguration } from '../../models/modal/confirm-configuration';

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ConfirmModalComponent  {

    ModalTypes = ModalTypes;

    callbackSubscriber = new Subject<ModalState<ConfirmConfiguration>>();

    message: string;
    title: string;
    yesLabel: string;
    noLabel: string;

    FormMessageTypes = FormMessageTypes;
    private confirmCallback: Subject<void>;
    private cancelCallback: Subject<void>;

    constructor(private modalService: ModalService) {
        this.callbackSubscriber.subscribe(config => this.configureForm(config.data));
    }

    closeModal() {
        this.modalService.closeModal(ModalTypes.CONFIRM_ITEM);

        if (this.cancelCallback != null) {
            this.cancelCallback.next();
        }
    }

    confirm() {
        if (this.confirmCallback != null) {
            this.confirmCallback.next();
        }
    }

    configureForm(data: ConfirmConfiguration) {
        if (data != null) {
            this.message = data.message;
            this.title = data.title;
            this.confirmCallback = data.confirmCallback;
            this.cancelCallback = data.cancelCallback;
            this.yesLabel = data.yesLabel;
            this.noLabel = data.noLabel;
        }
    }
}
