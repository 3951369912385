import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieConsentModalComponent } from "./cookie-consent-modal.component";
import { RouterModule } from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
    ],
    declarations: [
        CookieConsentModalComponent
    ],
    exports: [
        CookieConsentModalComponent
    ]
})

export class CookieConsentModalModule {
}
