import { Component, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

import { ModalTypes } from '../../models/modal/modal-types';
import { ModalService } from '../../services/modal.service';
import { ModalState } from '../../models/modal/modal-state';
import { RemoveItemConfiguration } from '../../models/modal/remove-item-configuration';
import { FormMessageTypes } from '../form-message/form-message-types';
import { F } from '@angular/cdk/keycodes';

@Component({
    selector: 'remove-item-modal',
    templateUrl: './remove-item-modal.component.html',
    encapsulation: ViewEncapsulation.None
})

export class RemoveItemModalComponent {

    ModalTypes = ModalTypes;

    callbackSubscriber = new Subject<ModalState<RemoveItemConfiguration>>();

    itemType: string;
    itemTitle: string;

    loading: boolean = false;

    FormMessageTypes = FormMessageTypes;
    private confirmCallback: Subject<void>;

    constructor(private modalService: ModalService) {
        this.callbackSubscriber.subscribe(config => this.configureForm(config.data));
    }

    closeModal() {
        this.itemType = null;
        this.itemTitle = null;
        this.loading = false;
        this.modalService.closeModal(ModalTypes.REMOVE_ITEM);
    }

    confirmRemoveItem() {
        this.loading = true;
        this.confirmCallback.next();
    }

    configureForm(data: RemoveItemConfiguration) {
        this.loading = false;
        this.itemType = data.itemType;
        this.itemTitle = data.itemTitle;
        this.confirmCallback = data.confirmCallback;
    }
}
