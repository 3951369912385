import { Injectable } from '@angular/core';

declare const fbq: Function;
declare let gtag: Function;

@Injectable()
export class AnalyticsService {
    constructor() {
        window.dataLayer = window.dataLayer || [];
    }

    setUser(reference, isManager) {
        const exists = window.dataLayer.find(m => m['user_id'] !== undefined);
        if (exists) {
            window.dataLayer.splice(window.dataLayer.indexOf(exists));
        }

        if (reference) {
            window.dataLayer.push({
                'user_id': reference,
                'is_manager': isManager
            });
        }
    }

    pushEvent(event: string, data: any) {
        let val = {
            event: event
        };

        val = {...val, ...data};

        window.dataLayer.push(val);
    }

    pushAppTrackingEvent(data: any) {
        this.pushEvent('appTracking', data);
    }

    pushFacebookPixelEvent(customEventName: string, data: any) {
        fbq('trackCustom', customEventName, data);
    }
}
