import {Component, ViewEncapsulation, Input, ViewChild, ElementRef, OnInit} from '@angular/core';
import {ConfigService} from "../../services/config.service";
import {AccountService} from "../../services/account.service";
import {Guid} from "../../models/guid/guid";
import {NavigateService} from "../../services/navigate.service";
@Component({
    selector: 'debug-info',
    templateUrl: './debug-info.component.html',
    encapsulation: ViewEncapsulation.None
})

export class DebugInfoComponent implements OnInit {
    @Input() startExpandedOnDev : boolean = false;   // Will override the expanded setting so you can set different behaviour on dev vs live
    @Input() showExpandButton: boolean = true;
    @Input() isTiny: boolean = false;
    @Input() expanded : boolean = false;
    @Input() jsonPrint : any = false;
    @Input() title  = "";

    enabled : boolean = false;
    domId : string;

    constructor(private configService : ConfigService, private accountService : AccountService, private navigateService : NavigateService) {
    }

    ngOnInit() {
        if (this.accountService.loginHasDebugEnabled())  this.enabled = true;
        if (this.configService.isDev && this.startExpandedOnDev) this.expanded = true;

        if (this.isTiny) {
            this.showExpandButton = false;
            this.expanded = true;
            this.title = "";
        }

        this.domId = "info-box-" + Guid.newGuid();
    }

    toggleExpand() {
        this.expanded = !this.expanded;

        // // Was closed, scroll to top
        // if (!this.expanded) {
        //     this.navigateService.scrollToElement('#' + this.domId);
        // }
    }
}
