import {HttpClient, HttpEvent, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ConfigService} from './config.service';
import {ReportMaintenanceIssuePost} from '../models/maintenance/report-maintenance-issue-post';
import {FileService} from "./file.service";
import {MaintenancePropertyOverview} from '../models/maintenance/maintenance-property-overview';
import {MaintenanceIssue} from '../models/maintenance/maintenance-issue';
import {MyMaintenanceOverview} from '../models/maintenance/my-maintenance-overview';
import {MaintenanceIssuePatch} from '../models/maintenance/maintenance-issue-patch';
import {MaintenanceOrganisationOverview} from '../models/maintenance/maintenance-organisation-overview';
import {MaintenanceOrganisationSummary} from '../models/maintenance/maintenance-organisation-summary';
import {MaintenancePropertySummary} from '../models/maintenance/maintenance-property-summary';
import {AccountService} from './account.service';
import {MaintenanceOwnerOverview} from '../models/maintenance/maintenance-owner-overview';
import {MaintenanceOwnerSummary} from '../models/maintenance/maintenance-owner-summary';
import {MaintenanceCategoryPost} from '../models/maintenance/maintenance-category-post';
import {MaintenanceCategory} from '../models/maintenance/maintenance-category';
import {MaintenanceCategoryPatch} from '../models/maintenance/maintenance-category-patch';
import {ManageMaintenanceCategory} from '../models/maintenance/manage-maintenance-category';
import {PaginatedList} from "../models/paginated-list";
import {
    MaintenanceIssueSendSupplierPostInterface
} from '../models/maintenance/maintenance-issue-send-supplier-post.interface';
import {MaintenanceSupplierOverview} from '../models/maintenance/maintenance-supplier-overview';
import {MaintenanceSupplierSummary} from '../models/maintenance/maintenance-supplier-summary';
import {MaintenanceIssuePostInvoiceInterface} from "../models/maintenance/maintenance-issue-post-invoice.interface";
import {MaintenanceIssueUserAssignInterface} from "../models/maintenance/maintenance-issue-user-assign.interface";
import {
    MaintenanceIssueSupplierAssignInterface
} from "../models/maintenance/maintenance-issue-supplier-assign.interface";
import {MaintenanceIssueTenantAssignInterface} from "../models/maintenance/maintenance-issue-tenant-assign.interface";
import {PostMaintenanceIssueNoLoginInterface} from "../models/maintenance/post-maintenance-issue-no-login.interface";
import {ConversationUserRoles} from "../models/message/conversation-user-roles";
import {MaintenanceIssueCategoryPatch} from "../models/maintenance/maintenance-issue-category-patch.interface";
import {MaintenanceIssueSummaryInterface} from "../models/maintenance/maintenance-issue-summary.interface";
import {ConversationToMaintenanceInterface} from "../models/maintenance/conversation-to-maintenance.interface";
import {ManageOrganisationService} from "./manage-organisation.service";
import {MaintenanceIssueCompletePatchInterface} from "../models/maintenance/maintenance-issue-complete-patch.interface";
import {
    MyHomeNewMaintenanceIssuePostInterface
} from "../models/maintenance/my-home-new-maintenance-issue-post.interface";
import {ManageOrganisationUsersService} from "./manage-organisation-users.service";
import {Panels} from "../models/organisation/organisation-person";
import {UserRoleContexts} from "../models/user/user-role-context";
import {MaintenanceIssueSchedulePatch} from "../models/maintenance/maintenance-issue-schedule-patch.interface";
import {RequestOwnerApprovalForMaintenance} from "../models/maintenance/request-owner-approval-for-maintenance";
import {UnassignSupplierPostInterface} from "../models/maintenance/unassign-supplier-post.interface";
import {PropertyOwner} from "../models/property-owner/property-owner";
import {FilePost} from "../models/file/file-post";
import {MaintenanceInvoicePatchStatus} from "../models/maintenance/maintenance-invoice-patch-status.interface";
import {mergeMap, tap} from "rxjs/operators";
import {HouseShareFile} from "../models/file/file";

@Injectable()
export class MaintenanceService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private fileService: FileService,
        private accountService: AccountService,
        private manageOrganisationService: ManageOrganisationService,
        private manageOrganisationUsersService: ManageOrganisationUsersService) {
    }

    getPropertyOverview(organisationReference: string, propertyReference: string): Observable<MaintenancePropertyOverview> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/overview`;
        const orgPersonDefaultFilter = this.manageOrganisationUsersService.getDefaultFilterValueForPanelFromLocalStorage(Panels.MAINTENANCE);
        let params = new HttpParams();
        if (orgPersonDefaultFilter) params = params.set("orgPersonDefaultFilter", orgPersonDefaultFilter);
        return this.http.get<MaintenancePropertyOverview>(url, {params});
    }

    getPropertySummary(organisationReference: string, propertyReference: string): Observable<MaintenancePropertySummary> {
        return this.http.get<MaintenancePropertySummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/summary`);
    }

    getOrganisationSummary(organisationReference: string): Observable<MaintenanceOrganisationSummary> {
        return this.http.get<MaintenanceOrganisationSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/maintenance/maintenance-summary`);
    }

    getOrganisationOverview(organisationReference: string): Observable<MaintenanceOrganisationOverview> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/maintenance/maintenance-overview`
        const orgPersonDefaultFilter = this.manageOrganisationUsersService.getDefaultFilterValueForPanelFromLocalStorage(Panels.MAINTENANCE);
        let params = new HttpParams();
        if (orgPersonDefaultFilter) params = params.set("orgPersonDefaultFilter", orgPersonDefaultFilter);
        return this.http.get<MaintenanceOrganisationOverview>(url, {params});
    }

    getManagerMaintenanceIssues(organisationReference: string, propertyReference: string, organisationPersons: string[] | null = null, includeUnassigned = false, closed = false): Observable<MaintenanceIssue[]> {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;

        if (propertyReference != null) {
            url += `/property/${propertyReference}`;
        }

        url += '/maintenance/maintenance-issues';

        let params = new HttpParams();
        if (organisationPersons) {
            if (organisationPersons.length == 0) params = params.append('organisationPersons', 'no-match');
            else organisationPersons.forEach(m => params = params.append('organisationPersons', m));
        }
        if (includeUnassigned) params = params.append('includeUnassigned', "true");
        params = params.append('closed', closed);

        return this.http.get<MaintenanceIssue[]>(url, {params});
    }

    getMyOverview(tenancyGuid?: string): Observable<MyMaintenanceOverview> {
        const url = `${this.configService.baseUrl}/my-home/my-maintenance/overview`
        const params = tenancyGuid ? {tenancyGuid: tenancyGuid} : {};
        return this.http.get<MyMaintenanceOverview>(url, {params});
    }

    getTenantMaintenanceIssues(page: number = 1): Observable<PaginatedList<MaintenanceIssue>> {
        let params = new HttpParams();
        if (page) params = params.set("page", page.toString());

        return this.http.get<PaginatedList<MaintenanceIssue>>(`${this.configService.baseUrl}/my-home/my-maintenance`, {params});
    }

    get(propertyReference: string, maintenanceIssueGuid: string): Observable<MaintenanceIssue> {
        return this.http.get<MaintenanceIssue>(`${this.configService.baseUrl}/manage/property/${propertyReference}/maintenance/${maintenanceIssueGuid}`);
    }

    getWithoutProperty(maintenanceIssueGuid: string, userRole?: ConversationUserRoles): Observable<MaintenanceIssue> {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}`
        let params = new HttpParams();
        if (userRole) params = params.set("userRole", userRole.toString());

        return this.http.get<MaintenanceIssue>(url, {params});
    }

    getSupplierMaintenanceIssue(supplierMagicLink: string): Observable<MaintenanceIssue> {
        const url = `${this.configService.baseUrl}/supplier/maintenance/${supplierMagicLink}`
        return this.http.get<MaintenanceIssue>(url);
    }

    addDefaultTroubleshootingCards(organisationReference: string): Observable<boolean> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/maintenance/add-default-troubleshooting-cards`
        return this.http.post<boolean>(url, null);
    }

    postMaintenanceIssueInvoice(organisationReference: string, propertyReference: string, maintenanceIssueGuid: string, model: MaintenanceIssuePostInvoiceInterface): Observable<MaintenanceIssue> {
        return this.fileService.uploadFiles(model.supplierInvoiceFiles)
            .pipe(tap(_ => model.supplierInvoiceFiles = this.fileService.prepareFilePosts(model.supplierInvoiceFiles)),
                mergeMap(_ => this.http.post<MaintenanceIssue>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/${maintenanceIssueGuid}/supplier-invoices`, model)));
    }

    patchMaintenanceIssueInvoice(organisationReference: string, propertyReference: string, maintenanceIssueGuid: string, model: MaintenanceIssuePostInvoiceInterface): Observable<MaintenanceIssue> {
        return this.fileService.uploadFiles(model.supplierInvoiceFiles)
            .pipe(tap(_ => model.supplierInvoiceFiles = this.fileService.prepareFilePosts(model.supplierInvoiceFiles)),
                mergeMap(_ => this.http.patch<MaintenanceIssue>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/${maintenanceIssueGuid}/supplier-invoices/${model.guid}`, model)));
    }

    patchMaintenanceIssueInvoiceStatus(organisationReference: string, propertyReference: string, maintenanceIssueGuid: string, model: MaintenanceInvoicePatchStatus): Observable<MaintenanceIssue> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/${maintenanceIssueGuid}/supplier-invoices/${model.guid}/status`
        return this.http.patch<MaintenanceIssue>(url, model);
    }

    deleteMaintenanceIssueInvoice(organisationReference: string, propertyReference: string, maintenanceIssueGuid: string, invoiceGuid: string): Observable<MaintenanceIssue> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/${maintenanceIssueGuid}/supplier-invoice/${invoiceGuid}`
        return this.http.delete<MaintenanceIssue>(url);
    }

    patchMaintenanceIssueProperty(organisationReference: string, propertyReference: string, maintenanceIssueGuid: string): Observable<MaintenanceIssue> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/${maintenanceIssueGuid}/assign-property`
        return this.http.patch<MaintenanceIssue>(url, propertyReference);
    }


    patchMaintenanceIssue(organisationReference: string, propertyReference: string, maintenanceIssueGuid: string, model: MaintenanceIssuePatch): Observable<MaintenanceIssue> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/${maintenanceIssueGuid}/status`
        return this.http.patch<MaintenanceIssue>(url, model);
    }

    patchMaintenanceIssueTenant(organisationReference: string, propertyReference: string, maintenanceIssueGuid: string, model: MaintenanceIssueTenantAssignInterface): Observable<MaintenanceIssue> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/${maintenanceIssueGuid}/assign-tenant`
        return this.http.patch<MaintenanceIssue>(url, model);
    }

    patchMaintenanceIssueUser(maintenanceIssueGuid: string, model: MaintenanceIssueUserAssignInterface): Observable<MaintenanceIssue> {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/assign-user`
        return this.http.patch<MaintenanceIssue>(url, model);
    }

    assignSupplier(maintenanceIssueGuid: string, model: MaintenanceIssueSupplierAssignInterface) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/assign-supplier`
        return this.http.patch<MaintenanceIssue>(url, model);
    }

    unassignSupplier(maintenanceIssueGuid: string, model: UnassignSupplierPostInterface) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/unassign-supplier`
        return this.http.patch<MaintenanceIssue>(url, model);
    }

    patchMaintenanceIssueCategory(maintenanceIssueGuid: string, model: MaintenanceIssueCategoryPatch): Observable<MaintenanceIssue> {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/maintenance-category/`
        return this.http.patch<MaintenanceIssue>(url, model);
    }

    sendSupplierIssue(organisationReference: string, propertyReference: string, maintenanceIssueGuid: string, model: MaintenanceIssueSendSupplierPostInterface): Observable<MaintenanceIssue> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/${maintenanceIssueGuid}/send-supplier`
        return this.http.post<MaintenanceIssue>(url, model)
    }

    reportMaintenance(model: MyHomeNewMaintenanceIssuePostInterface, tenancyGuid?: string) {
        const url = `${this.configService.baseUrl}/my-home/maintenance`;
        const params = tenancyGuid ? {tenancyGuid: tenancyGuid} : {};
        return this.fileService.uploadFiles(model.files)
            .pipe(
                tap(_ => model.files = this.fileService.prepareFilePosts(model.files)),
                mergeMap(_ => this.http.post<MaintenanceIssue>(url, model, {params})));
    }

    postMaintenanceIssueNoLogin(model: PostMaintenanceIssueNoLoginInterface): Observable<boolean> {
        return this.fileService.uploadFiles(model.files)
            .pipe(tap(_ => model.files = this.fileService.prepareFilePosts(model.files)),
                mergeMap(_ => this.http.post<boolean>(`${this.configService.baseUrl}/maintenance/new-issue-no-login`, model)));
    }

    add(propertyReference: string, model: ReportMaintenanceIssuePost): Observable<MaintenanceIssue> {
        return this.fileService.uploadFiles(model.files)
            .pipe(tap(_ => model.files = this.fileService.prepareFilePosts(model.files)),
                mergeMap(_ => this.http.post<MaintenanceIssue>(`${this.configService.baseUrl}/manage/property/${propertyReference}/maintenance`, model)));
    }

    setIssueShared(maintenanceIssueGuid: string): Observable<MaintenanceIssue> {
        return this.http.patch<MaintenanceIssue>(`${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/set-shared`, null);
    }

    setIssueSharedOwner(maintenanceIssueGuid: string, isShared: boolean): Observable<MaintenanceIssue> {
        return this.http.post<MaintenanceIssue>(`${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/set-shared-owner/${isShared}`, null);
    }

    setIssuePrivate(organisationReference: string, propertyReference: string, maintenanceIssueGuid: string): Observable<MaintenanceIssue> {
        return this.http.post<MaintenanceIssue>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/${maintenanceIssueGuid}/set-private`, null);
    }

    generateMaintenanceIssueCSV(organisationReference: string, closedRecords: boolean) {

        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/maintenance/generate-maintenance-csv`;
        const params = new HttpParams()
            .set('access_token', this.accountService.accessToken.bearerToken)
            .set('closedRecords', closedRecords.toString());

        return this.http.get(url, {params, responseType: 'blob'});
    }

    getOwnerOverview(): Observable<MaintenanceOwnerOverview> {
        return this.http.get<MaintenanceOwnerOverview>(`${this.configService.baseUrl}/owner/maintenance/overview`);
    }

    getOwnerSummary(): Observable<MaintenanceOwnerSummary> {
        return this.http.get<MaintenanceOwnerSummary>(`${this.configService.baseUrl}/owner/maintenance/summary`);
    }

    getOwnerMaintenanceIssues(propertyReference: string) {
        const url = `${this.configService.baseUrl}/owner/maintenance`;
        let params = new HttpParams();
        if (propertyReference) params = params.set("propertyReference", propertyReference);
        return this.http.get<MaintenanceIssue[]>(url, {params});
    }

    getOwnerMaintenanceIssue(maintenanceReference: string): Observable<MaintenanceIssue> {

        let url = `${this.configService.baseUrl}/owner/`;

        url += `maintenance/`;
        url += `${maintenanceReference}`;

        return this.http.get<MaintenanceIssue>(url);
    }

    getOwnerPropertyOverview(propertyReference: string): Observable<MaintenanceOwnerOverview> {
        return this.http.get<MaintenanceOwnerOverview>(`${this.configService.baseUrl}/owner/property/${propertyReference}/maintenance/overview`);
    }

    getOwnerPropertySummary(propertyReference: string): Observable<MaintenanceOwnerSummary> {
        return this.http.get<MaintenanceOwnerSummary>(`${this.configService.baseUrl}/owner/property/${propertyReference}/maintenance/summary`);
    }

    getHomeMaintenanceCategories(): Observable<MaintenanceCategory[]> {
        return this.http.get<MaintenanceCategory[]>(`${this.configService.baseUrl}/my-home/maintenance/maintenance-categories`);
    }

    getMaintenanceCategoriesNoLogin(propertyReference?: string): Observable<MaintenanceCategory[]> {
        const organisationReference = this.manageOrganisationService.getOrganisationReference()
        let params = new HttpParams();
        params = params.set("organisationReference", organisationReference);
        if (propertyReference != null) params = params.append('propertyReference', propertyReference);

        return this.http.get<MaintenanceCategory[]>(`${this.configService.baseUrl}/maintenance/maintenance-categories`, {params});
    }

    getMaintenanceCategories(organisationReference: string): Observable<ManageMaintenanceCategory[]> {
        return this.http.get<ManageMaintenanceCategory[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/maintenance/maintenance-categories`);
    }

    addMaintenanceCategory(organisationReference: string, propertyReference: string, model: MaintenanceCategoryPost): Observable<ManageMaintenanceCategory> {
        if (propertyReference != null) {
            return this.http.post<ManageMaintenanceCategory>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/maintenance-categories`, model);
        } else {
            return this.http.post<ManageMaintenanceCategory>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/maintenance/maintenance-categories`, model);
        }
    }

    patchMaintenanceCategory(organisationReference: string, propertyReference: string, maintenanceCategoryGuid: string, model: MaintenanceCategoryPatch): Observable<ManageMaintenanceCategory> {
        if (propertyReference != null) {
            return this.http.patch<ManageMaintenanceCategory>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/maintenance-categories/${maintenanceCategoryGuid}`, model);
        } else {
            return this.http.patch<ManageMaintenanceCategory>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/maintenance/maintenance-categories/${maintenanceCategoryGuid}`, model);
        }
    }

    getSupplierOverview(): Observable<MaintenanceSupplierOverview> {
        return this.http.get<MaintenanceSupplierOverview>(`${this.configService.baseUrl}/supplier/maintenance/overview`);
    }

    getSupplierSummary(): Observable<MaintenanceSupplierSummary> {
        return this.http.get<MaintenanceSupplierSummary>(`${this.configService.baseUrl}/supplier/maintenance/summary`);
    }

    overrideCategoryName(maintenanceIssueGuid: string, categoryNameOverride: string): Observable<MaintenanceIssue> {
        const params = new HttpParams().set('categoryNameOverride', categoryNameOverride);
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/category-name-override`;
        return this.http.patch<MaintenanceIssue>(url, null, {params});
    }

    convertConversationToMaintenanceIssue(organisationReference: string, propertyReference: string, model: ConversationToMaintenanceInterface): Observable<MaintenanceIssueSummaryInterface> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/convert-from-conversation`
        return this.http.post<MaintenanceIssueSummaryInterface>(url, model);
    }

    close(maintenanceIssueGuid: string, close: boolean) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/close`
        const params = new HttpParams().set('close', close);
        return this.http.patch<MaintenanceIssue>(url, null, {params});
    }

    delete(maintenanceIssueGuid: string) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}`
        return this.http.delete<MaintenanceIssue>(url);
    }

    completeMaintenanceIssue(organisationReference: string, propertyReference: string, maintenanceIssueGuid: string, model: MaintenanceIssueCompletePatchInterface) {
        return this.fileService.uploadFiles(model.files)
            .pipe(tap(_ => model.files = this.fileService.prepareFilePosts(model.files)),
                mergeMap(_ => this.http.patch<MaintenanceIssue>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/maintenance/${maintenanceIssueGuid}/complete`, model)));
    }

    getFinancialItemMaintenanceGuid(organisationReference: string, financialItemGuid: string) {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/maintenance/match-by-financial-item/${financialItemGuid}`
        return this.http.get<string>(url);
    }

    completionReview(maintenanceIssueGuid: string, approve: boolean, requireInvoice: boolean) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/completion-review`
        let params = new HttpParams();
        params = params.set("approve", approve);
        params = params.set("requireInvoice", requireInvoice);
        return this.http.patch<MaintenanceIssue>(url, null, {params});
    }

    proposeScheduleDate(maintenanceIssueGuid: string, model: MaintenanceIssueSchedulePatch) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/propose-schedule-date`
        return this.http.patch<MaintenanceIssue>(url, model);
    }

    approveScheduleDate(maintenanceIssueGuid: string, context: UserRoleContexts) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/approve-schedule-date`
        let params = new HttpParams();
        params = params.set("context", context);
        return this.http.patch<MaintenanceIssue>(url, null, {params});
    }


    createManagerOwnerConversation(maintenanceIssueGuid: string) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/create-manager-owner-conversation`
        return this.http.patch<MaintenanceIssue>(url, null);
    }

    requestOwnerApproval(maintenanceIssueGuid: string, model: RequestOwnerApprovalForMaintenance) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/request-owner-approval`
        return this.http.patch<MaintenanceIssue>(url, model);
    }

    getPropertyOwner(maintenanceIssueGuid: string) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/property-owner`
        return this.http.get<PropertyOwner>(url);
    }

    alertActiveTenants(maintenanceIssueGuid: string) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/alert-active-tenants`
        return this.http.patch(url, null);
    }

    patchFiles(maintenanceIssueGuid: string, managerOnly: boolean, model: HouseShareFile[]) {
        const url = `${this.configService.baseUrl}/manage/maintenance/${maintenanceIssueGuid}/files`
        let params = new HttpParams();
        params = params.set("managerOnly", managerOnly);

        let files: HouseShareFile[] | FilePost[] = model;

        return this.fileService.uploadFiles(model)
            .pipe(tap(_ => files = this.fileService.prepareFilePosts(model)),
                mergeMap(_ => this.http.patch<MaintenanceIssue>(url, files, {params})));
    }
}
