import { FileTypes } from './file-types';

export class File {
    reference: string;
    title: string;
    filename: string;
    originalFilename: string;
    dataString: string;
    url: string;
    downloadUrl: string;
    thumbnail: string;
    type: FileTypes;
    deleted: boolean = false;
    invalid: boolean;
    order?: number;
    isNew: boolean = false;
    isMoved: boolean = false;
    size: number;
    contentType: string;
    key: string;

    existingFile: boolean; //this is sent to the back to check if we need to upload the file or if it already exists
    existingFileName: string;

    extension: string;
    extensionType: string;
    public: boolean;

    downloading: boolean = false;

    get assumedType() : FileTypes {
        if (!this.dataString) {
            return FileTypes.UNKNOWN;
        }

        if (this.dataString.startsWith('data:image')) {
            return FileTypes.IMAGE;
        } else if (this.dataString.startsWith('data:video')) {
            return FileTypes.VIDEO;
        } else if (this.dataString.startsWith('data:audio')) {
            return FileTypes.MEDIA;
        } else {
            return FileTypes.DOCUMENT;
        }
    }
}

export class HouseShareFile extends File {}
