export class Guid {
  static newGuid() {
    let guid = Guid.generateGuid();
    while (/^[0-9]/.test(guid) === true) {
      guid = Guid.generateGuid();
    }
    return guid;
  }

  private static generateGuid(): string {
    return 'axxxxxxx-xxxx-4xxx-cxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }

    static empty() {
        return "00000000-0000-0000-0000-000000000000";
    }
}
