import { Injectable } from '@angular/core';

import * as _ from 'lodash';

@Injectable()
export class UtilitiesService {

    clone<T>(instance: any): T {
        return _.cloneDeep(instance);
    }

    equals(objA: any, objB: any): boolean {
        return _.isEqual(objA, objB);
    }

    merge(objA: any, objB: any) {
        return _.merge(objA, objB);
    }

    flatten(arr: any[]) {
        return _.flattenDeep(arr.filter(item => !!item));
    }

    uniqueBy<T>(arr: T[], field: string) {
        return _.uniqBy(arr, field);
    }
}
