import {Injectable, Injector, NgZone} from '@angular/core';
import LogRocket from 'logrocket';
import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';

import {User} from '../models/user/user';
import {ConfigService} from './config.service';
import { ActivityTypes, ActivityTypesReverse } from "../models/user/activity-types";
import {HttpClient} from "@angular/common/http";
import {UserTrackActivity} from "../models/user/user-track-activity";
import {AccountService} from "./account.service";
import {Guid} from "../models/guid/guid";
import { ManageOrganisationService } from "./manage-organisation.service";

@Injectable()
export class TrackingService {

    private hasInitLogRocket = false;
    private hasInitOpenReplay = false;
    private accountService: AccountService;
    private tracker: Tracker;
    private manageOrganisationService: ManageOrganisationService;

    constructor(private http: HttpClient, private configService: ConfigService, private injector: Injector, private zone: NgZone) {
    }

    private initLogRocket() {
        if (this.hasInitLogRocket) {
            return;
        }

        if (this.configService.logRocketKey) {
            LogRocket.init(this.configService.logRocketKey, {
                network: {
                    requestSanitizer: request => {
                        const url = request.url.toLowerCase();
                        if (url.indexOf('account/register') !== -1 || url.indexOf('account/login') !== -1 ||
                            url.indexOf('manage/accounting/add-payment-method') !== -1 || url.indexOf('manage/accounting/3d-secure-verification') !== -1) {
                            request.body = null;
                        }

                        if (request.body) {
                            const body = JSON.parse(request.body);
                            this.cleanseProperty(body, 'password');
                            request.body = JSON.stringify(body);
                        }

                        return request;
                    },
                    responseSanitizer: response => {
                        if (response.status === 401) {
                            return null;
                        }

                        return response;
                    }
                }
            });

            this.hasInitLogRocket = true;
        }
    }

    private initOpenReplay() {
        if (this.hasInitOpenReplay) {
            return;
        }

        if (this.configService.openReplayKey) {
            this.zone.runOutsideAngular(() => {
                this.tracker = new Tracker({
                    projectKey: this.configService.openReplayKey,
                    ingestPoint: "https://openreplay.coho.fun/ingest",
                    obscureTextNumbers: false,
                    obscureInputDates: false,
                    obscureInputEmails: false,
                    obscureInputNumbers: false,
                    obscureTextEmails: false,
                    defaultInputMode: 0,
                    __DISABLE_SECURE_MODE: this.configService.isDev,
                    network: {
                        capturePayload: true,
                        sessionTokenHeader: false,
                        failuresOnly: false,
                        ignoreHeaders: false,
                        captureInIframes: false,
                        sanitizer: (data) => {
                            if (!data.url.startsWith(this.configService.baseUrl)) {
                                data.request.body = null;
                                data.response.body = null;
                            }
                            return data;
                        }
                    }
                });

                this.tracker.use(trackerAssist());
            });
            this.hasInitOpenReplay = true;
        }
    }

    identify(user: User): void {
        if (!this.accountService) {
            this.accountService = this.injector.get(AccountService);
        }

        if (!this.manageOrganisationService) {
            this.manageOrganisationService = this.injector.get(ManageOrganisationService);
        }

        this.identifyOpenReplay(user);

        return;

        /*
        Commented out LogRocket as it is not used anymore

        const rand = Math.floor(Math.random() * (4))

        if (!this.hasInitLogRocket && user && !user.superAdmin && !this.accountService.loginStackCanPop() && (user.isManager || rand < 2)) {
            this.initLogRocket();
        }

        if (!this.hasInitLogRocket) {
            return;
        }

        if (user) {
            const name = `${user.person.forename} ${user.person.surname}`;
            const identity = {};
            if (user.person) {
                identity['name'] = name;
            }
            LogRocket.identify(user.email, identity);
        } else {
            LogRocket.startNewSession();
        }*/
    }

    private identifyOpenReplay(user: User): void {
        if (!this.hasInitOpenReplay && (!user?.superAdmin || this.configService.isDev)) {
            this.initOpenReplay();
        }

        if (!this.hasInitOpenReplay) {
            return;
        }

        this.zone.runOutsideAngular(() => {
            if (user) {
                let name;

                if (user.person) {
                    name = `${user.person.forename} ${user.person.surname}`;
                }

                if (this.tracker.isActive()) {
                    this.tracker.stop();
                }

                this.tracker.start({
                    userID: user.email,
                    metadata: {
                        UserType: this.getUserType(user)
                    }
                });

                if (name) {
                    this.tracker.setMetadata("Name", name);
                }

                if (user.isManager) {
                    this.manageOrganisationService.getOrganisationSummary(this.manageOrganisationService.getOrganisationReference())
                        .subscribe(org => {
                            if (org.name) {
                                this.tracker.setMetadata("Organisation", org.name);
                            }
                        });
                }

            } else {
                this.tracker.stop();
            }
        });
    }

    private cleanseProperty(obj, keyToFind) {
        if (!obj) {
            return;
        }

        if (obj[keyToFind]) {
            delete obj[keyToFind];
        }

        for (let key in obj) {
            if (typeof obj[key] === 'object') {
                const value = this.cleanseProperty(obj[key], keyToFind);
                if (value) {
                    return value;
                }
            }
        }
        return false;
    }

    trackActivity(type: ActivityTypes, data: string): void {
        const body: UserTrackActivity = {
            type: type,
            data: data
        }
        this.http.post(`${this.configService.baseUrl}/user/track-activity`, body)
            .subscribe();

        if (this.hasInitOpenReplay && this.tracker) {
            const typeReverse = ActivityTypesReverse[type];
            this.tracker.event(typeReverse, data);
        }
    }

    private getUserType(user: User) {
        if (user.isManager) {
            return 'Manager';
        } else if (user.isTenant) {
            return 'Tenant';
        } else if (user.isOwner) {
            return 'Owner';
        } else if (user.isSupplier) {
            return 'Supplier';
        } else if (user.isOnboarding) {
            return 'Onboarding';
        } else if (user.isViewing) {
            return 'Viewing';
        } else if (user.isLooking) {
            return 'Looking';
        }
    }
}
