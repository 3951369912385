<div class="form__message form__message--error" *ngIf="_responseErrors">
    <div class="form__message-icon">
        <svg-icon class="icon" name="icon-warning"></svg-icon>
    </div>

    <div class="form__message-text">
        <ng-container *ngFor="let keyName of _responseKeys">
                <p *ngFor="let errorString of _responseErrors[keyName]">{{ errorString }}<svg-icon class="icon" name="icon-find" (click)="goToControl(keyName)" *ngIf="controlExists(keyName) && false"></svg-icon></p>
        </ng-container>
        <ng-container *ngIf="_responseHasHidden && hiddenMessages == 'show'">
            <p class="u-margin-top--10"><strong>Developer Messages:</strong></p>
            <p *ngFor="let errorString of _responseErrors['_hidden_']" [innerHTML]="errorString"></p>
        </ng-container>
    </div>

    <div class="form__message-button" *ngIf="canTryAgain || onTryAgain.observed">
        <loader-button
            [icon]="true"
            [iconName]="'icon-reload'"
            [iconColor]="'white'"
            [cssClasses]="'button--small button--nomargin'"
            (click)="again()">
            Try again
        </loader-button>
    </div>
    <div class="form__message-button" *ngIf="canDismiss">
        <loader-button
            [icon]="true"
            [iconName]="'icon-cross'"
            [cssClasses]="'button--small button--nomargin'"
            (click)="dismiss()">
            Dismiss
        </loader-button>
    </div>
</div>
