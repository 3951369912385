import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { ConfigService } from './config.service';
import { Viewing } from '../models/tenant-find/viewing';
import { TenantFindRoomOverview } from '../models/tenant-find/tenant-find-room-overview';
import { ViewingInvitePost } from '../models/tenant-find/viewing-invite-post';
import { TenantFindPropertyOverview } from '../models/tenant-find/tenant-find-property-overview';
import { Lead } from '../models/tenant-find/lead';
import { RoomListingSourceItem } from '../models/tenant-find/room-listing-source-item';
import { LeadPatch } from '../models/tenant-find/lead-patch';
import { MyHomeViewingsOverview } from '../models/my-home/my-home-viewings-overview';
import { MySearchOverview } from '../models/my-home/my-search-overview';
import { LeadMessage } from '../models/tenant-find/lead-message';
import { TenantFindOrganisationSummary } from '../models/tenant-find/tenant-find-organisation-summary';
import { TenantFindPropertySummary } from '../models/tenant-find/tenant-find-property-summary';
import { LeadSource } from '../models/tenant-find/lead-source';
import {ViewingApplicationFormPostInterface} from "../models/tenant-find/viewing-application-form-post.interface";
import {ProposeViewingDateInterface} from "../models/tenant-find/propose-viewing-date.interface";
import {
    ViewingAssignOrganisationUserPostInterface
} from "../models/tenant-find/viewing-assign-organisation-user-post.interface";
import {PropertyViewingUserContexts} from "../models/tenant-find/viewing-user-role-context";
import {ViewingRequestPostInterface} from "../models/tenant-find/viewing-request-post.interface";
import { FileService } from './file.service';
import {
    ViewingCategoriesCountsAndActions
} from "../models/tenant-find/viewing-categories-counts-and-actions.interface";
import {
    ViewingSubCategoriesCountsAndActions
} from "../models/tenant-find/viewing-sub-categories-counts-and-actions.interface";
import {Availability} from "../models/tenant-find/availability";
import {RoomListingSource} from "../models/tenant-find/room-listing-source";
import {KnowledgeBaseArticle} from "../components/knowledge-articles/knowledge-articles.component";
import {CancelledViewingReasons} from "../models/tenant-find/cancelled-viewing-reasons";
import {ApplicationForm} from "../models/application-form/application-form";
import {ViewingNotification} from "../models/tenant-find/viewing-notifications";
import {PropertyViewingResponses} from "../models/tenant-find/property-viewing-responses";
import {ViewingFeedbackApplicantPost, ViewingFeedbackManagerPost} from "../models/tenant-find/viewing-feedback-post";
import {PostLead} from "../models/tenant-find/post-lead.interface";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class TenantFindService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private fileService: FileService) {
    }

    //SUMMARY

    getOrganisationSummary(organisationReference: string): Observable<TenantFindOrganisationSummary> {
        return this.http.get<TenantFindOrganisationSummary>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenant-find/summary`);
    }

    getPropertySummary(organisationReference: string, propertyReference: string): Observable<TenantFindPropertySummary> {
        return this.http.get<TenantFindPropertySummary>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/tenant-find/summary`);
    }

    //OVERVIEW

    getPropertyOverview(organisationReference: string, propertyReference: string): Observable<TenantFindPropertyOverview> {
        return this.http.get<TenantFindPropertyOverview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/tenant-find/overview`);
    }

    getRoomOverview(propertyReference: string, roomReference: string): Observable<TenantFindRoomOverview> {
        return this.http.get<TenantFindRoomOverview>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/room/${ roomReference }/tenant-find/overview`);
    }

    getMyHomeViewingsOverview(): Observable<MyHomeViewingsOverview> {
        return this.http.get<MyHomeViewingsOverview>(`${ this.configService.baseUrl }/my-home/home-viewings/overview`);
    }

    getMySearchOverview(): Observable<MySearchOverview> {
        return this.http.get<MySearchOverview>(`${ this.configService.baseUrl }/my-home/my-search/overview`);
    }

    //GET

    getViewings(organisationReference: string, propertyReferences: string[] = [], roomReference?: string, organisationPersons: string[] | null = null, includeUnassigned = false, archived = false, searchTerm: string = null){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/viewings`
        let params = new HttpParams();

        if (propertyReferences?.length) propertyReferences.forEach(p => params = params.append('propertyReferences', p));
        if(roomReference) params = params.append('roomReference', roomReference);

        if (organisationPersons) {
            if (organisationPersons.length == 0) params = params.append('organisationPersons', 'no-match');
            else organisationPersons.forEach(m => params = params.append('organisationPersons', m));
        }

        if (includeUnassigned) params = params.append('includeUnassigned', includeUnassigned);
        if (archived) params = params.append('archived', archived);
        if (searchTerm) params = params.append('searchTerm', searchTerm);

        return this.http.get<Viewing[]>(url, {params});
    }

    getViewingsCsv(organisationReference: string, propertyReferences: string[] = [], roomReference?: string, organisationPersons: string[] | null = null, includeUnassigned = false, archived = false, searchTerm: string = null){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/viewings/csv`
        let params = new HttpParams();

        if (propertyReferences?.length) propertyReferences.forEach(p => params = params.append('propertyReferences', p));
        if(roomReference) params = params.append('roomReference', roomReference);

        if (organisationPersons) {
            if (organisationPersons.length == 0) params = params.append('organisationPersons', 'no-match');
            else organisationPersons.forEach(m => params = params.append('organisationPersons', m));
        }

        if (includeUnassigned) params = params.append('includeUnassigned', includeUnassigned);
        if (archived) params = params.append('archived', archived);
        if (searchTerm) params = params.append('searchTerm', searchTerm);

        return this.http.get(url, { responseType: 'blob' });
    }

    getMyViewings(context: PropertyViewingUserContexts, archived = false, searchTerm: string = null) {
        const url = `${this.configService.baseUrl}/my-home/viewings`
        let params = new HttpParams();
        params = params.append('context', context);
        if (archived) params = params.append('archived', archived);
        if (searchTerm) params = params.append('searchTerm', searchTerm);
        return this.http.get<Viewing[]>(url, {params});
    }

    getCategoriesCountsAndActions(organisationReference: string, propertyReferences?: string[], roomReference? : string, organisationPersons: string[] | null = null, includeUnassigned = false, archived = false, searchTerm: string = null){
        const url = `${this.configService.baseUrl}/viewings/categories-counts-and-actions`
        let params = new HttpParams();
        if (organisationReference) params = params.set('organisationReference', organisationReference);
        if (propertyReferences?.length) propertyReferences.forEach(m => params = params.append('propertyReferences', m));
        if (roomReference) params = params.set('roomReference', roomReference);
        if (organisationPersons) {
            if (organisationPersons.length == 0) params = params.append('organisationPersons', 'no-match');
            else organisationPersons.forEach(m => params = params.append('organisationPersons', m));
        }
        if (includeUnassigned) params = params.append('includeUnassigned', includeUnassigned);
        if (archived) params = params.append('archived', archived);
        if (searchTerm) params = params.append('searchTerm', searchTerm);

        return this.http.get<ViewingCategoriesCountsAndActions>(url, {params});
    }

    //context is used to differentiate between viewings as an applicant (interact) and tenant (see and validate at the end)
    getMyCategoriesCountsAndActions(context: PropertyViewingUserContexts, archived = false, searchTerm: string = null){
        const url = `${this.configService.baseUrl}/my-home/categories-counts-and-actions`
        let params = new HttpParams();
        params = params.append('context', context);
        if (archived) params = params.append('archived', archived);
        if (searchTerm) params = params.append('searchTerm', searchTerm);
        return this.http.get<ViewingCategoriesCountsAndActions>(url, {params});
    }

    getSubCategoriesCountsAndActions(organisationReference: string, propertyReferences?: string[], roomReference? : string, organisationPersons: string[] | null = null, includeUnassigned = false, archived = false, searchTerm: string = null){
        const url = `${this.configService.baseUrl}/viewings/sub-categories-counts-and-actions`
        let params = new HttpParams();
        if (organisationReference) params = params.set('organisationReference', organisationReference);
        if (propertyReferences?.length) propertyReferences.forEach(m => params = params.append('propertyReferences', m));
        if (roomReference) params = params.set('roomReference', roomReference);
        if (organisationPersons) {
            if (organisationPersons.length == 0) params = params.append('organisationPersons', 'no-match');
            else organisationPersons.forEach(m => params = params.append('organisationPersons', m));
        }
        if (includeUnassigned) params = params.append('includeUnassigned', includeUnassigned);
        if (archived) params = params.append('archived', archived);
        if (searchTerm) params = params.append('searchTerm', searchTerm);

        return this.http.get<ViewingSubCategoriesCountsAndActions>(url, {params});
    }

    getViewing(viewingGuid: string) {
        const url = `${ this.configService.baseUrl }/viewings/${ viewingGuid }`
        return this.http.get<Viewing>(url);
    }

    getViewingApplicationForm(viewingGuid: string) {
        const url = `${ this.configService.baseUrl }/viewings/${ viewingGuid }/application-form`
        return this.http.get<ApplicationForm>(url);
    }

    getSources(organisationReference: string): Observable<LeadSource[]> {
        return this.http.get<LeadSource[]>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenant-find/sources`);
    }

    getGuidByViewingInvite(viewingReference: string): Observable<string> {
        return this.http.get<string>(`${ this.configService.baseUrl }/viewings/${ viewingReference }/guid`);
    }

    getMyViewing(viewingGuid: string, full: boolean = true): Observable<Viewing> {
        let params = new HttpParams();
        if (full) params = params.set('full', full.toString());
        return this.http.get<Viewing>(`${ this.configService.baseUrl }/my-home/viewings/${ viewingGuid }`, {params});
    }

    getMyHomeViewing(viewingGuid: string, full: boolean = true): Observable<Viewing> {
        let params = new HttpParams();
        if (full) params = params.set('full', full.toString());
        return this.http.get<Viewing>(`${ this.configService.baseUrl }/my-home/home-viewings/${ viewingGuid }`, {params});
    }

    getLead(organisationReference: string, leadGuid: string): Observable<Lead> {
        return this.http.get<Lead>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenant-find/leads/${ leadGuid }`);
    }

    getLeadMessages(organisationReference: string, leadGuid: string): Observable<LeadMessage[]> {
        return this.http.get<LeadMessage[]>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenant-find/leads/${ leadGuid }/messages`);
    }

    getAgreedViewingsForViewingDateSelection(propertyReference: string, isPublicReference: boolean){
        const url = `${this.configService.baseUrl}/find-a-home/${propertyReference}/agreed-viewings`
        let params = new HttpParams();
        params = params.set('isPublicReference', isPublicReference);
        return this.http.get<Viewing[]>(url, {params});
    }

    getLeads(organisationReference: string, archived: boolean, propertyReference?: string, roomReference?: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/leads/`
        let params = new HttpParams();
        params = params.set('archived', archived);
        if (propertyReference) params = params.set('propertyReference', propertyReference);
        if (roomReference) params = params.set('roomReference', roomReference);
        return this.http.get<Lead[]>(url, {params});
    }

    getAvailabilities(organisationReference: string, propertyReference?: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/availabilities/`
        let params = new HttpParams();
        if(propertyReference) {
            params = params.set('propertyReference', propertyReference);
        }
        return this.http.get<Availability[]>(url, {params});
    }

    getRoomAvailability(organisationReference: string, propertyReference?: string, roomReference?: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/properties/${propertyReference}/rooms/${roomReference}/availability`;
        return this.http.get<Availability>(url);
    }

    getUnAvailabilities(organisationReference: string, monthInAdvance: number = 1, propertyReference?: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/unavailabilities/`
        let params = new HttpParams();
        params = params.set('monthInAdvance', monthInAdvance);
        if(propertyReference) {
            params = params.set('propertyReference', propertyReference);
        }
        return this.http.get<Availability[]>(url, {params});
    }

    getRoomListingSources(organisationReference: string, propertyReference: string, roomReference?: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room-listing-sources/`
        let params = new HttpParams();
        if (roomReference) params = params.set('roomReference', roomReference);
        return this.http.get<RoomListingSource[]>(url, {params});
    }

    getKnowledgeBaseArticles(){
        const url = `${this.configService.baseUrl}/viewings/knowledge-base-articles/`
        return this.http.get<KnowledgeBaseArticle[]>(url);
    }

    getNotificationsStatus(viewingGuid: string){
        const url = `${this.configService.baseUrl}/viewings/${ viewingGuid }/notifications-status`
        return this.http.get<ViewingNotification[]>(url);
    }

    //POST

    inviteToViewing(organisationReference: string, propertyReference: string, model: ViewingInvitePost) {
        const url = `${ this.configService.baseUrl }/manage/organisation/${organisationReference}/property/${ propertyReference }/tenant-find/viewings/invite`
        return this.http.post<boolean>(url, model);
    }

    resendInviteToViewing(model: ViewingInvitePost) {
        const url = `${ this.configService.baseUrl }/viewings/${model.viewingGuid}/resend-invite`
        return this.http.post<boolean>(url, model);
    }

    sendViewingRequest(model: ViewingRequestPostInterface) {
        const url = `${this.configService.baseUrl}/find-a-home/request-viewing`
        return this.http.post<Viewing>(url, model);
    }

    setSpareRoomDeactivate(propertyReference: string): Observable<RoomListingSourceItem> {
        return this.http.post<RoomListingSourceItem>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/tenant-find/sources/spareroom/set-deactivate`, null);
    }

    setSpareRoomUpdate(propertyReference: string): Observable<RoomListingSourceItem> {
        return this.http.post<RoomListingSourceItem>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/tenant-find/sources/spareroom/set-update`, null);
    }

    setSpareRoomUpload(propertyReference: string): Observable<RoomListingSourceItem> {
        return this.http.post<RoomListingSourceItem>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/tenant-find/sources/spareroom/set-upload`, null);
    }

    setSpareRoomActivate(propertyReference: string): Observable<RoomListingSourceItem> {
        return this.http.post<RoomListingSourceItem>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/tenant-find/sources/spareroom/set-activate`, null);
    }

    postFacebookLeads(leads: string): Observable<number> {
        const body = {
            leads: leads
        };
        return this.http.post<number>(`${ this.configService.baseUrl }/tenant-find/facebook-leads-upload`, body);
    }

    resetLinkAttempts(propertyReference: string): Observable<void> {
        return this.http.post<void>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/tenant-find/sources/spareroom/reset-link-attempts`, null);
    }

    //PATCH

    updateLead(organisationReference: string, leadGuid: string, leadPatch: LeadPatch): Observable<Lead> {
        return this.http.patch<Lead>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenant-find/leads/${ leadGuid }`, leadPatch);
    }

    applicationFormCompletion(viewingGuid: string, model: ViewingApplicationFormPostInterface){
        let obs = of([]);

        if (model.completedApplicationForm) {
            if (model.completedApplicationForm.identification) {
                obs = obs.pipe(
                    mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.identification)),
                    tap(_ => model.completedApplicationForm.identification = this.fileService.prepareFilePosts(model.completedApplicationForm.identification)));
            }
            if (model.completedApplicationForm.bankStatements) {
                obs = obs.pipe(
                    mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.bankStatements)),
                    tap(_ => model.completedApplicationForm.bankStatements = this.fileService.prepareFilePosts(model.completedApplicationForm.bankStatements)));
            }
            if (model.completedApplicationForm.currentEmployerEmploymentContract) {
                obs = obs.pipe(
                    mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.currentEmployerEmploymentContract)),
                    tap(_ => model.completedApplicationForm.currentEmployerEmploymentContract = this.fileService.prepareFilePosts(model.completedApplicationForm.currentEmployerEmploymentContract)));
            }
            if (model.completedApplicationForm.currentEmployerCompanyAccounts) {
                obs = obs.pipe(
                    mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.currentEmployerCompanyAccounts)),
                    tap(_ => model.completedApplicationForm.currentEmployerCompanyAccounts = this.fileService.prepareFilePosts(model.completedApplicationForm.currentEmployerCompanyAccounts)));
            }
            if (model.completedApplicationForm.completedFiles) {
                obs = obs.pipe(
                    mergeMap(_ => this.fileService.uploadFiles(model.completedApplicationForm.completedFiles)),
                    tap(_ => model.completedApplicationForm.completedFiles = this.fileService.prepareFilePosts(model.completedApplicationForm.completedFiles)));
            }
        }

        return obs.pipe(mergeMap(_ =>  this.http.patch<boolean>(`${this.configService.baseUrl}/viewings/${viewingGuid}/pre-qualification`, model)));
    }

    patchApplicationFormToViewing(viewingGuid: string, model: ViewingApplicationFormPostInterface) {
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/pre-qualification-patch`
        return this.http.patch<boolean>(url, model);
    }

    proposeViewingDate(viewingGuid: string, model: ProposeViewingDateInterface) {
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/propose-date`
        return this.http.patch<boolean>(url, model);
    }

    cancelViewing(viewingGuid: string, context: PropertyViewingUserContexts, reason?: CancelledViewingReasons, rejectionReasonText?: string){
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/cancel`

        let model: ViewingApplicationFormPostInterface = { context };
        if (reason != null) model.reason = reason;
        if (rejectionReasonText != null) model.rejectionReasonText = rejectionReasonText;

        return this.http.patch<boolean>(url, model);
    }

    rejectViewingDate(viewingGuid: string, context: PropertyViewingUserContexts){
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/reject-date`
        let params = new HttpParams();
        params = params.set('context', context.toString());
        return this.http.patch<boolean>(url, null, {params});
    }

    acceptViewingDate(viewingGuid: string, context: PropertyViewingUserContexts, alertActiveTenants: boolean){
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/accept-date`
        let params = new HttpParams();
        params = params.set('context', context.toString());
        params = params.set('alertActiveTenants', alertActiveTenants)
        return this.http.patch<boolean>(url, null, {params});
    }

    archiveViewing(viewingGuid: string, archive: boolean, context: PropertyViewingUserContexts, onboarded: boolean = false) {
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/archive`
        let params = new HttpParams();
        params = params.set('archive', archive.toString());
        params = params.set('context', context.toString());
        params = params.set('onboarded', onboarded);

        return this.http.patch<boolean>(url, null, {params});
    }

    patchViewingOrganisationUser(viewingGuid: string, model: ViewingAssignOrganisationUserPostInterface){
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/organisation-users`
        return this.http.patch<boolean>(url, model);
    }

    assignRoom(viewingGuid: string, roomReference: string, unassign: boolean){
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/assign-room`
        let params = new HttpParams();
        params = params.set('roomReference', roomReference);
        params = params.set('unassign', unassign.toString());
        return this.http.patch<boolean>(url, null, {params});
    }

    confirmAttendance(viewingGuid: string, context: PropertyViewingUserContexts){
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/confirm-attendance`
        let params = new HttpParams();
        params = params.set('context', context.toString());
        return this.http.patch<Viewing>(url, null, {params});
    }

    managerFeedback(viewingGuid: string, model: ViewingFeedbackManagerPost) {
        const url = `${ this.configService.baseUrl }/viewings/${ viewingGuid }/manager-feedback`;
        let params = new HttpParams();
        return this.http.patch<boolean>(url, model, {params});
    }

    applicantFeedback(viewingGuid: string, model: ViewingFeedbackApplicantPost) {
        const url = `${ this.configService.baseUrl }/viewings/${ viewingGuid }/applicant-feedback`;
        let params = new HttpParams();
        return this.http.patch<boolean>(url, model, {params});
    }

    tenantFeedback(viewingGuid: string, response: PropertyViewingResponses, context: PropertyViewingUserContexts) {
        const url = `${ this.configService.baseUrl }/viewings/${ viewingGuid }/tenant-feedback`;
        let params = new HttpParams();
        params = params.set('context', context.toString());
        params = params.set('response', response.toString());
        return this.http.patch<boolean>(url, null, {params});
    }

    setSeen(viewingGuid: string, context: PropertyViewingUserContexts) {
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/seen`
        let params = new HttpParams();
        params = params.set('context', context.toString());
        return this.http.patch<boolean>(url, null, {params});
    }

    switchProperty(viewingGuid: string, propertyReference: string, roomReference?: string, meetingPoint?: string){
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/switch-property`
        let params = new HttpParams();
        params = params.set('propertyReference', propertyReference);
        if(roomReference) params = params.set('roomReference', roomReference);
        if(meetingPoint) params = params.set('meetingPoint', meetingPoint);
        return this.http.patch<boolean>(url, null, {params});
    }

    generateSeeds(organisationReference: string){
        const url = `${this.configService.baseUrl}/generate-viewings-seeds`
        let params = new HttpParams();
        params = params.set('organisationReference', organisationReference);
        return this.http.get<Viewing[]>(url, {params});
    }

    skipQuestionnaire(viewingGuid: string){
        const url = `${this.configService.baseUrl}/viewings/${viewingGuid}/skip-questionnaire`
        return this.http.patch<boolean>(url, null);
    }

    clearCaches(organisationReference: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/viewings/clear-caches`
        return this.http.patch<boolean>(url, null);
    }

    addLead(organisationReference: string, model: PostLead){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/leads`
        return this.http.post<Lead>(url, model);
    }
}
