import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import linkifyHtml from "linkify-html";

@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    transform(html: SafeHtml) {
        const sanitizedHtml : string | null = this.sanitizer.sanitize(SecurityContext.HTML, html);

        const linkifiedHtml =  linkifyHtml(sanitizedHtml, {
            target: '_blank',
            className: 'linkified'
        });

        return linkifiedHtml;
    }
}
