import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { EnergyPerformanceCertificate } from '../models/energy-performance-certificate/energy-performance-certificate';
import { EnergyPerformanceCertificatePost } from '../models/energy-performance-certificate/energy-performance-certificate-post';
import { EnergyPerformanceCertificateOverview } from '../models/energy-performance-certificate/energy-performance-certificate-overview';
import { FileService } from "./file.service";
import { OwnerEnergyPerformanceCertificateOverview } from '../models/energy-performance-certificate/owner-energy-performance-certificate-overview';
import {PropertyServicesSummary} from "../models/property-services/property-services-summary.interface";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class EnergyPerformanceCertificateService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string): Observable<EnergyPerformanceCertificateOverview> {
        return this.http.get<EnergyPerformanceCertificateOverview>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/energy-performance-certificate/overview`);
    }

    getList(propertyReference: string): Observable<EnergyPerformanceCertificate[]> {
        return this.http.get<EnergyPerformanceCertificate[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/energy-performance-certificate`);
    }

    getOwnerOverview(propertyReference: string): Observable<OwnerEnergyPerformanceCertificateOverview> {
        return this.http.get<OwnerEnergyPerformanceCertificateOverview>(`${this.configService.baseUrl}/owner/property/${propertyReference}/energy-performance-certificate/overview`);
    }

    getOwnerList(propertyReference: string): Observable<EnergyPerformanceCertificate[]> {
        return this.http.get<EnergyPerformanceCertificate[]>(`${this.configService.baseUrl}/owner/property/${propertyReference}/energy-performance-certificate`);
    }

    add(propertyReference: string, model: EnergyPerformanceCertificatePost): Observable<HttpEvent<EnergyPerformanceCertificate>> {
        return this.fileService.uploadFiles(model.energyPerformanceCertificateFiles)
            .pipe(tap(_ => model.energyPerformanceCertificateFiles = this.fileService.prepareFilePosts(model.energyPerformanceCertificateFiles)),
                mergeMap(_ => this.http.post<EnergyPerformanceCertificate>(`${this.configService.baseUrl}/manage/property/${propertyReference}/energy-performance-certificate`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(propertyReference: string, item: EnergyPerformanceCertificate): Observable<boolean> {
        return this.http.delete<boolean>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/energy-performance-certificate/${ item.guid }`);
    }

    getOwnerEnergyPerformanceCertificate(propertyReference: string, guid: string): Observable<EnergyPerformanceCertificate> {
        return this.http.get<EnergyPerformanceCertificate>(`${this.configService.baseUrl}/owner/property/${propertyReference}/energy-performance-certificate/${guid}`);
    }

    getSummary(propertyReference: string): Observable<any> {
        return this.http.get<PropertyServicesSummary>(`${this.configService.baseUrl}/owner/property/${propertyReference}/energy-performance-certificate/summary`);
    }
}
