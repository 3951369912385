<header>
    <div class="static__header-wrapper static__header-wrapper--reverse">
        <div class="static__header" [class.open]="mainMenuOpen">
            <div class="container">
                <button class="main__menu-icon toggle__trigger pointer" [class.open]="mainMenuOpen" (click)="toggleMainMenu()">
                    <span class="main__menu-bar">&nbsp;</span>
                    <span class="main__menu-bar">&nbsp;</span>
                    <span class="main__menu-bar">&nbsp;</span>
                    <span class="sr-only">Menu</span>
                </button>

                <a href="{{staticWebUrl}}?NR=1" class="header__logo">
                    <img src="https://d19qeljo1i8r7y.cloudfront.net/web-images/logos/logo--blue.png" alt="COHO logo">
                </a>

                <a href="{{staticWebUrl}}?NR=1" class="header__logo header__logo--reverse">
                    <img src="https://d19qeljo1i8r7y.cloudfront.net/web-images/logos/logo.png" alt="COHO logo" />
                </a>


                <div class="header__options">
                    <div class="button__group login-manage">
                        <div class="button__group-item" *ngIf="!isLoggedIn">
                            <a [routerLink]="['/login']" class="button button--ghost button--small" id="login">Login</a>
                        </div>
                        <div class="button__group-item" *ngIf="isLoggedIn && currentUser?.isManager">
                            <a [routerLink]="['/manage']" class="button button--small">Manage</a>
                        </div>
                        <div class="button__group-item" *ngIf="isLoggedIn && (currentUser?.isTenant || currentUser?.isViewing || currentUser?.isLooking || currentUser?.isOnboarding) && currentUser?.unclaimedAccount === false">
                            <a [routerLink]="['/my-home']" class="button button--small">
                               My COHO
                            </a>
                        </div>
                        <div class="button__group-item" *ngIf="isLoggedIn && currentUser?.isViewing && currentUser?.unclaimedAccount === true">
                            <a [routerLink]="['my-viewings','messages']" class="button button--small">
                                My Viewings
                            </a>
                        </div>
                        <div class="button__group-item" *ngIf="!isLoggedIn">
                            <a href="{{staticWebUrl}}instant-demo/" class="button button--secondary button--small" id="demo">Instant Demo</a>
                        </div>
                    </div>
                </div>

                <ul class="main__navigation main__navigation--mega">
                    <li><a [routerLink]="['/find-a-home']" (click)="navigateToFindAHome()">Find a home</a></li>
                    <li class="hover__trigger" [class.hovering]="megaHoverWhat" id="MegaWhat" (mouseover)="megaHoverWhat=true" (mouseout)="megaHoverWhat=false">
                        <a href="javascript:void(0);">What we do</a>
                        <div class="megamenu">
                            <p>COHO brings everything about managing shared living properties and housemates into one workflow in an all-in-one property management software platform .</p>
                            <div class="row row--space-half">
                                <div class="col col__md--6">
                                    <a href="{{staticWebUrl}}what-we-do/hmo-management-software" class="card card__mega card--image-left">
                                        <div class="card__image-content">
                                            <div class="card__image">
                                                <img src="https://d19qeljo1i8r7y.cloudfront.net/web-images/icons/misc/icon-landlord-new--large.png" alt="Onboarding icon">
                                            </div>
                                            <div class="card__content">
                                                <h5 veuid="c9unj9">HMO Management Software</h5>
                                                <p veuid="jqgzpu">Software to Manage your HMO Portfolio with one place to market and manage your properties.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col col__md--6">
                                    <a href="{{staticWebUrl}}what-we-do/rent-2-rent-software" class="card card__mega card--image-left">
                                        <div class="card__image-content">
                                            <div class="card__image">
                                                <img src="https://d19qeljo1i8r7y.cloudfront.net/web-images/icons/misc/icon-professional--large.png" alt="Repairs/maintenance icon">
                                            </div>
                                            <div class="card__content">
                                                <h5>Rent 2 Rent Software</h5>
                                                <p>All-in-one Software to Manage your Rent2Rent Portfolio. COHO makes running your Rent2Rent business easy.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <p>&zwnj;‌</p>
                        </div>
                    </li>
                    <li class="hover__trigger" [class.hovering]="megaHover" id="MegaFeatures" (mouseover)="megaHover=true" (mouseout)="megaHover=false">
                        <a href="{{staticWebUrl}}features/">Features</a>
                        <div class="megamenu">
                            <p>COHO is a property management platform bringing all aspects of managing shared living properties and housemates into one workflow.</p>
                            <div class="row row--space-half">
                                <div class="col col__md--6">
                                    <a href="{{staticWebUrl}}features/onboarding" class="card card__static-mega card--image-left">
                                        <div class="card__image-content">
                                            <div class="card__image">
                                                <img src="https://d19qeljo1i8r7y.cloudfront.net/web-images/icons/services/other/icon-onboarding--large.png" alt="Onboarding icon">
                                            </div>
                                            <div class="card__content">
                                                <h5>Applicant onboarding</h5>
                                                <p>Seamless, efficient management of a flexible onboarding process across your whole portfolio.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col col__md--6">
                                    <a href="{{staticWebUrl}}features/maintenance-messaging" class="card card__static-mega card--image-left">
                                        <div class="card__image-content">
                                            <div class="card__image">
                                                <img src="https://d19qeljo1i8r7y.cloudfront.net/web-images/icons/services/icon-repairs--large.png" alt="Repairs/maintenance icon">
                                            </div>
                                            <div class="card__content">
                                                <h5>Maintenance &amp; unified messaging</h5>
                                                <p>Housemates can log maintenance issues with pictures quickly and easily.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col col__md--6">
                                    <a href="{{staticWebUrl}}features/compliance" class="card card__static-mega card--image-left">
                                        <div class="card__image-content">
                                            <div class="card__image">
                                                <img src="https://d19qeljo1i8r7y.cloudfront.net/web-images/icons/services/icon-gas-safety-certificate--large.png" alt="An icon">
                                            </div>
                                            <div class="card__content">
                                                <h5>Compliance</h5>
                                                <p>Keep on top of all of the compliance issues, regulations and legislation on an HMO property.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col col__md--6">
                                    <a href="{{staticWebUrl}}features/viewings" class="card card__static-mega card--image-left">
                                        <div class="card__image-content">
                                            <div class="card__image">
                                                <img src="https://d19qeljo1i8r7y.cloudfront.net/web-images/icons/services/other/icon-viewing--large.png" alt="Tenant Find icon">
                                            </div>
                                            <div class="card__content">
                                                <h5>Managing viewings</h5>
                                                <p>Set up your property marketing marketing profile in one place and let COHO do the rest.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <p class="u-padding-top--10"><a href="{{staticWebUrl}}features/"><strong>View all features &gt;&gt;</strong></a></p>
                        </div>
                    </li>
                    <li><a href="{{staticWebUrl}}pricing/">Pricing</a></li>
                    <li><a href="{{staticWebUrl}}support/">Support</a></li>
                </ul>
                <ul class="main__navigation main__navigation--mobile toggle__target" id="MainMenu" [class.open]="mainMenuOpen">
                    <li><a [routerLink]="['/find-a-home']">Find a home</a></li>
                    <li><a href="{{staticWebUrl}}what-we-do/">What we do</a></li>
                    <li>
                        <a href="{{staticWebUrl}}features/">Features</a>
                        <ul>
                            <li><a href="{{staticWebUrl}}features/onboarding/">Tenant onboarding</a></li>
                            <li><a href="{{staticWebUrl}}features/maintenance/">Maintenance &amp; messaging</a></li>
                            <li><a href="{{staticWebUrl}}features/compliance/">Compliance</a></li>
                            <li><a href="{{staticWebUrl}}features/viewings/">Managing viewings</a></li>
                        </ul>
                    </li>
                    <li><a href="{{staticWebUrl}}pricing/">Pricing</a></li>
                    <li><a href="{{staticWebUrl}}support/">Support</a></li>
                    <li><a href="{{staticWebUrl}}instant-demo/" id="demo">Instant Demo</a></li>
                </ul>

            </div>
        </div>


    </div>
</header>
