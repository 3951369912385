import {Pipe, PipeTransform} from '@angular/core';
import {PermissionResources} from "../models/authorisation/permission-resources";
import {AuthorisationService} from "../services/authorisation.service";
import {PermissionAccessActions} from "../models/authorisation/permission-access-actions";

@Pipe({
    name: 'noAuthorisation',
    pure: true
})
export class NoAuthorisationPipe implements PipeTransform {
    constructor(private authorisationService: AuthorisationService) {
    }

    transform(resource: PermissionResources, accessAction: PermissionAccessActions): boolean {
        return this.authorisationService.check(resource, accessAction) == false;
    }
}
