import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable()
export class TitleService {

    constructor(private title: Title, private meta: Meta) {
    }

    setTitle(titleArray: string[]): void {

        titleArray.push('COHO');
        let newTitle = titleArray
            .filter(m => !!m)
            .reduce((current, next) => `${ current } | ${ next }`);

        this.title.setTitle(newTitle);

        if (this.meta.getTag('property=\'og:title\'') != null) {
            this.meta.removeTag('property=\'og:title\'');
        }
        this.meta.addTag({name: 'title', content: newTitle})
    }

    setMetaDescription(metaDescription: string): void {
        if (this.meta.getTag('description') != null) {
            this.meta.removeTag('description');
        }
        if (this.meta.getTag('property=\'og:description\'') != null) {
            this.meta.removeTag('property=\'og:description\'');
        }
        this.meta.addTag({name: 'description', content: metaDescription})
        this.meta.addTag({property: 'og:description', content: metaDescription})
    }

    setMetaImage(metaImage: string): void {
        if (this.meta.getTag('property=\'og:image\'') != null) {
            this.meta.removeTag('property=\'og:image\'');
        }
        if (metaImage != null && metaImage.length > 0) {
            this.meta.addTag({property: 'og:image', content: metaImage})
        }

        if (this.meta.getTag('property=\'twitter:image\'') != null) {
            this.meta.removeTag('property=\'twitter:image\'');
        }
        if (metaImage != null && metaImage.length > 0) {
            this.meta.addTag({property: 'twitter:image', content: metaImage})
        }

    }

    removeTags(): void {
        if (this.meta.getTag('property=\'og:title\'') != null) {
            this.meta.removeTag('property=\'og:title\'');
        }
        if (this.meta.getTag('property=\'og:image\'') != null) {
            this.meta.removeTag('property=\'og:image\'');
        }
        if (this.meta.getTag('property=\'og:url\'') != null) {
            this.meta.removeTag('property=\'og:url\'');
        }
        if (this.meta.getTag('description') != null) {
            this.meta.removeTag('description');
        }
        if (this.meta.getTag('property=\'og:description\'') != null) {
            this.meta.removeTag('property=\'og:description\'');
        }
    }
}
