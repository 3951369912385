import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { SubscriptionCheck } from "../models/accounting/subscripton-check";
import { InvoiceSummary } from "../models/accounting/invoice-summary";
import { Invoice } from "../models/accounting/invoice";
import { ConfigService } from "./config.service";
import { OrganisationAccountingOverview } from "../models/accounting/organisation-accounting-overview";
import { AddPaymentMethod } from "../models/accounting/add-payment-method";
import { ThreeDSecureVerification } from "../models/accounting/three-d-secure-verification";
import { AddPaymentResponse } from "../models/accounting/add-payment-response";
import { map } from 'rxjs/operators';
import { UpdateSubscriptionPost } from "../models/accounting/update-subscription-post";
import { OrganisationSummary } from "../models/organisation/organisation-summary";
import {SelectedSubscriptionPriceUpdateInfo} from "../models/accounting/selected-subscription-price-update-info";
import {SubscriptionPlans} from "../models/accounting/subscription-plans";
import {CalculateSelectedSubscriptionPriceUpdatePost} from "../models/accounting/calculate-selected-subscription-price-update-post";
import {CalculateSelectedSubscriptionPricePost} from "../models/accounting/calculate-selected-subscription-price-post";
import {SelectedSubscriptionInfo} from "../models/accounting/selected-subscription-info";
import {PaymentDetails} from "../models/accounting/payment-details";
import {OrganisationAccountingSummary} from "../models/accounting/organisation-accounting-summary.interface";

@Injectable()
export class AccountingService {
    showNewAccountPaymentSuccessMessage: boolean;

    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getSubscriptionCheck(organisationReference: string, propertyReference: string, roomReference: string): Observable<SubscriptionCheck> {
        let params = new HttpParams();
        if (propertyReference != null && propertyReference.length) {
            params = params.set("propertyReference", propertyReference);
        }
        if (roomReference != null && roomReference.length) {
            params = params.set("roomReference", roomReference);
        }

        let url = '';

        if (organisationReference != null && organisationReference.length) {
            url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/subscription-check`;
        }
        else {
            url = `${this.configService.baseUrl}/manage/accounting/subscription-check`;
        }

        return this.http.get<SubscriptionCheck>(url, { params });
    }

    getSubscriptionPaymentDetails(organisationReference: string): Observable<PaymentDetails> {

        if (organisationReference == null || organisationReference.length == 0) {
            return this.http.get<PaymentDetails>(`${this.configService.baseUrl}/manage/accounting/subscription-payment-details`);
        }
        else {
            return this.http.get<PaymentDetails>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/subscription-payment-details`);
        }
    }

    // calculates the subscription price for new user
    calculateSelectedSubscriptionPrice(body: CalculateSelectedSubscriptionPricePost): Observable<SelectedSubscriptionInfo> {
        return this.http.post<SelectedSubscriptionInfo>(`${this.configService.baseUrl}/accounting/calculate-selected-subscription-price`, body);
    }

    // calculates the difference and new subscription price
    calculateSelectedSubscriptionPriceUpdate(organisationReference: string, body: CalculateSelectedSubscriptionPriceUpdatePost): Observable<SelectedSubscriptionPriceUpdateInfo> {
        return this.http.post<SelectedSubscriptionPriceUpdateInfo>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/calculate-selected-subscription-price-update`, body);
    }

    updateSubscription(organisationReference: string, body: UpdateSubscriptionPost) {
        return this.http.post(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/update-subscription`, body);
    }

    getInvoices(organisationReference: string, page: number = 1): Observable<InvoiceSummary[]> {
        let params = new HttpParams();
        if (page) {
            params = params.set("page", page.toString());
        }

        return this.http.get<InvoiceSummary[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/invoices`, { params });
    }

    getInvoice(guid: string): Observable<Invoice> {
        return this.http.get<Invoice>(`${this.configService.baseUrl}/invoice/${guid}`);
    }

    getAccountingOverview(organisationReference: string): Observable<OrganisationAccountingOverview> {
        return this.http.get<OrganisationAccountingOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/overview`);
    }

    requiresPaymentDetails(organisationReference: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/requires-payment-details`);
    }

    addPaymentMethod(organisationReference: string, body: AddPaymentMethod): Observable<AddPaymentResponse> {
        return this.http.post<AddPaymentResponse>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/add-payment-method`, body);
    }

    paymentSetup(organisationReference: string) {
        return this.http.post<string>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/payment-setup`, null, {responseType: 'text' as 'json'});
    }

    payInvoice(organisationReference: string, invoiceGuid: string) {
        return this.http.post<any>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/${invoiceGuid}/pay`, null);
    }

    getAccountOrganisationSummary(organisationReference: string) {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/accounting/summary`
        return this.http.get<OrganisationAccountingSummary>(url);
    }
}
