import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountService } from './account.service';


@Injectable()
export class AuthorisedRegistrationIncompleteAuthGuard implements CanActivate, CanActivateChild {

    constructor(private accountService: AccountService, private _router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkIsAuthorised(state);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkIsAuthorised(state);
    }

    private checkIsAuthorised(state: RouterStateSnapshot): boolean | Observable<boolean> {
        const isLoggedIn = this.accountService.isLoggedIn();
        if (!isLoggedIn) {
            return from(this._router.navigate(['/login'], {queryParams: {redirect_to: state.url}}))
                .pipe(map(() => false));
        }

        const user = this.accountService.getCurrentUser();
        if (user && !user.emailConfirmed) {
            return from(this._router.navigate(['/register', 'verify']))
                .pipe(map(() => false));
        } else if (user && user.registrationJourneyCompleted) {
            return from(this._router.navigate(['/']))
                .pipe(map(() => false));
        }

        return isLoggedIn;
    }
}
