import { AmenitiesResolver } from './amenities.resolver';
import { ConversationResolver } from './conversation.resolver';
import { ManageOrganisationFeaturesResolver } from './manage-organisation-features.resolver';
import {AuthorisationResolver} from "./authorisation.resolver";

export const resolvers = [
    AmenitiesResolver,
    AuthorisationResolver,
    ConversationResolver,
    ManageOrganisationFeaturesResolver
];
