import { DataTypes } from './data-types';

export class ServiceParameter {
    name: string;
    reference: string;
    description: string;
    manualApplicable: boolean;
    automaticApplicable: boolean;
    dataType: DataTypes;   
}
