import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { GasSafetyCertificate } from '../models/gas-safety-certificate/gas-safety-certificate';
import { GasSafetyCertificatePost } from '../models/gas-safety-certificate/gas-safety-certificate-post';
import { GasSafetyCertificateOverview } from '../models/gas-safety-certificate/gas-safety-certificate-overview';
import { FileService } from "./file.service";
import { OwnerGasSafetyCertificateOverview } from '../models/gas-safety-certificate/owner-gas-safety-certificate-overview';
import {PropertyServicesSummary} from "../models/property-services/property-services-summary.interface";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class GasSafetyCertificateService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string): Observable<GasSafetyCertificateOverview> {
        return this.http.get<GasSafetyCertificateOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/gas-safety-certificates/overview`);
    }

    getList(propertyReference: string): Observable<GasSafetyCertificate[]> {
        return this.http.get<GasSafetyCertificate[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/gas-safety-certificates`);
    }

    add(propertyReference: string, model: GasSafetyCertificatePost): Observable<HttpEvent<GasSafetyCertificate>> {
        return this.fileService.uploadFiles(model.gasSafetyCertificateFiles)
            .pipe(tap(_ => model.gasSafetyCertificateFiles = this.fileService.prepareFilePosts(model.gasSafetyCertificateFiles)),
                mergeMap(_ => this.http.post<GasSafetyCertificate>(`${this.configService.baseUrl}/manage/property/${propertyReference}/gas-safety-certificates`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(propertyReference: string, item: GasSafetyCertificate): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/gas-safety-certificates/${item.guid}`);
    }

    getOwnerOverview(propertyReference: string): Observable<OwnerGasSafetyCertificateOverview> {
        return this.http.get<OwnerGasSafetyCertificateOverview>(`${this.configService.baseUrl}/owner/property/${propertyReference}/gas-safety-certificates/overview`);
    }

    getOwnerList(propertyReference: string): Observable<GasSafetyCertificate[]> {
        return this.http.get<GasSafetyCertificate[]>(`${this.configService.baseUrl}/owner/property/${propertyReference}/gas-safety-certificates`);
    }

    getOwnerGasSafetyCertificate(propertyReference: string, guid: string): Observable<GasSafetyCertificate> {
        return this.http.get<GasSafetyCertificate>(`${this.configService.baseUrl}/owner/property/${propertyReference}/gas-safety-certificates/${guid}`);
    }

    getSummary(propertyReference: string) {
        return this.http.get<PropertyServicesSummary>(`${this.configService.baseUrl}/manage/property/${propertyReference}/gas-safety-certificates/summary`);
    }
}
