import { Inject, Injectable, Injector } from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, of, ReplaySubject, retry, throwError, timer} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import {DateTime} from 'luxon';

import { LoginRequest } from '../models/account/login-request';
import { LoginResponse } from '../models/account/login-response';
import { ConfigService } from './config.service';
import { RegisterRequest } from '../models/account/register-request';
import { VerifyCodeRequest } from '../models/account/verify-code-request';
import { AuthenticationToken } from '../models/account/authentication-token';
import { LoginResponseStatus } from '../models/account/login-response-status';
import { ResendCodeRequest } from '../models/account/resend-code-request';
import { VerifyGuidRequest } from '../models/account/verify-guid-request';
import { ForgottenPasswordRequest } from '../models/account/forgotten-password-request';
import { ForgottenPasswordResponseStatus } from '../models/account/forgotten-password-response-status';
import { ChangePasswordRequest } from '../models/account/change-password-request';
import { User } from '../models/user/user';
import { UserService } from './user.service';
import { TrackingService } from './tracking.service';
import { RegisterReasons } from '../pages/register/register/register-reasons';
import { DemoDetails } from '../models/account/demo-details';
import { MobileAppService } from './mobile-app.service';
import {UserOrganisationsView} from "../models/user/user-organisations-view";
import {DebugResponse} from "../models/account/debug-response";
import {RegisterRequestPreview} from "../models/account/register-request-preview";
import {ExistingUser} from "../models/tenancy/existing-user";
import {AdminSearchResult} from "../models/admin/admin-search-result";
import {responseExtractModelStateErrorsAsString} from "./form.service";
import {Router} from "@angular/router";

const currentAuthenticationKey: string = 'COHO_AUTH';
const loggedInKey: string = 'LOGGED_IN';
const stayLoggedInKey: string = 'STAY_LOGGED_IN';
const redirectUrlKey: string = 'REDIRECT_URL';
const sessionStackKey: string = 'session-stack';
const debugConfigKey: string = 'session-debug';


@Injectable({
    providedIn: 'root',
})
export class AccountService {
    private jwtHelper: JwtHelperService;
    private refreshTokenTimeout: number;

    set accessToken(value: AuthenticationToken) {
        localStorage.setItem(currentAuthenticationKey, JSON.stringify(value));
    }

    get accessToken(): AuthenticationToken {
        try {
            return JSON.parse(localStorage.getItem(currentAuthenticationKey));
        } catch {
            return null;
        }
    }

    set stayLoggedIn(value: boolean) {
        localStorage.setItem(stayLoggedInKey, value ? 'true' : 'false');
    }

    get stayLoggedIn(): boolean {
        try {
            return JSON.parse(localStorage.getItem(stayLoggedInKey));
        } catch {
            return false;
        }
    }

    private guarantorTokenSubject = new BehaviorSubject<string>(null);
    private isLoggedInSubject = new BehaviorSubject<boolean>(false);
    private currentUserSubject = new BehaviorSubject<User>(null);

    constructor(private httpClient: HttpClient, private configService: ConfigService,
                private userService: UserService, private trackingService: TrackingService, @Inject(DOCUMENT) private document: Document,
                private injector: Injector, private mobileAppService: MobileAppService, private cookieService: CookieService, private router: Router) {

        this.jwtHelper = new JwtHelperService();
    }

    public isAccessTokenExpired() : null | true | false
    {
        if (!this.accessToken) return null;

        return this.jwtHelper.isTokenExpired(this.accessToken.bearerToken);
    }

    public hasAccessToken() : boolean {
        return this.accessToken && this.accessToken.bearerToken && this.accessToken.refreshToken ? true : false;
    }

    setup() {
        let obs: Observable<any> = EMPTY;

        if (!this.accessToken) {
            this.logout();
        } else if (this.jwtHelper.isTokenExpired(this.accessToken.bearerToken) && !this.stayLoggedIn) {
            if (this.accessToken?.refreshToken) {
                this.refreshToken()
                    .pipe(
                        filter(response => response.loginResponseStatus === LoginResponseStatus.ERROR)
                    ).subscribe(_ => {
                        this.logout();
                    });
            } else {
                this.logout();
            }
        } else {
            this.setupRefreshTokenTimer(this.accessToken.bearerToken);

            this.mobileAppService.login(this.accessToken.bearerToken);
            this.isLoggedInSubject.next(true);

            obs = this.getCurrentUserData();
        }

        const domain = window.location.hostname;

        this.isLoggedInSubject
            .subscribe(isLoggedIn => {
                if (isLoggedIn) {
                    this.cookieService.set(loggedInKey, 'true', { path: '/', domain: domain, sameSite: 'Lax' });
                } else {
                    console.log("Deleting logged in cookie");
                    this.cookieService.delete(loggedInKey, '/', domain, true, 'Lax' );
                }
            });

        this.watchCurrentUser()
            .subscribe(user => {
                if (user) {
                    if (user.darkMode) {
                        if (!document.querySelector('body').classList.contains('coho__dark')) {
                            document.querySelector('body').classList.add('coho__dark');
                        }
                    } else {
                        if (document.querySelector('body').classList.contains('coho__dark')) {
                            document.querySelector('body').classList.remove('coho__dark');
                        }
                    }

                } else {
                    if (document.querySelector('body').classList.contains('coho__dark')) {
                        document.querySelector('body').classList.remove('coho__dark');
                    }
                }
            });

        return obs;
    }

    private getCurrentUserData() {
        const obs =  this.userService.getMe()
            .pipe(
                retry({
                    count: 5,
                    delay: (error: any, retryCount: number) => {
                        if (error.error.exception === 'SecurityTokenSignatureKeyNotFoundException') {
                            return throwError(() => error);
                        }
                        console.error(`Attempt ${retryCount}: retrying in ${retryCount * 200}ms - ${error}`);
                        return timer(retryCount * 200);
                    },
                    resetOnSuccess: true
                }),
                catchError(error   => {
                    const httpErrorResponse : HttpErrorResponse = error as HttpErrorResponse;

                    // Logout only when given clear directions due to the error code forbidden/not authorized which is what the backend
                    //    will send back if there is an authorisation error
                    //  NOTE: Error 401 shouldn't reach here due to an expird token because the retry thing is built into our system that if you get a token error
                    //     it refreshes the token and tries again
                    if (!httpErrorResponse || httpErrorResponse.status === 401 || httpErrorResponse.status === 403) {
                        this.logout();
                        this.router.navigate(['/login']);

                        return EMPTY;
                    } else {
                        if (httpErrorResponse.status == 0) {
                            this.router.navigate(['/authorisation/server-unreachable'], { queryParams: {statusText: httpErrorResponse.statusText, message: httpErrorResponse.message}});
                        }
                        else {
                            this.router.navigate(['/authorisation/error'], { queryParams: { statusText: httpErrorResponse.statusText, message: httpErrorResponse.message}});
                        }

                        return EMPTY;
                    }
                }),
                tap(user => this.setCurrentUser(user))
            );

        return obs;
    }

    isLoggedIn(): boolean {
        return this.isLoggedInSubject.getValue();
    }

    watchIsLoggedIn(): Observable<boolean> {
        return this.isLoggedInSubject.asObservable();
    }

    getGuarantorToken(): string {
        return this.guarantorTokenSubject.getValue();
    }

    setGuarantorToken(value: string) {
        this._loginStackHasSuperAdmin = null;

        this.guarantorTokenSubject.next(value);
    }

    login(loginRequest: LoginRequest): Observable<LoginResponse> {
        this._loginStackHasSuperAdmin = null;


        if (loginRequest.stayLoggedIn) {
            this.stayLoggedIn = true;
        } else {
            console.log("You have chose to not stay logged in if your session expires/closes");
        }

        return this.httpClient.post<LoginResponse>(`${ this.configService.baseUrl }/account/login`, loginRequest)
            .pipe(
                map(response => {
                    // Don't take our login down if there is an error with the loginStack code, so we wrap it in a try block and squash any error
                    try {
                        // If this new user is a superAdmin or we currently have a superAdmin in the stack (probably the first login) then we save the login onto our stack so that
                        // we can later pop it off when logging out
                        if (response && response.user && (response.user.superAdmin || this.loginStackHasSuperAdmin())) {

                            this.loginStackPush(response);
                        }
                    } catch (e) {
                        console.log("Could not push log-in onto stack due to " + e)
                    }

                    return this.setToken(response);
                }),
                catchError(e => {
                    // Do NOT change these strings without also changing the back-end code that expects exactly these strings
                    const HardcodedFrontendMessage_InvalidLogin = "Wrong email password combination";
                    const HardcodedFrontendMessage_LinkUsed = "Link already used";
                    const HardcodedFrontendMessage_PersonNotExist = "Person doesn't exist";
                    const HardcodedFrontendMessage_InviteNotFound = "Invite not found";

                    if (e.error.password != null || e.error == HardcodedFrontendMessage_InvalidLogin) {
                        let response = new LoginResponse();
                        response.loginResponseStatus = LoginResponseStatus.INCORRECT_USER_PASSWORD;
                        response.errorMessage = "We didn't recognise that email and password combination. Please try again.";

                        return of(response);
                    }
                    else if (e.error == HardcodedFrontendMessage_LinkUsed) {
                        let response = new LoginResponse();
                        response.loginResponseStatus = LoginResponseStatus.INVITATION_LINK_USED;
                        response.errorMessage = "The invite link has already been used. If this was you, check to see if you have used another email address. Otherwise please get in touch with your property manager.";

                        return of(response);
                    }
                    else if (e.error == HardcodedFrontendMessage_PersonNotExist) {
                        let response = new LoginResponse();
                        response.loginResponseStatus = LoginResponseStatus.ERROR;
                        response.errorMessage = "Could not find a person for the invite used.";

                        return of(response);
                    }
                    else if (e.error == HardcodedFrontendMessage_InviteNotFound) {
                        let response = new LoginResponse();
                        response.loginResponseStatus = LoginResponseStatus.ERROR;
                        response.errorMessage = "The invite link or code has not been found";

                        return of(response);
                    }

                    const responseErrors: string | null = responseExtractModelStateErrorsAsString(e);
                    if (responseErrors) {
                        let response = new LoginResponse();
                        response.loginResponseStatus = LoginResponseStatus.ERROR;
                        response.errorMessage = responseErrors;

                        return of(response);
                    }

                    let errorResponse = new LoginResponse();
                    errorResponse.loginResponseStatus = LoginResponseStatus.ERROR;

                    if(e.status == 500){
                        errorResponse.errorMessage = "Unexpected server error occurred."
                    } else if(e.status == 400){
                        errorResponse.errorMessage = "The requested resource returned a bad request response."
                    } else if(e.status == 404){
                        errorResponse.errorMessage = "The requested resource was not found."
                    }

                    errorResponse.errorMessage = "The server did not respond or timed out. Please check your Internet connection and if the issue persists contact COHO.";

                    return of(errorResponse);
                })
            );
    }

    loginUserWithMagicLink(guid: string): Observable<LoginResponse> {
        this._loginStackHasSuperAdmin = null;
        let params = new HttpParams();
        params = params.set('guid', guid);

        return this.httpClient.post<LoginResponse>(`${this.configService.baseUrl}/account/magic`, null, { params })
            .pipe(
                map(response => this.setToken(response)),
                catchError(e => {
                    if (e.error.expiredToken == 'Invalid Token') {
                        let incorrectPasswordResponse = new LoginResponse();
                        incorrectPasswordResponse.loginResponseStatus = LoginResponseStatus.MAGIC_LINK_INVALID;

                        return of(incorrectPasswordResponse);

                    }
                    else if (e.error.expiredToken == 'Token Expired') {
                        let incorrectPasswordResponse = new LoginResponse();
                        incorrectPasswordResponse.loginResponseStatus = LoginResponseStatus.MAGIC_LINK_EXPIRED;

                        return of(incorrectPasswordResponse);

                    }

                    let errorResponse = new LoginResponse();
                    errorResponse.loginResponseStatus = LoginResponseStatus.MAGIC_LINK_INVALID;

                    return of(errorResponse);
                })
            );
    }

    loginSupplierWithMaintenanceMagicLink(guid: string): Observable<LoginResponse> {
        this._loginStackHasSuperAdmin = null;
        let params = new HttpParams();
        params = params.set('guid', guid);

        return this.httpClient.post<LoginResponse>(`${this.configService.baseUrl}/account/magic/supplier`, null, { params })
            .pipe(
                map(response => this.setToken(response)),
                catchError(e => {
                    if (e.error.expiredToken == 'Invalid Token') {
                        let incorrectPasswordResponse = new LoginResponse();
                        incorrectPasswordResponse.loginResponseStatus = LoginResponseStatus.MAGIC_LINK_INVALID;

                        return of(incorrectPasswordResponse);

                    }
                    else if (e.error.expiredToken == 'Token Expired') {
                        let incorrectPasswordResponse = new LoginResponse();
                        incorrectPasswordResponse.loginResponseStatus = LoginResponseStatus.MAGIC_LINK_EXPIRED;

                        return of(incorrectPasswordResponse);

                    } else if (e.error.exception) {
                        let resp = new LoginResponse();
                        resp.loginResponseStatus = LoginResponseStatus.ERROR;
                        resp.errorMessage = e.error.exception;

                        return of(resp);
                    }

                    let errorResponse = new LoginResponse();
                    errorResponse.loginResponseStatus = LoginResponseStatus.MAGIC_LINK_INVALID;

                    return of(errorResponse);
                })
            );
    }

    acceptInvite(registerReason: RegisterReasons, guid: string): Observable<LoginResponse> {
        let params = new HttpParams();
        params = params.set('registerReason', (+registerReason).toString());
        params = params.set('guid', guid);

        return this.httpClient.post<LoginResponse>(`${ this.configService.baseUrl }/account/accept-invite`, null, {params})
            .pipe(
                map(response => this.setToken(response))
            );
    }

    loginAsDemo(guid: string): Observable<LoginResponse> {
        this._loginStackHasSuperAdmin = null;
        this.logout();

        let params = new HttpParams();
        if (guid != null) {
            params = params.set('guid', guid);
        }
        this.stayLoggedIn = true;

        return this.httpClient.post<LoginResponse>(`${ this.configService.baseUrl }/account/demo-login`, null, {params})
            .pipe(
                map(response => this.setToken(response)),
                catchError(e => {
                    let errorResponse = new LoginResponse();
                    errorResponse.loginResponseStatus = LoginResponseStatus.ERROR;

                    return of(errorResponse);
                })
            );
    }

    getDemoDetails(): Observable<DemoDetails> {
        return this.httpClient.get<DemoDetails>(`${ this.configService.baseUrl }/account/demo-details`);
    }

    refreshToken(forceLogout:boolean = true, attempts: number = 0): Observable<LoginResponse> {
        this._loginStackHasSuperAdmin = null;

        return this.httpClient.post<LoginResponse>(`${ this.configService.baseUrl }/account/refresh-token`, this.accessToken)
            .pipe(
                tap(response => this.setToken(response)),
                catchError(e => {
                    let errorResponse = new LoginResponse();
                    errorResponse.loginResponseStatus = LoginResponseStatus.ERROR;

                    if (forceLogout) {
                        this.logout();
                    }

                    if (this.accessToken) {
                        this.setupRefreshTokenTimer(this.accessToken.bearerToken, attempts + 1);
                    }

                    return of(errorResponse);
                })
            );
    }

    attemptRefreshToken(): Observable<LoginResponse> {
        const subject = new ReplaySubject<LoginResponse>(1);

        this.httpClient.post<LoginResponse>(`${ this.configService.baseUrl }/account/refresh-token`, this.accessToken)
            .subscribe(
                response => {
                    this.setToken(response);

                    this.setupRefreshTokenTimer(this.accessToken.bearerToken);

                    subject.next(response);
                    subject.complete();
                },
                error => {
                    let response = new LoginResponse();
                    response.loginResponseStatus = LoginResponseStatus.ERROR;
                    response.errorMessage = responseExtractModelStateErrorsAsString(error);

                    subject.next(response);
                    subject.complete();
                }
            );

        return subject;
    }

    register(registerRequest: RegisterRequest): Observable<LoginResponse> {
        this.stayLoggedIn = true;

        return this.httpClient.post<LoginResponse>(`${ this.configService.baseUrl }/account/register`, registerRequest)
            .pipe(
                map(response => this.setToken(response)),
                catchError(e => {
                    let errorResponse = new LoginResponse();

                    if (e.error == 'A user already exists with this email address') {
                        errorResponse.loginResponseStatus = LoginResponseStatus.EMAIL_EXISTS;

                    }
                    else if (e.error == 'Link already used') {
                        errorResponse.loginResponseStatus = LoginResponseStatus.INVITATION_LINK_USED;

                    } else {
                        errorResponse.loginResponseStatus = LoginResponseStatus.ERROR;
                    }

                    return of(errorResponse);
                })
            );
    }

    registerPreview(personGuid: string, tenancyTenantGuid: string) {
        const url = `${ this.configService.baseUrl }/account/register-preview`;
        let params = new HttpParams();
        if (personGuid != null) params = params.set('personGuid', personGuid);
        if (tenancyTenantGuid != null) params = params.set('tenancyTenantGuid', tenancyTenantGuid);

        return this.httpClient.get<RegisterRequestPreview>(url, {params});
    }

    logout(): void {
        this._loginStackHasSuperAdmin = null;
        this.clearRefreshTokenTime();

        localStorage.removeItem(stayLoggedInKey);
        localStorage.removeItem(currentAuthenticationKey);
        this.loginStackDelete();
        this.setCurrentUser(null);

        this.mobileAppService.logout();
        this.isLoggedInSubject.next(false);
    }

    verifyCode(email: string, code: string): Observable<LoginResponse> {
        const data = new VerifyCodeRequest();
        data.email = email;
        data.code = code;

        return this.httpClient.post<LoginResponse>(`${ this.configService.baseUrl }/account/verify-code`, data)
            .pipe(
                map(response => this.setToken(response)),
                catchError(_ => {
                    let errorResponse = new LoginResponse();
                    errorResponse.loginResponseStatus = LoginResponseStatus.ERROR;

                    return of(errorResponse);
                })
            );
    }

    resendCode(email: string): Observable<boolean> {
        const data = new ResendCodeRequest();
        data.email = email;

        return this.httpClient.post<boolean>(`${ this.configService.baseUrl }/account/resend-code`, data);
    }

    verifyGuid(guid: string): Observable<LoginResponse> {
        const data = new VerifyGuidRequest();
        data.guid = guid;

        return this.httpClient.post<LoginResponse>(`${ this.configService.baseUrl }/account/verify-guid`, data)
            .pipe(
                map(response => this.setToken(response)),
                catchError(_ => {
                    let errorResponse = new LoginResponse();
                    errorResponse.loginResponseStatus = LoginResponseStatus.ERROR;

                    return of(errorResponse);
                })
            );
    }

    forgottenPassword(forgottenPasswordRequest: ForgottenPasswordRequest): Observable<ForgottenPasswordResponseStatus> {
        return this.httpClient.post<ForgottenPasswordResponseStatus>(`${ this.configService.baseUrl }/account/forgotten-password`, forgottenPasswordRequest)
            .pipe(
                map(response => response ? ForgottenPasswordResponseStatus.SUCCESS : ForgottenPasswordResponseStatus.NO_ACCOUNT),
                catchError(e => {
                    if (e.error.email !== null) {
                        return of(ForgottenPasswordResponseStatus.NO_ACCOUNT);
                    } else {
                        return of(ForgottenPasswordResponseStatus.ERROR);
                    }
                })
            );
    }

    changePassword(changePasswordRequest: ChangePasswordRequest) {
        return this.httpClient.post(`${ this.configService.baseUrl }/account/change-password`, changePasswordRequest)
            .pipe(
                map(() => {
                    let successResponse = new LoginResponse();
                    successResponse.loginResponseStatus = LoginResponseStatus.SUCCESS;
                    return successResponse;
                }),
                catchError(e => {
                    if (e.error.code !== null) {
                        let failedResponse = new LoginResponse();
                        failedResponse.loginResponseStatus = LoginResponseStatus.FAILED_VERIFICATION;
                        return of(failedResponse);
                    }

                    let errorResponse = new LoginResponse();
                    errorResponse.loginResponseStatus = LoginResponseStatus.ERROR;
                    return of(errorResponse);
                })
            );
    }

    setCurrentUser(user: User) {
        this.trackingService.identify(user);

        this.currentUserSubject.next(user);
    }

    getCurrentUser(): User {
        return this.currentUserSubject.getValue();
    }

    // Returns the Guid for the OrganisationPerson associated with the current user (if they are a manager)
    getCurrentUserPersonReference() : string | null {
        // Get the current user
        const currentUser = this.getCurrentUser();
        if (currentUser && currentUser.isManager) {
            return currentUser.organisationPersonReference;
        }

        return null;
    }

    watchCurrentUser(): Observable<User> {
        return this.currentUserSubject;
    }

    private setToken(response: LoginResponse): LoginResponse {
        this._loginStackHasSuperAdmin = null;

        if (response) {
            this.accessToken = {
                email: response.user.email,
                bearerToken: response.bearerToken,
                refreshToken: response.refreshToken
            };

            if (!this.isLoggedIn()) {
                this.isLoggedInSubject.next(true);
            }

            if (!response.user.tokenOnly) {
                this.setCurrentUser(response.user);
            }

            this.mobileAppService.login(response.bearerToken);
            this.setupRefreshTokenTimer(response.bearerToken);

            response.loginResponseStatus = LoginResponseStatus.SUCCESS;
        } else {
            response.loginResponseStatus = LoginResponseStatus.ERROR;
        }

        return response;
    }

    private loginStackPush(response: LoginResponse)
    {
        this._loginStackHasSuperAdmin = null;

        if (response) {
            let responsesString = localStorage.getItem(sessionStackKey);
            let responses = [];

            if (responsesString)
            {
                responses = JSON.parse(responsesString);
            }

            responses.push(response);

            localStorage.setItem(sessionStackKey, JSON.stringify(responses));
        }
    }

    private loginStackPop() : LoginResponse {
        this._loginStackHasSuperAdmin = null;

        const responseString = localStorage.getItem(sessionStackKey);

        if (responseString)
        {
            const responses = (JSON.parse(responseString) as Array<LoginResponse>);

            // Nothing to pop. You cannot pop the last log-in off of the stack
            if (responses.length < 2) return null;

            // Pop the response unless it is the last one on the stack (the original user). This is the user we no longer want to be logged-in as
            responses.pop();

            localStorage.setItem(sessionStackKey, JSON.stringify(responses));

            // Return the last log-in left on the stack
            return responses[responses.length - 1];
        }

        return null;
    }

    private loginStackDelete() {
        this._loginStackHasSuperAdmin = null;

        localStorage.removeItem(sessionStackKey);
    }

    // Cache'd check for superAdmin in log-in stack
    private _loginStackHasSuperAdmin: boolean = null;
    public loginStackHasSuperAdmin()
    {
        if (this._loginStackHasSuperAdmin !== null) return this._loginStackHasSuperAdmin;

        const responsesString = localStorage.getItem(sessionStackKey);

        if (responsesString) {
            const responses = (JSON.parse(responsesString) as Array<LoginResponse>);

            this._loginStackHasSuperAdmin = responses.filter(m => m.user && m.user.superAdmin).length > 0;
            return this._loginStackHasSuperAdmin;
        }

        return false;
    }

    private _loginHasDebugEnabled: boolean = null;
    public loginHasDebugEnabled()
    {
        if (!this.getCurrentUser()) return false;

        if (this._loginHasDebugEnabled !== null) return this._loginHasDebugEnabled;
        if (!this.loginStackHasSuperAdmin() && !this.getCurrentUser().superAdmin) return false;

        const response = this.getDebugStorageResponse();
        this._loginHasDebugEnabled = response.debugEnabled;
        return this._loginHasDebugEnabled;
    }

    public loginToggleDebug() {
        if (!this.getCurrentUser()) return false;

        if (!this.loginStackHasSuperAdmin() && !this.getCurrentUser().superAdmin) return false;

        const response = this.getDebugStorageResponse();
        response.debugEnabled = !response.debugEnabled;
        this.setDebugStorage(response);
    }

    public getDebugStorageResponse() {
        const responsesString = localStorage.getItem(debugConfigKey);

        if (responsesString) {
            return JSON.parse(responsesString) as DebugResponse;
        } else {
            // Default to enabled on DEV and save it to local storage
            const response = new DebugResponse();
            response.debugEnabled = this.configService.isDev;

            // Save it so next round we have something to load
            this.setDebugStorage(response);

            return response;
        }
    }

    public setDebugStorage(response: DebugResponse) {
        localStorage.setItem(debugConfigKey, JSON.stringify(response));
    }

    public loginStackCanPop() {
        const responsesString = localStorage.getItem(sessionStackKey);

        if (responsesString) {
            const responses = (JSON.parse(responsesString) as Array<LoginResponse>);
            return responses.length > 1;
        }

        return false;
    }

    adminLogoutAsUser(reload: boolean = true) {
        const response = this.loginStackPop();

        if (response) {
            this.setToken(response);

            // Reload the current page
            if (reload) {
                location.reload();
            }
        }
        else this.logout();
    }

    adminLoginAsUser(email: string): Observable<LoginResponse> {
        let params = new HttpParams();
        params = params.set('email', email);

        this.stayLoggedIn = true;

        return this.httpClient.post<LoginResponse>(`${this.configService.baseUrl}/account/admin-login-as-user-account`, null, { params })
            .pipe(
                map(response => {
                    this.loginStackPush(response);

                    return this.setToken(response);
                }),
                catchError(e => {
                    let errorResponse = new LoginResponse();
                    errorResponse.loginResponseStatus = LoginResponseStatus.ERROR;

                    return of(errorResponse);
                })
            );
    }

    adminGetLoginAsOptions(): Observable<UserOrganisationsView[]> {
        return this.httpClient.get<UserOrganisationsView[]>(`${this.configService.baseUrl}/account/admin-get-login-as-options`);
    }

    storeRedirectUrl(url: string) {
        localStorage.setItem(redirectUrlKey, url);
    }

    retrieveRedirectUrl(): string {
        let redirectUrl = localStorage.getItem(redirectUrlKey);
        localStorage.removeItem(redirectUrlKey);
        if (redirectUrl == "undefined") {
            redirectUrl = null;
        }

        return redirectUrl;
    }

    loginFromAccessToken() {
        return this.userService.getMe()
            .pipe(
                tap(user => {
                    this.setCurrentUser(user)
            }));
    }

    private clearRefreshTokenTime() {
        if (this.refreshTokenTimeout) {
            clearTimeout(this.refreshTokenTimeout);
        }
    }

    private setupRefreshTokenTimer(bearerToken: string, attempts: number = 0) {
        this.clearRefreshTokenTime();

        // Attempt token refresh five times in a row, upon failure just do nothing
        if (attempts <= 5) {
            let expiryDate = DateTime.fromJSDate(this.jwtHelper.getTokenExpirationDate(bearerToken));
            expiryDate = expiryDate.minus({seconds: 120});
            let ms = Math.abs(expiryDate.diffNow().toMillis());
            this.refreshTokenTimeout = setTimeout(_ => this.refreshToken(false, attempts).subscribe(), ms);
        }
    }

    isTokenExpired() {
        if (!this.accessToken) {
            return true;
        }

        return this.jwtHelper.isTokenExpired(this.accessToken.bearerToken);
    }

    getPossibleExistingAccount(inviteCode: string): Observable<ExistingUser> {

        let params = new HttpParams();

        if (inviteCode != null && inviteCode.length > 0) {
            params = params.set('inviteCode', inviteCode);
        }

        return this.httpClient.get<ExistingUser>(`${ this.configService.baseUrl }/account/possible-existing-user`, {params});
    }

    connectPossibleExistingAccount(inviteCode: string): Observable<boolean> {

        let params = new HttpParams();

        if (inviteCode != null && inviteCode.length > 0) {
            params = params.set('inviteCode', inviteCode);
        }

        return this.httpClient.post<boolean>(`${ this.configService.baseUrl }/account/possible-existing-user`, null, {params});
    }

    adminSearch(query: string): Observable<AdminSearchResult> {
        return this.httpClient.get<AdminSearchResult>(`${this.configService.baseUrl}/admin/search/${query}`);
    }

    loginPersonIsViewingWithPersonGuid(guid: string): Observable<LoginResponse> {
        this._loginStackHasSuperAdmin = null;
        let params = new HttpParams();
        params = params.set('guid', guid);

        return this.httpClient.post<LoginResponse>(`${this.configService.baseUrl}/account/magic/viewing`, null, { params })
            .pipe(
                map(response => this.setToken(response)),
                catchError(e => {
                    if (e.error.expiredToken == 'Invalid Token') {
                        let incorrectPasswordResponse = new LoginResponse();
                        incorrectPasswordResponse.loginResponseStatus = LoginResponseStatus.MAGIC_LINK_INVALID;
                        return of(incorrectPasswordResponse);
                    }
                    else if (e.error.expiredToken == 'Token Expired') {
                        let incorrectPasswordResponse = new LoginResponse();
                        incorrectPasswordResponse.loginResponseStatus = LoginResponseStatus.MAGIC_LINK_EXPIRED;
                        return of(incorrectPasswordResponse);
                    } else if(e.error === "account-claimed"){
                        let resp = new LoginResponse();
                        resp.loginResponseStatus = LoginResponseStatus.ERROR;
                        resp.errorMessage = 'Account was claimed, please connect instead'
                        return of(resp);
                    } else if(e.error === "not-found"){
                        let resp = new LoginResponse();
                        resp.loginResponseStatus = LoginResponseStatus.ERROR;
                        resp.errorMessage = 'No user found'
                        return of(resp);
                    } else if(e.error === "removed"){
                        let resp = new LoginResponse();
                        resp.loginResponseStatus = LoginResponseStatus.ERROR;
                        resp.errorMessage = `A user was found but it's been deleted`
                        return of(resp);
                    } else if (e.error.exception) {
                        let resp = new LoginResponse();
                        resp.loginResponseStatus = LoginResponseStatus.ERROR;
                        resp.errorMessage = e.error.exception;

                        return of(resp);
                    }

                    let errorResponse = new LoginResponse();
                    errorResponse.loginResponseStatus = LoginResponseStatus.MAGIC_LINK_INVALID;
                    return of(errorResponse);
                })
            );
    }

    verifyChangePasswordCode(model){
        const url = `${this.configService.baseUrl}/account/verify-change-password-code`;
        return this.httpClient.post(url, model);
    }
}
