import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RadioButtonComponent } from "./radio-button.component";
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { SpriteImageModule } from '../sprite-image/sprite-image.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode'}),
        FormsModule,
        SvgIconModule,
        SpriteImageModule
    ],
    declarations: [
        RadioButtonComponent
    ],
    exports: [
        RadioButtonComponent
    ]
})

export class RadioButtonModule {
}
