import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorService } from './error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private errorService: ErrorService) {
    }

    handleError(error) {
        error = this.findOriginalError(error);

        this.errorService.logError(error);
    }

    private findOriginalError(error: any): any {
        while (error && error.originalError) {
            error = error.originalError;
        }
        return error;
    }
}
