import { Component, ViewEncapsulation} from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { MobileAppService } from '../../services/mobile-app.service';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'site-footer',
  templateUrl: './site-footer.component.html',
  encapsulation: ViewEncapsulation.None
})

export class SiteFooterComponent {

    isLoggedIn: boolean = false;
    showAppFooter: boolean = true;
    showLegal: boolean = false;
    staticWebUrl: string;

    constructor(private mobileAppService: MobileAppService, private configService: ConfigService,private accountService: AccountService) {

        this.staticWebUrl = this.configService.staticWebUrl;

        if (configService.isDev) this.showAppFooter = false;

    }

    ngOnInit() {

        if (this.mobileAppService.isMobileApp()) {
                this.showAppFooter = false;
        }

        this.accountService.watchIsLoggedIn()
            .subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn;
            })
    }

}
