<div #backdrop
     *ngIf="modalState.state === ModalStates.OPEN"
     class="modal__wrapper open"
     [class]="class"
     (click)="modalClicked($event)">

    <div #dialog
         class="modal"
         [class.hasDropdownElement]="hasDropdownElement"
         [class.full-height]="fullHeight || fullScreen"
         [class.large-width]="largeWidth || fullScreen">

        <div #header class="modal__header">
            <h2><ng-content select="[modal-header]"></ng-content></h2>

            <div class="button__group">
                <div class="button__group-item" *ngIf="fullHeight !== true && largeWidth !== true">
                    <loader-button
                        [cssClasses]="'toggle_size button--transparent-full u-margin-bottom--none'"
                        [icon]="true"
                        [iconName]="'icon-maximise'"
                        [iconOnly]="true"
                        [iconColor]="'light-grey'"
                        (click)="toggleFullScreen()">
                    </loader-button>
                </div>
                <div class="button__group-item">
                    <loader-button
                        [cssClasses]="'button--transparent-full u-margin-bottom--none'"
                        [icon]="true"
                        [iconName]="IconNames.cross"
                        [iconOnly]="true"
                        [iconColor]="'magenta'"
                        (click)="closeModal()">
                    </loader-button>
                </div>
            </div>
        </div>

        <div class="modal__body" [class.hasDropdownElement]="hasDropdownElement">
            <ng-content select="[modal-body]"></ng-content>
            <focus-overlay></focus-overlay>
        </div>

        <div #footer class="modal__footer" [hidden]="footer.children.length === 0">
            <ng-content select="[modal-footer]"></ng-content>
        </div>
    </div>
</div>
