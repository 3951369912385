import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderButtonComponent } from './loader-button.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

@NgModule({
    imports: [
        CommonModule,
        SvgIconModule,
    ],
    declarations: [
        LoaderButtonComponent
    ],
    exports: [
        LoaderButtonComponent
    ]
})

export class LoaderButtonModule {
}
