import { Injectable } from '@angular/core';
import { Subject, Subscription, timer} from "rxjs";

@Injectable()
export class LoaderService {
    private _loaderTimer: Subscription;
    private _loader: HTMLElement;
    private get loader(): HTMLElement {
        if (!this._loader) {
            this._loader = document.getElementById('preloader');
            if (!this._loader) {
                console.warn('Preloader element not found');
            }
        }

        return this._loader;
    }

    hidden: Subject<void>;

    constructor() {
        this.hidden = new Subject<void>();
    }

    enable() {
        if (!this.loader) return
        this.loader.style.visibility = 'visible';
        this.loader.classList.remove('loaderDelayed');
        this.changeAnimationDuration('2.5s');
        this.cancelLoaderDelayedTimer();
        this.setLoaderDelayedTimer();
    }

    disable() {
        if (!this.loader) return;
        this.loader.style.visibility = 'hidden';
        document.body.classList.remove('body-loading');
        this.loader.classList.remove('loaderDelayed');
        this.cancelLoaderDelayedTimer();
        this.hidden.next();
    }

    // setLoaderDelayedTimer() {
    //     if (this._loaderTimer) throw new Error("A loader delayed timer already exists. First cancel it before setting a new one");
    //     this._loaderTimer = timer(30_000).subscribe(_ => this.loader.classList.add('loaderDelayed'));
    //     this.changeAnimationDuration('15s');
    // }

    setLoaderDelayedTimer() {
        if (this._loaderTimer) {
          throw new Error("A loader delayed timer already exists. First cancel it before setting a new one");
        }

        this._loaderTimer = timer(30_000).subscribe(_ => {
          this.loader.classList.add('loaderDelayed');
          if (this.loader.classList.contains('loaderDelayed')) {
            this.changeAnimationDuration('15s');
          }
        });
      }

    cancelLoaderDelayedTimer() {
        if (this._loaderTimer) {
            this._loaderTimer.unsubscribe();
            this._loaderTimer = null;
        }
    }

    changeAnimationDuration(duration: string): void {
        const animateTransform = document.querySelector('.loader__wrapper svg animateTransform');
        if (animateTransform) {
            animateTransform.setAttribute('dur', duration);
        } else {
            console.warn('animateTransform element not found');
        }
    }

}
