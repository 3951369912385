import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { Guid } from '../models/guid/guid';
import { environment } from '../../environments/environment';

@Injectable()
export class LazyLoaderService {
    private _loadedLibraries: { [url: string]: ReplaySubject<void> } = {};

    constructor(@Inject(DOCUMENT) private readonly document: any, private http: HttpClient, private platformLocation: PlatformLocation) {
    }

    loadLightbox(): Observable<any> {
        return this.loadStyle('ngx-lightbox.css');
    }

    loadJquery(): Observable<any> {
        return this.loadScript('jquery.js');
    }

    loadFroala(): Observable<any> {
        return forkJoin([
            this.loadStyle('froala-editor.css'),
            this.loadStyle('froala-style.css'),
            this.loadStyle('font-awesome.css'),
            this.loadScript('froala.js')
        ]);
    }

    loadSlickCarousel(): Observable<any> {
        return this.loadJquery()
            .pipe(
                switchMap(() => forkJoin([
                    this.loadStyle('slick-base.css'),
                    this.loadStyle('slick-theme.css'),
                    this.loadScript('slick.js')
                ])));
    }

    loadIFrameResizer(): Observable<any> {
        return this.loadJquery()
            .pipe(
                switchMap(() => forkJoin([
                    this.loadScript('iframe-resizer.js')
                ])));
    }

    loadVirtualTours(): Observable<any> {
        return this.loadJquery()
            .pipe(
                switchMap(() => forkJoin([
                    this.loadScript('virtual-tour.js')
                ])));
    }

    loadRangeSlider(): Observable<any> {
        return this.loadJquery()
            .pipe(switchMap(() => forkJoin([
                    this.loadStyle('range-slider-base.css'),
                    this.loadScript('range-slider.js')
                ])
            ));
    }

    loadGoogleMaps(): Observable<any> {
        return this.loadScript(`https://maps.googleapis.com/maps/api/js?key=${ environment.googleMapsToken }&libraries=places`, false);
    }

    loadGoCardless(): Observable<any> {
        return this.loadScript(`https://pay.gocardless.com/billing/static/dropin/v2/initialise.js`, false);
    }

    loadPrimeNg(): Observable<any> {

        return forkJoin([
            this.loadStyle('primeicons.css'),
            this.loadStyle('primeng-theme.css'),
            this.loadStyle('primeng.css'),
            this.loadStyle('quill-core.css'),
            this.loadStyle('quill-snow.css'),
            this.loadScript('quill.js')
        ]);
    }

    loadCropperJs(): Observable<any> {
        return this.loadStyle('cropperjs.css');
    }

    loadLoqate() {
        return forkJoin([
            this.loadScript(`https://services.postcodeanywhere.co.uk/js/address-3.91.js`, false),
            this.loadStyle('https://services.postcodeanywhere.co.uk/css/address-3.91.css')
        ]);
    }

    loadAngularMaterial() {
        return this.loadStyle('angular-material.css');
    }

    loadGTagManager() {
        if (!environment.googleTagManagerId) {
            return;
        }

        const s = this.document.createElement('script');
        const noscript = this.document.createElement('noscript');
        const head = this.document.getElementsByTagName('head')[0];
        s.type = 'text/javascript';
        s.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${environment.googleTagManagerId}');`;
        noscript.innerHTML = `
<iframe src="https://www.googletagmanager.com/ns.html?id=${ environment.googleTagManagerId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `;
        head.appendChild(s);
        head.appendChild(noscript);
    }

    loadSvgs() {
        this.http.get(`${this.platformLocation.getBaseHrefFromDOM()}assets/images/svg/svg-symbols.svg`, { responseType: 'text' })
            .subscribe(svgs => {
                const div = this.document.createElement('div');
                div.className = 'symbols--hide';
                div.innerHTML = svgs;

                this.document.body.prepend(div);
            })
    }

    loadHelpMeFix(): Observable<any> {
        return this.loadJquery()
            .pipe(
                switchMap(() => forkJoin([
                    this.loadScript('help-me-fix.js')
                ])));
    }

    private loadScript(url: string, addRandomString: boolean = true): Observable<any> {
        if (this._loadedLibraries[url]) {
            return this._loadedLibraries[url].asObservable();
        }

        if (addRandomString) {
            url = `${ url }?id=${ Guid.newGuid() }`;
        }

        this._loadedLibraries[url] = new ReplaySubject();

        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        script.onload = () => {
            this._loadedLibraries[url].next();
            this._loadedLibraries[url].complete();
        };

        this.document.body.appendChild(script);

        return this._loadedLibraries[url].asObservable();
    }

    private loadStyle(url: string): Observable<any> {
        if (this._loadedLibraries[url]) {
            return this._loadedLibraries[url].asObservable();
        }

        this._loadedLibraries[url] = new ReplaySubject();

        const style = this.document.createElement('link');
        style.type = 'text/css';
        style.href = `${ url }?id=${ Guid.newGuid() }`;
        style.rel = 'stylesheet';
        style.onload = () => {
            this._loadedLibraries[url].next();
            this._loadedLibraries[url].complete();
        };

        const head = document.getElementsByTagName('head')[0];
        head.appendChild(style);

        return this._loadedLibraries[url].asObservable();
    }
}
