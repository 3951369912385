import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
@Injectable()
export class DateService {
    private dateStringPattern = /^\d\d\d\d-\d\d-\d\d$/;


    // Javascript Date object typically carries timezone information which if passed to the server as a timestamp with timezone results in the time element and timezone
    // element being stored. It is determined by the client's settings and timezone conversions. To avoid this, when working with a date only, you can use this method to
    // take an input Date object and return a string 'YYYY-MM-DD'
    asDateString(maybeDate : any) {
        // Empty in means null out
        if (!maybeDate) return null;

        // Already a date string, pass through
        if (typeof maybeDate === 'string' && maybeDate.trim().length == 10 && this.dateStringPattern.test(maybeDate)) return maybeDate;

        // Convert to a Date() object and grab the year, month, and date (ignoring timezone stuff)
        const date = maybeDate instanceof Date ? maybeDate : new Date(maybeDate);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const paddedYear = date.getFullYear();
        const paddedMonth = month >= 10 ? "" + month : "0" + month;
        const paddedDay = day >= 10 ? "" + day : "0" + day;
        const dateString = paddedYear + '-' + paddedMonth + '-' + paddedDay;

        // console.log("Converted from A to B");
        // console.log(maybeDate);
        // console.log(dateString);

        return dateString;
    }


    // Should be used for tenancy starts/tenancy ends date object to pass into the GUI (date-picker).
    //   This will return the correct date-time-timezone as it is going to get stored on the server
    startOfTodayAccordingToServer() {
        // Convert the current date & time to the Europe/London without as-if we have the wrong timezone selected. This will actually change the underlying timestamp which is what we want.
        // We want the concept of the start-of-the-day (as defined by the browser) but with Europe/London timestamp enforced on it
        return DateTime.now().startOf('day').setZone('Europe/London', { keepLocalTime: true }).toISO();
    }

    //   This will return the correct date-time-timezone as it is going to get stored on the server
    endOfTodayAccordingToServer() {
        // Convert the current date & time to the Europe/London without as-if we have the wrong timezone selected. This will actually change the underlying timestamp which is what we want.
        // We want the concept of the start-of-the-day (as defined by the browser) but with Europe/London timestamp enforced on it
        return DateTime.now().endOf('day').setZone('Europe/London', { keepLocalTime: true }).toISO();
    }

    startOfTomorrowAccordingToServer() {
        return DateTime.now().startOf('day').setZone('Europe/London', { keepLocalTime: true }).plus({days: 1}).toISO();
    }

    // Convert's the SQL server's UTC datetime to the datetime used for storing things in Local UK/London time (GMT).
    // * NOTE: If you pass a JS date object it is assumed to be in UTC as coming from a data stored in the SQL server
    getServerDateTime(date: string | Date): DateTime {

        if (typeof date == 'string') return DateTime.fromISO(date).setZone('Europe/London', { keepLocalTime: false })
        if (date instanceof Date) return DateTime.fromJSDate(date, { zone: 'utc' }).setZone('Europe/London', { keepLocalTime: false })
    }

    startOfLastMonthAccordingToServer() {
        return DateTime.now().minus({month: 1}).startOf('month').setZone('Europe/London', { keepLocalTime: true }).toISO();
    }

    startOfThisMonthAccordingToServer() {
        return DateTime.now().startOf('month').setZone('Europe/London', { keepLocalTime: true }).toISO();
    }

    endOfLastMonthAccordingToServer() {
        return DateTime.now().minus({month: 1}).endOf('month').setZone('Europe/London', { keepLocalTime: true }).toISO();
    }

    max(...args) {
        if (args.length == 0) throw new Error("Cannot call DateService.max with 0 arguments");
        if (args.length == 1) return args[0];

        // Add validation that all inputs are of type : Date | Falsey (Aka don't allow things that we cannot either say for sure are NULL nor convert to a date)
        // Upgrade to convert o actual date objects so and then all to the same timezone so comparison is accurate
        if (args.length == 2) {
            if (!args[0]) return args[1];
            if (!args[1]) return args[0];

            return args[0] > args[1] ? args[0] : args[1];
        }

        // Iteratively compare until we have one final challenger, the king of the hill
        let currentMax = null;

        do {
            const newDate = args.pop();
            currentMax = this.max(currentMax, newDate);
        } while (args.length);

        return currentMax;
    }

    min(...args) {
        if (args.length == 0) throw new Error("Cannot call DateService.min with 0 arguments");
        if (args.length == 1) return args[0];

        // Add validation that all inputs are of type : Date | Falsey (Aka don't allow things that we cannot either say for sure are NULL nor convert to a date)
        // Upgrade to convert o actual date objects so and then all to the same timezone so comparison is accurate
        if (args.length == 2) {
            if (!args[0]) return args[1];
            if (!args[1]) return args[0];

            return args[0] < args[1] ? args[0] : args[1];
        }

        // Iteratively compare until we have one final challenger, the king of the hill
        let currentMax = null;

        do {
            const newDate = args.pop();
            currentMax = this.max(currentMax, newDate);
        } while (args.length);

        return currentMax;
    }
}
