import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { PortableApplianceTest } from '../models/portable-appliance-test/portable-appliance-test';
import { PortableApplianceTestPost } from '../models/portable-appliance-test/portable-appliance-test-post';
import { PortableApplianceTestOverview } from '../models/portable-appliance-test/portable-appliance-test-overview';
import { FileService } from "./file.service";
import {PropertyServicesSummary} from "../models/property-services/property-services-summary.interface";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class PortableApplianceTestService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string): Observable<PortableApplianceTestOverview> {
        return this.http.get<PortableApplianceTestOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/portable-appliance-tests/overview`);
    }

    getPastList(propertyReference: string): Observable<PortableApplianceTest[]> {
        return this.http.get<PortableApplianceTest[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/portable-appliance-tests/past`);
    }

    add(propertyReference: string, model: PortableApplianceTestPost): Observable<HttpEvent<PortableApplianceTest>> {
        return this.fileService.uploadFiles(model.portableApplianceTestFiles)
            .pipe(tap(_ => model.portableApplianceTestFiles = this.fileService.prepareFilePosts(model.portableApplianceTestFiles)),
                mergeMap(_ => this.http.post<PortableApplianceTest>(`${this.configService.baseUrl}/manage/property/${propertyReference}/portable-appliance-tests`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(propertyReference: string, item: PortableApplianceTest): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/portable-appliance-tests/${item.guid}`);
    }

    getSummary(propertyReference: string) {
        return this.http.get<PropertyServicesSummary>(`${this.configService.baseUrl}/manage/property/${propertyReference}/portable-appliance-tests/summary`);
    }
}
