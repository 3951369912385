import { KeyValue } from "@angular/common";
import { UntypedFormControl } from "@angular/forms";

export class ErrorSummary extends Array<KeyValue<string, UntypedFormControl>> {
    constructor(items?: Array<KeyValue<string, UntypedFormControl>>) {
        if (items) {
            super(...items);
        } else {
            super();
        }
        Object.setPrototypeOf(this, Object.create(ErrorSummary.prototype));
    }
}
