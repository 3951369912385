import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import {RentReviewOverview} from "../models/rent-review/rent-review-overview";
import {RentReviewSummary} from "../models/rent-review/rent-review-summary";
import {RentReviewRoom} from "../models/rent-review/rent-review-room";
import {PropertyFilterPresets} from "../models/manage-property/property-details";
import {RentReview} from "../models/rent-review/rent-review";
import {RentReviewRoomPost} from "../models/rent-review/rent-review-room-post";
import {AccountService} from "./account.service";
import {RentReviewTenancy} from "../models/rent-review/rent-review-tenancy";
import {RentReviewTenancyPost} from "../models/rent-review/rent-review-tenancy-post";
import {RentReviewRoomOverview} from "../models/rent-review/rent-review-room-overview";

@Injectable()
export class RentReviewService {
    constructor(private http: HttpClient, private configService: ConfigService, private accountService: AccountService) {
    }

    getOverview(organisationReference: string, propertyReference: string = null): Observable<RentReviewOverview> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/`;
        if (propertyReference?.length) {
            url += ``;
        }

        url += "rent-review/overview";

        return this.http.get<RentReviewOverview>(url);
    }

    getRoomOverview(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<RentReviewRoomOverview> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/rent-review/overview`;
        let params = new HttpParams();

        if (tenancyGuid != null) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.get<RentReviewRoomOverview>(url, {params});
    }

    getRoomList(organisationReference: string, propertyReference: string, propertyReferences: string[] | null = null, dueOnly: boolean = true): Observable<RentReviewRoom[]> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/`;
        if (propertyReference?.length) {
            url += "property/${propertyReference}/";
        }

        url += "rent-review/room";

        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        if (dueOnly == false) params = params.append('dueOnly', 'false');

        return this.http.get<RentReviewRoom[]>(url, { params: params });
    }

    getTenancyList(organisationReference: string, propertyReference: string, propertyReferences: string[] | null = null, dueOnly: boolean = true): Observable<RentReviewTenancy[]> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/`;
        if (propertyReference?.length) {
            url += "property/${propertyReference}/";
        }

        url += "rent-review/tenancy";

        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        if (dueOnly == false) params = params.append('dueOnly', 'false');

        return this.http.get<RentReviewTenancy[]>(url, { params: params });
    }

    getRoom(organisationReference: string, propertyReference: string, roomReference: string): Observable<RentReviewRoom> {
        return this.http.get<RentReviewRoom>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/rent-review/room`);
    }

    getRoomListCSVUrl(organisationReference: string, propertyReference: string, propertyReferences: string[] | null = null, dueOnly: boolean = true): string {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/`;
        if (propertyReference?.length) {
            url += "property/${propertyReference}/";
        }

        url += "rent-review/room/csv";

        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        if (dueOnly == false) params = params.append('dueOnly', 'false');

        params = params.set('access_token', this.accountService.accessToken.bearerToken);

        return `${url}?${params.toString()}`
    }

    getTenancyListCSVUrl(organisationReference: string, propertyReference: string, propertyReferences: string[] | null = null, dueOnly: boolean = true): string {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/`;
        if (propertyReference?.length) {
            url += "property/${propertyReference}/";
        }

        url += "rent-review/tenancy/csv";

        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        if (dueOnly == false) params = params.append('dueOnly', 'false');

        params = params.set('access_token', this.accountService.accessToken.bearerToken);

        return `${url}?${params.toString()}`
    }

    getRentReviewRoomList(organisationReference: string, propertyReference: string, roomReference: string): Observable<RentReview[]> {
        return this.http.get<RentReview[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/rent-review/review`);
    }

    addRentReviewRoom(organisationReference: string, propertyReference: string, roomReference: string, model: RentReviewRoomPost): Observable<RentReviewRoom> {
        return this.http.post<RentReviewRoom>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/rent-review/review`, model);
    }

    removeRentReviewRoom(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<RentReviewRoom> {
        return this.http.delete<RentReviewRoom>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/rent-review/review/${guid}`);
    }

    getRentReviewTenancyList(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<RentReview[]> {
        return this.http.get<RentReview[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/rent-review/review`);
    }

    addRentReviewTenancy(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, model: RentReviewTenancyPost): Observable<RentReviewTenancy> {
        return this.http.post<RentReviewTenancy>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/rent-review/review`, model);
    }

    removeRentReviewTenancy(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, guid: string): Observable<RentReviewTenancy> {
        return this.http.delete<RentReviewTenancy>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/rent-review/review/${guid}`);
    }
}
