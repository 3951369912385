import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingIndicatorComponent } from './loading-indicator.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

@NgModule({
    imports: [
        CommonModule,
        SvgIconModule
    ],
    declarations: [
        LoadingIndicatorComponent
    ],
    exports: [
        LoadingIndicatorComponent
    ]
})

export class LoadingIndicatorModule {
}
