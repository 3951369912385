import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {AutomaticAssignment} from "../models/automatic-assignment/automatic-assignment.interface";
import {AutomaticAssignmentPost} from "../models/automatic-assignment/automatic-assignment-post.interface";
import {AutomaticAssignmentPatch} from "../models/automatic-assignment/automatic-assignment-patch.interface";
import {BaseEntityTypes} from "../models/base-entity-types";

@Injectable()
export class AutomaticAssignmentService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    get(organisationReference: string) {
        const url = `${this.configService.baseUrl}/manage/${organisationReference}/automatic-assignments`
        return this.http.get<AutomaticAssignment[]>(url);
    }

    getForType(organisationReference: string, propertyReference: string, baseEntityType: BaseEntityTypes){
        const url = `${this.configService.baseUrl}/manage/${organisationReference}/properties/${propertyReference}/automatic-assignments/types/${baseEntityType}`
        return this.http.get<AutomaticAssignment>(url);
    }

    post(organisationReference: string, model: AutomaticAssignmentPost) {
        const url = `${this.configService.baseUrl}/manage/${organisationReference}/automatic-assignments`
        return this.http.post(url, model);
    }

    delete(organisationReference: string, automaticAssignmentGuid: string) {
        const url = `${this.configService.baseUrl}/manage/${organisationReference}/automatic-assignments/${automaticAssignmentGuid}`;
        return this.http.delete(url);
    }
}
