export class DeviceInfo
{
    browser: string;
    device: string;
    os: string;
    browserVersion: string;
    osVersion: string;
    userAgent: string;
    ua: string;
}
