import { Component, ViewEncapsulation} from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { MobileAppService } from '../../services/mobile-app.service';

@Component({
  selector: 'site-footer-static',
  templateUrl: './site-footer-static.component.html',
  encapsulation: ViewEncapsulation.None
})

export class SiteFooterStaticComponent {

    showAppFooter: boolean = true;
    showLegal: boolean = false;
    staticWebUrl: string;

    constructor(private mobileAppService: MobileAppService, private configService: ConfigService) {

        this.staticWebUrl = this.configService.staticWebUrl;

    }

    ngOnInit() {
        if (this.mobileAppService.isMobileApp()) {
                this.showAppFooter = false;
        }
    }

}
