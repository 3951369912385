import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { ElectricalInstallationConditionReport } from '../models/electrical-installation-condition-report/electrical-installation-condition-report';
import { ElectricalInstallationConditionReportPost } from '../models/electrical-installation-condition-report/electrical-installation-condition-report-post';
import { ElectricalInstallationConditionReportOverview } from '../models/electrical-installation-condition-report/electrical-installation-condition-report-overview';
import { FileService } from "./file.service";
import { OwnerElectricalInstallationConditionReportOverview } from '../models/electrical-installation-condition-report/owner-electrical-installation-condition-report-overview';
import {PropertyServicesSummary} from "../models/property-services/property-services-summary.interface";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class ElectricalInstallationConditionReportService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string): Observable<ElectricalInstallationConditionReportOverview> {
        return this.http.get<ElectricalInstallationConditionReportOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/electrical-installation-condition-report/overview`);
    }

    getList(propertyReference: string): Observable<ElectricalInstallationConditionReport[]> {
        return this.http.get<ElectricalInstallationConditionReport[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/electrical-installation-condition-report`);
    }

    getOwnerOverview(propertyReference: string): Observable<OwnerElectricalInstallationConditionReportOverview> {
        return this.http.get<OwnerElectricalInstallationConditionReportOverview>(`${this.configService.baseUrl}/owner/property/${propertyReference}/electrical-installation-condition-report/overview`);
    }

    getOwnerList(propertyReference: string): Observable<ElectricalInstallationConditionReport[]> {
        return this.http.get<ElectricalInstallationConditionReport[]>(`${this.configService.baseUrl}/owner/property/${propertyReference}/electrical-installation-condition-report`);
    }

    add(propertyReference: string, model: ElectricalInstallationConditionReportPost): Observable<HttpEvent<ElectricalInstallationConditionReport>> {
        return this.fileService.uploadFiles(model.electricalInstallationConditionReportFiles)
            .pipe(tap(_ => model.electricalInstallationConditionReportFiles = this.fileService.prepareFilePosts(model.electricalInstallationConditionReportFiles)),
                mergeMap(_ => this.http.post<ElectricalInstallationConditionReport>(`${this.configService.baseUrl}/manage/property/${propertyReference}/electrical-installation-condition-report`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(propertyReference: string, item: ElectricalInstallationConditionReport): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/electrical-installation-condition-report/${item.guid}`);
    }

    getOwnerElectricalInstallationConditionReport(propertyReference: string, guid: string): Observable<ElectricalInstallationConditionReport> {
        return this.http.get<ElectricalInstallationConditionReport>(`${this.configService.baseUrl}/owner/property/${propertyReference}/electrical-installation-condition-report/${guid}`);
    }

    getSummary(propertyReference: string) {
        return this.http.get<PropertyServicesSummary>(`${this.configService.baseUrl}/manage/property/${propertyReference}/electrical-installation-condition-report/summary`);
    }
}
