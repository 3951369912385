import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { PropertyMarketingOverview } from '../models/property-marketing/property-marketing-overview';
import { FilePost } from '../models/file/file-post';
import { Image } from '../models/image/image';
import { Video } from '../models/property/video';
import { VirtualTour } from '../models/property/virtual-tour';
import { VirtualTourPost } from '../models/property-marketing/virtual-tour-post';
import { VideoPost } from '../models/property-marketing/video-post';


@Injectable()
export class PropertyMarketingService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getPropertyOverview(organisationReference: string, propertyReference: string): Observable<PropertyMarketingOverview> {
        return this.http.get<PropertyMarketingOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/property-marketing/overview`);
    }

    getRoomOverview(propertyReference: string, roomReference: string): Observable<PropertyMarketingOverview> {
        return this.http.get<PropertyMarketingOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/rooms/${roomReference}/property-marketing/overview`);
    }

    addPropertyImages(propertyReference: string, model: FilePost[]): Observable<Image[]> {
        return this.http.post<Image[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/property-marketing/images`, model);
    }

    addRoomImages(propertyReference: string, roomReference: string, model: FilePost[]): Observable<Image[]> {
        return this.http.post<Image[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/property-marketing/images`, model);
    }

    addPropertyFloorPlans(propertyReference: string, model: FilePost[]): Observable<Image[]> {
        return this.http.post<Image[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/property-marketing/floor-plans`, model);
    }

    addPropertyVideo(propertyReference: string, model: VideoPost): Observable<Video> {
        return this.http.post<Video>(`${this.configService.baseUrl}/manage/property/${propertyReference}/property-marketing/videos`, model);
    }

    addPropertyVirtualTour(propertyReference: string, model: VirtualTourPost): Observable<VirtualTour> {
        return this.http.post<VirtualTour>(`${this.configService.baseUrl}/manage/property/${propertyReference}/property-marketing/virtual-tours`, model);
    }

    addRoomVirtualTour(propertyReference: string, roomReference: string, model: VirtualTourPost): Observable<VirtualTour> {
        return this.http.post<VirtualTour>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/property-marketing/virtual-tours`, model);
    }

    removePropertyImage(propertyReference: string, guid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/property-marketing/images/${guid}`);
    }

    removeRoomImage(propertyReference: string, roomReference: string, guid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/rooms/${roomReference}/property-marketing/images/${guid}`);
    }

    removePropertyFloorPlan(propertyReference: string, guid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/property-marketing/floor-plans/${guid}`);
    }

    removePropertyVideo(propertyReference: string, guid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/property-marketing/videos/${guid}`);
    }

    removePropertyVirtualTour(propertyReference: string, guid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/property-marketing/virtual-tours/${guid}`);
    }

    removeRoomVirtualTour(propertyReference: string, roomReference: string, guid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/rooms/${roomReference}/property-marketing/virtual-tours/${guid}`);
    }
}
