import { DropdownOption } from "src/app/components/dropdown/dropdown-option"

export enum FinancialItemParentCategories {
    OTHER = 0,
    COMPLIANCE = 1,
    TENANCY = 2,
    OWNERSHIP = 3,
    MARKETING = 4,
    PROPERTY_HEALTH = 5,
    MAINTENANCE = 6,
    RENT = 7,
    UTILITIES_BILLS = 8,
    MANAGEMENT_FEE = 9,
    SETTLEMENT = 10,
    COMMISSION = 11
}

export const FinancialItemParentCategoriesReverse = {
    '1': "Compliance",
    '4': "Marketing",
    '6': "Maintenance",
    '9': "Management Fee",
    '0': "Other",
    '3': "Ownership",
    '5': "Property Health",
    '7': "Rent",
    '10': "Settlement",
    '2': "Tenancy",
    '8': "Utilities_bills",
}

export const FinancialItemParentCategoriesDropdownList = [
    new DropdownOption("Compliance", '1'),
    new DropdownOption("Marketing", '4'),
    new DropdownOption("Maintenance", '6'),
    new DropdownOption("Management Fee" , '9'),
    new DropdownOption("Other", '0'),
    new DropdownOption("Ownership", '3'),
    new DropdownOption("Property Health" , '5'),
    new DropdownOption("Rent", '7'),
    new DropdownOption("Settlement", '10'),
    new DropdownOption("Tenancy", '2'),
    new DropdownOption("Utilities Bills", '8'),
]

