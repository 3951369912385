export enum FormMessageTypes {
    NOTIFICATION = 'NOTIFICATION',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    CONFIRM = 'CONFIRM',
    TENANT_NOT_INVITED = "TENANT_NOT_INVITED",
    TENANT_INVITED = "TENANT_INVITED",
    TENANT_JOINED = "TENANT_JOINED",
    LIGHT_BULB = "LIGHT_BULB",
    LOVE = "LOVE"
}
