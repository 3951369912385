import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';

import { ConfigService } from './config.service';
import {OpenBankingOverview} from "../models/open-banking/open-banking-overview";
import {NewSession} from "../models/open-banking/new-session";
import {OpenBankingConnection} from "../models/open-banking/open-banking-connection";
import {TransactionSync} from "../models/open-banking/transaction-sync";
import {TransactionsReport} from "../models/open-banking/transactions-report";
import {PatchConnectionAccountConfigModel} from "../models/open-banking/patch-connection-account-config-model";

@Injectable()
export class OpenBankingService {

    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getOverview(): Observable<OpenBankingOverview> {
        return this.http.get<OpenBankingOverview>(`${this.configService.baseUrl}/open-banking/overview`);
    }

    addSession(): Observable<NewSession> {
        return this.http.get<NewSession>(`${this.configService.baseUrl}/open-banking/session`);
    }

    getConnections(): Observable<OpenBankingConnection[]> {
        return this.http.get<OpenBankingConnection[]>(`${this.configService.baseUrl}/open-banking/connections`);
    }

    reconnectConnection(connectionGuid: string): Observable<NewSession> {
        return this.http.get<NewSession>(`${this.configService.baseUrl}/open-banking/connections/${connectionGuid}/reconnect`);
    }

    refreshConnection(connectionGuid: string): Observable<NewSession> {
        return this.http.get<NewSession>(`${this.configService.baseUrl}/open-banking/connections/${connectionGuid}/refresh`);
    }

    deleteConnection(connectionGuid: string): Observable<void> {
        return this.http.delete<void>(`${this.configService.baseUrl}/open-banking/connections/${connectionGuid}`);
    }

    deleteAccount(connectionGuid: string, accountGuid: string): Observable<void> {
        return this.http.delete<void>(`${this.configService.baseUrl}/open-banking/connections/${connectionGuid}/accounts/${accountGuid}`);
    }

    syncTransactions(connectionGuid: string): Observable<TransactionSync> {
        return this.http.get<TransactionSync>(`${this.configService.baseUrl}/open-banking/connections/${connectionGuid}/sync`);
    }

    getTransactionReport(): Observable<TransactionsReport> {
        return this.http.get<TransactionsReport>(`${this.configService.baseUrl}/open-banking/report/`);
    }

    agreeTerms(): Observable<void> {
        return this.http.post<void>(`${this.configService.baseUrl}/open-banking/terms/`, true);
    }

    dismissConnectMessage(): Observable<void> {
        return this.http.post<void>(`${this.configService.baseUrl}/open-banking/dismiss-connect/`, true);
    }

    setAccountFriendlyName(connectionGuid: string, accountGuid: string, friendlyName: string): Observable<void> {
        return this.http.post<void>(`${this.configService.baseUrl}/open-banking/connections/${connectionGuid}/accounts/${accountGuid}/friendly-name/${friendlyName}`, null)
    }

    patchConnectionAccountConfig(organisationReference: string, connectionGuid: string, accountGuid: string, model: PatchConnectionAccountConfigModel) {
        const url = `${this.configService.baseUrl}/manage/${organisationReference}/open-banking/connections/${connectionGuid}/accounts/${accountGuid}/config`
        return this.http.patch(url, model)
    }
}
