import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ConfigService} from './config.service';
import {PropertyAccessOverview} from '../models/property-access/property-access-overview';
import {PropertyAccessCodePost} from '../models/property-access/property-access-code-post';
import {PropertyAccessCode} from '../models/property-access/property-access-code';
import {MyPropertyAccessOverview} from "./my-property-access-overview";
import {PropertyAccessOrganisationOverview} from "../models/property-access/property-access-organisation-overview";

@Injectable()
export class PropertyAccessService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getOverview(organisationReference: string, propertyReference: string): Observable<PropertyAccessOverview> {
        return this.http.get<PropertyAccessOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/property-access/overview`);
    }

    getOrganisationOverview(organisationReference: string): Observable<PropertyAccessOrganisationOverview> {
        return this.http.get<PropertyAccessOrganisationOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property-access/overview`);
    }

    getList(organisationReference: string, propertyReference: string): Observable<PropertyAccessCode[]> {
        return this.http.get<PropertyAccessCode[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/property-access/list`);
    }


    add(organisationReference: string, propertyReference: string, model: PropertyAccessCodePost): Observable<PropertyAccessCode> {
        return this.http.post<PropertyAccessCode>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/property-access`, model);
    }

    update(organisationReference: string, propertyReference: string, guid: string, model: PropertyAccessCodePost): Observable<PropertyAccessCode> {
        return this.http.patch<PropertyAccessCode>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/property-access/${guid}`, model);
    }

    remove(organisationReference: string, propertyReference: string, guid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/property-access/${guid}`);
    }

    getMyHomeOverview() {
        return this.http.get<MyPropertyAccessOverview>(`${this.configService.baseUrl}/my-home/property-access-codes/overview`);
    }
}
