import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '../../pipes/pipes.module';
import {DebugInfoComponent} from "./debug-info.component";

@NgModule({
    imports: [
        CommonModule,
        PipesModule
    ],
    declarations: [
        DebugInfoComponent
    ],
    exports: [
        DebugInfoComponent
    ]
})

export class DebugInfoModule {
}
