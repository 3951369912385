import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { Amenities } from '../models/amenity/amenities';
import { LookupService } from '../services/lookup.service';

@Injectable()
export class AmenitiesResolver implements Resolve<Amenities> {

    constructor(private lookupService: LookupService) { }

    resolve(): Observable<Amenities> {
        return this.lookupService.getAmenities();
    }
}
