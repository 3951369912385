import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor as AngularHttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {EMPTY, Observable, retry, throwError, timer} from 'rxjs';
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {AccountService} from "../services/account.service";

@Injectable()
export class RetryInterceptor implements AngularHttpInterceptor {

    private readonly MAX_NUMBER_OF_RETRY_NO_CONNECTION: number = 2;
    private readonly RETRY_DELAY: number = 500;

    constructor(private accountService: AccountService, private router: Router) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry({
                    count: this.MAX_NUMBER_OF_RETRY_NO_CONNECTION,
                    delay: (error: HttpErrorResponse, retryAttempt: number): Observable<number> => {
                        // if maximum number of retries have been met, throw error
                        if (retryAttempt > this.MAX_NUMBER_OF_RETRY_NO_CONNECTION || !error.status.toString().startsWith('5') ||
                            request.method.toUpperCase() !== 'GET' || error.error.exception === 'SecurityTokenSignatureKeyNotFoundException') {
                            return throwError(() => error);
                        }
                        console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * this.RETRY_DELAY}ms`);
                        // retry after 1s, 2s, etc...
                        return timer(retryAttempt * this.RETRY_DELAY);
                    },
                })
            );
    }
}
