import {Injectable} from '@angular/core';
import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from '@microsoft/signalr';

import {ConfigService} from './config.service';
import {AccountService} from './account.service';
import {User} from '../models/user/user';

@Injectable()
export class WebsocketService {
    private connectionIsEstablished: boolean = false;
    private currentUser: User;
    protected _hubConnection: HubConnection;
    private url: string;

    private connecting: boolean = false;

    constructor(private configService: ConfigService, private accountService: AccountService) {
        accountService.watchCurrentUser()
            .subscribe(user => {
                const currentUser = this.currentUser;
                this.currentUser = user;

                if (!user || currentUser?.person.reference !== user?.person.reference) {
                    this.stopConnection();
                }

                if (user){
                    this.startConnection();
                }
            });
    }
    protected createConnection(url: string) {
        this.url = url;

        if (this._hubConnection) {
            return;
        }

        this._hubConnection = new HubConnectionBuilder()
            .withAutomaticReconnect()
            .withUrl(`${this.configService.baseUrl}${url}`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                accessTokenFactory: () => this.accountService.accessToken.bearerToken
            })
            .configureLogging(LogLevel.None)
            .build();

        this._hubConnection
            .onclose(err => {
                if (!this.currentUser) {
                    return;
                }

                if (this.connectionIsEstablished) {
                    if (err) {
                        console.error(`Error with ${this.url} - ${err}`);
                    }
                }
            });
    }

    startConnection(): void {
        if (!this._hubConnection || !this.currentUser || this.connecting || this._hubConnection.state === HubConnectionState.Connected) {
            return;
        }

        this.connecting = true;

        const self = this;

        this._hubConnection
            .start()
            .then(() => {
                this.connecting = false;
                this.connectionIsEstablished = true;
            })
            .catch(err => {
                this.connecting = false;
                console.error(`Error with ${this.url} - ${err}`);
            });
    }

    stopConnection() {
        if (!this.connectionIsEstablished || !this._hubConnection || this._hubConnection.state === HubConnectionState.Disconnected) {
            return;
        }

        this._hubConnection.stop()
            .then(() => {
                this.connectionIsEstablished = false;
            });
    }
}
