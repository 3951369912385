import { Injectable } from '@angular/core';
import {Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ParamMap} from '@angular/router';

import { AccountService } from './account.service';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';

import { from, Observable } from 'rxjs';
import {AuthenticationToken} from "../models/account/authentication-token";
import {Location} from "@angular/common";

@Injectable()
export class AuthorisedAuthGuard implements CanActivate, CanActivateChild {

    constructor(private accountService: AccountService, private _router: Router, private location: Location) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkIsAuthorised(state);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.checkIsAuthorised(state);
    }

    private checkIsAuthorised(state: RouterStateSnapshot): boolean | Observable<boolean> {
        const isLoggedIn = this.accountService.isLoggedIn();
        if (!isLoggedIn) {
            const loginWithTokens = this.checkAccessTokens(state);
            if (loginWithTokens) {
                window.location.reload();
            }

            if (!this.accountService.isLoggedIn()) {
                return from(this._router.navigate(['/login'], {queryParams: {redirect_to: state.url}}))
                    .pipe(map(() => false));
            }
        }

        const user = this.accountService.getCurrentUser();
        if (user && user.unclaimedAccount) {

        }
        else if (user && !user.emailConfirmed) {
            return from(this._router.navigate(['/register', 'verify']))
                .pipe(map(() => false));
        } else if (user && !user.registrationJourneyCompleted) {
            return from(this._router.navigate(['/register', 'about']))
                .pipe(map(() => false));
        }

        return isLoggedIn;
    }

    private checkAccessTokens(router: RouterStateSnapshot) {
        const params = router.root.queryParamMap;
        let bearerToken = params.get('bearerToken');
        let refreshToken = params.get('refreshToken');
        let email = params.get('email');

        if (!bearerToken || !refreshToken || !email) {
            return false;
        }

        this.accountService.accessToken = new AuthenticationToken(email, bearerToken, refreshToken);
        this.accountService.loginFromAccessToken()
            .subscribe(_ => {


                let newParams: string = '';
                params.keys
                    .filter(k => ['bearerToken'].indexOf(k) === -1 && ['refreshToken'].indexOf(k) === -1 && ['email'].indexOf(k) === -1)
                    .forEach(k => {
                        newParams += `&${ k }=${ params.get(k) }`;
                    });

                let baseUrl = router.url.split('?')[0];
                if (newParams.length) {
                    newParams = `?${ newParams.substr(1) }`;
                }

                this.location.replaceState(`${ baseUrl }${ newParams }`);
            });
        return true;
    }
}
