import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, SecurityContext, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';

import {BaseControlValueAccessor} from '../../models/forms/base-control-value-accessor';
import {UtilitiesService} from "../../services/utilities.service";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {DomSanitizer} from "@angular/platform-browser";
import {StringService} from "../../services/string.service";

@Component({
    selector: 'multiline-textbox',
    templateUrl: './multiline-textbox.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultilineTextboxComponent),
            multi: true
        }
    ]
})

export class MultilineTextboxComponent extends BaseControlValueAccessor<string> implements AfterViewInit {
    private _maxLength: number = null;

    @Input() id: string = null;
    @Input() rows: number = 3;
    @Input() cssClasses: string = null;
    @Input() showCharacterCount = false;
    @Input() placeholder: string;
    @Input() encodeValue: boolean = false;

    @Input() set readonly(value: boolean) {
        this._readonly = value;

        if (this.multilineTextboxElement && this.multilineTextboxElement.nativeElement) {
            if (value) {
                this.multilineTextboxElement.nativeElement.readOnly = true;
            } else {
                this.multilineTextboxElement.nativeElement.removeAttribute('readOnly');
            }
        }
    }

    @Input() set disabled(value: boolean) {
        this._disabled = value;

        if (this.multilineTextboxElement && this.multilineTextboxElement) {
            if (value) {
                this.multilineTextboxElement.nativeElement.disabled = true;
            } else {
                this.multilineTextboxElement.nativeElement.removeAttribute('disabled');
            }
        }
    }

    @Input() set maxLength(value: number) {
        this._maxLength = value;
        this.maxLengthValue = value;

        if (this.multilineTextboxElement && this.multilineTextboxElement.nativeElement) {
            if (value) {
                this.multilineTextboxElement.nativeElement.maxLength = value;
            } else {
                this.multilineTextboxElement.nativeElement.removeAttribute('maxLength');
            }
        }
    }

    @Output() onEnter = new EventEmitter();
    @Output() onChangeListener = new EventEmitter();

    characterCount: number = 0;
    maxLengthValue: number;
    characterDiff: number;
    originalRowNumber: number;
    private onChangeSubject = new Subject<string>()

    @ViewChild('multilineTextbox', {static: true}) multilineTextboxElement: ElementRef;

    constructor(private element: ElementRef, protected controlContainer: ControlContainer, protected utilitiesService: UtilitiesService,
                private stringService: StringService) {
        super(controlContainer, utilitiesService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.element.nativeElement.id) {
            this.element.nativeElement.removeAttribute('id');
        }

        this.disabled = this._disabled;
        this.readonly = this._readonly;
        this.maxLength = this._maxLength;
        this.originalRowNumber = this.rows
    }

    ngOnChanges(changes: SimpleChanges) {

        //re-ajust the rows when the quick answer is selected to send or to update
        if (changes.placeholder) {
            if ((changes.placeholder.previousValue === "Search or type new quick answer" && changes.placeholder.currentValue === "Type a message") ||
                (changes.placeholder.previousValue === "Search or type new quick answer" && changes.placeholder.currentValue === "Edit quick answer")) {
                if (this.showCharacterCount) {
                    this.updateCharacterCount();
                    this.updateTextareaRows();
                }
            }
        }
    }

    ngAfterViewInit() {
        this.onChangeSubject
            .subscribe(value => {
                this.onChange(value);
                this.onChangeListener.emit(value);
            });

        if (this.showCharacterCount) {
            this.onChangeSubject.pipe(debounceTime(100))
                .subscribe(_ => {
                    this.updateCharacterCount();
                    this.updateTextareaRows();
                });
            this.updateCharacterCount();
            this.updateTextareaRows();
        }
    }

    internalOnChange(event) {
        let value = event.target.value;
        if (this.encodeValue) {
            value = this.stringService.htmlEncode(value);
        }
        this.onChangeSubject.next(value);
    }


    internalOnEnter($event: any) {
        this.onEnter.emit($event);
    }


    // Code to be re-thought out before it can go back into the system because this is causing issues on typing speeds when tyring to count characters
    private updateCharacterCount() {
        if (this.value) {
            this.characterCount = this.value.length;
            this.characterDiff = this.maxLengthValue - this.characterCount;
        } else {
            this.characterCount = 0;
            this.characterDiff = this.maxLengthValue - this.characterCount;
        }
    }

    private updateTextareaRows() {
        if (this.value) {
            const char = this.value.length / 70;
            const linebreaks = this.value ? this.value.split('\n').length : 1;
            const calculatedRows = Math.min(12, char + linebreaks); // Limit to a maximum of 9 rows
            this.rows = Math.max(this.originalRowNumber, calculatedRows); // Use the maximum of originalRowNumber and calculatedRows
        } else {
            this.rows = this.originalRowNumber;
        }
    }

    focus() {
        this.multilineTextboxElement.nativeElement.focus();
    }
}
