import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import { ConfigService } from './config.service';
import { ManagePropertyOverview } from '../models/manage-property/manage-property-overview';
import { AddProperty } from '../models/manage/add-property';
import { JsonPatch } from '../models/json-patch/json-patch';
import { Conversation } from '../models/message/conversation';
import { PropertyDefaultDescription } from '../models/property/property-default-description';
import { PropertyService } from '../models/property/property-service';
import { PropertySummary } from '../models/manage-property/property-summary';
import {PropertyDetails, PropertyFilterPresets} from '../models/manage-property/property-details';
import { TenantSummary } from '../models/tenancy/tenant-summary';
import { Image } from '../models/image/image';
import { RoomSummary } from '../models/manage-room/room-summary';
import { ActionOverview } from '../models/action/action-overview';
import { NotificationSummary } from '../models/manage-organisation/notification-summary';
import { PropertyCompletion } from '../models/action/property-completion';
import { TenancySummary } from '../models/tenancy/tenancy-summary';
import {DataCacheSubject} from "../helpers/data-cache-subject";
import {ManagePropertyCompliancyOverview} from "../models/manage-property/manage-property-compliancy-overview";
import {assertNever} from "../helpers/assert-never";

import * as _ from 'lodash';
import {User} from "../models/user/user";
import {SelectablePartial} from "../models/selectable-partial";
import {TenancySelectablePartialFormats} from "../models/tenancy/tenancy-selectable-partial-formats";
import {PropertyPageSummaries} from "../models/manage-property/property-page-summaries.interface";
import {TenantFindPropertySettings} from "../models/tenant-find/tenant-find-property-settings";

@Injectable({
    providedIn: 'root',
})
export class ManagePropertyService {
    currentUser: User;

    private  _organisationNotificationsCache : { [p: string]: DataCacheSubject<NotificationSummary>} = {};
    private  _roomSummariesCache : { [p: string]: DataCacheSubject<RoomSummary[]>} = {};
    private  _allTenanciesCache : { [p: string]: DataCacheSubject<TenancySummary[]>} = {};
    private _tenancySelectablePartials : { [p: string]: DataCacheSubject<SelectablePartial[]>} = {};

    constructor(
        private http: HttpClient,
        private configService: ConfigService) {
    }

    addProperty(model: AddProperty): Observable<HttpEvent<PropertyDetails>> {
        return this.http.post<PropertyDetails>(`${ this.configService.baseUrl }/manage/property`, model, {
            reportProgress: true, observe: 'events'
        });
    }

    getServices(propertyReference: string, includeComingSoon: boolean = false, active: boolean = false, inactive: boolean = false): Observable<PropertyService[]> {
        let params = new HttpParams();
        if (includeComingSoon) {
            params = params.set('includeComingSoon', 'true');
        }

        if (active) {
            params = params.set('includeActive', 'true');
        }

        if (inactive) {
            params = params.set('includeInactive', 'true');
        }

        return this.http.get<PropertyService[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/services`, {params});
    }

    patchService(propertyReference: string, serviceReference: string, body: JsonPatch[], allProperties: boolean): Observable<PropertyService> {
        let params = new HttpParams();
        if (allProperties) {
            params = params.set('allProperties', 'true');
        }

        return this.http.patch<PropertyService>(`${this.configService.baseUrl}/manage/property/${propertyReference}/services/${serviceReference}`, body, { params });
    }

    patchProperty(propertyReference: string, body: JsonPatch[]): Observable<HttpEvent<PropertyDetails>> {
        return this.http.patch<PropertyDetails>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }`, body, {
            reportProgress: true, observe: 'events'
        });
    }

    getProperty(propertyReference: string, includeAvailableRooms: boolean = false): Observable<PropertyDetails> {
        let params = new HttpParams();
        if (includeAvailableRooms) {
            params = params.set('includeAvailableRooms', 'true');
        }

        return this.http.get<PropertyDetails>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }`, {params});
    }

    getRoomSummaries(organisationReference: string, propertyReferences: string[] | null = null): Observable<RoomSummary[]> {
        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }


        return this.http.get<RoomSummary[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rooms-by-properties`, { params });
    }

    getTenancySummaries(organisationReference: string, propertyReferences: string[] | null = null, includePast: boolean, includeFuture: boolean, outstandingRentOnly: boolean = false, includeCurrent: boolean = true, includeProspective: boolean = false): Observable<TenancySummary[]> {

        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        params = params.appendAll({
            'outstandingRentOnly': outstandingRentOnly,
            'includePast': includePast,
            'includeFuture': includeFuture,
            'includeCurrent': includeCurrent,
            'includeProspective': includeProspective,
        });

        return this.http.get<TenancySummary[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/tenancies-by-properties`, { params });
    }

    getTenancySelectableOptions(organisationReference: string, format: TenancySelectablePartialFormats, propertyReferences: string[] | null = null, includePast: boolean, includeFuture: boolean, outstandingRentOnly: boolean = false, includeCurrent: boolean = true, includeProspective: boolean = false){

        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        params = params.appendAll({
            'outstandingRentOnly': outstandingRentOnly,
            'includePast': includePast,
            'includeFuture': includeFuture,
            'includeCurrent': includeCurrent,
            'includeProspective': includeProspective,
        });

        return this.http.get<SelectablePartial[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/get-tenancy-selectables/format/${format}`, { params });
    }

    getAvailableRoomSummaries(organisationReference: string, propertyReferences: string[] | null = null): Observable<RoomSummary[]> {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/rooms-by-properties/available`

        let params = new HttpParams();
        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        return this.http.get<RoomSummary[]>(url, { params });
    }

    getImages(propertyReference: string): Observable<Image[]> {
        return this.http.get<Image[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/images`);
    }

    getManagePropertyOverview(propertyReference: string): Observable<ManagePropertyOverview> {
        return this.http.get<ManagePropertyOverview>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/overview`);
    }

    getManagePropertyCompliancyOverview(organisationReference: string, propertyReference: string): Observable<ManagePropertyCompliancyOverview> {
        return this.http.get<ManagePropertyCompliancyOverview>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/property/${ propertyReference }/overview/compliancy`);
    }

    getManagePropertySummary(propertyReference: string): Observable<PropertySummary> {
        return this.http.get<PropertySummary>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/summary`);
    }

    getActiveAndFutureTenantSummaries(propertyReference: string, includeProspective: boolean = false): Observable<TenantSummary[]> {
        let params = new HttpParams();
        if (includeProspective) {
            params = params.set('includeProspective', true);
        }

        return this.http.get<TenantSummary[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/tenants`, { params });
    }

    getTenantSummariesFromTenancy(organisationReference: string, propertyReference: string, tenancyGuid: string) {
        const url = `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/tenancy/${tenancyGuid}/tenants`
        return this.http.get<TenantSummary[]>(url);
    }

    getManagePropertyService(propertyReference: string, serviceReference: string): Observable<PropertyService> {
        return this.http.get<PropertyService>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/services/${ serviceReference }`);
    }

    getSettingsTenantFind(organisationReference, propertyReference: string): Observable<TenantFindPropertySettings> {
        return this.http.get<TenantFindPropertySettings>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/property/${ propertyReference }/tenant-find/settings`);
    }

    getPropertyNotifications(organisationReference: string, propertyReference: string): Observable<NotificationSummary> {
        const constraints = {'organisationReference': organisationReference, 'propertyReference': propertyReference};

        // Create the cache if it does not exist (this does not trigger the event yet)
        if (!this._organisationNotificationsCache[propertyReference]) {
            this._organisationNotificationsCache[propertyReference] = new DataCacheSubject<NotificationSummary>(x => {
                if (organisationReference == null) {
                    return this.http.get<NotificationSummary>(`${this.configService.baseUrl}/manage/property/${propertyReference}/notifications`);
                }
                else {
                    return this.http.get<NotificationSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/notifications`);
                }
            }, constraints, 10);
        }

        return this._organisationNotificationsCache[propertyReference].cached(constraints);
    }

    getManagePropertyViewings(propertyReference: string): Observable<Conversation[]> {
        return this.http.get<Conversation[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/viewings`);
    }

    getPropertyDefaultDescription(propertyReference: string): Observable<PropertyDefaultDescription> {
        return this.http.get<PropertyDefaultDescription>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/default-description`);
    }

    getActions(organisationReference: string, propertyReference: string): Observable<ActionOverview> {
        return this.http.get<ActionOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${ propertyReference }/actions`);
    }

    getPropertyCompletion(organisationReference: string, propertyReference: string): Observable<PropertyCompletion> {
        return this.http.get<PropertyCompletion>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/actions/completion`);
    }

    dismissCompletion(organisationReference: string, propertyReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/actions/completion/dismiss`, null);
    }

    patchPropertyServices(reference: string, body: { [p: string]: JsonPatch[] }) {
        return this.http.patch<PropertyService[]>(`${ this.configService.baseUrl }/manage/property/${ reference }/services`, body);
    }

    checkRoomAvailability(reference: string): Observable<boolean> {
        return this.http.get<boolean>(`${ this.configService.baseUrl }/manage/property/${ reference }/room-availability`);
    }

    deactivateProperty(propertyReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/deactivate`, null);
    }

    activateProperty(propertyReference: string, manuallyAccept: boolean, alwaysAccept: boolean = false): Observable<PropertySummary> {
        let params = new HttpParams();

        if (manuallyAccept) {
            params = params.set('manuallyAccept', 'true');
        }
        if (alwaysAccept) {
            params = params.set('alwaysAccept', 'true');
        }

        return this.http.post<PropertySummary>(`${this.configService.baseUrl}/manage/property/${propertyReference}/activate`, null, { params });
    }

    deleteProperty(propertyReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/delete`, null);
    }


    //----------------------------------------------------//
    // Cached variations of the calls
    //----------------------------------------------------//


    getTenancySelectableOptionsCached(organisationReference: string, type: 'ongoing_set'|'working_set'|'confirmed_tenancies', format: TenancySelectablePartialFormats)
    {
        const cacheKey = type + format;
        const constraints = {'organisationReference': organisationReference, 'type': type, 'format': format};

        // Create the cache if it does not exist (this does not trigger the event yet)
        if (!this._tenancySelectablePartials[cacheKey]) {
            let includePast;
            let includeFuture;
            let outstandingRentOnly;
            let includeCurrent;
            let includeProspective;

            switch (type) {
                case 'ongoing_set':
                    includePast = false;
                    includeFuture = true;
                    outstandingRentOnly = false;
                    includeCurrent = true
                    includeProspective = true;
                    break;
                case 'working_set':
                    includePast = true;
                    includeFuture = true;
                    outstandingRentOnly = false;
                    includeCurrent = true
                    includeProspective = true;
                    break;
                case 'confirmed_tenancies':
                    includePast = true;
                    includeFuture = true;
                    outstandingRentOnly = false;
                    includeCurrent = true
                    includeProspective = false;
                    break;
                default:
                    assertNever(type);
            }


            this._tenancySelectablePartials[cacheKey] = new DataCacheSubject<SelectablePartial[]>(x => {
                return this.getTenancySelectableOptions(organisationReference, format, null, includePast, includeFuture, outstandingRentOnly, includeCurrent, includeProspective);
            }, constraints);
        }

        return this._tenancySelectablePartials[cacheKey].cached(constraints);
    }


    getAllTenancySummariesCached(organisationReference: string, type: 'ongoing_set'|'working_set'|'confirmed_tenancies')
    {
        const constraints = {'organisationReference': organisationReference, 'type': type};

        // Create the cache if it does not exist (this does not trigger the event yet)
        if (!this._allTenanciesCache[type]) {
            let includePast;
            let includeFuture;
            let outstandingRentOnly;
            let includeCurrent;
            let includeProspective;

            switch (type) {
                case 'ongoing_set':
                    includePast = false;
                    includeFuture = true;
                    outstandingRentOnly = false;
                    includeCurrent = true
                    includeProspective = true;
                    break;
                case 'working_set':
                    includePast = true;
                    includeFuture = true;
                    outstandingRentOnly = false;
                    includeCurrent = true
                    includeProspective = true;
                    break;
                case 'confirmed_tenancies':
                    includePast = true;
                    includeFuture = true;
                    outstandingRentOnly = false;
                    includeCurrent = true
                    includeProspective = false;
                    break;
                default:
                    assertNever(type);
            }


            this._allTenanciesCache[type] = new DataCacheSubject<TenancySummary[]>(x => {
                return this.getTenancySummaries(organisationReference, null, includePast, includeFuture, outstandingRentOnly, includeCurrent, includeProspective)
            }, constraints);
        }

        return this._allTenanciesCache[type].cached(constraints);
    }

    clearAllTenancySummariesCached() {
        this._allTenanciesCache = {};
        this._tenancySelectablePartials = {};
    }

    getRoomSummariesCached(organisationReference: string, propertyReference: string)  : Observable<RoomSummary[]> {
        const constraints = {'organisationReference': organisationReference, 'propertyReference': propertyReference};

        // Create the cache if it does not exist (this does not trigger the event yet)
        if (!this._roomSummariesCache[propertyReference]) {
            this._roomSummariesCache[propertyReference] = new DataCacheSubject<RoomSummary[]>(x => {
                return this.getRoomSummaries(organisationReference, [propertyReference])
            }, constraints);
        }

        return this._roomSummariesCache[propertyReference].cached(constraints);
    }

    clearRoomSummariesCached() {
        this._roomSummariesCache = {};
    }



    sortTenanciesByProperty(sortedProperties: PropertySummary[], tenancies: TenancySummary[], iteratees: any[] = []): TenancySummary[] {
        if (!tenancies || tenancies.length <= 1 || !sortedProperties || sortedProperties.length <= 1) return tenancies;


        // Construct a lookup that translates PropertyReference -> Sorting ID
        let orderId = 0;
        const propertyOrderId = {};
        sortedProperties.forEach(property => propertyOrderId[property.reference] = orderId++);

        // Apply our lookup to the passed in tenancies array
        return _.orderBy(tenancies, [tenancy => {
            return propertyOrderId[tenancy.propertyReference] || 10_000;
        }, ...iteratees]);
    }

    sortListByPropertyReference<T>(sortedProperties: PropertySummary[], items: T[], iteratees: any[] = []): T[] {
        if (!items || items.length <= 1 || !sortedProperties || sortedProperties.length <= 1) return items;


        // Construct a lookup that translates PropertyReference -> Sorting ID
        let orderId = 0;
        const propertyOrderId = {};
        sortedProperties.forEach(property => propertyOrderId[property.reference] = orderId++);

        // Apply our lookup to the passed in tenancies array
        return _.orderBy(items, [item => {
            return propertyOrderId[item?.propertyReference] || 10_000;
        }, ...iteratees]);
    }

    getPropertyPageSummaries(propertyReference: string){
        const url = `${ this.configService.baseUrl }/manage/property/${ propertyReference }/page-summaries`
        return this.http.get<PropertyPageSummaries>(url);
    }
}
