import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subject, Subscriber} from 'rxjs';

import {ModalService} from '../../services/modal.service';
import {ModalState} from '../../models/modal/modal-state';
import {ModalTypes} from '../../models/modal/modal-types';
import {ModalStates} from '../../models/modal/modal-states';
import {DomService} from '../../services/dom.service';
import {IconNames} from "../../models/icon-names";

@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
    encapsulation: ViewEncapsulation.None,
    host: {
        '(window:keyup)': 'onKeyUp($event)'
    }
})

export class ModalComponent implements OnInit {

    modalState: ModalState<any>;
    ModalStates = ModalStates;
    ModalTypes = ModalTypes;

    @Input() type: ModalTypes | string;
    @Input() state: ModalStates = ModalStates.CLOSED;
    @Input() callback: Subscriber<any> | Subject<any> = null;
    @Input() data: any = null;
    @Input() noScroll: boolean = false;
    @Input() closeOnBlur: boolean = true;
    @Input() additionalClass: string = '';
    @Input() closeMethod: any;
    @Input() fullHeight: boolean = false;
    @Input() largeWidth: boolean = false;
    @Input() fullScreen: boolean = false;
    @Input() class: string;
    @Output() onClose = new EventEmitter<void>();

    @ViewChild('backdrop', {static: true}) backdrop: ElementRef;
    @ViewChild('dialog') dialog: ElementRef;
    @ViewChild('footer', {static: true}) footer: ElementRef;
    @ViewChild('header', {static: true}) header: ElementRef;

    hasDropdownElement = false;

    constructor(private modalService: ModalService, private domService: DomService, private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        this.modalState = new ModalState(this.type, this.state, this.callback, this.data);
        this.modalService.addModal(this.modalState);
        if(this.fullHeight === true && this.largeWidth === true) this.fullScreen = true;
    }

    ngAfterViewChecked(){
        if (this.state == ModalStates.CLOSED) return;

        // We check if the searchable dropdown is there & is open within the modal body. If so let it overflow
        // ngAfterViewChecked calls are NOT supposed to change variables that result in the view model changing. So we need to make this adjustment on a timeout.
        // ADDED: 2024-09 = A check that if the modal has a scrollbar then we don't need to make such a class name attachment or modification because the user
        //    will be able to scroll the dropdown.
        const newValue = !!document.querySelector('.ng-dropdown-panel');
        if (newValue != this.hasDropdownElement) {
            const modalObject = this.elementRef.nativeElement = document.querySelector('.modal__body');

            if (this.hasScroll(modalObject) == false) setTimeout(_ => this.hasDropdownElement = newValue, 100);
        }
    }

    hasScroll(el) {
        var direction = 'scrollTop';
        var result = !! el[direction];

        if (!result) {
            el[direction] = 1;
            result = !!el[direction];
            el[direction] = 0;
        }
        return result;
    }

    toggleFullScreen() {
        this.fullScreen = !this.fullScreen
    }

    closeModal() {
        if (this.modalState.state === ModalStates.CLOSED) {
            return;
        }

        if (this.onClose.observers.length) {
            this.onClose.emit();
        } else {
            if (this.closeMethod !== undefined && this.closeMethod !== null) {
                this.closeMethod();
            }
        }

        this.modalService.closeModal(this.type, false);

        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
    }

    modalClicked(event: MouseEvent): void {
        if (this.closeOnBlur && !this.domService.isDescendant(this.dialog.nativeElement, event.target)) {
            this.closeModal();
        }
    }

    onKeyUp(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.closeModal();
        }
    }

    protected readonly IconNames = IconNames;
}
