<modal [type]="ModalTypes.FORM_NAVIGATE_AWAY_CONFIRM" [closeOnBlur]="false">
    <ng-container modal-header>Confirm navigate away?</ng-container>
    <ng-container modal-body>
        <p>
            You have unsaved changes on the form
        </p>
    </ng-container>
    <ng-container modal-footer>
        <div class="form__grid-element">
            <div class="button__group">
                <div class="button__group-item">
                    <button class="button" type="button" (click)="confirmNavigateAway()">Yes</button>
                </div>
                <div class="button__group-item">
                    <button class="button button--ghost" type="button" (click)="cancelNavigateAway()">No</button>
                </div>
            </div>
        </div>
    </ng-container>
</modal>
