import {HttpClient, HttpEvent, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {mergeMap, Observable} from 'rxjs';

import { ConfigService } from './config.service';
import { FileService } from "./file.service";
import {InventoryOverview} from "../models/inventory/inventory-overview";
import {Inventory} from "../models/inventory/inventory";
import {InventoryPost} from "../models/inventory/inventory-post";
import {InventorySummary} from "../models/inventory/inventory-summary";
import {ManageOrganisationService} from "./manage-organisation.service";
import {InventoryTemplate} from "../models/inventory-template/inventory-template.interface";
import {InventoryTemplatePost} from "../models/inventory-template/inventory-template-post.interface";
import {InventoryTemplatesSummary} from "../models/inventory-template/inventory-templates-summary.interface";
import {HouseShareFile} from "../models/file/file";
import {InventoryConversationPost} from "../models/inventory/inventory-conversation-post.interface";
import {tap} from "rxjs/operators";


@Injectable()
export class InventoryService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService, private manageOrganisationService: ManageOrganisationService) {
    }

    getSummary(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<InventorySummary> {
        return this.http.get<InventorySummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/inventory/summary`);
    }

    getOverview(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<InventoryOverview> {
        return this.http.get<InventoryOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/inventory/overview`);
    }

    getList(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<Inventory[]> {
        return this.http.get<Inventory[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/inventory`);
    }

    add(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, model: InventoryPost) {
        return this.fileService.uploadFiles(model.inventoryFiles)
            .pipe(tap(_ => model.inventoryFiles = this.fileService.prepareFilePosts(model.inventoryFiles)),
                mergeMap(_ => this.http.post<Inventory>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/inventory`, model)));
    }

    remove(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, item: Inventory): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}/inventory/${item.guid}`);
    }

    getInventoryTemplatesSummary(propertyReference: string, roomReference?: string){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/inventory-templates/summary`
        let params = new HttpParams();
        if (propertyReference) params = params.set('propertyReference', propertyReference);
        if (roomReference) params = params.set('roomReference', roomReference);
        return this.http.get<InventoryTemplatesSummary>(url, {params});
    }

    getInventoryTemplates(archived: boolean, propertyReference?: string, roomReference?: string){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/inventory-templates`
        let params = new HttpParams();
        params = params.set('archived', archived);
        if (propertyReference) params = params.set('propertyReference', propertyReference);
        if (roomReference) params = params.set('roomReference', roomReference);
        return this.http.get<InventoryTemplate[]>(url, {params});
    }

    createInventoryTemplate(model: InventoryTemplatePost){
        let files = model.files;
        let sectionFiles = model.sections.map(section => section.files).reduce((acc, val) => acc.concat(val), []);
        let sectionItemFiles = model.sections.map(section => section.items.map(item => item.files).reduce((acc, val) => acc.concat(val), [])).reduce((acc, val) => acc.concat(val), []);
        files = files.concat(sectionFiles, sectionItemFiles);

        return this.fileService.uploadFiles(files)
            .pipe(mergeMap(_ => {
                model.files = this.fileService.prepareFilePosts(model.files);
                if (model.sections && model.sections.length > 0) {
                    model.sections = model.sections.map(section => {
                        if (section.files) section.files = this.fileService.prepareFilePosts(section.files);

                        if (section.items && section.items.length > 0) {
                            section.items = section.items.map(item => {
                                if (item.files) item.files = this.fileService.prepareFilePosts(item.files);
                                return item;
                            });
                        }
                        return section;
                    });
                }

                const organisationReference = this.manageOrganisationService.getOrganisationReference();
                const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/inventory-templates/`
                return this.http.post<InventoryTemplate>(url, model);
            }));
    }

    patchInventoryTemplate(guid: string, model: InventoryTemplatePost) {
        let files = model.files;
        let sectionFiles = model.sections.map(section => section.files).reduce((acc, val) => acc.concat(val), []);
        let sectionItemFiles = model.sections.map(section => section.items.map(item => item.files).reduce((acc, val) => acc.concat(val), [])).reduce((acc, val) => acc.concat(val), []);
        files = files.concat(sectionFiles, sectionItemFiles);
        files = files.filter(m => !m.existingFile);

        return this.fileService.uploadFiles(files)
            .pipe(mergeMap(_ => {
                model.files = this.fileService.prepareFilePosts(model.files);
                if (model.sections && model.sections.length > 0) {
                    model.sections = model.sections.map(section => {
                        if (section.files) section.files = this.fileService.prepareFilePosts(section.files);

                        if (section.items && section.items.length > 0) {
                            section.items = section.items.map(item => {
                                if (item.files) item.files = this.fileService.prepareFilePosts(item.files);
                                return item;
                            });
                        }
                        return section;
                    });
                }

                const organisationReference = this.manageOrganisationService.getOrganisationReference();
                const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/inventory-templates/${guid}`
                return this.http.patch<InventoryTemplate>(url, model);
            }));
    }

    toggleArchive(guid: string){
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/inventory-templates/${guid}/archive`
        return this.http.patch<InventoryTemplate>(url, null);
    }

    getInventoryTemplate(guid: string){
        const url = `${this.configService.baseUrl}/inventory-templates/${guid}`
        return this.http.get<InventoryTemplate>(url);
    }

    triggerInventoryTemplatePdfGeneration(guid: string) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/inventory-templates/${guid}/pdf`
        return this.http.post(url, null);
    }

    createInventoryConversation(organisationReference: string, inventoryGuid: string, model: InventoryConversationPost){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/inventories/${inventoryGuid}/conversation`
        return this.http.post<Inventory>(url, model);
    }

    getLatestInventoryForTenancy(tenancyGuid: string){
        const url = `${this.configService.baseUrl}/manage/tenancies/${tenancyGuid}/inventories/latest`
        return this.http.get<Inventory>(url);
    }
}
