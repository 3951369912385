import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'bracketsWrap', pure: true})
export class HouseShareBracketsWrap implements PipeTransform {
    constructor() {
    }
    transform(text: any, fallback = null) {
        if (!text) {
            if (fallback) return ' (' + fallback + ')';
            return '';
        }

        return ' (' + text + ')';
    }
}
