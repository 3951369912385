import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgObjectPipesModule } from 'ngx-pipes';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { ErrorResponseSummaryComponent } from './error-response-summary.component';
import {LoaderButtonModule} from "../loader-button/loader-button.module";
import {ErrorResponseSummaryNewComponent} from "./error-response-summary-new.component";

@NgModule({
    imports: [
        CommonModule,
        SvgIconModule,
        NgObjectPipesModule,
        LoaderButtonModule,
    ],
    declarations: [
        ErrorResponseSummaryComponent,
        ErrorResponseSummaryNewComponent
    ],
    exports: [
        ErrorResponseSummaryComponent,
        ErrorResponseSummaryNewComponent
    ]
})
export class ErrorResponseSummaryModule {
}
