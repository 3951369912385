export enum AgeRanges {
  UNSPECIFIED = -1,
  UNDER_20 = 0,
  EARLY_20 = 1,
  MID_20 = 2,
  LATE_20 = 3,
  EARLY_30 = 4,
  MID_30 = 5,
  LATE_30 = 6,
  EARLY_40 = 7,
  MID_40 = 8,
  LATE_40 = 9,
  OVER_50 = 10
}
