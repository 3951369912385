import {HttpClient, HttpEvent, HttpParams, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';

import { ConfigService } from './config.service';
import { RightToRent } from '../models/right-to-rent/right-to-rent';
import { RightToRentPost } from '../models/right-to-rent/right-to-rent-post';
import { RightToRentIdentityDocumentType } from '../models/right-to-rent/right-to-rent-identity-document-type';
import { FileService } from "./file.service";
import { RightToRentOverview } from '../models/right-to-rent/right-to-rent-overview';
import {TenancyAgreement} from "../models/tenancy-agreement/tenancy-agreement";
import {mergeMap, tap} from "rxjs/operators";
import {RightToRentValidatePatch} from "../models/right-to-rent/right-to-rent-validate-patch.interface";


@Injectable()
export class RightToRentService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string, roomReference: string, tenancyGuid: string, tenancyTenantGuid: string): Observable<RightToRentOverview> {
        let params = new HttpParams();
        if (tenancyGuid) params = params.set('tenancyGuid', tenancyGuid);
        if (tenancyTenantGuid) params = params.set('tenancyTenantGuid', tenancyTenantGuid);

        return this.http.get<RightToRentOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/right-to-rent/overview`, { params });
    }

    getLatestRightToRents(propertyReference: string, roomReference: string, tenancyGuid: string, tenancyTenantGuid: string = null) {
        let params = new HttpParams();
        if (tenancyTenantGuid) params = params.set('tenancyTenantGuid', tenancyTenantGuid);

        // Returns a lookup of the right to rent by TenancyTenantGuid
        return this.http.get<[{
            tenancyTenantGuid: string,
            latestRightToRent: RightToRent,
        }]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/right-to-rent/${tenancyGuid}/latest`, { params });
    }

    add(propertyReference: string, roomReference: string, tenancyReference: string, tenancyTenantGuid: string, model: RightToRentPost) {
        let obs = of([]);

        for (let document of model.rightToRentDocuments) {
            obs = obs.pipe(mergeMap(_ => this.fileService.uploadFiles(document.files)),
                tap(_ => document.files = this.fileService.prepareFilePosts(document.files)));
        }

        const url = `${this.configService.baseUrl}/manage/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyReference}/tenant/${tenancyTenantGuid}/right-to-rent`;

        return obs.pipe(mergeMap(_ => this.http.post<RightToRent>(url, model)));
    }

    validate(rightToRentGuid: string, model: RightToRentValidatePatch) {
        const url = `${this.configService.baseUrl}/manage/right-to-rents/${rightToRentGuid}/validate`;
        return this.http.patch(url, model);
    }

    getIdentityDocumentTypes(): Observable<RightToRentIdentityDocumentType[]> {
        return this.http.get<RightToRentIdentityDocumentType[]>(`${this.configService.baseUrl}/right-to-rent-identity-document-types`);
    }

    remove(organisationReference: string, propertyReference: string, roomReference: string, tenancyReference: string, item: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyReference}/right-to-rent/${item}`);
    }

    get(rightToRentGuid: string){
        return this.http.get<RightToRent>(`${this.configService.baseUrl}/manage/right-to-rents/${rightToRentGuid}`);
    }

    markAsCheckedInPerson(rightToRentDocumentGuid: string){
        const url = `${this.configService.baseUrl}/manage/right-to-rents-documents/${rightToRentDocumentGuid}/checked-in-person`
        return this.http.patch<void>(url, null)
    }
}
