import { File } from '../file/file';
import { OrganisationSupplier } from '../organisation-supplier/organisation-supplier';
import {FinancialItemInvoiceEarnedBy} from "./financial-item-invoice-earned-by";

export class FinancialItemInvoice {
    invoiceDate: Date;
    invoiceDueDate?: Date;
    invoiceNumber: string;
    amount: number;
    files: File[];
    organisationSupplier: OrganisationSupplier;
    earnerName: string;
    earnedBy: FinancialItemInvoiceEarnedBy;
}
