import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'joinAnd',
    pure: true
})
export class JoinAndPipe implements PipeTransform {
    transform(input:Array<any>, separator:string = ', ', finalSeparator: string = ' and '): string {
        if (input.length == 0) return '';
        if (input.length == 1) return input[0].toString();
        if (input.length == 2) return input[0].toString() + finalSeparator +  input[1].toString();

        const allButLast = input.slice(0, -1);
        const last = input[input.length - 1];

        return allButLast.join(separator) + finalSeparator + last;
    }
}
