import { Action } from '../action/action';
import { PropertyService } from '../property/property-service';
import { AllowedTenantTypes } from '../property/allowed-tenant-types';
import { RequestedGenders } from '../user/requested-genders';
import { RentPaymentFrequencies } from '../tenancy/rent-payment-frequencies';
import { PropertyTypes } from '../property/property-types';
import { Amenity } from '../amenity/amenity';
import { Address } from '../address/address';
import { Image } from '../image/image';
import { LoqateData } from '../../components/address-editor/loqate.data';
import { RoomDetails } from '../manage-room/room-details';
import { FullAddress } from '../address/full-address';
import { BillsInclusiveTypes } from '../property/bills-inclusive-types';
import { RentalTypes } from '../property/rental-types';
import {FurnishedState} from "../property/furnished-state";
import {RightmoveProperty} from "../rightmove/rightmove-property.interface";
import {CouncilTaxBand} from "../property/council-tax-band";

export class PropertyDetails {
    name: string;
    reference: string;
    headlineDescription: string;
    description: string;
    defaultDescription: string;
    type: PropertyTypes;
    rentalType: RentalTypes;
    allowedTenantType: AllowedTenantTypes;
    requestedGender: RequestedGenders;
    minAgeRange: number;
    maxAgeRange: number;
    minimumMonthsRental: number;
    maximumMonthsRental: number;
    billsInclusive: BillsInclusiveTypes;
    billsPartiallyInclusiveDetail: string;
    averageBillCost: number;
    billsFrequency: RentPaymentFrequencies;
    councilTaxBand: CouncilTaxBand;
    tenantFindEnabled: boolean;

    petsAllowed: boolean;
    smokingAllowed: boolean;

    roomsDetails: RoomDetails[];

    actions: Action[];
    recommendations: Action[];

    services: PropertyService[];
    amenities: Amenity[];

    majorArea: Address;
    minorArea: Address;

    images: Image[];
    image: Image;

    floorPlans: Image[];

    addressData: LoqateData;
    address: FullAddress;

    disabled: Date;
    furnished: FurnishedState;
    numberOfBedrooms: number;
    numberOfBathrooms: number;

    rightmoveProperty: RightmoveProperty;
}

export enum PropertyFilterPresets {
    NONE = "preset:none",
    SINGLE_LETS = "preset:single-lets",
    MULTI_LETS = "preset:multi-lets",
    ALL = "present:all"
}
