<div class="footer footer--supplier">
    <div class="container">
        <div class="row u-align--center">
            <div class="col col__md--3 col__lg--2">
                <div class="footer__logo">
                    <img src="./assets/images/logos/logo--blue.png" alt="COHO logo" height="48">
                </div>
            </div>
            <div class="col col__md--9 col__lg--10">
                <p class="u-text--small">COHO is property management software for landlords, property managers and letting agents.</p>
            </div>
        </div>
        <div class="row u-padding-top--20">
            <div class="col">
                <p class="copyright"><copyright-text></copyright-text></p>
            </div>
        </div>
    </div>
</div>
