<header class="demo__header visible manager" [class.expanded]="demoInfoExpanded" *ngIf="demo">
    <div class="container">
        <div class="demo__header--header">
            <div class="demo__header--content-description">
                <p>
                    Welcome to the COHO <strong>demo</strong>.
                    <a href="javascript:void(0)" (click)="toggleDemoShowMore(true)" class="demo__header--more" [class.hidden]="demoInfoExpanded">More info</a>
                    <a href="javascript:void(0)" (click)="toggleDemoShowMore(false)" class="demo__header--less" [class.hidden]="!demoInfoExpanded">Less info</a>
                    <!--<a href="javascript:void(0)">Hide me</a>-->
                </p>
            </div>
            <div class="demo__header--content-action-wrapper">
                <div class="demo__header--content-actions">
                    <div [formGroup]="demoForm">
                        <dropdown formControlName="demoUser" [placeholder]="(demoUserOptions.length > 0 ? 'Select user' : 'Loading users')" [options]="demoUserOptions" [cssClasses]="'form__select--small'"></dropdown>
                    </div>
                </div>
                <div class="demo__header--content-actions">
                    <a href="{{liveWebUrl}}register?source=demo" class="button button--ghost button--small">Register for real</a>
                </div>
            </div>
        </div>
        <div class="demo__header--content" [class.visible]="demoInfoExpanded">
            <div class="demo__header--content-description">
                <p class="demo-manager" *ngIf="currentUser?.isManager">You are logged in to your own demo <strong>manager</strong> account, with properties, rooms and housemates we have provided. You can add, change or remove anything. You can return to this demo account at any time until deletion using the link and credentials we sent you by email.</p>
                <p class="demo-manager" *ngIf="currentUser?.isTenant">You are logged in to your own demo <strong>housemate</strong> account, in a tenancy in one of the demo properties. You can add, change or remove anything. You can return to this demo account at any time until deletion using the link and credentials we sent you by email.</p>
                <p class="demo-manager" *ngIf="!currentUser?.isManager && !currentUser?.isTenant">You are logged in to your own demo <strong>property owner</strong> account, viewing the read only owner dashboard for one of the demo properties. You can return to this demo account at any time until deletion using the link and credentials we sent you by email.</p>
            </div>
        </div>
    </div>
</header>
<div id="firstDemoContainer" [class.expanded]="demoInfoExpanded" *ngIf="demo"></div>
<header class="site__header site__header--fixed"
    [class.site__header--logged-in]="isLoggedIn"
    [class.site__header--logged-out]="!isLoggedIn"
    [class.site__header--slim]="conversationPage"
    [class.site__header--announcements]="showAnnouncements"
    [class.site__header--feedback]="isLoggedIn && currentUser"
    [class.site__header--demo]="demo"
    [class.site__header--demo-expanded]="demo && demoInfoExpanded">
    <div class="container">
        <button class="main__menu-icon toggle__trigger pointer" [class.open]="mainMenuOpen" [hidden]="!currentUser || !currentUser.isManager" (click)="toggleMainMenu()">
            <span class="main__menu-bar">&nbsp;</span>
            <span class="main__menu-bar">&nbsp;</span>
            <span class="main__menu-bar">&nbsp;</span>
            <span class="sr-only">Menu</span>
        </button>

        <div class="hide-for-mobile">
            <a class="button button--small button--icon" id="backButton1" *ngIf="conversationPage" [routerLink]="currentUser.isManager ? ['/manage'] : currentUser.isTenant ? ['/my-home'] : currentUser.isOwner ? ['/owner'] : ['/']">
                <svg-icon role="img" class="icon icon-left-arrow icon--white" name="icon-left-arrow"></svg-icon>
                Dashboard
            </a>
        </div>
        <div class="show-for-mobile">
            <a class="button button--small button--icon button--icon-only show-for-mobile" id="backButton2" *ngIf="conversationPage" [routerLink]="currentUser.isManager ? ['/manage'] : currentUser.isTenant ? ['/my-home'] : currentUser.isOwner ? ['/owner'] : ['/']">
                <svg-icon role="img" class="icon icon-left-arrow icon--white" name="icon-left-arrow"></svg-icon>
            </a>
        </div>


        <a *ngIf="isLoggedIn && currentUser" class="header__logo" [class.menu_hidden]="!currentUser.isManager" href="/">
            <sprite-image reference="coho-logo" defaultImage="https://d19qeljo1i8r7y.cloudfront.net/web-images/logos/logo.png" alt="COHO logo"></sprite-image>
        </a>

        <div class="header__options" *ngIf="isLoggedIn && currentUser">

            <coho-feedback></coho-feedback>

            <div class="dropdown dropdown__announcements" [class.open]="announceMenuOpen" #announceDropdown *ngIf="showAnnouncements">
                <button class="header__options-button dropdown__button" (click)="toggleAnnounceMenu()">
                    <div class="profile__image header__profile-image" (click)="toggleAnnounceMenu()">
                        <svg-icon role="img" class="icon icon-bell icon--white" name="icon-bell"></svg-icon>
                    </div>
                    <div class="badge" *ngIf="articleCount > 0 && articleCount <= 99">{{articleCount}}</div>
                    <div class="badge badge-over-99" *ngIf="articleCount > 99">99<span>+</span></div>
                </button>

                <ul class="dropdown__menu dropdown__menu--right">

                    <li>
                        <span class="dropdown__menu-title">
                            What&lsquo;s new?
                        </span>
                        <ul>
                            <li *ngFor="let article of articles">
                                <div class="dropdown__menu-item">
                                    <a href="javascript:void(0);" class="close" (click)="articleMarkRead(article)">
                                        <svg-icon role="img" class="icon icon-cross" name="icon-cross"></svg-icon>
                                    </a>
                                    <h5>{{article.title}}</h5>
                                    <p [innerHTML]="article.summary"></p>
                                    <a [routerLink]="['/release-notes',articleType,article.reference]" (click)="articleMarkRead(article)">Read more</a>
                                </div>
                            </li>
                            <li>  <div class="dropdown__menu-item">
                                <a [routerLink]="['/release-notes', 'management']">View all</a>
                            </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="dropdown dropdown__messages">
                <a class="header__options-button dropdown__button" [routerLink]="['/messages']" [queryParams]="currentUser.isTenant ? {includeAllMedium: '1', tenancy: tenancyGuid ? tenancyGuid : null} : {}" (click)="getNewUserNotifications()">
                    <div class="profile__image header__profile-image">
                        <svg-icon applycss="true" class="icon icon-conversation icon--white" name="icon-conversation"></svg-icon>
                    </div>
                    <div class="badge" *ngIf="userNotifications && userNotifications.messagesTotal && !userNotifications.myHomeMessagesTotal" [class.no_count]="userNotifications.messagesTotal == -1">
                        <ng-container *ngIf="userNotifications.messagesTotal > 0">{{userNotifications.messagesTotal}}</ng-container>
                        <ng-container *ngIf="userNotifications.messagesTotal == -1">&zwnj;<!-- Uncounted --></ng-container>
                    </div>
                    <div class="badge" *ngIf="userNotifications && userNotifications.myHomeMessagesTotal" [class.no_count]="userNotifications.myHomeMessagesTotal == -1">
                        <ng-container *ngIf="userNotifications.myHomeMessagesTotal > 0">{{userNotifications.myHomeMessagesTotal}}</ng-container>
                        <ng-container *ngIf="userNotifications.myHomeMessagesTotal == -1">&zwnj;&zwnj;<!-- Uncounted --></ng-container>
                    </div>
                </a>
            </div>

            <div class="dropdown" [class.open]="userMenuOpen" #userMenuDropdown>
                <button class="header__options-button dropdown__button" (click)="toggleUserMenu()">
                    <div class="profile__image header__profile-image" (click)="toggleUserMenu()">
                        <img [src]="profileImage" [alt]="profileImageAlt">
                    </div>
<!--                    <div class="badge" *ngIf="userNotifications != null && userNotifications.total > 0 && userNotifications.total <= 99">{{userNotifications.total}}</div>-->
<!--                    <div class="badge badge-over-99" *ngIf="userNotifications != null && userNotifications.total > 99">99<span>+</span></div>-->
                </button>

                <ul class="dropdown__menu dropdown__menu--right" (click)="toggleUserMenu(false)">

                    <li>
                        <span class="dropdown__menu-title dropdown__menu-title--with-details">
                            <svg-icon role="img" class="icon icon-user" name="icon-user"></svg-icon>
                                <div class="dropdown__menu-title--details">
                                    <span>{{currentUser.person.fullname}}</span>
                                    <span class="u-text--tiny">{{currentUser.email}}</span>
                                </div>
                        </span>
                        <ul>
                            <li *ngIf="currentUser?.isManager">
                                <a [routerLink]="['/manage']"  class="dropdown__menu-item" (click)="toggleUserMenu(false)">
                                    <svg-icon role="img" class="icon icon-room icon--" name="icon-room"></svg-icon>
                                    Management Dashboard
                                </a>
                            </li>
                            <li *ngIf="currentUser?.isSupplier">
                                <a [routerLink]="['/supplier']"  class="dropdown__menu-item" (click)="toggleUserMenu(false)">
                                    <svg-icon role="img" class="icon icon-repair icon--" name="icon-repair"></svg-icon>
                                    Supplier Dashboard
                                </a>
                            </li>
                            <li *ngIf="currentUser?.isOwner">
                                <a [routerLink]="['/owner']"  class="dropdown__menu-item" (click)="toggleUserMenu(false)">
                                    <svg-icon role="img" class="icon icon-landlord icon--" name="icon-landlord"></svg-icon>
                                    Owner Dashboard
                                </a>
                            </li>
                            <li *ngIf="currentUser?.isTenant || currentUser?.isLooking || currentUser?.isOnboarding">
                                <a [routerLink]="['/my-home']"  class="dropdown__menu-item" (click)="toggleUserMenu(false)">
                                    <svg-icon role="img" class="icon icon-mixed icon--" name="icon-mixed"></svg-icon>
                                    Tenant Dashboard
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/account']" class="dropdown__menu-item" (click)="toggleUserMenu(false)">
                                    <svg-icon role="img" class="icon icon-settings icon--" name="icon-settings"></svg-icon>
                                    Settings
                                </a>
                            </li>
                            <li *ngIf="!currentUser?.darkMode">
                                <a href="javascript:void(0)" class="dropdown__menu-item" (click)="toggleDarkMode(true)">
                                    <svg-icon role="img" class="icon icon-dark icon--" name="icon-dark"></svg-icon>
                                    Dark Mode
                                </a>
                            </li>
                            <li *ngIf="currentUser?.darkMode">
                                <a href="javascript:void(0)" class="dropdown__menu-item" (click)="toggleDarkMode(false)">
                                    <svg-icon role="img" class="icon icon-light icon--" name="icon-light"></svg-icon>
                                    Light Mode
                                </a>
                            </li>
                            <li *ngIf="currentUser?.isManager && (currentUser?.isTenant || currentUser?.isOnboarding)">
                                <a *ngIf="isManageRoute()" href="javascript:void(0)" [routerLink]="['/my-home']" class="dropdown__menu-item">
                                    <svg-icon role="img" class="icon icon-user" name="icon-user"></svg-icon>
                                    Log as Tenant
                                </a>
                                <a *ngIf="isMyHomeRoute()" href="javascript:void(0)" [routerLink]="['/manage']" class="dropdown__menu-item">
                                    <svg-icon role="img" class="icon icon-user" name="icon-user"></svg-icon>
                                    Log as Manager
                                </a>
                            </li>
                            <li *ngIf="currentUser?.superAdmin || loginStackCanPop" class="internal">
                                <a href="javascript:void(0)" (click)="loginAs()" class="dropdown__menu-item">
                                    <svg-icon role="img" class="icon icon-coho icon--violet" name="icon-coho"></svg-icon>
                                    Super Admin: Log-in As
                                </a>
                            </li>
                            <li *ngIf="loginStackCanPop" class="internal">
                                <a href="javascript:void(0)" (click)="logoutAs()" class="dropdown__menu-item">
                                    <svg-icon role="img" class="icon icon-reload icon--violet" name="icon-reload"></svg-icon>
                                    Super Admin: Return
                                </a>
                            </li>
                            <li *ngIf="currentUser?.superAdmin || loginStackCanPop" class="internal">
                                <a href="javascript:void(0)" (click)="toggleDebug()" class="dropdown__menu-item">
                                    <svg-icon role="img" class="icon icon-viewing icon--violet" name="icon-viewing"></svg-icon>
                                    Super Admin: Debug is {{ debugEnabled ? "ON" : "OFF" }}
                                </a>
                            </li>
                            <li *ngIf="configService.isDev" class="internal">
                                <a href="http://localhost:5050/profiler/results-index" target="_blank" class="dropdown__menu-item">
                                    <svg-icon role="img" class="icon icon-new-tab icon--violet" name="icon-new-tab"></svg-icon>
                                    Mini Profiler
                                </a>
                            </li>
                            <li *ngIf="configService.isDev" class="internal">
                                <a href="http://localhost:4200/playground/form" target="_blank" class="dropdown__menu-item">
                                    <svg-icon role="img" class="icon icon-file icon--violet" name="icon-file"></svg-icon>
                                    Form Playground
                                </a>
                            </li>

                            <li>
                                <a href="javascript:void(0)" (click)="logout()" class="dropdown__menu-item">
                                    <svg-icon role="img" class="icon icon-logout icon--" name="icon-logout"></svg-icon>
                                    Log out
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

        <ul class="main__navigation toggle__target">
            <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" *ngIf="!demo && (currentUser == null || ((currentUser?.isLooking || currentUser?.isViewing) && !currentUser.isManager && !currentUser.isOwner))">
                <a [routerLink]="['/find-a-home']" (click)="closeMainMenu()">Find a Home</a>
            </li>
            <ng-container *ngIf="(currentUser?.isLooking || currentUser?.isViewing) && !demo">
                <li [routerLinkActive]="['active']" *ngFor="let city of mainCities">
                    <a [routerLink]="['/find-a-home', city.reference]" (click)="closeMainMenu()">{{city.label}}</a>
                </li>
            </ng-container>
            <li [routerLinkActive]="['active']" class="manage" *ngIf="!isLoggedIn">
                <a [routerLink]="['/register']" (click)="closeMainMenu()">Register</a>
            </li>
            <li [routerLinkActive]="['active']" class="manage" *ngIf="!isLoggedIn">
                <a [routerLink]="['/login']" (click)="closeMainMenu()">Login</a>
            </li>
        </ul>
    </div>
</header>
