import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, EMPTY} from 'rxjs';

import { ConfigService } from './config.service';
import { DeviceService } from './device.service';
import { AccountService } from "./account.service";
import { catchError } from "rxjs/operators";
import {RedirectionPages} from "../components/error-modal/error-modal.component";

@Injectable()
export class ErrorService {

    hasErrored: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    caughtErrors: string[] = [];
    routingCaughtErrors: { error: HttpErrorResponse, redirected: RedirectionPages }[] = [];

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private location: Location,
        private accountService: AccountService,
        private deviceService: DeviceService) {
    }

    getLastCaughtRoutingError(): { error: HttpErrorResponse, redirected: RedirectionPages } {
        let errorsArray: { error: HttpErrorResponse, redirected: RedirectionPages }[];
        errorsArray = this.routingCaughtErrors;
        return errorsArray.at(-1);
    }

    resetErrorService(): void {
        this.hasErrored.next(false);
    }

    deleteLastCaughtError(type?: 'routing' | 'other') {
        if (type === 'routing' && this.routingCaughtErrors.length > 0) {
            this.routingCaughtErrors.pop();
        } else if (type !== 'routing' && this.caughtErrors.length > 0) {
            this.caughtErrors.pop();
        }
    }

    logRoutingError(error: HttpErrorResponse, redirected: RedirectionPages = null){
        this.routingCaughtErrors.push({error, redirected})
        console.error(error);
        this.hasErrored.next(true);
    }

    logError(error: Error): void {
        if (this.caughtErrors.indexOf(error.message) >= 0) {
            return;
        }

        this.caughtErrors.push(error.message);
        console.error(error);

        const user = this.accountService.getCurrentUser();
        let data: any = {
            message: error.message,
            stackTrace: error.stack
        };

        if (this.location.path()) {
            data.url = this.location.path();
        } else {
            data.url = window.location.href;
        }

        if (user) {
            data.userId = 'me';
        }

        let device = this.deviceService.getDeviceInfo();
        data.browser = device.browser;
        data.device = device.device;
        data.browserVersion = device.browserVersion;
        data.os = device.os;
        data.osVersion = device.osVersion;
        data.ua = device.ua;
        data.userAgent = device.userAgent;

        this.http.post(`${this.configService.baseUrl}/error/web`, data)
            .pipe(
                catchError(_ => {
                    return EMPTY;
                })
            )
            .subscribe();

        this.hasErrored.next(true);
    }
}
