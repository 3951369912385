import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { BoilerService } from '../models/boiler-service/boiler-service';
import { BoilerServicePost } from '../models/boiler-service/boiler-service-post';
import { BoilerServiceOverview } from '../models/boiler-service/boiler-service-overview';
import { FileService } from "./file.service";
import { OwnerBoilerServiceOverview } from '../models/boiler-service/owner-boiler-service-overview';
import {PropertyServicesSummary} from "../models/property-services/property-services-summary.interface";
import {mergeMap, tap} from "rxjs/operators";


@Injectable()
export class BoilerServiceService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string): Observable<BoilerServiceOverview> {
        return this.http.get<BoilerServiceOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/boiler-service/overview`);
    }

    getList(propertyReference: string): Observable<BoilerService[]> {
        return this.http.get<BoilerService[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/boiler-service`);
    }

    getOwnerOverview(propertyReference: string): Observable<OwnerBoilerServiceOverview> {
        return this.http.get<OwnerBoilerServiceOverview>(`${this.configService.baseUrl}/owner/property/${propertyReference}/boiler-service/overview`);
    }

    getOwnerList(propertyReference: string): Observable<BoilerService[]> {
        return this.http.get<BoilerService[]>(`${this.configService.baseUrl}/owner/property/${propertyReference}/boiler-service`);
    }

    add(propertyReference: string, model: BoilerServicePost): Observable<HttpEvent<BoilerService>> {
        return this.fileService.uploadFiles(model.boilerServiceFiles)
            .pipe(tap(_ => model.boilerServiceFiles = this.fileService.prepareFilePosts(model.boilerServiceFiles)),
                mergeMap(_ => this.http.post<BoilerService>(`${this.configService.baseUrl}/manage/property/${propertyReference}/boiler-service`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(propertyReference: string, item: BoilerService): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/boiler-service/${item.guid}`);
    }

    getOwnerBoilerService(propertyReference: string, guid: string): Observable<BoilerService> {
        return this.http.get<BoilerService>(`${this.configService.baseUrl}/owner/property/${propertyReference}/boiler-service/${guid}`);
    }

    getSummary(propertyReference: string) {
        const url = `${this.configService.baseUrl}/manage/property/${propertyReference}/boiler-service/summary`;
        return this.http.get<PropertyServicesSummary>(url);
    }
}
