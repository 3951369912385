import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { FormNavigateAwayConfirmComponent } from "./form-navigate-away-confirm.component";
import { ModalModule } from "../modal/modal.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ModalModule,
    ],
    declarations: [
        FormNavigateAwayConfirmComponent
    ],
    exports: [
        FormNavigateAwayConfirmComponent
    ]
})

export class FormNavigateAwayConfirmModule {
}
