import { Pipe, PipeTransform } from '@angular/core';


export enum CostRender {
    AUTO = 1,
    AUTO_ROUNDED = 2,
    AUTO_FORCE_PERCENTAGE = 4,
    AUTO_FORCE_DECIMALS = 5,
    AUTO_ABSOLUTE = 6,
}

@Pipe({name: 'costStatic', pure: true})
export class HouseShareCostPipe implements PipeTransform {
    constructor() {
    }

    // Inputs to handle
    // organisationAmount: number;
    // organisationAmountTax: number;
    // organisationTotalAmount: number;
    //
    //
    // clientAmount: number;
    // clientAmountTax: number;
    // clientTotalAmount: number;
    //
    // formAmount: number;
    // formAmountTax: number;
    // formTaxPercentage: number;
    // formTotalAmount: number;
    transform(object: {}, prefix: string, render: CostRender = CostRender.AUTO, invertDirection: boolean = false) {
        if (!object) return '';
        if (!prefix) return '';

        // Remove the part of the prefix. So 'clientAmount' becomes 'client' so that we can use that to index all the thigns
        prefix = prefix.substring(0, prefix.length - 6);

        // Grab parts
        let amount = object[prefix + 'Amount'];
        let tax = object[prefix + 'AmountTax'];
        let total = object[prefix + 'TotalAmount'];
        let percentage : number | null = object[prefix + 'TaxPercentage'];

        // Null values in amount means nothing is set up
        if (amount === null || amount === undefined) return '';

        // Amount is null, so like other things cannot have values
        if (amount === 0) {
            if (tax) return "[Amount is 0 but tax amount set!]";
            if (total) return "[Amount is 0 but total amount set!]";
        }

        // Guess missing values
        if (amount && total && !tax) tax = total - amount;
        if (amount && tax && !total) total = amount + tax;

        let showPercentage = (percentage && render == CostRender.AUTO) || render == CostRender.AUTO_FORCE_PERCENTAGE;
        let forceAbsolute = render == CostRender.AUTO_ABSOLUTE;
        let round = render == CostRender.AUTO_ROUNDED;

        // Guess percentage if not supplied
        if (!percentage && !tax) percentage = 0;
        else if (!percentage && tax && amount) percentage = Math.round((tax / amount) * 100) / 100;
        else percentage = null;

        // Apply absolute on all fields
        if (forceAbsolute && amount < 0) {
            amount = -amount;
            tax = -tax;
            total = -total;
            if (percentage) percentage = -percentage;
        }

        // Now invert (after absolute is applied, if requested)
        if (invertDirection) {
            amount = -amount;
            tax = -tax;
            total = -total;
        }

        if (round) {
            amount = Math.round(amount);
            tax = Math.round(tax);
            total = Math.round(total);
        }


        // Determine if any items have decimals
        const showDecimalPoints =
            render == CostRender.AUTO_FORCE_DECIMALS
            || ((render == CostRender.AUTO || CostRender.AUTO_ABSOLUTE) && (Number(tax) % 1 !== 0 || Number(total) % 1 !== 0 || Number(amount) % 1 !== 0));

        let amountValueString = Number(amount).toFixed(showDecimalPoints ? 2 : 0);
        let amountCurrencyString = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: (+amountValueString % 1 != 0 ? 2 : 0), maximumFractionDigits: (showDecimalPoints ? 2 : 0) }).format(+amountValueString);
        if (showDecimalPoints && !amountCurrencyString.includes('.')) amountCurrencyString = amountCurrencyString + ".00";

        let taxValueString = Number(tax).toFixed(showDecimalPoints ? 2 : 0);
        let taxCurrencyString = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: (+taxValueString % 1 != 0 ? 2 : 0), maximumFractionDigits: (showDecimalPoints ? 2 : 0) }).format(+taxValueString);
        if (showDecimalPoints && !taxCurrencyString.includes('.')) taxCurrencyString = taxCurrencyString + ".00";

        let totalValueString = Number(total).toFixed(showDecimalPoints ? 2 : 0);
        let totalCurrencyString = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: (+totalValueString % 1 != 0 ? 2 : 0), maximumFractionDigits: (showDecimalPoints ? 2 : 0) }).format(+totalValueString);
        if (showDecimalPoints && !totalCurrencyString.includes('.')) totalCurrencyString = totalCurrencyString + ".00";

        let fullString = amountCurrencyString;
        if (!tax) return fullString + ' (no VAT)';

        fullString += " + VAT";
        if (showPercentage) fullString += " (" + totalCurrencyString + " + " + Number(percentage).toFixed(0) + " VAT)";
        else fullString += " (" + totalCurrencyString + ")";

        return fullString;

    }
}
