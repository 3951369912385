import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import moment from 'moment';

import {ConfigService} from './config.service';
import {FinanceOverview} from '../models/finance/finance-overview';
import {FinancialItem} from '../models/finance/financial-item';
import {FinancialItemPost} from '../models/finance/financial-item-post';
import {FinancialItemCategory} from '../models/finance/financial-item-category';
import {ProfitabilityReportPost} from '../models/report/profitability-report/profitability-report-post';
import {SettlementPost} from '../models/finance/settlement-post';
import {Settlement} from '../models/finance/settlement';
import {PropertyOwnerSettlementsSummary} from '../models/finance/property-owner-settlements-summary';
import {SettlementsSummary} from '../models/finance/settlements-summary';
import {Guid} from '../models/guid/guid';
import {FinancialItemPatch} from '../models/finance/financial-item-patch';
import {SettlementParty} from '../models/finance/settlement-party';
import {ProfitabilityReport} from '../models/report/profitability-report/profitability-report';
import {FinancialGraphData} from '../models/finance/financial-graph-data';
import {SettlementPartyReport} from '../models/finance/settlement-party-report';
import {FileService} from './file.service';
import {OwnerFinanceOverview} from '../models/finance/owner-finance-overview';
import {OwnerSettlement} from '../models/finance/owner-settlement';
import {AccountService} from './account.service';
import {FinancialItemCSVExportPost} from '../models/finance/financial-item-csv-export-post';
import {FinancialItemXeroMappingCategoryPost} from "../models/finance/financial-item-xero-mapping-category-post";
import {FinancialItemXeroMappingTaxPost} from "../models/finance/financial-item-xero-mapping-tax-post";
import {FinancialItemXeroMappingGet} from "../models/finance/financial-item-xero-mapping-get";
import {TransactionItem} from '../models/finance/transaction-item';
import {TransactionItemPost} from '../models/finance/transaction-item-post';
import {FinancialItemGroup} from "../models/finance/financial-item-group";
import {FinancialItemRepeatingPost} from '../models/finance/financial-item-repeating-post';
import {FinancialItemRepeating} from '../models/finance/financial-item-repeating';
import {PaginatedList} from "../models/paginated-list";
import {FinancialItemConstructResponse} from "../models/finance/financial-item-construct-response";
import {FinancialItemRepeatingRunResult} from "../models/finance/financial-item-repeating-run-result";
import {SettlementPartyReportV2} from "../models/finance/settlement-party-reportv2";
import {BaseEntityStatuses} from "../models/base-entity-statuses";
import {TenancySummary} from '../models/tenancy/tenancy-summary';
import {PropertySummary} from "../models/manage-property/property-summary";
import {RoomSummary} from "../models/manage-room/room-summary";
import {PropertyDetails} from "../models/manage-property/property-details";
import {BaseEntityTypes} from "../models/base-entity-types";
import {XeroSuccessErrorStrings} from "../models/xero/xero-success-error-strings";
import {SelectablePartial} from '../models/selectable-partial';
import { XeroSettingsPageOverview } from '../models/xero/xero-settings-page-overview';
import {XeroChartMapFromConnectionAccountTupleUpsert, XeroChartMapFromFinancialItemCategoryTupleUpsert, XeroChartMapFromMoneyTypeTupleUpsert, XeroChartMapFromPaymentMethodTupleUpsert } from '../models/xero/xero-chart-map-upsert';
import { XeroOptionsMapUpsert } from '../models/xero/xero-options-map-upsert';
import { XeroContactMapUpsert } from '../models/xero/xero-contact-map-upsert';
import { XeroSynchronisationSessionTableView } from '../models/xero/xero-synchronisation-session-table-view';
import {OrganisationPropertyXeroSettings, OrganisationPropertyXeroSettingsPatch, OrganisationPropertyXeroSettingsPost } from '../models/xero/organisation-property-xero-settings';
import { XeroTaxMapUpsert } from '../models/xero/xero-tax-map-upsert';
import {SettlementPatch} from "../models/finance/settlement-patch";
import {mergeMap, tap} from "rxjs/operators";
import {File as HouseShareFile} from "../models/file/file";
import {FilePost} from "../models/file/file-post";


interface xeroAPI_SynchroniseOptions {commit: boolean, verbose?: boolean, forceUpdate?: boolean, incrementalUpdate?: boolean, createActions?: boolean, action: string}

@Injectable()
export class FinanceService {

    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService, private accountService: AccountService) {
    }

    getOrganisationOverview(organisationReference: string): Observable<FinanceOverview> {
        return this.http.get<FinanceOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/overview`);
    }

    getPropertyOverview(organisationReference: string, propertyReference: string): Observable<FinanceOverview> {
        return this.http.get<FinanceOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/finance/overview`);
    }

    getOwnerOverview(organisationReference: string): Observable<OwnerFinanceOverview> {
        return this.http.get<OwnerFinanceOverview>(`${this.configService.baseUrl}/owner/finance/overview`);
    }

    getGraphData(organisationReference: string, propertyReference: string[], ownerReference: string[]): Observable<FinancialGraphData> {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;

        url += '/finance/graph-data';

        let params = new HttpParams();
        if (propertyReference?.length > 0) {
            propertyReference.forEach(pr => {
                params = params.append('propertyReference', pr)
            });
        }

        if (ownerReference?.length > 0) {
            ownerReference.forEach(pr => {
                params = params.append('ownerReference', pr)
            });
        }

        return this.http.get<FinancialGraphData>(url, {params: params});
    }

    generateSettlementPartyCSV(organisationReference: string, settlementGuid: Guid, settlementPartGuid: Guid, inverse: boolean = false): string {
        let params = new HttpParams();

        params = params.append('access_token', this.accountService.accessToken.bearerToken);
        params = params.append('inverse', inverse ? 'true' : 'false');


        return `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${settlementGuid}/settlement-party/${settlementPartGuid}/csv?${params.toString()}`;
    }

    addTransactionItem(organisationReference: string, model: TransactionItemPost): Observable<TransactionItem> {
        return this.http.post<TransactionItem>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/transaction-item`, model);
    }

    getFinancialItems(organisationReference: string, propertyReferenceOrReferences: string | string[], roomReference: string, tenancyGuid: string = null, rdrGuid: string = null, finItemGuid: string = null, startDate: Date = null, endDate: Date = null,
                      ownerReference: string = null, category: string = null, description: string = null, includeClientMoney: boolean = false, includeTenantMoney: boolean = false, includeRentDueRecords: boolean = false, includeDeleted: boolean = false, includeClientItems: boolean = false): Observable<FinancialItem[]> {
        // From & to dates are mandatory and if not supplied the backend limits it to 1 month. So we have to override that here
        startDate ||= new Date(1985, 1, 1);
        endDate ||= new Date(2100, 1, 1);


        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;
        if (propertyReferenceOrReferences) {
            if (Array.isArray(propertyReferenceOrReferences)) url += `/property/${propertyReferenceOrReferences.join(",")}`;
            else url += `/property/${propertyReferenceOrReferences}`;
        }

        if (roomReference != null) {
            url += `/room/${roomReference}`;
        }

        url += '/finance/financial-item';

        let params = new HttpParams();
        params = params.set('includeOrganisationItems', true);

        if (startDate != null) params = params.set('startDate', moment(startDate).toISOString());
        if (endDate != null) params = params.set('endDate', moment(endDate).toISOString());
        if (ownerReference != null)params = params.set('ownerReference', ownerReference);
        if (tenancyGuid != null) params = params.set('tenancyGuid', tenancyGuid);
        if (rdrGuid != null) params = params.set('rdrGuid', rdrGuid);
        if (finItemGuid != null) params = params.set('financialItemGuid', finItemGuid);
        if (category != null) params = params.set('category', category);
        if (description != null) params = params.set('description', description);
        if (includeClientMoney) params = params.set('includeClientMoney', true);
        if (includeTenantMoney) params = params.set('includeTenantMoney', true);
        if (includeClientItems) params = params.set('includeClientItems', true);
        if (includeRentDueRecords) params = params.set('includeRentDueRecords', true);
        if (includeDeleted) params = params.set('includeDeleted', true);

        return this.http.get<FinancialItem[]>(url, {params});
    }


    getFinancialItemGroups(organisationReference: string, propertyReferenceOrReferences: string | string[], roomReference: string, tenancyGuid: string = null, startDate: Date = null,
                           endDate: Date = null, ownerReference: string = null, category: string = null, description: string = null, includeClientMoney: boolean = false,
                           includeTenantMoney: boolean = false, minAmount: number = null, maxAmount: number = null, hasSupplier: boolean = null, supplierGuid: string = null,
                           unpaid: boolean = null): Observable<FinancialItemGroup[]> {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;

        if (propertyReferenceOrReferences) {
            if (Array.isArray(propertyReferenceOrReferences)) {
                url += `/property/${propertyReferenceOrReferences.join(",")}`;
            } else url += `/property/${propertyReferenceOrReferences}`;
        }

        if (roomReference != null) {
            url += `/room/${roomReference}`;
        }

        url += '/finance/financial-item/group';

        let params = new HttpParams();
        if (startDate != null) {
            params = params.set('startDate', moment(startDate).toISOString());
        }

        if (endDate != null) {
            params = params.set('endDate', moment(endDate).toISOString());
        }

        if (ownerReference != null) {
            params = params.set('ownerReference', ownerReference);
        }

        if (tenancyGuid != null) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        if (category != null) {
            params = params.set('category', category);
        }

        if (description != null) {
            params = params.set('description', description);
        }

        if (includeClientMoney) {
            params = params.set('includeClientMoney', true);
        }

        if (includeTenantMoney) {
            params = params.set('includeTenantMoney', true);
        }

        if (supplierGuid) {
            params = params.set('supplierGuid', supplierGuid);
            params = params.set('hasSupplier', true);
        } else if (hasSupplier == true) {
            params = params.set('hasSupplier', true);
        } else if (hasSupplier === false) {
            params = params.set('hasSupplier', false);
        }

        if (minAmount !== null) {
            params = params.set('minAmount', minAmount);
        }

        if (maxAmount !== null) {
            params = params.set('maxAmount', maxAmount);
        }

        if (unpaid == true) {
            params = params.set('unpaid', true);
        } else if (unpaid == false)
            params = params.set('unpaid', false);

        params = params.set('includeOrganisationItems', true);

        return this.http.get<FinancialItemGroup[]>(url, {params});
    }

    getFinancialItemsCsv(organisationReference: string, propertyReferenceOrReferences: string | string[], roomReference: string, tenancyGuid: string = null, startDate: Date = null, endDate: Date = null,
                         ownerReference: string = null, category: string = null, description: string = null, includeClientMoney: boolean = false, includeTenantMoney: boolean = false, includeRentDueRecords: boolean = false, minAmount: number = null, maxAmount: number = null, hasSupplier: boolean = null, supplierGuid: string = null, unpaid: boolean = null): string {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;

        if (propertyReferenceOrReferences) {
            if (Array.isArray(propertyReferenceOrReferences)) {
                url += `/property/${propertyReferenceOrReferences.join(",")}`;
            } else url += `/property/${propertyReferenceOrReferences}`;
        }

        if (roomReference != null) {
            url += `/room/${roomReference}`;
        }

        url += '/finance/financial-item/csv';

        let params = new HttpParams();
        if (startDate != null) {
            params = params.set('startDate', moment(startDate).toISOString());
        }

        if (endDate != null) {
            params = params.set('endDate', moment(endDate).toISOString());
        }

        if (ownerReference != null) {
            params = params.set('ownerReference', ownerReference);
        }

        if (category != null) {
            params = params.set('category', category);
        }

        if (description != null) {
            params = params.set('description', description);
        }

        if (includeClientMoney) {
            params = params.set('includeClientMoney', true);
        }

        if (includeTenantMoney) {
            params = params.set('includeTenantMoney', true);
        }

        params = params.set('includeOrganisationItems', true);

        if (includeRentDueRecords)
            params = params.set('includeRentDueRecords', true);

        if (supplierGuid) {
            params = params.set('supplierGuid', supplierGuid);
            params = params.set('hasSupplier', true);
        } else if (hasSupplier === true) {
            params = params.set('hasSupplier', true);
        } else if (hasSupplier === false) {
            params = params.set('hasSupplier', false);
        }

        if (minAmount !== null) {
            params = params.set('minAmount', minAmount.toString());
        }

        if (maxAmount !== null) {
            params = params.set('maxAmount', maxAmount.toString());
        }

        if (unpaid === true) {
            params = params.set('unpaid', true);
        } else if (unpaid === false)
            params = params.set('unpaid', false);

        params = params.append('access_token', this.accountService.accessToken.bearerToken);

        return `${url}?${params.toString()}`;
    }

    getFinancialItem(organisationReference: string, guid: string): Observable<FinancialItem> {
        return this.http.get<FinancialItem>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/financial-item/${guid}`);
    }

    addFinancialItem(organisationReference: string, model: FinancialItemPost, commit: boolean = false): Observable<FinancialItemConstructResponse> {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;
        if (model.propertyReference != null) url += `/property/${model.propertyReference}`;
        if (model.roomReference != null) url += `/room/${model.roomReference}`;

        url += '/finance/financial-item/commit/' + (commit ? 'true' : 'false');

        let fileUploaderObs: Observable<(HouseShareFile | FilePost)[]>;
        fileUploaderObs = this.fileService.uploadFiles(model.invoiceFiles)
            .pipe(tap(_ => model.invoiceFiles = this.fileService.prepareFilePosts(model.invoiceFiles)));

        return fileUploaderObs
            .pipe(mergeMap(_ => this.http.post<FinancialItemConstructResponse>(url, model)));
    }

    patchFinancialItem(organisationReference: string, guid: string, model: FinancialItemPatch): Observable<FinancialItem> {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;
        url += `/finance/financial-item/${guid}`;

        return this.fileService.uploadFiles(model.invoiceFiles)
            .pipe(tap(_ => model.invoiceFiles = this.fileService.prepareFilePosts(model.invoiceFiles)),
                mergeMap(_ => this.http.patch<FinancialItem>(url, model)));
    }

    deleteFinancialItem(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<boolean> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;

        if (propertyReference != null) {
            url += `/property/${propertyReference}`;
        }

        if (roomReference != null) {
            url += `/room/${roomReference}`;
        }

        url += `/finance/financial-item/${guid}`;

        return this.http.delete<boolean>(url);
    }

    setPaidFinancialItem(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<FinancialItem[]> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;

        if (propertyReference != null) {
            url += `/property/${propertyReference}`;
        }

        if (roomReference != null) {
            url += `/room/${roomReference}`;
        }

        url += `/finance/financial-item/${guid}/set-paid`;

        return this.http.post<FinancialItem[]>(url, null);
    }


    setAllPaidFinancialItem(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<FinancialItem[]> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;

        if (propertyReference != null) {
            url += `/property/${propertyReference}`;
        }

        if (roomReference != null) {
            url += `/room/${roomReference}`;
        }

        url += `/finance/financial-item/${guid}/set-all-in-split-group-paid/true`;

        return this.http.post<FinancialItem[]>(url, null);
    }
    setUnpaidFinancialItem(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<FinancialItem[]> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;

        if (propertyReference != null) {
            url += `/property/${propertyReference}`;
        }

        if (roomReference != null) {
            url += `/room/${roomReference}`;
        }

        url += `/finance/financial-item/${guid}/set-unpaid`;

        return this.http.post<FinancialItem[]>(url, null);
    }

    setAllUnpaidFinancialItem(organisationReference: string, propertyReference: string, roomReference: string, guid: string): Observable<FinancialItem[]> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;

        if (propertyReference != null) {
            url += `/property/${propertyReference}`;
        }

        if (roomReference != null) {
            url += `/room/${roomReference}`;
        }

        url += `/finance/financial-item/${guid}/set-all-in-split-group-unpaid/true`;

        return this.http.post<FinancialItem[]>(url, null);
    }

    getFinancialItemRepeatings(organisationReference: string): Observable<PaginatedList<FinancialItemRepeating>> {
        return this.http.get<PaginatedList<FinancialItemRepeating>>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/financial-item-repeating/list/1/1000`);
    }

    addFinancialItemRepeating(organisationReference: string, model: FinancialItemRepeatingPost, commit: boolean = false): Observable<FinancialItemConstructResponse> {
        return this.http.post<FinancialItemConstructResponse>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/financial-item-repeating/add/commit/` + (commit ? 'true' : 'false'), model);
    }

    patchFinancialItemRepeating(organisationReference: string, financialItemRepeatingGuid: string, model: FinancialItemRepeatingPost, commit: boolean = false): Observable<FinancialItemConstructResponse> {
        return this.http.patch<FinancialItemConstructResponse>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/financial-item-repeating/${financialItemRepeatingGuid}/patch/commit/` + (commit ? 'true' : 'false'), model);
    }

    deleteFinancialItemRepeating(organisationReference: string, financialItemRepeatingGuid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/financial-item-repeating/${financialItemRepeatingGuid}/delete`);
    }

    stopFinancialItemRepeating(organisationReference: string, financialItemRepeatingGuid: string): Observable<FinancialItemRepeating> {
        return this.http.patch<FinancialItemRepeating>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/financial-item-repeating/${financialItemRepeatingGuid}/stop`, {commit: true});
    }

    runFinancialItemRepeating(organisationReference: string, financialItemRepeatingGuid: string): Observable<FinancialItemRepeatingRunResult> {
        return this.http.post<FinancialItemRepeatingRunResult>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/financial-item-repeating/${financialItemRepeatingGuid}/run/commit/true`, {});
    }

    runDueFinancialItemRepeatings(organisationReference: string) {
        return this.http.post<{
            message: string
        }>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/financial-item-repeating/run-due`, {});
    }

    previewFinancialItemRepeating(organisationReference: string, financialItemRepeatingGuid: string): Observable<FinancialItemConstructResponse> {
        return this.http.get<FinancialItemConstructResponse>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/financial-item-repeating/${financialItemRepeatingGuid}/preview`);
    }


    addProfitabilityReport(organisationReference: string, model: ProfitabilityReportPost): Observable<ProfitabilityReport> {
        return this.http.post<ProfitabilityReport>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/profitability-report`, model);
    }

    getCategories(organisationReference: string): Observable<FinancialItemCategory[]> {
        return this.http.get<FinancialItemCategory[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/categories`);
    }

    addSettlement(organisationReference: string, model: SettlementPost): Observable<Settlement> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements`;

        return this.http.post<Settlement>(url, model);
    }

    editSettlement(organisationReference: string, guid: Guid, model: SettlementPatch): Observable<Settlement> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${guid}`;

        return this.http.patch<Settlement>(url, model);
    }

    saveSettlement(organisationReference: string, guid: Guid): Observable<Settlement> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${guid}/save`;

        return this.http.post<Settlement>(url, null);
    }

    publishSettlement(organisationReference: string, guid: Guid): Observable<Settlement> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${guid}/publish`;

        return this.http.post<Settlement>(url, null);
    }

    deleteSettlement(organisationReference: string, guid: Guid): Observable<boolean> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${guid}/delete`;

        return this.http.delete<boolean>(url);
    }

    setSettlementPartyPaid(organisationReference: string, settlementGuid: Guid, settlementPartyGuid: Guid): Observable<SettlementParty> {

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${settlementGuid}/settlement-party/${settlementPartyGuid}/set-paid`;

        return this.http.post<SettlementParty>(url, null);
    }

    getSettlementPartyReport(organisationReference: string, settlmentGuid: Guid, settlementPartyGuid: Guid): Observable<SettlementPartyReport> {
        return this.http.get<SettlementPartyReport>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${settlmentGuid}/settlement-party/${settlementPartyGuid}/view`);
    }

    getSettlementPartyReportV2(organisationReference: string, settlmentGuid: Guid, settlementPartyGuid: Guid): Observable<SettlementPartyReportV2> {
        return this.http.get<SettlementPartyReportV2>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${settlmentGuid}/settlement-party/${settlementPartyGuid}/viewv2`);
    }

    getPropertyOwnerSettlementSummaries(organisationReference: string, loadAmounts: boolean): Observable<SettlementsSummary> {

        let params = new HttpParams();
        params = params.set('includeAmounts', loadAmounts ? 'true' : 'false');


        return this.http.get<SettlementsSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/summary`, {params});
    }

    getPropertyOwnerSettlementsSummary(organisationReference: string, propertyOwnerReference: string, propertyReference: string = null, tenancyGuid: string | null = null, dateFrom: Date = null, dateTo: Date = null) {

        let params = new HttpParams();
        if (dateFrom) params = params.set('dateFrom', moment(dateFrom).utc().toISOString());
        if (dateTo) params = params.set('dateTo', moment(dateTo).utc().toISOString());
        if (propertyReference) params = params.set('propertyReference', propertyReference);
        if (tenancyGuid) params = params.set('tenancyGuid', tenancyGuid);

        return this.http.get<PropertyOwnerSettlementsSummary>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/owner/${propertyOwnerReference}/summary`, {params});
    }


    getPropertyOwnerSettlementSelectables(organisationReference: string, guid: string, dateFrom: Date = null, dateTo: Date = null, loadDeleted: boolean = false) {
        let params = new HttpParams();
        if (dateFrom) params = params.set('dateFrom', moment(dateFrom).utc().toISOString());
        if (dateTo) params = params.set('dateTo', moment(dateTo).utc().toISOString());
        params = params.set('loadDeleted', loadDeleted ? 'true' : 'false');

        return this.http.get<SelectablePartial[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/get-selectables-for-owner/${guid}`, {params});
    }



    //getOwnerSettlementsSummary(organisationReference: string, propertyOwnerReference: string): Observable<PropertyOwnerSettlementsSummary> {
    //    return this.http.get<PropertyOwnerSettlementsSummary>(`${this.configService.baseUrl}/owner/finance/settlements`);
    //}

    getUnsettledFinancialItems(organisationReference: string, propertyOwnerReference: string, propertyReference, startDate: Date, endDate: Date, includePast = true, includeFuture = true): Observable<FinancialItem[]> {
        let params = new HttpParams();

        if (startDate != null) {
            params = params.set('startDate', moment(startDate).utc().toISOString());
        }

        if (endDate != null) {
            params = params.set('endDate', moment(endDate).utc().toISOString());
        }

        if (includePast) {
            params = params.set('includePast', true);
        } else {
            params = params.set('includePast', false);
        }

        if (includeFuture) {
            params = params.set('includeFuture', true);
        } else {
            params = params.set('includeFuture', false);
        }

        if (propertyReference) {
            params = params.set('propertyReference', propertyReference);
        }

        params = params.set('propertyOwnerReference', propertyOwnerReference);

        return this.http.get<FinancialItem[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/unsettled-financial-items`, {params});
    }

    getProfitabilityReports(organisationReference: string): Observable<ProfitabilityReport[]> {
        return this.http.get<ProfitabilityReport[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/profitability-report`);
    }

    deleteProfitabilityReport(organisationReference: string, reference: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/profitability-report/${reference}`);
    }

    getSettlementPartySummary(organisationReference: string, settlementGuid: Guid, settlementPartyGuid: Guid, limitTenancyGuid: string | null = null): Observable<SettlementParty> {
        let params = new HttpParams();
        if (limitTenancyGuid) params = params.append('limitTenancyGuid', limitTenancyGuid);

        return this.http.get<SettlementParty>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${settlementGuid}/settlement-party/${settlementPartyGuid}/summary`, {params});
    }

    getOwnerSettlementSummary(settlementPartyGuid: Guid): Observable<OwnerSettlement> {
        return this.http.get<OwnerSettlement>(`${this.configService.baseUrl}/owner/finance/settlements/${settlementPartyGuid}`);
    }

    getSettlementSuppliersSummary(organisationReference: string, settlementGuid: Guid, limitTenancyGuid: string | null = null): Observable<SettlementParty[]> {
        let params = new HttpParams();
        if (limitTenancyGuid) params = params.append('limitTenancyGuid', limitTenancyGuid);

        return this.http.get<SettlementParty[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${settlementGuid}/suppliers/summary`, {params});
    }

    getOwnerSettlement(guid: string): Observable<OwnerSettlement> {
        return this.http.get<OwnerSettlement>(`${this.configService.baseUrl}/owner/settlements/${guid}`);
    }


    //// SECTION -> Move to XeroService object

    setXeroMappingCategories(organisationReference: string, mappings: FinancialItemXeroMappingCategoryPost[]): Observable<boolean> {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/set-xero-mapping-categories`;
        return this.http.post<boolean>(url, mappings);
    }

    setXeroMappingTaxes(organisationReference: string, mappings: FinancialItemXeroMappingTaxPost[]): Observable<boolean> {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/set-xero-mapping-taxes`;
        return this.http.post<boolean>(url, mappings);
    }

    setXeroMappingProperties(organisationReference: string, mappings: {}): Observable<boolean> {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/set-xero-mapping-properties`;
        return this.http.post<boolean>(url, mappings);
    }

    getXeroMappingByProperties(organisationReference: string): Observable<FinancialItemXeroMappingGet> {
        let params = new HttpParams().appendAll({
            fromType: 5, // FinancialXeroTranslationFromTypes.COHO_PROPERTY_ID
            toType: 501, // FinancialXeroTranslationToTypes.XERO_TRACKING_OPTION1
        });

        return this.http.get<FinancialItemXeroMappingGet>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/get-xero-mapping-by-type`, {params});
    }

    getXeroMappingByCategories(organisationReference: string): Observable<FinancialItemXeroMappingGet> {
        let params = new HttpParams().appendAll({
            fromType: 2, // FinancialXeroTranslationFromTypes.COHO_FINANCIAL_CATEGORY_GUID_PLUS_BELONGS
            toType: 201, // FinancialXeroTranslationToTypes.XERO_CHART_OF_ACCOUNT
        });

        return this.http.get<FinancialItemXeroMappingGet>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/get-xero-mapping-by-type`, {params});
    }

    getXeroMappingByTaxes(organisationReference: string): Observable<FinancialItemXeroMappingGet> {
        let params = new HttpParams().appendAll({
            fromType: 4,  // FinancialXeroTranslationFromTypes.COHO_TAX_RATE_PLUS_BELONGS
            toType: 401,  // FinancialXeroTranslationToTypes.XERO_TAX_TYPE
        });

        return this.http.get<FinancialItemXeroMappingGet>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/get-xero-mapping-by-type`, {params});
    }

    getOrganisationPropertyXeroSettingsOverview(organisationReference: string, guid: string) {
        return this.http.get<XeroSettingsPageOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/get-organisation-property-xero-settings/${guid}/overview`);
    }

    getOrganisationPropertyXeroSettings(organisationReference: string, showOnlyArchived: boolean = false) {
        let params = new HttpParams();
        params = params.set('showOnlyArchived', showOnlyArchived ? "true" : "false");
        return this.http.get<OrganisationPropertyXeroSettings[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/get-organisation-property-xero-settings`, { params: params });
    }

    getSpecificOrganisationPropertyXeroSettings(organisationReference: string, settingsGuid : string) {
        return this.http.get<OrganisationPropertyXeroSettings>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/get-organisation-property-xero-settings/${settingsGuid}`);
    }

    setSpecificOrganisationPropertyXeroSettingsTenant(organisationReference: string, settingsGuid : string, xeroTenantId: string) {
        return this.http.put<void>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/get-organisation-property-xero-settings/${settingsGuid}/set-xero-tenant/${xeroTenantId}`, {});
    }

    addOrganisationPropertyXeroSettings(organisationReference: string, model: OrganisationPropertyXeroSettingsPost) {
        return this.http.post<OrganisationPropertyXeroSettings>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings`, model);
    }

    editOrganisationPropertyXeroSettings(organisationReference: string, model: OrganisationPropertyXeroSettingsPatch) {
        return this.http.patch<OrganisationPropertyXeroSettings>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${ model.guid }`, model);
    }

    deleteOrganisationPropertyXeroSettings(organisationReference: string, guid: string) {
        return this.http.post<true>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/set-entity-status/${BaseEntityStatuses.DELETED}`, {});
    }

    archiveOrganisationPropertyXeroSettings(organisationReference: string, guid: string) {
        return this.http.post<true>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/set-entity-status/${BaseEntityStatuses.ACTIVE_AND_ARCHIVED}`, {});
    }

    unarchiveOrganisationPropertyXeroSettings(organisationReference: string, guid: string) {
        return this.http.post<true>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/set-entity-status/${BaseEntityStatuses.ACTIVE_NOT_ARCHIVED}`, {});
    }


    getXeroLoginLinkUrl(organisationReference: string, guid: string) {
        return this.http.post<{loginUrl: string;}>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/authorise-and-link-account`, {});
    }

    getXeroReconnectLinkUrl(organisationReference: string, guid: string) {
        return this.http.post<{loginUrl: string;}>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/authorise-and-link-account`, {});
    }

    xeroAPI_refreshSelectablesData(organisationReference: string, guid: string) {
        return this.http.post<XeroSuccessErrorStrings>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/refresh-selectables-data`, {});
    }

    xeroAPI_matchContacts(organisationReference: string, guid: string) {
        return this.http.post<XeroSuccessErrorStrings>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/match-contacts`, {});
    }

    xeroAPI_synchroniseTestConnection(organisationReference: string, guid: string, options: xeroAPI_SynchroniseOptions) {
        return this.xeroAPI_synchroniseCommand(organisationReference, guid, BaseEntityTypes.ORGANISATION_PROPERTY_XERO_SETTINGS, guid, options);
    }

    xeroAPI_synchroniseTenancy(organisationReference: string, guid: string, tenancyGuid: string, options: xeroAPI_SynchroniseOptions)  {
        return this.xeroAPI_synchroniseCommand(organisationReference, guid, BaseEntityTypes.TENANCY, tenancyGuid, options);
    }

    xeroAPI_synchroniseSupplier(organisationReference: string, guid: string, supplierGuid: string, options: xeroAPI_SynchroniseOptions)  {
        return this.xeroAPI_synchroniseCommand(organisationReference, guid, BaseEntityTypes.ORGANISATION_SUPPLIER, supplierGuid, options);
    }

    xeroAPI_synchroniseSettlement(organisationReference: string, guid: string, settlementGuid: string, options: xeroAPI_SynchroniseOptions)  {
        return this.xeroAPI_synchroniseCommand(organisationReference, guid, BaseEntityTypes.FINANCIAL_ITEM_SETTLEMENT, settlementGuid, options);
    }

    xeroAPI_synchronisePropertyOwner(organisationReference: string, guid: string, ownerGuid: string, options: xeroAPI_SynchroniseOptions)  {
        return this.xeroAPI_synchroniseCommand(organisationReference, guid, BaseEntityTypes.PROPERTY_OWNER, ownerGuid, options);
    }


    xeroAPI_synchroniseProperty(organisationReference: string, guid: string, propertyReference: string, options: xeroAPI_SynchroniseOptions)  {
        return this.xeroAPI_synchroniseCommand(organisationReference, guid, BaseEntityTypes.PROPERTY, propertyReference, options);
    }

    xeroAPI_synchroniseOrganisation(organisationReference: string, guid: string, options: xeroAPI_SynchroniseOptions)  {
        return this.xeroAPI_synchroniseCommand(organisationReference, guid, BaseEntityTypes.ORGANISATION, organisationReference, options);
    }
    xeroAPI_getSynchronisationSessionLogs(organisationReference: string, guid: string, sessionGuid: string){
        return this.http.get<{results: string}>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/synchronisation-sessions/${sessionGuid}/log-lines`);
    }

    xeroAPI_requestSynchronisationSessionTermination(organisationReference: string, guid: string, sessionGuid: string) {
        return this.http.post<{results: string}>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/synchronisation-sessions/${sessionGuid}/request-termination`, {});
    }

    xeroAPI_setSynchronisationSessionAborted(organisationReference: string, guid: string, sessionGuid: string) {
        return this.http.post<{results: string}>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/synchronisation-sessions/${sessionGuid}/set-aborted`, {});
    }

    xeroAPI_requestActiveSynchronisationSessionTermination(organisationReference: string, guid: string){
        return this.http.post<{results: string}>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/synchronisation/request-termination`, {});
    }
    xeroAPI_requestActiveSynchronisationOverview(organisationReference: string, guid: string) {
        return this.http.get<{model: XeroSynchronisationSessionTableView | null}>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/synchronisation/active-session-overview`, {});
    }



    xeroAPI_synchroniseCommand(organisationReference: string, guid: string, entityType: BaseEntityTypes, referenceOrGuid: string, options: xeroAPI_SynchroniseOptions) : Observable<{results: string}> {
        let params = new HttpParams();
        params = params.append('entityType', entityType);
        params = params.append('referenceOrGuid', referenceOrGuid);
        if (options.createActions) params = params.append('createActions', "true");
        if (options.verbose) params = params.append('verbose', "true");
        if (options.incrementalUpdate) params = params.append('incrementalUpdate', "true");
        if (options.forceUpdate) params = params.append('forceUpdate', "true");
        if (options.action) params = params.append('action', options.action);

        return this.http.post<{results: string}>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/synchronise/commit/${options.commit}?${params.toString()}`, {});
    }

    xeroAPI_getMappingsAndSelectablesForContacts(organisationReference: string, guid: string) {
        return this.http.get<{
            mappingForOrganisation: string,
            mappingsForTenancies: {[p: string]: string },
            mappingsForSuppliers: {[p: string]: string },
            mappingsForOwners: {[p: string]: string },
            properties: PropertyDetails[],
            tenanciesByProperty: {[p: string]: SelectablePartial[]},
            contacts: SelectablePartial[],
            suppliers: SelectablePartial[],
            owners: SelectablePartial[],
        }>( `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/get-mappings-and-selectables-for-contacts`);
    }

    xeroAPI_getMappingsAndSelectablesForTaxRates(organisationReference: string, guid: string) {
        return this.http.get<{
            taxStrings: string[],
            mappingsForTaxes: {[p: string]: string },
            taxRates: SelectablePartial[],
        }>( `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/get-mappings-and-selectables-for-taxes`);
    }

    xeroAPI_getMappingsAndSelectablesForCharts(organisationReference: string, guid: string) {
        return this.http.get<{
            mappingsForMoneyTypeTuple: {[p:string]: {[p:string]: string}},
            mappingsForFinancialItemCategoriesTuple: {[p:string]: {[p:string]: string}},
            mappingsForPaymentMethodTuple: {[p:string]: {[p:string]: string}},
            mappingsForConnectionAccountTuple: string, /*{[p:string]: {[p:string]: string}}*/
            charts: SelectablePartial[],
            paymentCharts: SelectablePartial[],
            connectionAccounts: SelectablePartial[],
        }>( `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/get-mappings-and-selectables-for-charts`);
    }

    xeroAPI_getSynchronisationSessions(organisationReference: string, guid: string, page : number = 1, recordsPerPage : number = null) {
        let params = new HttpParams();
        if (recordsPerPage) params = params.append('recordsPerPage', recordsPerPage);

        return this.http.get<PaginatedList<XeroSynchronisationSessionTableView>>( `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/synchronisation-sessions/page-${page}?${params.toString()}`);
    }

    xeroAPI_getMappingsAndSelectablesForOptions(organisationReference: string, guid: string) {
        return this.http.get<{
            mappingsForPropertyOwners: {[p: string]: string[] },
            mappingsForProperties: {[p: string]: string[] },
            mappingsForRooms: {[p: string]: string[] },

            properties: PropertySummary[],
            roomsByProperty: {[p: string]: RoomSummary[] },

            trackingOptions: SelectablePartial[],
        }>( `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/get-mappings-and-selectables-for-options`);
    }

    xeroAPI_getPropertyOwnersAsSelectables(organisationReference: string, guid: string) {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/get-property-owners-as-selectable`;
        return this.http.get<SelectablePartial[]>(url);
    }

    xeroAPI_setContactMapping(organisationReference: string, guid: string, model: XeroContactMapUpsert){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/set-contact-mapping`
        return this.http.patch<boolean>(url, model);
    }

    xeroAPI_setOptionMapping(organisationReference: string, guid: string, model: XeroOptionsMapUpsert){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/set-options-mapping`
        return this.http.patch<boolean>(url, model);
    }

    xeroAPI_setTaxMapping(organisationReference: string, guid: string, model: XeroTaxMapUpsert){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/set-tax-mapping`
        return this.http.patch<boolean>(url, model);
    }

    xeroAPI_setChartMappingFromMoneyTypeTuple(organisationReference: string, guid: string, model: XeroChartMapFromMoneyTypeTupleUpsert){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/set-chart-mapping-from-money-type-tuple`
        return this.http.patch<boolean>(url, model);
    }

    xeroAPI_setChartMappingFromFinancialItemCategoryTuple(organisationReference: string, guid: string, model: XeroChartMapFromFinancialItemCategoryTupleUpsert){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/set-chart-mapping-from-financial-item-category-tuple`
        return this.http.patch<boolean>(url, model);
    }

    xeroAPI_setChartMappingFromPaymentMethodTuple(organisationReference: string, guid: string, model: XeroChartMapFromPaymentMethodTupleUpsert){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/set-chart-mapping-from-payment-method-tuple`
        return this.http.patch<boolean>(url, model);
    }

    xeroAPI_setChartMappingFromConnectionAccountTuple(organisationReference: string, guid: string, model: XeroChartMapFromConnectionAccountTupleUpsert){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/xero/organisation-property-xero-settings/${guid}/set-chart-mapping-from-connection-account-tuple`
        return this.http.patch<boolean>(url, model);
    }


    //// SECTION -> Move to XeroService object

    financialItemsCSVExport(organisationReference: string, model: FinancialItemCSVExportPost): string {
        let params = new HttpParams();

        // Mandatory parameters with safe guarding exceptions
        if (!model.startDate) throw new Error("startDate not supplied but is a mandatory parameter - financialItemsCSVExport");
        if (!model.endDate) throw new Error("endDate not supplied but is a mandatory parameter - financialItemsCSVExport");
        if (!organisationReference) throw new Error("organisationReference not supplied but is a mandatory parameter - financialItemsCSVExport");

        // Optional parameters
        if (model.propertyReferences && Array.isArray(model.propertyReferences) && model.propertyReferences.length > 0) {
            params = params.append('propertyReferences', model.propertyReferences.join(","));
        } else params = params.append('propertyReferences', '');

        if (model.categoryReferences && Array.isArray(model.categoryReferences) && model.categoryReferences.length > 0) {
            params = params.append('categoryReferences', model.categoryReferences.join(","));
        } else params = params.append('categoryReferences', '');

        params = params.append('startDate', moment(model.startDate).utc().toISOString());
        params = params.append('endDate', moment(model.endDate).utc().toISOString());
        params = params.append('asJson', model.asJson ? "true" : "false");
        params = params.append('skipPrevious', model.skipPrevious ? "true" : "false");
        params = params.append('includePrepayments', model.includePrepayments ? "true" : "false");
        params = params.append('propertyTrackingType', model.propertyTrackingType);
        params = params.append('roomTrackingType', model.roomTrackingType);
        params = params.append('fieldsType', model.fieldsType);
        params = params.append('documentType', model.documentType);
        params = params.append('clientMoneyType', model.clientMoneyType);


        // Attach JWT Access Token
        params = params.append('access_token', this.accountService.accessToken.bearerToken);

        return `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/csv?${params.toString()}`;
    }

    generatePartyReport(organisationReference: string, settlementGuid: Guid, settlementPartyGuid: Guid) {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${settlementGuid}/settlement-party/${settlementPartyGuid}/create-and-attach-report`;
        return this.http.get<SettlementParty>(url);
    }

    sendPartyReport(organisationReference: string, settlementGuid: Guid, settlementPartyGuid: Guid) {
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/finance/settlements/${settlementGuid}/settlement-party/${settlementPartyGuid}/send-report`;
        return this.http.post<SettlementParty>(url, null);
    }
}
