import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { AccountService } from './account.service';
import {TenancyRecurringTransactionSummary} from "../models/tenancy/tenancy-recurring-transaction-summary";
import {TenancyRecurringTransactionOverview} from "../models/tenancy/tenancy-recurring-transaction-overview";
import {TenancyRecurringTransaction} from "../models/tenancy/tenancy-recurring-transaction";
import {TenancyRecurringTransactionPost} from "../models/tenancy/tenancy-recurring-transaction-post";
import {TenancyRecurringTransactionPreview} from "../models/tenancy/tenancy-recurring-transaction-preview";
import {TenancyRecurringTransactionPatch} from "../models/tenancy/tenancy-recurring-transaction-patch";
import { TenancyRecurringTransactionPatchPreview } from '../models/tenancy/tenancy-recurring-transaction-patch-preview';
import {TenancyRecurringTransactionReviewPatch} from "../models/tenancy/tenancy-recurring-transaction-review-patch";
import {TenancyRecurringTransactionReviewPatchPreview} from "../models/tenancy/tenancy-recurring-transaction-review-patch-preview";

@Injectable()
export class TenancyRecurringTransactionService {
    constructor(private http: HttpClient, private configService: ConfigService, private accountService: AccountService) {
    }

    getOrganisationSummary(organisationReference: string): Observable<TenancyRecurringTransactionSummary> {
        return this.http.get<TenancyRecurringTransactionSummary>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenancy-recurring-transactions/summary`);
    }

    getPropertySummary(organisationReference: string, propertyReference: string): Observable<TenancyRecurringTransactionSummary> {
        return this.http.get<TenancyRecurringTransactionSummary>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/tenancy-recurring-transactions/summary`);
    }

    getTenancySummary(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<TenancyRecurringTransactionSummary> {
        return this.http.get<TenancyRecurringTransactionSummary>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${ tenancyGuid }/tenancy-recurring-transactions/summary`);
    }

    getOrganisationOverview(organisationReference: string, itemsCard : string): Observable<TenancyRecurringTransactionOverview> {
        let params = new HttpParams();
        params = params.set('itemsCard',itemsCard);

        return this.http.get<TenancyRecurringTransactionOverview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenancy-recurring-transactions/overview`, { params });
    }

    getPropertyOverview(organisationReference: string, propertyReference: string, itemsCard : string): Observable<TenancyRecurringTransactionOverview> {
        let params = new HttpParams();
        params = params.set('itemsCard',itemsCard);

        return this.http.get<TenancyRecurringTransactionOverview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/tenancy-recurring-transactions/overview`, { params });
    }

    getTenancyOverview(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, itemsCard : string): Observable<TenancyRecurringTransactionOverview> {
        let params = new HttpParams();
        params = params.set('itemsCard',itemsCard);

        return this.http.get<TenancyRecurringTransactionOverview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${ tenancyGuid }/tenancy-recurring-transactions/overview`, { params });
    }

    getTemplateForAdd(organisationReference: string, propertyReference: string, roomReference: string, tenancyReference: string, isDefault: boolean, isRent: boolean, frequency: any): Observable<TenancyRecurringTransaction> {
        let params = new HttpParams();
        if (isDefault && isRent) params = params.set('type','DEFAULT_RENT');
        if (isDefault && !isRent) params = params.set('type','DEFAULT_NON_RENT');
        if (!isDefault && isRent) params = params.set('type','EXTRA_RENT');
        if (!isDefault && !isRent) params = params.set('type','EXTRA_NON_RENT');
        if (frequency) params = params.set('frequency',frequency);


        return this.http.get<TenancyRecurringTransaction>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${ tenancyReference }/tenancy-recurring-transactions/get-template-for-add`, { params });
    }

    validateModel(organisationReference: string, propertyReference: string, roomReference: string, tenancyReference: string, model: TenancyRecurringTransactionPost): Observable<TenancyRecurringTransactionPreview> {
        return this.http.post<TenancyRecurringTransactionPreview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${ tenancyReference }/tenancy-recurring-transactions/validate-model`, model);
    }

    AddModel(organisationReference: string, propertyReference: string, roomReference: string, tenancyReference: string, model: TenancyRecurringTransactionPost): Observable<TenancyRecurringTransactionPreview> {
        return this.http.post<TenancyRecurringTransactionPreview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${ tenancyReference }/tenancy-recurring-transactions/add-model`, model);
    }


    validatePatchModel(organisationReference: string, trtGuid: string, model: TenancyRecurringTransactionPatch): Observable<TenancyRecurringTransactionPatchPreview> {
        return this.http.post<TenancyRecurringTransactionPatchPreview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenancy-recurring-transactions/${ trtGuid }/patch-model/commit/false`, model);
    }

    patchModel(organisationReference: string, trtGuid: string, model: TenancyRecurringTransactionPatch): Observable<TenancyRecurringTransactionPatchPreview> {
        return this.http.post<TenancyRecurringTransactionPatchPreview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenancy-recurring-transactions/${ trtGuid }/patch-model/commit/true`, model);
    }

    validateDeleteModel(organisationReference: string, trtGuid: string): Observable<TenancyRecurringTransactionPatchPreview> {
        return this.http.post<TenancyRecurringTransactionPatchPreview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenancy-recurring-transactions/${ trtGuid }/delete-model/commit/false`, {});
    }

    deleteModel(organisationReference: string, trtGuid: string): Observable<TenancyRecurringTransactionPatchPreview> {
        return this.http.post<TenancyRecurringTransactionPatchPreview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenancy-recurring-transactions/${ trtGuid }/delete-model/commit/true`, {});
    }

    validateReviewPatch(organisationReference: string, trtGuid: string, model: TenancyRecurringTransactionReviewPatch): Observable<TenancyRecurringTransactionReviewPatchPreview> {
        return this.http.post<TenancyRecurringTransactionReviewPatchPreview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenancy-recurring-transactions/${ trtGuid }/amount-review-patch/commit/false`, model);
    }

    reviewPatch(organisationReference: string, trtGuid: string, model: TenancyRecurringTransactionReviewPatch): Observable<TenancyRecurringTransactionReviewPatchPreview> {
        return this.http.post<TenancyRecurringTransactionReviewPatchPreview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenancy-recurring-transactions/${ trtGuid }/amount-review-patch/commit/true`, model);
    }

    getFullModel(organisationReference: string, trtGuid: string) {
        return this.http.get<TenancyRecurringTransactionPreview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenancy-recurring-transactions/${ trtGuid }/get-full-model`);
    }

    getItemTypeTexts(organisationReference: string): Observable<string[]> {
        return this.http.get<string[]>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/tenancy-recurring-transactions/item-type-texts`);
    }
}
