export const environment = {
    production: true,
    demo: false,
    labs: false,
    baseUrl: 'https://api.coho.life',
    liveWebUrl: 'https://coho.life/app/',
    staticWebUrl: 'https://coho.life/',
    demoWebUrl: 'https://demo.coho.life/',
    facebookPixelId: '',
    googleTagManagerId: 'GTM-TPRVV7K',
    googleTagManagerAuthKey: '',
    googleMapsToken: 'AIzaSyBD0Y3r4TJozLyjXlBNNZHGqKRXy4ivX80',
    googleAnalyticsId: 'UA-134795792-1',
    tinyMceKey: 'u32ahkcuikaio25141vzm3q29eigrspjg7yweyyra2iee0nx',
    paysafeAccountId: '1002961604',
    paysafeKey: 'cG1sZS0zOTU4MzQ6Qi1wMS0wLTVmOTk1NjdjLTAtMzAyYzAyMTQ2OTZkZjRjZDBiZWY5ZGEzNGQxMDI1NWQxZTI5NDE3MTU4OWI4MjZmMDIxNDQ0YzFiM2M0NmU4NDFlZjNiMWMwNWZhNjExMjY4OGUwNmRhN2E3Njc=',
    logRocketKey: 'dckewl/coho',
    openReplayKey: '2DZgxeGgs65hdrtvPgkj',
    stripeKey: 'pk_live_51HOUW5J9rKtSpEeho7yvhtoWVGd3zn3qiiXSmJ24K82o8cLtp9tGQwzf1jwGMQkbrMTX9pscUpsXyA7ixQcQDEc6006I0R1Z50',
    pdfJsKey: '6udEU9ssqMG3NzQju56B'
};
