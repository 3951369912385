import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { ConfigService } from './config.service';
import { from } from 'rxjs';


@Injectable()
export class StripeService {

    client: Stripe;

    constructor(configService: ConfigService) {
        from(loadStripe(configService.stripeKey))
            .subscribe(data => this.client = data);

    }
}
