import {BehaviorSubject, Observable, Observer, Subject} from 'rxjs';
import {DateTime} from 'luxon';

const cloneRecursive = (items) => items.map(item => Array.isArray(item) ? cloneRecursive(item) : item);

export class DataCacheSubject<T> {
    httpRequestFactory: Function = null;              // The factory generator that kicks of our HTTP request
    constraints: {} = null;

    // Internal
    rxSubjectOnce: Subject<T> = null;                  // Start as null, only initialise if constructed
    rxSubjectWatch: Subject<T> = null;                 // Start as null, only initialise if constructed
    data: T = null;
    loadingData: boolean = false;
    timeoutSeconds: number;
    lastUpdated: DateTime;

    constructor(httpRequestFactory: Function, constraints: {} = {}, timeoutSeconds: number = 60 * 60) {
        this.httpRequestFactory = httpRequestFactory;
        this.constraints = constraints;
        this.timeoutSeconds = timeoutSeconds;

        this.rxSubjectOnce = new Subject<T>();
        this.rxSubjectWatch = new Subject<T>();
    }

    // Returns true if all keys and values match
    constraintsMatchOrError(constraints: {}) {
        const keysA = Object.keys(this.constraints);
        const keysB = Object.keys(constraints);

        // If the key length doesn't match it cannot be equal. If keys are mismatched (same length but different names)
        // then that wil lbe caught in the next loop
        if (keysA.length != keysB.length) {
            throw new Error('Constraints for Cache are not matching with size mismatch Expect: ' + JSON.stringify(this.constraints) + ' and received: ' + JSON.stringify(constraints));
        }

        // Run through all keys and check that a == b
        keysA.forEach((x: string) => {
            if (this.constraints[x] != constraints[x]) {
                new Error('Constraints for Cache are not matching on column \'' + x + '\' Expect: ' + JSON.stringify(this.constraints) + ' and received: ' + JSON.stringify(constraints));
            }
        })

        // Got here so must be equal
        return true;
    }

    private deepCopy(data: T): T {
        // Using Lodash
        //return lodashClonedeep(data);
        // other method
        //return cloneRecursive(data);

        // Available on modern browsers
        // return structuredClone(data)

        // Find a more efficient clone method
        return JSON.parse(JSON.stringify(data)) as T;
    }

    private triggerRequest() {
        if (this.loadingData == true) {
            return;
        }

        this.loadingData = true;

        // Get a new observable from our factory (aka hit the back-end server, this is the equivalent of calling
        // this.manageOrganisationService.getPropertySummaries(this.organisationReference)
        this.httpRequestFactory()
            .subscribe(
                data => {
                    this.loadingData = false;
                    this.data = data;
                    this.lastUpdated = DateTime.utc();

                    // Pass on the data to all observers wanting it once and clear the list
                    this.rxSubjectOnce.next(this.deepCopy(data));
                    this.rxSubjectOnce.unsubscribe();

                    // Pass on the data to all observers watching who need ongoing updates
                    // TASK
                    // We need to do a 'diff' on the data coming back and the data we have stored
                    // because if the data is a 1:1 match then there is no need to do an update to
                    // the watchers.
                    this.rxSubjectWatch.next(this.deepCopy(data));

                    // console.log("DataCacheResponse");
                    // console.log(this.constraints);
                    // console.log(data);
                },
                error => {
                    this.loadingData = false;

                    // Pass on the error to all observers wanting it once and clear the list
                    this.rxSubjectOnce.error(this.deepCopy(error));
                    this.rxSubjectOnce.unsubscribe();

                    // Pass on the error to all observers watching who need ongoing updates
                    this.rxSubjectWatch.error(this.deepCopy(error));

                    // console.log("DataCacheError");
                    // console.log(this.constraints);
                    // console.log(error);
                },
            );
    }


    // Forces a request to the server for the latest version even if a cached version is available
    latest(constraints: any): Subject<T> {
        // We must make sure the constraints on the cache matches the constraints we have in the incoming parameters
        if (constraints != null) {
            this.constraintsMatchOrError(constraints);
        }

        const subject = new Subject<T>();

        // Add the subscriber to the updateOnce multi-cast list
        this.rxSubjectOnce.subscribe(subject);
        this.triggerRequest();

        return subject;
    }

    // Grabs the cache data (or latest if it doesn't exist) and then watches for any further
    // updates to process as and when they come in
    watch(constraints: any): Subject<T> {
        // We must make sure the constraints on the cache matches the constraints we have in the incoming parameters
        if (constraints != null) this.constraintsMatchOrError(constraints);

        // Initialising a BehaviourSubject allows you to define a 'Default' or starting value that is emitted immediately upon subscribe
        // and allows you to implement the behaviour we want
        const subject = this.data ? new BehaviorSubject<T>(this.deepCopy(this.data)) : new Subject<T>();

        // Subcribe our watcher to the watcher Observable and re-broadcast from there
        this.rxSubjectWatch
            .subscribe(
                data => {
                    subject.next(this.deepCopy(data));
                },
                error => {
                    subject.error(error);
                },
                () => {
                    subject.complete();
                });

        // Trigger a request
        this.triggerRequest();

        return subject;
    }

    // Grabs the current cached version if there is, and if not triggers a request
    cached(constraints: any = null): Subject<T> {
        // We must make sure the constraints on the cache matches the constraints we have in the incoming parameters
        if (constraints != null) {
            this.constraintsMatchOrError(constraints);
        }

        if (this.lastUpdated != null && this.lastUpdated.plus({seconds: this.timeoutSeconds}) < DateTime.utc()) {
            this.data = null;
            this.rxSubjectOnce = new Subject<T>();
        }

        // If we have already got the data then emit it directly to the subject on a Timeout to allow the caller to subscribe
        // to the new subject
        if (this.data) {
            const subject = new Subject<T>();

            // Run in a queue so that we first return the empty subject giving the caller time to subscribe before we push our result into the data.
            setTimeout(_ => {
                subject.next(this.deepCopy(this.data));
            });

            return subject;

        }

        // No cache available, grab the latest from the server
        return this.latest(null);
    }
}
