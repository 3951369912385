import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, throwError, EMPTY } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AccountService } from '../services/account.service';
import { ConfigService } from "../services/config.service";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private accountService: AccountService;

    constructor(private injector: Injector, private router: Router, private configService: ConfigService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.accountService) {
            this.accountService = this.injector.get(AccountService);
        }

        if (!request.url.startsWith(this.configService.baseUrl)) {
            if (request.url.endsWith('.svg')) {
                request = request.clone({
                    headers: request.headers.delete('Content-Type')
                });
            }

            return next.handle(request);
        }

        if (this.accountService.isLoggedIn()) {
            if (this.accountService.accessToken.bearerToken && this.accountService.accessToken.refreshToken) {
                request = this.addAuthenticationToken(request);
            }
        }

        return next.handle(request)
            .pipe(
                catchError(error => {
                    if (request.url.includes('account/refresh-token') || request.url.includes('account/login')) {
                        return throwError(error);
                    }

                    if (error.status === 401 && this.accountService.accessToken === null) {
                        this.router.navigate(['/login']);
                        return EMPTY;
                    }

                    if (error.status !== 401 || this.accountService.accessToken === null) {
                        return throwError(error);
                    }

                    if (this.refreshTokenInProgress) {
                        return this.refreshTokenSubject
                            .pipe(
                                filter(result => result !== null),
                                take(1),
                                switchMap(() => next.handle(this.addAuthenticationToken(request)))
                            );
                    } else {
                        this.refreshTokenInProgress = true;
                        this.refreshTokenSubject.next(null);
                        return this.accountService
                            .refreshToken()
                            .pipe(
                                switchMap((token: any) => {
                                    this.refreshTokenInProgress = false;
                                    this.refreshTokenSubject.next(token);

                                    return next.handle(this.addAuthenticationToken(request));
                                }),
                                catchError((err: any) => {
                                    this.refreshTokenInProgress = false;
                                    return throwError(error);
                                }));
                    }

                }));
    }

    private addAuthenticationToken(request) {
        const accessToken = this.accountService.accessToken;
        if (!accessToken) {
            return request;
        }
        return request.clone({
            setHeaders: {
                Authorization: `bearer ${this.accountService.accessToken.bearerToken}`
            }
        });
    }
}
