import {DropdownOption} from "../components/dropdown/dropdown-option";


export enum BaseEntityTypes {
    PROPERTY = 1,
    ROOM = 2,
    TENANCY = 3,
    TENANCY_TENANT = 4,
    RENT_DUE_RECORD = 5,
    FINANCIAL_ITEM = 6,
    CONVERSATION = 7,
    CONVERSATION_MESSAGE = 8,
    MAINTENANCE_ITEM = 9,
    PROPERTY_OWNER = 10,
    ACTION = 11,
    ONBOARDING = 12,
    TENANCY_RECURRING_TRANSACTION = 13,
    BANK_ACCOUNT = 14,
    ORGANISATION_SUPPLIER = 15,
    ORGANISATION_PERSON = 16,
    COHO_INVOICE = 17,              // Payments table
    PROPERTY_VIEWING = 18,
    SUPPLIER_INVOICE = 19,
    ACTION_PROGRESSION = 20,
    ACTION_PROGRESSION_STEP = 21,
    ACTION_PROGRESSION_FILE = 22,
    ACTION_PROGRESSION_TEMPLATE_FILE = 23,
    ONBOARDING_APPLICATION_FORM = 24,
    ORGANISATION = 25,
    ORGANISATION_PROPERTY_XERO_SETTINGS = 26,
    PERSON = 27,
    SUPPLIER = 28,
    FINANCIAL_ITEM_CATEGORY = 29,
    FINANCIAL_ITEM_SETTLEMENT = 30,
}

export const BaseEntityTypesReverse = {
    '1': 'Property',
    '2': 'Room',
    '3': 'Tenancy',
    '4': 'Tenant',
    '5': 'Rent due record',
    '6': 'Financial item',
    '7': 'Conversation',
    '8': 'Conversation message',
    '9': 'Maintenance item',
    '10': 'Property owner',
    '11': 'Action',
    '12': 'Onboarding',
    '13': 'Tenancy recurring transaction',
    '14': 'Bank account',
    '15': 'Organisation supplier',
    '16': 'Organisation person',
    '17': 'COHO invoice',
    '18': 'Property viewing',
    '19': 'Supplier invoice',
    '20': 'Action progression',
    '21': 'Action progression step',
    '22': 'Action progression file',
    '23': 'Action progression template file',
    '24': 'Onboarding application form',
    '25': 'Organisation',
    '26': 'Organisation property xero settings',
}

// Colour choices are
// .tags__entity--brown
// .tags__entity--teal
// .tags__entity--cyan
// .tags__entity--pink
// .tags__entity--green
// .tags__entity--violet
// .tags__entity--pumpkin
// .tags__entity--red
// .tags__entity--grey

export const BaseEntityTypesCssClass = {
    "1": "tags__entity--brown", // PROPERTY
    "2": "tags__entity--teal",  // ROOM
    "3": "tags__entity--cyan",  // TENANCY
    "4": "tags__entity--pumpkin",  // TENANCY_TENANT
    "5": "tags__entity--green",   // RENT_DUE_RECORD
    "6": "tags__entity--violet",  // FINANCIAL_ITEM
    "7": "tags__entity--brown", // CONVERSATION
    "8": "tags__entity--red",  // CONVERSATION_MESSAGE
    "9": "tags__entity--cyan",  // MAINTENANCE_ITEM
    "10": "tags__entity--brown",  // PROPERTY_OWNER
    "11": "tags__entity--red",  // ACTION
    "12": "tags__entity--pumpkin",  // ONBOARDING
    "13": "tags__entity--violet",  // TENANCY_RECURRING_TRANSACTION
    "14": "tags__entity--brown",  // BANK_ACCOUNT
    "15": "tags__entity--violet",  // ORGANISATION_SUPPLIER
    "16": "tags__entity--grey",  // ORGANISATION_PERSON
    "17": "tags__entity--grey",  // COHO_INVOICE
    "18": "tags__entity--green"  //  PROPERTY_VIEWING
}

