import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SiteHeaderStaticComponent } from './site-header-static.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { SpriteImageModule } from '../sprite-image/sprite-image.module';

@NgModule({
    imports: [
        CommonModule,
        SvgIconModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownModule,
        SpriteImageModule
    ],
    declarations: [
        SiteHeaderStaticComponent
    ],
    exports: [
        SiteHeaderStaticComponent
    ]
})

export class SiteHeaderStaticModule { }
