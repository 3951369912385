import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { FindHomeSearchResult } from '../models/search/find-home-search-result';

@Injectable()
export class SearchService {
    constructor(private httpClient: HttpClient, private configService: ConfigService) {
    }

    findHomeSearch(query: string): Observable<FindHomeSearchResult> {
        return this.httpClient.get<FindHomeSearchResult>(`${this.configService.baseUrl}/homes/search/${query}`);
    }
}
