import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mustEndInFullStop',
    pure: true
})
export class MustEndInFullStopPipe implements PipeTransform {

    transform(value: string) {
        if (value.endsWith('.')) {
            return value;
        }

        return `${ value }.`;
    }
}
