import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

// luxon .local
import { DateTime, Settings } from 'luxon';

import { DateFormats } from "../models/dates/date-formats";

@Pipe({
    name: 'plurality',
    pure: true
})
export class PluralityPipe implements PipeTransform {
    transform(value: any, singular: string, plural: string = null): string {
        // If no plural is passed in we assuming the standard english s addition
        if (plural === null) plural = singular + 's';

        if (value === null || value === false || value === '' || value === undefined) {
            return 'are - ' + plural;
        }

        // Handle special numbers
        if (value === 0 || _.trim(value) === "0") return 'are no ' + plural;
        if (value === 1 || _.trim(value) === "1") return 'is 1 ' + singular;

        // Check if it is a number and print it accordingly
        const parsed = parseInt(_.trim(value), 10);
        if (!isNaN(parsed)) return 'are ' + parsed + ' ' + plural;

        // Fallback when we don't know what the value is
        return 'are ' + String(_.trim(value)) + ' ' + plural;
    }
}
