import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor as AngularHttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { MobileAppService } from '../services/mobile-app.service';


@Injectable()
export class MobileAppHttpInterceptor implements AngularHttpInterceptor {

    constructor(private mobileAppService: MobileAppService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.mobileAppService.token()) {
            request = request.clone({headers: request.headers.set('x-mobile-token', this.mobileAppService.token())});
        }

        return next.handle(request);
    }
}
