import {FinancialItemIncomeExpenseTypes} from './financial-item-income-expense-types';
import {FinancialItemClientCostTypes} from './financial-item-client-cost-types';
import {FinancialItemParentCategories} from './financial-item-parent-categories';
import {FinancialItemNewCategoryPost} from './financial-item-new-category-post';
import {FinancialItemPaidStatuses} from './financial-item-paid-statuses';
import {FilePost} from '../file/file-post';
import {OrganisationSupplierPost} from '../organisation-supplier/organisation-supplier-post';
import {FinancialItemCarriedOutByTypes} from "./financial-item-carried-out-by-types";
import {BaseEntityIdentifier} from "../base-entity-identifier";
import {HouseShareFile} from "../file/file";

export class FinancialItemPost {
    propertyReference: string;
    roomReference: string;
    tenancyGuid: string;

    invoiceDate: Date;
    feeInvoiceDate: Date;

    description: string;
    notes: string;
    category: string;
    newCategory: FinancialItemNewCategoryPost;

    rawAmount: number;
    rawAmountTax: number;
    rawTaxPercentage: number;
    rawTotalAmount: number;

    organisationAmount: number;
    organisationAmountTax: number;
    organisationTaxPercentage: number;
    organisationTotalAmount: number;

    clientAmount: number;
    clientAmountTax: number;
    clientTaxPercentage: number;
    clientTotalAmount: number;

    supplierCommission: boolean;
    commissionAmount: number;
    commissionAmountTax: number;
    commissionTaxPercentage: number;
    commissionTotalAmount: number;

    incomeExpense: FinancialItemIncomeExpenseTypes;
    incomeExpenseReverse: boolean;
    clientCostType: FinancialItemClientCostTypes;

    showOnSettlement: boolean;
    paidStatus: FinancialItemPaidStatuses;
    paidDate: Date;

    internalOnly: boolean;
    clientOnly: boolean;

    invoiceFiles: HouseShareFile[] | FilePost[];
    supplierGuid: string;
    newSupplier: OrganisationSupplierPost;

    carriedOutBy: FinancialItemCarriedOutByTypes;

    linkBaseEntity: BaseEntityIdentifier;

    splitByProperty: boolean;
    splitPropertyReferences: string[] | null;
    splitPropertyRatios: number[] | null;
}
