import { Injectable } from "@angular/core";
import moment from "moment";
import { CookieService } from 'ngx-cookie-service';

const affiliateRefKey: string = 'A_REF';
const affiliateValidToKey: string = 'A_VALID_TO';

@Injectable()
export class AffiliateService {

    constructor(cookieService: CookieService) {
        const cookie = cookieService.get(affiliateRefKey);
        if (cookie) {
            const expiry = cookieService.get(affiliateValidToKey);

            this.setAffiliateCode(cookie, expiry);
        }
    }
    getAffiliateCode(): string {
        let code = localStorage.getItem(affiliateRefKey);
        let expiry = localStorage.getItem(affiliateValidToKey);

        let expiryDate = moment(expiry);

        if (expiryDate.isAfter(moment.utc())) {
            return code;
        }
        else {
            return '';
        }
    }

    setAffiliateCode(code: string, expiry?: string): void {
        localStorage.setItem(affiliateRefKey, code);

        if (!expiry) {
            expiry = moment.utc().add(30, 'days').format();
        }

        localStorage.setItem(affiliateValidToKey, expiry);
    }
}
