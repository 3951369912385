import {AbstractControl} from "@angular/forms";

export class DropdownOption {
    public label: string = null;
    public value: any = null;
    public groupValue? : string;
    public exclusive?: boolean;

    constructor(label: string = null, value: any = null, groupValue?: string | number, exclusive: boolean = false) {
        this.label = label;
        this.value = value;

        // Force group value from a 'number' to a string with toString() so that we keep the contract that groupValue is a string or undefined/null
        this.groupValue = typeof groupValue == 'number' ? groupValue.toString() : groupValue;

        this.exclusive = exclusive ?? false;

    }
}

// Valid inputs:
// 1. The form control you get back from `this.form.get('FieldName')`
// 2. A single DropdownOption -> Returns [ValueOfOption]
// 3. A list of DropdownOptions -> Returns [...values of options]
// 4. Any input that evaluates to falsy -> Returns []
export function CollectDropdownValues(input :AbstractControl | any) {
    const values = input instanceof  AbstractControl ? input.value : input;
    // Is certainly a DropdownOption array so map to extract the selected values
    if (!values) return [];
    if (Array.isArray(values) && values.length == 0) return [];
    if (Array.isArray(values) && values.filter(item => typeof item === 'number' || typeof item === 'string').length == values.length) return values;
    if (Array.isArray(values)) return values.filter(item => item && 'value' in item).map(item => item.value);
    if (typeof values === 'object' && 'value' in values) return [values.value];

    throw new Error("Cannot use CollectDropdownValues to process value");
}
