import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { MortgageRenewal } from '../models/mortgage-renewal/mortgage-renewal';
import { MortgageRenewalPost } from '../models/mortgage-renewal/mortgage-renewal-post';
import { MortgageRenewalOverview } from '../models/mortgage-renewal/mortgage-renewal-overview';
import { FileService } from "./file.service";
import {PropertyServicesSummary} from "../models/property-services/property-services-summary.interface";
import {mergeMap, tap} from "rxjs/operators";

@Injectable()
export class MortgageRenewalService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string): Observable<MortgageRenewalOverview> {
        return this.http.get<MortgageRenewalOverview>(`${this.configService.baseUrl}/manage/property/${propertyReference}/mortgage-renewal/overview`);
    }

    getPastList(propertyReference: string): Observable<MortgageRenewal[]> {
        return this.http.get<MortgageRenewal[]>(`${this.configService.baseUrl}/manage/property/${propertyReference}/mortgage-renewal/past`);
    }

    add(propertyReference: string, model: MortgageRenewalPost): Observable<HttpEvent<MortgageRenewal>> {
        return this.fileService.uploadFiles(model.mortgageRenewalFiles)
            .pipe(tap(_ => model.mortgageRenewalFiles = this.fileService.prepareFilePosts(model.mortgageRenewalFiles)),
                mergeMap(_ => this.http.post<MortgageRenewal>(`${this.configService.baseUrl}/manage/property/${propertyReference}/mortgage-renewal`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(propertyReference: string, item: MortgageRenewal): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/property/${propertyReference}/mortgage-renewal/${item.guid}`);
    }

    getSummary(propertyReference: string) {
        return this.http.get<PropertyServicesSummary>(`${this.configService.baseUrl}/manage/property/${propertyReference}/mortgage-renewal/summary`);
    }
}
