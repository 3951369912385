import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { Address } from '../models/address/address';
import { AddressTypes } from '../components/address-editor/address-types';
import { Address as AddressEdit } from '../components/address-editor/address';
import { Service } from '../models/service/service';
import {HowToRentGuideInterface} from "../models/how-to-rent/how-to-rent-guide.interface";

@Injectable()
export class AddressService {
    constructor(private httpClient: HttpClient, private configService: ConfigService) {
    }

    getAddresses(): Observable<Address[]> {
        return this.httpClient.get<Address[]>(`${this.configService.baseUrl}/address`);
    }

    getAddressPartByName(name: string, type?: AddressTypes): Observable<Address> {
        let params = new HttpParams();
        if (type) {
            params = params.set('type', type.toString());
        }

        return this.httpClient.get<Address>(`${this.configService.baseUrl}/address/${name}`, { params });
    }

    search(query: string, type?: AddressTypes): Observable<Address[]> {
        let params = new HttpParams();
        if (type) {
            params = params.set('type', type.toString());
        }

        return this.httpClient.get<Address[]>(`${this.configService.baseUrl}/address/search/${query}`, { params });
    }

    parseAddress(data: AddressEdit): Observable<any> {
        let params = new HttpParams();

        ['address2','area', 'town', 'county'].forEach(prop => {
            if (data[prop]) {
                params = params.set(prop, data[prop]);
            }
        });

        return this.httpClient.get<Address[]>(`${this.configService.baseUrl}/address/parse`, { params });
    }

    services(addressReference: string): Observable<Service[]> {
        return this.httpClient.get<Service[]>(`${this.configService.baseUrl}/address/${addressReference}/services`);
    }
}
