import { Component, ViewEncapsulation, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { DOCUMENT } from '@angular/common';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user/user';
import { UserNotifications } from '../../models/user/user-notifications';
import { LookupService } from '../../services/lookup.service';
import { Address } from '../../models/address/address';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import { LayoutType } from '../../models/layout-type/layout-type';
import { UserPatch } from '../../models/user/user-patch';
import { DropdownOption } from '../dropdown/dropdown-option';
import { UntypedFormBuilder} from '@angular/forms';
import { ConfigService } from '../../services/config.service';
import { WebsocketNotificationService } from '../../services/websocket-notifications.service';
import { ArticleService } from '../../services/article.service';
import { ArticleSummary } from '../../models/article/article-summary';

@Component({
    selector: 'site-header-static',
    templateUrl: './site-header-static.component.html',
    encapsulation: ViewEncapsulation.None,
    host: {
        '(document:click)': 'onClick($event)'
    }
})

export class SiteHeaderStaticComponent implements OnInit, OnDestroy {

    @ViewChild('userMenuDropdown') userMenuDropdown: ElementRef;
    @ViewChild('announceDropdown') announceDropdown: ElementRef;

    megaHover: boolean = false;
    megaHoverWhat: boolean = false;
    isLoggedIn: boolean = false;
    mainMenuOpen: boolean = false;
    userMenuOpen: boolean = false;
    announceMenuOpen: boolean = false;
    currentUser: User;
    profileImage: string;
    profileImageAlt: string;
    userNotifications: UserNotifications;
    mainCities: Address[];
    conversationPage: boolean = false;
    articleType: string;
    articleCount: number;
    articles: ArticleSummary[];
    showAnnouncements: boolean = false;

    liveWebUrl: string;
    staticWebUrl: string;
    demo: boolean = false;
    demoDetailsLoaded: boolean = false;
    demoInfoExpanded: boolean = false;
    demoUserOptions: DropdownOption[] = [];

    demoForm = this.formBuilder.group({
        demoUser: [null]
    });

    private stopRequestingWebsocketNotifications = new Subject();

    constructor(private accountService: AccountService, private lookupService: LookupService, private userService: UserService, private router: Router,
                @Inject(DOCUMENT) private document: Document, private layoutService: LayoutService, private websocketNotificationService: WebsocketNotificationService,
                private formBuilder: UntypedFormBuilder, private configService: ConfigService, private articlesService: ArticleService) {

        accountService.watchCurrentUser()
            .subscribe(user => {
                this.currentUser = user;

                if (user && user.person) {
                    this.profileImage = user.person.image.thumbnail;
                    this.profileImageAlt = `${ user.person.forename } ${ user.person.surname }`;
                } else {
                    this.profileImage = null;
                    this.profileImageAlt = null;
                }
            });

        this.layoutService.layout
            .subscribe(data => {
                this.conversationPage = data === LayoutType.CONVERSATION;
            });


        this.liveWebUrl = this.configService.liveWebUrl;
        this.staticWebUrl = this.configService.staticWebUrl;

        if (this.configService.isDemo) {
            this.demo = true;

            this.accountService.getDemoDetails()
                .subscribe(data => {
                    this.demoUserOptions = [];

                    for (let user of data.users) {
                        this.demoUserOptions.push({value: user.guid, label: user.name});
                    }

                    this.demoDetailsLoaded = true;
                });
        }
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.demoForm.controls['demoUser'].valueChanges
                .subscribe(value => {
                    this.router.navigate(['/demo', 'login'], {queryParams: {guid: value}});

                });
        });

        this.websocketNotificationService.newNotificationsAlertReceived
            .pipe(takeUntil(this.stopRequestingWebsocketNotifications))
            .subscribe((data: boolean) => {
                if (data) {
                    this.getNewUserNotifications();
                }
            });

        this.websocketNotificationService.articleReadReceived
            .pipe(takeUntil(this.stopRequestingWebsocketNotifications))
            .subscribe((articleReference: string) => {
                this.articles = this.articles.filter(m => m.reference != articleReference);
                this.articleCount = this.articles.length;
            });


        this.userNotifications = new UserNotifications();

        this.accountService.watchIsLoggedIn()
            .subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn;
                if (this.currentUser != null && this.currentUser.emailConfirmed && this.currentUser.registrationJourneyCompleted) {
                    this.articleType = this.currentUser.isManager ? 'management' : 'tenant';

                    this.getNewUserNotifications();
                    this.getAnnouncements();
                }
            });

      //  this.mainCities = this.lookupService.getMainCities();
    }

    accountSettings() {
        this.router.navigate(['/account']);
    }

    logout() {
        this.accountService.logout();
        const loginUrl = this.router.createUrlTree(['/login']);
        window.location.href = loginUrl.toString();
    }

    toggleUserMenu(open?: boolean) {
        if (open === false) {
            this.userMenuOpen = false;
        } else if (open === true) {
            this.userMenuOpen = true;
            this.announceMenuOpen = false;
        } else {
            if (this.userMenuOpen) {
                setTimeout(() => this.userMenuOpen = false);
            } else {
                setTimeout(() => this.userMenuOpen = true);
                setTimeout(() => this.announceMenuOpen = false);
            }
        }
    }

    toggleAnnounceMenu(open?: boolean) {
        if (open === false) {
            this.announceMenuOpen = false;
        } else if (open === true) {
            this.announceMenuOpen = true;
            this.userMenuOpen = false;
        } else {
            if (this.announceMenuOpen) {
                setTimeout(() => this.announceMenuOpen = false);
            } else {
                setTimeout(() => this.announceMenuOpen = true);
                setTimeout(() => this.userMenuOpen = false);
            }
        }
    }

    toggleMainMenu() {
        if (this.mainMenuOpen) {
            setTimeout(() => this.mainMenuOpen = false);
        } else {
            setTimeout(() => this.mainMenuOpen = true);
        }
    }

    closeMainMenu() {
        this.mainMenuOpen = false;
    }

    onClick(event: Event): void {
        if (this.userMenuOpen && this.userMenuDropdown && this.userMenuDropdown.nativeElement) {
            if (!this.userMenuDropdown.nativeElement.contains(event.target)) {
                this.userMenuOpen = false;
            }
        }

        if (this.announceMenuOpen && this.announceDropdown && this.announceDropdown.nativeElement) {
            if (!this.announceDropdown.nativeElement.contains(event.target)) {
                this.announceMenuOpen = false;
            }
        }
    }

    getNewUserNotifications() {
        this.userService.userNotifications()
            .subscribe(data => this.userNotifications = data);
    }

    ngOnDestroy() {
        this.stopRequestingWebsocketNotifications.next(true);
    }

    toggleDarkMode(darkModeOn: boolean) {
        const model = new UserPatch();
        model.darkMode = darkModeOn;

        this.userService.patchUser(model)
            .subscribe();
    }

    toggleDemoShowMore(show: boolean) {
        this.demoInfoExpanded = show;
    }

    private getAnnouncements() {
        this.articlesService.getUnreadAnnouncements(this.articleType)
            .subscribe(data => {
                this.articleCount = data.count;
                this.articles = data.articles;
                if (this.articleCount > 0) {
                    this.showAnnouncements = true;
                } else {
                    this.showAnnouncements = false;
                }
            });
    }

    articleMarkRead(article: ArticleSummary) {
        this.articlesService.markAnnouncementRead(this.articleType, article.reference)
            .subscribe(data => {
                this.articleCount = data.count;
                this.articles = data.articles;
                if (this.articleCount > 0) {
                    this.showAnnouncements = true;
                } else {
                    this.showAnnouncements = false;
                }
            });
    }

    navigateToFindAHome() {
        this.router.navigate(['/find-a-home']);
    }
}
