import {DropdownOption} from "../../components/dropdown/dropdown-option";

export enum RentPaymentFrequencies {
    MONTH = 1,
    WEEK = 2,
    TERM = 3,  // Custom university terms
    FOUR_WEEK = 4,
    FORTNIGHT = 5,
    QUARTER = 6,
    ANNUAL = 7
}

export const RentPaymentFrequenciesReverse = {
    "1": "Monthly",
    "2": "Weekly",
    "3": "Term",
    "4": "Four Weekly",
    "5": "Fortnightly",
    "6": "Quarterly",
    "7": "Annually"
}


export const RentPaymentFrequenciesPerText = {
    "1" : "month",
    "2" : "week",
    "3" : "term",
    "4" : "4 weeks",
    "5" : "fortnight",
    "6" : "quarter",
    "7" : "year",
}

export const RentPaymentFrequenciesPostfix = {
    "1" : "pcm",
    "2" : "pw",
    "3" : "",
    "4" : "p4w",
    "5" : "p2w",
    "6" : "p3m",
    "7" : "pa",
}

export const RentPaymentFrequenciesSets = {
    "1": "month(s)",
    "2": "week(s)",
    "3": "term(s)",
    "4": " X four weeks",
    "5": "x two Weeks",
    "6": "quarter(s)",
    "7": "year(s)",
}

export const RentPaymentFrequenciesDropdownOptions = [
    new DropdownOption("Monthly", RentPaymentFrequencies.MONTH),
    new DropdownOption("Weekly", RentPaymentFrequencies.WEEK),
    new DropdownOption("Term", RentPaymentFrequencies.TERM),
    new DropdownOption("Four Weekly", RentPaymentFrequencies.FOUR_WEEK),
    new DropdownOption("Fortnightly", RentPaymentFrequencies.FORTNIGHT),
    new DropdownOption("Quarterly", RentPaymentFrequencies.QUARTER),
    new DropdownOption("Annually", RentPaymentFrequencies.ANNUAL),
]

export const DayOfWeekText = {
    "1": "Monday",
    "2": "Tuesday",
    "3": "Wednesday",
    "4": "Thursday",
    "5": "Friday",
    "6": "Saturday",
    "7": "Sunday",
}
