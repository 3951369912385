import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { RentDueRecord } from '../models/rent-collection/rent-due-record';
import { RentDueRecordPatch } from '../models/rent-collection/rent-due-record-patch';
import { RentPaymentPost } from '../models/rent-collection/rent-payment-post';
import { RentPayment } from '../models/rent-collection/rent-payment';
import { RentCollectionPropertyOverview } from '../models/rent-collection/rent-collection-property-overview';
import { RentCollectionRoomOverview } from '../models/rent-collection/rent-collection-room-overview';
import { RentUnpaidPost } from '../models/rent-collection/rent-unpaid-post';
import { RentDuePost } from '../models/rent-collection/rent-due-post';
import { RentCollectionOrganisationOverview } from '../models/rent-collection/rent-collection-organisation-overview';
import { MyRentCollectionOverview } from '../models/rent-collection/my-rent-collection-overview';
import { RentPaymentFrequencies } from '../models/tenancy/rent-payment-frequencies';
import { RentDetails } from '../models/tenancy/rent-details';
import { RentDueRecordScheduleItem } from '../models/rent-collection/rent-due-record-schedule-item';
import moment from 'moment';
import { RentPaymentAssignPost } from '../models/rent-collection/rent-payment-assign-post';
import { RentDueRecordsFilter } from '../models/rent-collection/rent-due-records-filter';
import { RentDueRecordsFilterResults } from '../models/rent-collection/rent-due-records-filter-results';
import { RentCollectionSummary } from '../models/rent-collection/rent-collection-summary';
import { AccountService } from './account.service';
import { RentReminderPost } from '../models/rent-collection/rent-reminder-post';
import { RentReminderMediums } from '../models/rent-collection/rent-reminder-mediums';
import { RentReminderContent } from '../models/rent-collection/rent-reminder-content';
import { RentReminderContentPost } from '../models/rent-collection/rent-reminder-content-post';
import { OrganisationCreditControlSettings } from '../models/rent-collection/organisation-credit-control-settings';
import { OrganisationCreditControlSettingsPatch } from '../models/rent-collection/organisation-credit-control-settings-patch';
import {OrganisationCreditControlNotificationTemplatePatch, OrganisationCreditControlNotificationTemplatePost} from '../models/rent-collection/organisation-credit-control-notification-template-post';
import {CreditControlNotificationExecutionPayload, OrganisationCreditControlNotificationTemplate} from '../models/rent-collection/organisation-credit-control-notification-template';
import { OrganisationCreditControlNotificationPreview } from '../models/rent-collection/organisation-credit-control-notification-preview';
import { OrganisationCreditControlNotificationTemplatePreviewPost } from '../models/rent-collection/organisation-credit-control-notification-template-preview-post';
import {RentPaymentMatchPost} from "../models/rent-collection/rent-payment-match-post";
import { LogKeepRentDueRecordPost } from '../models/rent-collection/log-keep-rent-due-record';
import {PropertyFilterPresets} from "../models/manage-property/property-details";
import {RentItemImport} from "../models/rent-collection/rent-item-import";
import { RentCollectionGroupItemUnassignedPayment } from '../models/rent-collection/rent-collection-group-item-unassigned-payment';
import {BaseEntityStatuses} from "../models/base-entity-statuses";
import {CreditControlTenanciesArrears} from "../models/rent-collection/credit-control-tenancies-arrears";
import {CreditControlTenancyDetailsPatch} from "../models/rent-collection/credit-control-tenancy-details-patch";
import {CreditControlTenancyDetails} from "../models/rent-collection/credit-control-tenancy-details";
import {OrganisationRentCollectionSettings} from "../models/rent-collection/organisation-rent-collection-settings";
import {OrganisationRentCollectionSettingsPatch} from "../models/rent-collection/organisation-rent-collection-settings-patch";
import {MissingRentCharge} from "../models/rent-collection/missing-rent-charge";
import {MissingRentChargesFilter} from "../models/rent-collection/missing-rent-charges-filter";
import {mergeMap, tap} from "rxjs/operators";
import {FileService} from "./file.service";

export type CreditControlFindByOptions = 'guid' | 'all' | 'overdue' | 'dueToday' | 'dueNextFiveDays' | 'duePastFiveDays';

@Injectable()
export class RentCollectionService {
    constructor(private http: HttpClient, private configService: ConfigService, private accountService: AccountService, private fileService: FileService) {
        //configService.wrapHttpClient(http);
        // DPJ TASK -> This wrapper was somehow creating duplicate HTTP requests, so I need to solve that first. Plus Liam wants me to re-write it as an interceptor so that's a good idea
    }

    getOrganisationOverview(organisationReference: string): Observable<RentCollectionOrganisationOverview> {
        return this.http.get<RentCollectionOrganisationOverview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/rent-collection/overview`);
    }

    getOrganisationUnassignedPayments(organisationReference: string): Observable<RentCollectionGroupItemUnassignedPayment[]> {
        return this.http.get<RentCollectionGroupItemUnassignedPayment[]>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/rent-collection/unassigned-payments`);
    }

    getOrganisationCreditControlTenancyArrears(organisationReference: string, findBy: CreditControlFindByOptions, limitToMoneyTypes: string[] | null = null, propertyReferences: string[] | null = null, tenancyGuid: string = null, limitToTenancyStatuses: string[] | null = null) {
        let params = new HttpParams();
        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        if (limitToMoneyTypes) limitToMoneyTypes.forEach(type => params = params.append('moneyTypes', type));
        if (limitToTenancyStatuses) limitToTenancyStatuses.forEach(type => params = params.append('tenancyStatuses', type));
        if (tenancyGuid) params = params.append("tenancyGuid", tenancyGuid);


        return this.http.get<CreditControlTenanciesArrears>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/rent-collection/credit-control/${findBy}/tenancies-and-arrears?${ params.toString() }`);
    }

    updateRentDueRecord(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string, model: RentDueRecordPatch): Observable<RentDueRecord> {
        return this.http.patch<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }`, model);
    }

    setRentDueRecordBadDebt(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string): Observable<RentDueRecord> {
        return this.http.patch<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/set-bad-debt/true`, {});
    }

    setRentDueRecordNotBadDebt(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string): Observable<RentDueRecord> {
        return this.http.patch<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/set-bad-debt/false`, {});
    }

    setRentDueRecordPaid(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string): Observable<RentDueRecord> {
        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/paid`, null);
    }

    setRentDueRecordLateOverride(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string, undo: boolean = false): Observable<RentDueRecord> {
        let params = new HttpParams();
        if (undo) {
            params = params.set('undo', undo ? 'true' : 'false');
        }

        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/override-late`, null, { params: params });
    }

    setAllUnpaidRentDueRecordPaid(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${ tenancyGuid }/rent-collection/rent-due-records/mark-overdue-paid`, null);
    }

    reassignLastMonthsHeldRent(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${ tenancyGuid }/rent-collection/rent-due-records/reassign-last-months-held-rent`, null);
    }

    deleteRentDueRecord(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string): Observable<RentDueRecord> {
        return this.http.delete<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }`);
    }

    deleteManyRentDueRecord(organisationReference: string, rentDueRecordGuids: string[]) {
        const url = `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/rent-collection/rent-due-records/delete-many`
        return this.http.patch<boolean>(url, rentDueRecordGuids);
    }

    refundRentDueRecord(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string): Observable<RentDueRecord> {
        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/refund`, null);
    }

    reverseRentDueRecord(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string) {
        return this.http.post<void>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/reverse`, null);
    }


    cancelRefundRentDueRecord(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string): Observable<RentDueRecord> {
        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/cancel-refund`, null);
    }

    keepRentDueRecord(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string, model: LogKeepRentDueRecordPost): Observable<RentDueRecord> {
        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/keep`, model);
    }

    addRentDue(organisationReference: string, propertyReference: string, roomReference: string, model: RentDuePost, tenancyGuid): Observable<RentDueRecord> {
        let params = new HttpParams();
        params = params.set('tenancyGuid', tenancyGuid);

        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records`, model, {params: params});
    }

    addRentPayment(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, model: RentPaymentPost): Observable<RentPayment> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.post<RentPayment>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-payments`, model, {params: params});
    }

    reassociateRentPayments(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${tenancyGuid}/rent-collection/reassociate-rent-payments`, null);
    }

    runDiagnostics(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<void> {
        return this.http.post<void>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${tenancyGuid}/rent-collection/run-diagnostics`, null);
    }

    matchRentPayment(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, model: RentPaymentMatchPost): Observable<boolean> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-payments/match`, model, {params: params});
    }

    rentPaymentAssign(organisationReference: string, propertyReference: string, roomReference: string, rentPaymentGuid: string, model: RentPaymentAssignPost): Observable<RentPayment> {
        return this.http.patch<RentPayment>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-payments/${ rentPaymentGuid }`, model);
    }

    addRentDueRecordUnpaid(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string, model: RentUnpaidPost): Observable<RentDueRecord> {
        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/rent-unpaid`, model);
    }

    addMyRentDueRecordUnpaid(rentDueRecordGuid: string, model: RentUnpaidPost): Observable<RentDueRecord> {
        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/my-home/rent-collection/rent-due-records/${ rentDueRecordGuid }/rent-unpaid`, model);
    }

    addTenantRentDueRecordUnpaid(rentDueRecordGuid: string, model: RentUnpaidPost): Observable<RentDueRecord> {
        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/my-home/rent-collection/rent-due-records/${ rentDueRecordGuid }/rent-unpaid`, model);
    }

    addRentUnpaid(organisationReference: string, propertyReference: string, roomReference: string, model: RentUnpaidPost, tenancyGuid: string = null): Observable<RentDueRecord> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-unpaid`, model, {params: params});
    }

    getPropertyOverview(organisationReference: string, propertyReference: string): Observable<RentCollectionPropertyOverview> {
        return this.http.get<RentCollectionPropertyOverview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/rent-collection/overview`);
    }

    getRoomOverview(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string = null): Observable<RentCollectionRoomOverview> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.get<RentCollectionRoomOverview>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/overview`, {params});
    }

    getMyHomeOverview(tenancyGuid: string = null): Observable<MyRentCollectionOverview> {
        let params = new HttpParams();
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }

        return this.http.get<MyRentCollectionOverview>(`${ this.configService.baseUrl }/my-home/rent-collection/overview`, {params});
    }

    getMyRentDueRecord(rentDueRecordGuid: string): Observable<RentDueRecord> {
        return this.http.get<RentDueRecord>(`${ this.configService.baseUrl }/my-home/rent-collection/rent-due-records/${ rentDueRecordGuid }`);
    }

    getRentDueRecords(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, page: number,
                      outstanding: boolean = false, futureOnly: boolean = false, dateFrom: string = null, rentOnly: boolean = false): Observable<RentDueRecord[]> {
        let params = new HttpParams();
        if (page) {
            params = params.set('page', page.toString());
        }
        if (tenancyGuid) {
            params = params.set('tenancyGuid', tenancyGuid);
        }
        if (outstanding) {
            params = params.set('outstanding', 'true');
        }
        if (futureOnly) {
            params = params.set('futureOnly', 'true');
        }
        if (dateFrom) {
            params = params.set('dateFrom', dateFrom);
        }

        if (rentOnly) {
            params = params.set('rentOnly', 'true');
        }

        return this.http.get<RentDueRecord[]>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records`, {params});
    }

    getRentDueRecord(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string): Observable<RentDueRecord> {
        return this.http.get<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }`);
    }

    getRentDueRecordByReminder(organisationReference: string, propertyReference: string, roomReference: string, rentReminderGuid: string): Observable<RentDueRecord> {
        let params = new HttpParams();
        params = params.set('rentReminderGuid', rentReminderGuid);

        return this.http.get<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/by-reminder`, {params});
    }

    getRentDueRecordByMissedPayment(organisationReference: string, propertyReference: string, roomReference: string, missedPaymentGuid: string): Observable<RentDueRecord> {
        let params = new HttpParams();
        params = params.set('missedPaymentGuid', missedPaymentGuid);

        return this.http.get<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/by-missed-payment`, {params});
    }

    getMyRentDueRecordByReminder(rentReminderGuid: string): Observable<RentDueRecord> {
        let params = new HttpParams();
        params = params.set('rentReminderGuid', rentReminderGuid);


        return this.http.get<RentDueRecord>(`${ this.configService.baseUrl }/my-home/rent-collection/rent-due-records/by-reminder`, {params});
    }

    deleteRentPayment(organisationReference: string, propertyReference: string, roomReference: string, rentPaymentGuid: string): Observable<void> {
        return this.http.delete<void>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-payments/${ rentPaymentGuid }`);
    }

    deleteRentPaymentAssociation(organisationReference: string, propertyReference: string, roomReference: string, rentPaymentGuid: string, rentPaymentAssociationGuid: string): Observable<void> {
        return this.http.delete<void>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-payments/${ rentPaymentGuid }/association/${rentPaymentAssociationGuid}`);
    }

    regenerateFinancialItemsForRdr(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, rdrGuid: string): Observable<void> {
        return this.http.put<void>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${tenancyGuid}/rent-collection/rent-due-record/${ rdrGuid }/regenerate-financial-items`, {});
    }

    regenerateFinancialItemsForTenancy(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): Observable<void> {
        return this.http.put<void>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${tenancyGuid}/rent-collection/regenerate-financial-items`, {});
    }
    regenerateFinancialItemsForProperties(organisationReference: string, propertyReferences: string[]){
        let params = new HttpParams();
        propertyReferences.forEach(reference => params = params.set('propertyReferences', reference));

        return this.http.get<{
            tenanciesSuccesses: string;     // Guids
            failures: {[p:string]: string}; // Tenancy Guid ==> Error message
            hasFailures: boolean;
        }>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/regenerate-financial-items?${ params.toString() }`);
    }

    generateRentDueRecords(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, startDate: string, endDate: string, ignoreFirstProRata: boolean,
                           rentDetails: RentDetails, proRataFinalRent: boolean = false, periodic: boolean = false, proRataCalculationAnnual: boolean = false,
                           rentChangeDate: string = null, newRent: number = null, newRentTax: number = null, newRentTaxPercentage: number = null): Observable<RentDueRecordScheduleItem[]> {

        let params = new HttpParams();
        params = params.set('tenancyGuid', tenancyGuid);
        params = params.set('startDate', startDate);
        if (endDate) params = params.set('endDate', endDate);
        params = params.set('rent', rentDetails.rent.toString());
        params = params.set('rentTax', rentDetails.rentTax?.toString());
        params = params.set('rentTaxPercentage', rentDetails?.rentTaxPercentage.toString());
        params = params.set('paymentFrequency', rentDetails.paymentFrequency.toString());

        if (ignoreFirstProRata) {
            params = params.set('ignoreFirstProRata', 'true');
        }

        if (proRataFinalRent) {
            params = params.set('proRataFinalRent', 'true');
        }

        if (proRataCalculationAnnual) {
            params = params.set('proRataCalculationAnnual', 'true');
        }

        if (periodic) {
            params = params.set('periodic', 'true');
        }

        if ((rentDetails.paymentFrequency === RentPaymentFrequencies.MONTH) && rentDetails.rentDayOfMonth || rentDetails.paymentFrequency === RentPaymentFrequencies.QUARTER || rentDetails.paymentFrequency === RentPaymentFrequencies.ANNUAL) {
            params = params.set('rentDayOfMonth', rentDetails.rentDayOfMonth.toString());
        } else if ((rentDetails.paymentFrequency === RentPaymentFrequencies.WEEK) && rentDetails.rentDayOfWeek) {
            params = params.set('rentDayOfWeek', rentDetails.rentDayOfWeek.toString());
        } else if (rentDetails.paymentFrequency === RentPaymentFrequencies.FOUR_WEEK || rentDetails.paymentFrequency === RentPaymentFrequencies.FORTNIGHT) {
            params = params.set('rentStartDate', rentDetails.rentStartDate);
        }

        if (rentChangeDate != null) {
            params = params.set('rentChangeDate', rentChangeDate);
            params = params.set('newRent', newRent);
            params = params.set('newRentTax', newRentTax);
            params = params.set('newRentTaxPercentage', newRentTaxPercentage);
        }

        return this.http.get<RentDueRecordScheduleItem[]>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/rent-collection/generate-rent-due-records`, {params});
    }

    getRentDueRecordsFilterResults(organisationReference: string, propertyReference: string, filterModel: RentDueRecordsFilter): Observable<RentDueRecordsFilterResults> {

        let url = `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/rent-collection/rent-due-records/filter`;
        if (propertyReference != null) {
            url = `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/rent-collection/rent-due-records/filter`;
        }

        let params = new HttpParams();
        if (filterModel.fromDate != null) {
            params = params.set('fromDate', moment(filterModel.fromDate).utc().toISOString());
        }
        if (filterModel.toDate != null) {
            params = params.set('toDate', moment(filterModel.toDate).utc().toISOString());
        }

        if (filterModel.includeOverdue) {
            params = params.set('includeOverdue', 'true');
        }

        if (filterModel.includeRentOnly) {
            params = params.set('includeRentOnly', 'true');
        }

        if (filterModel.tenantStatus) {
            params = params.set('tenantStatus', filterModel.tenantStatus);
        }


        if (filterModel.moneyTypes) {
            filterModel.moneyTypes.forEach(moneyType => {
                params = params.append('moneyTypes', moneyType);
            });
        }

        params = params.set('filterGrouping', filterModel.filterGrouping.toString());

        return this.http.get<RentDueRecordsFilterResults>(url, {params});
    }

    getSummary(organisationReference: string, propertyReference: string): Observable<RentCollectionSummary> {

        let url = `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/rent-collection/rent-due-records/summary`;
        if (propertyReference != null) {
            url = `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/rent-collection/rent-due-records/summary`;
        }


        return this.http.get<RentCollectionSummary>(url);
    }

    generateActionCSV(organisationReference: string, propertyReferences: string[] | null, filterModel: RentDueRecordsFilter, paymentReference: string, paymentStatus: string, selectedCategory: number): string {
        let params = new HttpParams();
        if (filterModel.fromDate != null) {
            params = params.set('fromDate', moment(filterModel.fromDate).utc().toISOString());
        }
        if (filterModel.toDate != null) {
            params = params.set('toDate', moment(filterModel.toDate).utc().toISOString());
        }

        if (filterModel.includeOverdue) {
            params = params.set('includeOverdue', 'true');
        }

        if (filterModel.includeRentOnly) {
            params = params.set('includeRentOnly', 'true');
        }

        if (filterModel.tenantStatus) {
            params = params.set('tenantStatus', filterModel.tenantStatus);
        }

        if (paymentStatus !== null && paymentStatus !== '') {
            params = params.set('paymentStatus', paymentStatus);
        }

        if (paymentReference !== null && paymentReference !== '') {
            params = params.set('paymentReference', paymentReference);
        }

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        if (filterModel.moneyTypes) {
            filterModel.moneyTypes.forEach(moneyType => {
                params = params.append('moneyTypes', moneyType);
            });
        }

        params = params.set('selectedCategory', selectedCategory);

        params = params.set('filterGrouping', filterModel.filterGrouping.toString());
        params = params.append('access_token', this.accountService.accessToken.bearerToken);



        return `${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/rent-collection/rent-due-records/filter/csv?${ params.toString() }`;
    }

    addRentReminder(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string, model: RentReminderPost): Observable<RentDueRecord> {
        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/reminder`, model);
    }

    getRentReminderContent(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string, rentReminderGuid: string, medium: RentReminderMediums): Observable<RentReminderContent> {

        let params = new HttpParams();
        params = params.set('medium', medium);

        let model = new RentReminderContentPost();

        return this.http.post<RentReminderContent>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/reminder/${ rentReminderGuid }/content`, model, {params});
    }

    getMyRentReminderContent(rentDueRecordGuid: string, rentReminderGuid: string, medium: RentReminderMediums): Observable<RentReminderContent> {

        let params = new HttpParams();
        params = params.set('medium', medium);

        let model = new RentReminderContentPost();

        return this.http.post<RentReminderContent>(`${ this.configService.baseUrl }/my-home/rent-collection/rent-due-records/${ rentDueRecordGuid }/reminder/${ rentReminderGuid }/content`, model, {params});
    }

    getRentReminderPreview(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string, model: RentReminderContentPost, medium: RentReminderMediums): Observable<RentReminderContent> {

        let params = new HttpParams();
        params = params.set('medium', medium);

        return this.http.post<RentReminderContent>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/reminder/preview`, model, {params});
    }

    restoreRentDueRecord(organisationReference: string, propertyReference: string, roomReference: string, rentDueRecordGuid: string): Observable<RentDueRecord> {
        return this.http.post<RentDueRecord>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/rent-collection/rent-due-records/${ rentDueRecordGuid }/restore`, null);
    }

    getCreditControlSettings(organisationReference: string) : Observable<OrganisationCreditControlSettings> {
        return this.http.get<OrganisationCreditControlSettings>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-settings`);
    }

    patchCreditControlSettings(organisationReference: string, model: OrganisationCreditControlSettingsPatch) {
        return this.http.patch<void>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-settings`, model);
    }

    patchCreditControlTenancyDetails(organisationReference: string, tenancyGuid: string, model: CreditControlTenancyDetailsPatch) {
        return this.http.patch<CreditControlTenancyDetails>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-tenancy-details/${tenancyGuid}`, model);
    }

    createCreditControlSeedNotificationTemplates(organisationReference: string) {
        return this.http.post<void>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification-template/create-seed-templates`, {});
    }

    getCreditControlNotificationTemplates(organisationReference: string, showOnlyArchived: boolean = false) {
        let params = new HttpParams();
        params = params.set('showOnlyArchived', showOnlyArchived ? "true" : "false");
        return this.http.get<OrganisationCreditControlNotificationTemplate[]>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification-template`, { params: params });
    }

    addCreditControlNotificationTemplate(organisationReference: string, model: OrganisationCreditControlNotificationTemplatePost) {
        return this.fileService.uploadFiles(model.serveFiles)
            .pipe(tap(_ => model.serveFiles = this.fileService.prepareFilePosts(model.serveFiles)),
                mergeMap(_ => this.http.post<OrganisationCreditControlNotificationTemplate>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification-template`, model)));
    }

    editCreditControlNotificationTemplate(organisationReference: string, model: OrganisationCreditControlNotificationTemplatePatch) {
        return this.fileService.uploadFiles(model.serveFiles)
            .pipe(tap(_ => model.serveFiles = this.fileService.prepareFilePosts(model.serveFiles)),
                mergeMap(_ => this.http.patch<OrganisationCreditControlNotificationTemplate>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification-template/${model.templateReference}`, model)));
    }

    deleteCreditControlNotificationTemplate(organisationReference: string, notificationTemplateReference: string) {
        return this.http.post<true>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification-template/${notificationTemplateReference}/set-entity-status/${BaseEntityStatuses.DELETED}`, {});
    }

    archiveCreditControlNotificationTemplate(organisationReference: string, notificationTemplateReference: string) {
        return this.http.post<true>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification-template/${notificationTemplateReference}/set-entity-status/${BaseEntityStatuses.ACTIVE_AND_ARCHIVED}`, {});
    }

    unarchiveCreditControlNotificationTemplate(organisationReference: string, notificationTemplateReference: string) {
        return this.http.post<true>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification-template/${notificationTemplateReference}/set-entity-status/${BaseEntityStatuses.ACTIVE_NOT_ARCHIVED}`, {});
    }

    previewCreditControlNotificationTemplate(organisationReference: string, notificationTemplateReference: string, executionPayload: CreditControlNotificationExecutionPayload | null = null) {
        let params = new HttpParams();
        if (executionPayload) {
            params = params.set('tenancyGuid', executionPayload.tenancyGuid);
            if (executionPayload.daysOverdue) params = params.set('daysOverdue', executionPayload.daysOverdue);
            if (executionPayload.amountOverdue) params = params.set('amountOverdue', executionPayload.amountOverdue);
        }

        return this.http.get<OrganisationCreditControlNotificationPreview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification-template/${notificationTemplateReference}/preview`, {params});
    }

    executeCreditControlNotificationTemplate(organisationReference: string, executionPayload: CreditControlNotificationExecutionPayload | null = null) {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification-template/execute`, executionPayload);
    }

    previewNewCreditControlNotificationTemplate(organisationReference: string, model: OrganisationCreditControlNotificationTemplatePreviewPost) {
        return this.http.post<OrganisationCreditControlNotificationPreview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification-template/preview`, model);
    }

    // Returns a lookup string(guid/reference) --> DatetimeString of last execution
    getCreditControlTenancyLastRunsByTemplate(organisationReference: string, tenancyGuid: string) {
        return this.http.get<{[p: string]: Date}>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/rent-collection/credit-control-notification/${tenancyGuid}/get-latest-sent-summary`);
    }

    generateMyRentSchedule(tenancyGuid: string) {
        return `${ this.configService.baseUrl }/my-home/rent-collection/rent-due-records/csv?tenancyGuid=${tenancyGuid}`;
    }

    importRentItems(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string, model: RentItemImport) : Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/property/${ propertyReference }/room/${ roomReference }/tenancy/${tenancyGuid}/rent-collection/import`, model);
    }

    importRentItemsOrganisation(organisationReference: string, model: RentItemImport) : Observable<boolean> {
        return this.http.post<boolean>(`${ this.configService.baseUrl }/manage/organisation/${ organisationReference }/rent-collection/import`, model);
    }

    getRentImportTemplate(organisationReference: string, propertyReference: string, roomReference: string, tenancyGuid: string): string {
        let params = new HttpParams();
        params = params.append('access_token', this.accountService.accessToken.bearerToken);

        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}`;

        if (propertyReference != null) {
            url = `${url}/property/${propertyReference}`;
        }

        if (roomReference != null) {
            url = `${url}/room/${roomReference}`;
        }

        if (tenancyGuid != null) {
            url = `${url}/tenancy/${tenancyGuid}`;
        }

        url = `${url}/rent-collection/import/template?${params.toString()}`;

        return url;
    }

    getOrganisationRentCollectionSettings(organisationReference: string){
        const url = `${ this.configService.baseUrl }/manage/organisation/${organisationReference}/rent-collection/settings`
        return this.http.get<OrganisationRentCollectionSettings>(url);
    }

    patchOrganisationRentCollectionSettings(organisationReference: string, model: OrganisationRentCollectionSettingsPatch){
        return this.http.patch<OrganisationRentCollectionSettings>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/rent-collection/settings`, model)
    }

    redirectHoldingFee(organisationReference: string, propertyReference: string, roomReference: string, holdingFeeRentDueRecordGuid: string, rentPaymentGuid: string, model: RentPaymentAssignPost) {
        return this.http.patch<RentPayment>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/room/${roomReference}/rent-collection/redirect-holding-fee/${holdingFeeRentDueRecordGuid}/${rentPaymentGuid}`, model);
    }

    getOrganisationMissingCharges(organisationReference: string, filterModel: MissingRentChargesFilter): Observable<MissingRentCharge[]> {
        let params = new HttpParams();
        if (filterModel.fromDate != null) {
            params = params.set('fromDate', moment(filterModel.fromDate).utc().toISOString());
        }
        if (filterModel.toDate != null) {
            params = params.set('toDate', moment(filterModel.toDate).utc().toISOString());
        }
        if (filterModel.rentPaymentFrequency != null) {
            params = params.set('rentPaymentFrequency', filterModel.rentPaymentFrequency);
        }
        if (filterModel.groupByDate != null) {
            params = params.set('groupByDate', filterModel.groupByDate);
        }
        if (filterModel.includeMismatchedRentAmounts != null) {
            params = params.set('includeMismatchedRentAmounts', filterModel.includeMismatchedRentAmounts);
        }

        return this.http.get<MissingRentCharge[]>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/rent-collection/missing-charges`, {params});
    }

    setIgnoreStatusMissingRentCharge(organisationReference: string, missingRentCharge: MissingRentCharge, ignore: boolean): Observable<void> {
        let params = new HttpParams();
        if (ignore != null) {
            params = params.set('ignore', ignore);
        }
        if (missingRentCharge.tenancyGuid != null) {
            params = params.set('tenancyGuid', missingRentCharge.tenancyGuid);
        }
        if (missingRentCharge.rentPaymentFrequency != null) {
            params = params.set('rentPaymentFrequency', missingRentCharge.rentPaymentFrequency);
        }
        if (missingRentCharge.monthStart != null) {
            params = params.set('dateStart', missingRentCharge.monthStart);
        } else if (missingRentCharge.weekStart != null) {
            params = params.set('dateStart', missingRentCharge.weekStart);
        }

        return this.http.patch<void>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/rent-collection/set-ignore-status`, null, {params});
    }
}
