import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { DateTime } from 'luxon';
import { ErrorSummary } from "../../models/error/error-summary";
import { NavigateService } from "../../services/navigate.service";

@Component({
    selector: 'error-summary',
    templateUrl: './error-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    host: {'class': 'error-summary'},
})

export class ErrorSummaryComponent {

    @Input() errors: ErrorSummary = null;

    constructor(private navigateService: NavigateService) {

    }

    private getControlKey(index: number, key: string): string {
        const control = this.errors[index][key];
        let controlKey = '';
        if (control.parent) {
            controlKey = Object.keys(control.parent.controls).find(key => control.parent.controls[key] === control);
        } else if (control instanceof UntypedFormGroup) {
            controlKey = Object.keys(control.controls).find(key => control.controls[key] === control);
        }
        return `[formcontrolname="${controlKey}"]`
    }

    goToControl(index: number, key: string) {
        let formControlKey = this.getControlKey(index, key);

        this.navigateService.scrollToElement(formControlKey);
    }

    controlExists(index: number, key: any) {
        let formControlKey = this.getControlKey(index, key);
        return document.querySelector(formControlKey) !== null;
    }

    hasErrors() {
        if (!this.errors) {
            return false;
        }

        return this.errors.some(error => Object.values(error).some(err => err.invalid));
    }

    invalidDateTime(){
        if (!this.errors) {
            return false;
        }

        return this.errors.some(error => Object.values(error).some(err => err.value instanceof DateTime && err.value.invalid))
    }
}
