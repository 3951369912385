import { Injectable } from '@angular/core';


import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {PlatformLocation} from "@angular/common";


@Injectable()
export class ConfigService {
    baseHref: string = this.platformLocation.getBaseHrefFromDOM();
    baseUrl = environment.baseUrl;
    webUrl = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    liveWebUrl = environment.liveWebUrl;
    staticWebUrl = environment.staticWebUrl;
    demoWebUrl = environment.demoWebUrl;
    isDev = !environment.production;
    isDemo = environment.demo;
    googleAnalyticsId = environment.googleAnalyticsId;
    tinyMceKey = environment.tinyMceKey;
    googleMapsToken = environment.googleMapsToken;
    paysafeAccountId = environment.paysafeAccountId;
    paysafeKey = environment.paysafeKey;
    logRocketKey= environment.logRocketKey;
    openReplayKey = environment.openReplayKey;
    stripeKey = environment.stripeKey;
    pdfJsKey = environment.pdfJsKey;

    constructor(private platformLocation: PlatformLocation) {
    }
}
