import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { PropertyService } from '../models/property/property-service';
import { ServiceParameter } from '../models/service/service-parameter';
import { PropertyServiceParameter } from '../models/property/property-service-parameter';


@Injectable()
export class PropertyServiceService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    updatePropertyServiceParameter(propertyService: PropertyService, fieldName: string, fieldValue: string): PropertyService {
        let found = false;

        propertyService.propertyServiceParameters.forEach(function (propertyServiceParameter) {
            if (propertyServiceParameter.serviceParameter.reference == fieldName) {
                propertyServiceParameter.fieldValue = fieldValue;
                found = true;
            }
        });

        if (!found) {

            let newPropertyServiceParameter = new PropertyServiceParameter();
            newPropertyServiceParameter.fieldValue = fieldValue;
            newPropertyServiceParameter.serviceParameter = new ServiceParameter();
            newPropertyServiceParameter.serviceParameter.reference = fieldName;

            propertyService.propertyServiceParameters.push(newPropertyServiceParameter);
        }

        return propertyService;
    }
}
