import { Injectable } from '@angular/core';
import { addProfile, CamelCaseNamingConvention, createMap, createMapper, forMember, mapFrom, Mapper, MappingProfile } from '@automapper/core';
import { classes } from '@automapper/classes';

import { FinancialItem } from '../models/finance/financial-item';
import { FinancialItemPost } from '../models/finance/financial-item-post';
import {MaintenanceIssue} from "../models/maintenance/maintenance-issue";
import {FinancialItemParentCategories} from "../models/finance/financial-item-parent-categories";
import {MaintenanceSupplier} from "../models/maintenance/maintenance-supplier";
import {FinancialItemInvoice} from "../models/finance/financial-item-invoice";
import {File} from "../models/file/file";
import {OrganisationSupplier} from "../models/organisation-supplier/organisation-supplier";
import {SupplierInvoice} from "../models/maintenance/maintenance-invoice";
import {FinancialItemCarriedOutByTypes} from "../models/finance/financial-item-carried-out-by-types";
import { FinancialItemPaidStatuses } from '../models/finance/financial-item-paid-statuses';

const namingConventions = {
    source: new CamelCaseNamingConvention(),
    destination: new CamelCaseNamingConvention()
}

@Injectable()
export class AutomapperService {
    mapper: Mapper;

    constructor() {
        this.mapper = createMapper({strategyInitializer: classes(), namingConventions: namingConventions});

        addProfile(this.mapper, financeProfile);
    }
}

const financeProfile: MappingProfile = (mapper) => {
    createMap(mapper,
        FinancialItem,
        FinancialItemPost,
        forMember((destination) => destination.invoiceDate, mapFrom((source) => source.invoiceDate)),
        forMember((destination) => destination.rawAmount, mapFrom((source) => source.rawAmount)),
        forMember((destination) => destination.rawAmountTax, mapFrom((source) => source.rawAmountTax)),
        forMember((destination) => destination.rawTotalAmount, mapFrom((source) => source.rawTotalAmount)),
        forMember((destination) => destination.organisationAmount, mapFrom((source) => source.organisationAmount)),
        forMember((destination) => destination.organisationAmountTax, mapFrom((source) => source.organisationAmountTax)),
        forMember((destination) => destination.organisationTotalAmount, mapFrom((source) => source.organisationTotalAmount)),
        forMember((destination) => destination.clientAmount, mapFrom((source) => source.clientAmount)),
        forMember((destination) => destination.clientAmountTax, mapFrom((source) => source.clientAmountTax)),
        forMember((destination) => destination.clientTotalAmount, mapFrom((source) => source.clientTotalAmount)),
        forMember((destination) => destination.description, mapFrom((source) => source.description)),
        forMember((destination) => destination.notes, mapFrom((source) => source.notes)),
        forMember((destination) => destination.category, mapFrom((source) => source.category?.guid)),
        forMember((destination) => destination.paidStatus, mapFrom((source) => source.paidStatus)),
        forMember((destination) => destination.clientCostType, mapFrom((source) => source.clientCostType)),
        forMember((destination) => destination.showOnSettlement, mapFrom((source) => source.showOnSettlement)),
        forMember((destination) => destination.carriedOutBy, mapFrom((source) => source.carriedOutBy))
    );

    createMap(mapper,
        SupplierInvoice,
        FinancialItem,
        forMember((destination) => destination.invoiceDate, mapFrom((source) => source.invoiceDate)),
        forMember((destination) => destination.invoiceNumber, mapFrom((source) => source.invoiceNumber)),
        forMember((destination) => destination.invoiceDueDate, mapFrom((source) => source.payByDate)),
        forMember((destination) => destination.rawAmount, mapFrom((source) => source.amount)),
        forMember((destination) => destination.rawAmountTax, mapFrom((source) => source.amountTax)),
        forMember((destination) => destination.rawTaxPercentage, mapFrom((source) => source.amountTaxPercentage)),
        forMember((destination) => destination.rawTotalAmount, mapFrom((source) => source.totalAmount)),
        forMember((destination) => destination.parentCategory, mapFrom(() => FinancialItemParentCategories.MAINTENANCE)),
        forMember((destination) => destination.paidStatus, mapFrom(() => FinancialItemPaidStatuses.UNKNOWN)),
        forMember((destination) => destination.carriedOutBy, mapFrom(() => FinancialItemCarriedOutByTypes.EXTERNAL)),
        forMember((destination) => destination.financialItemInvoice, mapFrom((source) => mapper.map(source, SupplierInvoice, FinancialItemInvoice))),
    );

    createMap(mapper,
        SupplierInvoice,
        FinancialItemInvoice,
        forMember((destination) => destination.invoiceDate, mapFrom((source) => source.invoiceDate)),
        forMember((destination) => destination.amount, mapFrom((source) => source.amount)),
        forMember((destination) => destination.files, mapFrom((source) => source.files)),
        forMember((destination) => destination.organisationSupplier, mapFrom((source) => source.organisationSupplier)),
    );
};
