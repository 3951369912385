import {BuildingLogicalType} from "./building-logical-type";
import {BuildingSvgIcon} from "./building-svg-icon";
import {BuildingTier} from "./building-tier";
import {PropertySummary} from "../manage-property/property-summary";
import {Router} from "@angular/router";


export class BuildingPartial {
    parentGuid: string;
    parent: BuildingPartial;
    guid: string;
    reference: string;
    name: string;
    publicName: string;
    logicalType: BuildingLogicalType;
    tier: BuildingTier;
    icon: BuildingSvgIcon;
}

export class Building {
    guid: string;
    parentGuid: string | null;
    tier: BuildingTier;
    name: string;
    publicName: string;
    description: string;
    logicalType: BuildingLogicalType;
    icon: BuildingSvgIcon;

    parent: Building | null;
    childBuildings: Building[];
    properties: PropertySummary[];
    propertyReferences: string[];
    childBuildingGuids: string[];

    static fromPartial(partial: BuildingPartial, parent: Building | null = null): Building {
        const building = new Building();
        building.name = partial.name;
        building.publicName = partial.publicName;
        building.guid = partial.guid;
        building.tier = partial.tier;
        building.icon = partial.icon;
        building.logicalType = partial.logicalType;
        building.description = "- Not Loaded -";

        building.parentGuid = parent ? parent.guid : null;
        building.parent = parent ? parent : null;
        building.childBuildings = [];
        building.childBuildingGuids = [];
        building.propertyReferences = [];
        building.properties = [];

        return building;
    }

    static unloadedBuilding(guid: string): Building {
        const building = new Building();
        building.name = building.publicName = "Unloaded Building";
        building.guid = guid;
        building.parentGuid = null;
        building.childBuildings = [];
        building.childBuildingGuids = [];
        building.propertyReferences = [];
        building.properties = [];
        building.tier = BuildingTier.TOP_LEVEL;
        building.icon = BuildingSvgIcon.MIXED_USE;
        building.logicalType = BuildingLogicalType.NON_PHYSICAL_GROUPING;
        building.description = "- Not Loaded -";

        return building;
    }
}
