import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from './user.service';
import { ConfigService } from './config.service';

@Injectable()
export class AdminAuthGuard implements CanActivate, CanActivateChild {

    constructor(private userService: UserService, private _router: Router, private configService: ConfigService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkIfAdmin();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkIfAdmin();
    }

    private checkIfAdmin(): Observable<boolean> {
        return this.userService.admin();
    }
}
