import {Injectable} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { DeviceInfo } from '../models/device/device-info';

@Injectable()
export class DeviceService {

    constructor(private deviceService: DeviceDetectorService) {  }

    isMobile(): boolean {
        return this.deviceService.isMobile();
    }
    isTablet(): boolean {
        return this.deviceService.isTablet();
    }
    isDesktop(): boolean {
        return this.deviceService.isDesktop();
    }

    getDeviceInfo(): DeviceInfo {
        let device = new DeviceInfo();
        device.browser = this.deviceService.browser;
        device.device  = this.deviceService.device;
        device.browserVersion = this.deviceService.browser_version;
        device.os = this.deviceService.os;
        device.osVersion = this.deviceService.os_version;
        device.ua = this.deviceService.ua;
        device.userAgent = this.deviceService.userAgent;
        return device;
    }

    showCompactView(itemCount: number): boolean {
        if (this.isMobile() == false) {
            return true;
        }
        else {
            return false;
        }
    }


    // Angular library says we will get a MouseEvent, but we are actually getting a PointerEvent. All code is wrapped in a try block so that any errors will just result in no block
    // RETURNS: True if execution is blocked, and False if execution is
    // USAGE:
    //   Option 1 -> From your typescript file like so:
    //        if (deviceService.afterTooltipTrigger(event) == 'execute') { ... your execution logic here }
    //   Option 2 -> In your HTML template file like so:
    //        (click)="deviceService.afterTooltipTrigger($event, this, toggleSnooze, param1ForToggleSnooze, param2ForToggleSnooze, param3...)"
    afterTooltipTrigger(event: PointerEvent | MouseEvent, thisContext: any | null, callback: Function | null, ...params: any[] | null): 'tooltip_shown' | 'execute' | 'tooltip_closed' {
        try {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();

            let target = event.target;
            //console.log("Event");
            //console.log(event);

            // Handle the case where we click on the pop-out, we then close the pop-out
            // @ts-ignore
            if (target.classList.contains('button--tooltip-content')) {
                // @ts-ignore
                target.parentElement.classList.remove('open');
                return 'tooltip_closed';
            }

            // Handle the case where we clicked on the SVG Icon or a paragraph or something within the button-tooltip so that we change our target to the parent which hopefully is the button tooltip
            // @ts-ignore
            if (!target.classList.contains('button--tooltip')) {

                // @ts-ignore
                while (target.parentElement) {
                    // Let's look at the parent for answers
                    // @ts-ignore
                    target = target.parentElement;
                    //console.log("Adjusted target");
                    //console.log(target);

                    // @ts-ignore - Break out of while loop if we found our button
                    if (target.classList.contains('button--tooltip')) break;
                }

                // @ts-ignore
                if (!target || !target.parentElement) {
                    if (callback) callback.apply(thisContext, params);
                    return 'execute';
                }
            }


            // If the tooltip is already open we are done and we don't block the click, but we do remove the tooltip so a second click removes it
            // @ts-ignore
            if (target.classList.contains('open')) {
                // @ts-ignore
                target.classList.remove('open');

                if (callback) callback.apply(thisContext, params);
                return 'execute';
            }


            // Fallback check, Find the child element that is the content to determine whether it is visible (so we handle the hover case), if it is already visible then we don't block the click
            let tooltip = null;
            // @ts-ignore
            target.childNodes.forEach(child => {
                if (child.classList.contains('button--tooltip-content')) tooltip = child;
            })
            //console.log("tooltip", tooltip);
            if (tooltip) {
                const style = window.getComputedStyle(tooltip);
              //  console.log(style);
//                console.log(style.visibility);
                if (style.visibility == 'visible') {

                    if (callback) callback.apply(thisContext, params);
                    return 'execute';
                }
            }


            // If we reached this point it means the tooltip is neither visible nor has the OPEN class, so we add the open class to make it visible and we create a global click callback that fires once which will
            //   remove the tooltip open class (hence closing it).

            // Add the class name for opening the tooltip so the user can see it
            // @ts-ignore
            target.classList.add('open');

            // Remove the class on the next click event no matter where it is on the page
            // @ts-ignore
            document.addEventListener('click', (event) => target.classList.remove('open'), { once: true })

            // Return true, we have blocked the click from registering
            return 'tooltip_shown';
        }
        catch (exception) {
            console.log("Error handling tooltip management");
            console.log(exception);

            if (callback) callback.apply(thisContext, params);
            return 'execute';
        }
    }
}
