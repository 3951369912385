import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from './account.service';
import { ConfigService } from './config.service';

@Injectable()
export class IsDemoAuthGuard implements CanActivate, CanActivateChild {

    constructor(private accountService: AccountService, private _router: Router, private configService: ConfigService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.redirectIfNotDemo();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.redirectIfNotDemo();
    }

    private redirectIfNotDemo(): boolean {
        if (!this.configService.isDemo && !this.configService.isDev) {
            const liveUrl = this.configService.liveWebUrl;
            window.location.replace(liveUrl + 'management');
        } else {
            return true;
        }
    }
}
