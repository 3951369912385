import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {mergeMap, Observable} from 'rxjs';

import { ConfigService } from './config.service';
import { FireSafetyRecord } from '../models/fire-safety/fire-safety-record';
import { FireSafetyRecordPost } from '../models/fire-safety/fire-safety-record-post';
import { FireSafetyOverview } from '../models/fire-safety/fire-safety-overview';
import { FileService } from "./file.service";
import { FireSafetyRecordType } from '../models/fire-safety/fire-safety-record-type';
import {PropertyServicesSummary} from "../models/property-services/property-services-summary.interface";
import {tap} from "rxjs/operators";

@Injectable()
export class FireSafetyService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOverview(propertyReference: string): Observable<FireSafetyOverview> {
        return this.http.get<FireSafetyOverview>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/fire-safety/overview`);
    }

    getFireSafetyRecordTypes(propertyReference: string): Observable<FireSafetyRecordType[]> {
        return this.http.get<FireSafetyRecordType[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/fire-safety/types`);
    }

    getPastList(propertyReference: string): Observable<FireSafetyRecord[]> {
        return this.http.get<FireSafetyRecord[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/fire-safety/past`);
    }

    add(propertyReference: string, model: FireSafetyRecordPost): Observable<HttpEvent<FireSafetyRecord>> {
        return this.fileService.uploadFiles(model.fireSafetyRecordFiles)
            .pipe(tap(_ => model.fireSafetyRecordFiles = this.fileService.prepareFilePosts(model.fireSafetyRecordFiles)),
                mergeMap(_ => this.http.post<FireSafetyRecord>(`${this.configService.baseUrl}/manage/property/${propertyReference}/fire-safety`, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(propertyReference: string, item: FireSafetyRecord): Observable<boolean> {
        return this.http.delete<boolean>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/fire-safety/${ item.guid }`);
    }

    getUnapprovedRecord(propertyReference: string, recordReference: string): Observable<FireSafetyRecord> {
        return this.http.get<FireSafetyRecord>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/fire-safety/unapproved/${ recordReference }`);
    }

    approveRecord(propertyReference: string, recordReference: string): Observable<FireSafetyRecord> {
        return this.http.patch<FireSafetyRecord>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/fire-safety/${ recordReference }/approve`, null);
    }

    rejectRecord(propertyReference: string, recordReference: string): Observable<boolean> {
        return this.http.patch<boolean>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/fire-safety/${ recordReference }/reject`, null);
    }

    getSummaries(propertyReference: string) {
        return this.http.get<PropertyServicesSummary[]>(`${ this.configService.baseUrl }/manage/property/${ propertyReference }/fire-safety/summaries`);
    }
}
