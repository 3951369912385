// @ts-ignore

import { Component, ViewEncapsulation, Input, forwardRef, ViewChild, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from '../../models/forms/base-control-value-accessor';
import { UtilitiesService } from '../../services/utilities.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {MessageService} from "primeng/api";

@Component({
    selector: 'toast',
    templateUrl: './toast.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ToastComponent {

    constructor(private messageService: MessageService) {
    }
    onReject() {
        this.messageService.clear('confirm');
    }

    onConfirm() {
        this.messageService.clear('confirm');
    }
}
