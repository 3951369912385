<textarea
       [attr.id]="id"
       [rows]="rows"
       [(ngModel)]="value"
       (change)="internalOnChange($event)"
       (keyup)="internalOnChange($event)"
       [class]="cssClasses"
       (keydown.enter)="internalOnEnter($event)"
       [class.required]="invalid && touched"
       [placeholder]="placeholder ? placeholder : ''"
       #multilineTextbox>
</textarea>

<div class="character_count" *ngIf="showCharacterCount && characterDiff < 100">
    <p class="u-text--small u-text--bold u-text-color--pink u-margin-bottom--none">{{ characterCount }}/{{ maxLengthValue }}</p>
</div>
