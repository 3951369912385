export class OrganisationPerson {
    guid: string;
    email: string;
    forename: string;
    surname: string;
    currentUser: boolean;
    // roleName: string;
    // role: number;
    // accepted: boolean;
    // image: Image;
}

export enum OrganisationPersonFilterPresets {
    NONE = "preset:none",
    MINE_AND_UNASSIGNED = "preset:mine-and-unassigned",
    MINE = "preset:mine",
    UNASSIGNED = "preset:unassigned",
    ALL = "preset:all"
}

export interface OrganisationPersonFilterDefaultPost {
    default: OrganisationPersonFilterPresets;
    panel: Panels
}

export enum Panels { //add more if needed
    MAINTENANCE,
    ONBOARDING,
    ACTIONS,
    CALENDAR,
    VIEWING
}
