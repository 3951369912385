import { AgeRanges } from '../models/user/age-ranges';
import {DateTime} from "luxon";

export class AgeRangeService {
    getAgeRangeText(ageRange: AgeRanges, plural: boolean) {
        let ageRangeText = '';

        switch (ageRange) {
            case AgeRanges.UNDER_20:
                ageRangeText = 'Under 20';
                break;
            case AgeRanges.EARLY_20:
                ageRangeText = 'Early 20';
                break;
            case AgeRanges.MID_20:
                ageRangeText = 'Mid 20';
                break;
            case AgeRanges.LATE_20:
                ageRangeText = 'Late 20';
                break;
            case AgeRanges.EARLY_30:
                ageRangeText = 'Early 30';
                break;
            case AgeRanges.MID_30:
                ageRangeText = 'Mid 30';
                break;
            case AgeRanges.LATE_30:
                ageRangeText = 'Late 30';
                break;
            case AgeRanges.EARLY_40:
                ageRangeText = 'Early 40';
                break;
            case AgeRanges.MID_40:
                ageRangeText = 'Mid 40';
                break;
            case AgeRanges.LATE_40:
                ageRangeText = 'Late 40';
                break;
            case AgeRanges.OVER_50:
                ageRangeText = 'Over 50';
                break;
        }

        if (ageRangeText && plural && ageRange != AgeRanges.OVER_50 && ageRange != AgeRanges.UNDER_20) {
            ageRangeText = ageRangeText + 's';
        }

        return ageRangeText;
    }

    getAgeRangesText(ageRangeMin: AgeRanges, ageRangeMax: AgeRanges, anyAgeText: string, prefix: string) {

        let tempText = '';

        if (ageRangeMin == AgeRanges.UNDER_20 && ageRangeMax == AgeRanges.OVER_50) {
            tempText = anyAgeText;
        } else if (ageRangeMax == null || ageRangeMax == AgeRanges.OVER_50) {
            tempText = prefix + this.getAgeRangeText(ageRangeMin, true) + ' and over';
        } else if (ageRangeMax == null || ageRangeMin == AgeRanges.OVER_50) {
            tempText = 'aged 50 and over';
        } else if (ageRangeMin == null || ageRangeMin == AgeRanges.UNDER_20) {
            tempText = prefix + this.getAgeRangeText(ageRangeMax, true) + ' and under';
        } else if (ageRangeMin == null || ageRangeMax == AgeRanges.UNDER_20) {
            tempText = 'aged under 20';
        } else {
            if ((this.isIn20s(ageRangeMin) && this.isIn20s(ageRangeMax))
                || (this.isIn30s(ageRangeMin) && this.isIn30s(ageRangeMax))
                || (this.isIn40s(ageRangeMin) && this.isIn40s(ageRangeMax))
            ) {
                if (this.isEarly(ageRangeMin) && this.isLate(ageRangeMax)) {
                    tempText = prefix + this.get203040(ageRangeMin);
                } else {
                    tempText = prefix + this.getEarlyMidLate(ageRangeMin) + ' to ' + this.getEarlyMidLate(ageRangeMax) + ' ' + this.get203040(ageRangeMin);
                }
            } else {
                tempText = prefix + this.getAgeRangeText(ageRangeMin, true) + ' to ' + this.getAgeRangeText(ageRangeMax, true);
            }
        }

        return tempText;
    }


    isEarly(ageRange: AgeRanges) {
        return ageRange == AgeRanges.EARLY_20 || ageRange == AgeRanges.EARLY_30 || ageRange == AgeRanges.EARLY_40;
    }

    isLate(ageRange: AgeRanges) {
        return ageRange == AgeRanges.LATE_20 || ageRange == AgeRanges.LATE_30 || ageRange == AgeRanges.LATE_40;
    }

    isIn20s(ageRange: AgeRanges) {
        return ageRange == AgeRanges.EARLY_20 || ageRange == AgeRanges.MID_20 || ageRange == AgeRanges.LATE_20;
    }

    isIn30s(ageRange: AgeRanges) {
        return ageRange == AgeRanges.EARLY_30 || ageRange == AgeRanges.MID_30 || ageRange == AgeRanges.LATE_30;
    }

    isIn40s(ageRange: AgeRanges) {
        return ageRange == AgeRanges.EARLY_40 || ageRange == AgeRanges.MID_40 || ageRange == AgeRanges.LATE_40;
    }

    getEarlyMidLate(ageRange: AgeRanges) {
        switch (ageRange) {
            case AgeRanges.EARLY_20:
            case AgeRanges.EARLY_30:
            case AgeRanges.EARLY_40:
                return 'early';
            case AgeRanges.MID_20:
            case AgeRanges.MID_30:
            case AgeRanges.MID_40:
                return 'mid';
            case AgeRanges.LATE_20:
            case AgeRanges.LATE_30:
            case AgeRanges.LATE_40:
                return 'late';
        }
    }

    get203040(ageRange: AgeRanges) {
        switch (ageRange) {
            case AgeRanges.EARLY_20:
            case AgeRanges.MID_20:
            case AgeRanges.LATE_20:
                return '20s';
            case AgeRanges.EARLY_30:
            case AgeRanges.MID_30:
            case AgeRanges.LATE_30:
                return '30s';
            case AgeRanges.EARLY_40:
            case AgeRanges.MID_40:
            case AgeRanges.LATE_40:
                return '40s';
        }
    }

    getAgeRangeFromDate(birthdate: Date): AgeRanges {
        const years = DateTime.now().diff(DateTime.fromJSDate(birthdate), 'years').years;

        if (years < 20) {
            return AgeRanges.UNDER_20;
        } else if (years <= 23) {
            return AgeRanges.EARLY_20;
        } else if (years <= 27) {
            return AgeRanges.MID_20;
        } else if (years <= 29) {
            return AgeRanges.LATE_20;
        } else if (years <= 33) {
            return AgeRanges.EARLY_30;
        } else if (years <= 37) {
            return AgeRanges.MID_30;
        } else if (years <= 39) {
            return AgeRanges.LATE_30;
        } else if (years <= 43) {
            return AgeRanges.EARLY_40;
        } else if (years <= 47) {
            return AgeRanges.MID_40;
        } else if (years <= 49) {
            return AgeRanges.LATE_40;
        } else if (years >= 50) {
            return AgeRanges.OVER_50;
        } else {
            return AgeRanges.UNSPECIFIED;
        }
    }
}
