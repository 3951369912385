import { Injectable } from "@angular/core";
import {TenancySummary} from "../models/tenancy/tenancy-summary";
import {PropertySummary} from "../models/manage-property/property-summary";
import {RoomSummary} from "../models/manage-room/room-summary";
import {TenantSummary} from "../models/tenancy/tenant-summary";
import {Router} from "@angular/router";

@Injectable()
export class NavigateService {

    constructor(private router: Router) {

    }
    scrollToElement(selector: string, additionalOffset: number = 0): void {
        const element = document.querySelector(selector);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - additionalOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });

        }
    }

    scrollToNativeElement(element: HTMLElement): void {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    scrollToTop(el) {
        var to = 0;
        var duration = 300;
        var start = el.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var easeInOutQuad = function (t, b, c, d) {
            t /= d / 2;
            if (t < 1)
                return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        }

        var animateScroll = function () {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);

            el.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        }
        animateScroll();
    }





    // Shared code for generating Routes
    navigateToRoute_Tenancy(tenancy: TenancySummary): Promise<boolean> {
        return this.router.navigate(this.getRoute_Tenancy(tenancy));
    }

    getRoute_Tenancy(tenancy: TenancySummary): string[] {
        const params = this.getRouterLinkParams({ tenancy: tenancy }, 'tenancy');
        return ['/manage', 'property', params.propertyReference, params.roomReference, params.tenancyGuid];
    }

    // Prepare the params and log errors. Returns NULL if the depth requested is not achievable
    getRouterLinkParams(params: {
        // Primary references/guids. If set these are used
        propertyReference?: string;
        roomReference?: string;
        tenancyGuid?: string;
        tenancyTenantGuid?: string;
        // Objects/Secondary. If set the references/guids are retrieved out of this
        property?: PropertySummary;
        room?: RoomSummary;
        tenancy?: TenancySummary;
        tenant?: TenantSummary;
    }, depth: 'tenant' | 'tenancy' | 'room' | 'property'): { propertyReference?: string; roomReference?: string; tenancyGuid?: string; tenancyTenantGuid?: string; }
    {
        const routeParams : {
            propertyReference?: string;
            roomReference?: string;
            tenancyGuid?: string;
            tenancyTenantGuid?: string;
        } = {};

        // Guard checks - Switch statement purposefully using 'fallthrough' functionality to create a hierarchy of the cases
        switch (depth) {
            case 'tenant':
                routeParams.tenancyTenantGuid = params.tenancyTenantGuid;

                if (!routeParams.tenancyTenantGuid) {
                    if (params.tenant) routeParams.tenancyTenantGuid = params.tenant.guid;
                }

                if (!routeParams.tenancyTenantGuid) {
                    console.log("Unable to resolve params up to Tenant", params);
                    return null;
                }
            case 'tenancy':
                routeParams.tenancyGuid = params.tenancyGuid;

                if (!routeParams.tenancyGuid) {
                    if (params.tenancy) routeParams.tenancyGuid = params.tenancy.guid;
                    else if (params.tenant) routeParams.tenancyGuid = params.tenant.tenancySummary?.guid;
                }

                if (!routeParams.tenancyGuid) {
                    console.log("Unable to resolve params up to Tenancy", params);
                    return null;
                }
            case 'room':
                routeParams.roomReference = params.roomReference;

                if (!routeParams.roomReference) {
                    if (params.room) routeParams.roomReference = params.room.reference;
                    else if (params.tenancy) routeParams.roomReference = params.tenancy.roomReference ?? params.tenancy.roomSummary?.reference;
                }

                if (!routeParams.roomReference) {
                    console.log("Unable to resolve params up to Room", params);
                    return null;
                }
            case 'property':
                routeParams.propertyReference = params.propertyReference;

                if (!routeParams.propertyReference) {
                    if (params.property) routeParams.propertyReference = params.property.reference;
                    else if (params.room) routeParams.propertyReference = params.room.propertyReference;
                    else if (params.tenancy) routeParams.propertyReference = params.tenancy.propertyReference ?? params.tenancy.roomSummary?.propertyReference ?? params.tenancy.roomSummary?.propertySummary?.reference;
                }

                if (!routeParams.propertyReference) {
                    console.log("Unable to resolve params up to Property", params);
                    return null;
                }
        }

        return routeParams;
    }
}
