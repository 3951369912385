import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from './account.service';
import { ConfigService } from './config.service';

@Injectable()
export class NotDemoAuthGuard implements CanActivate, CanActivateChild {

    constructor(private accountService: AccountService, private _router: Router, private configService: ConfigService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.redirectIfDemo();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.redirectIfDemo();
    }

    private redirectIfDemo(): boolean {
        if (this.configService.isDemo && !this.configService.isDev) {

            const liveUrl = this.configService.liveWebUrl;
            const demoUrl = this.configService.demoWebUrl;
            const currentUrl = window.location.href;
            const newLiveUrl = currentUrl.replace(demoUrl, liveUrl);

            window.location.replace(newLiveUrl);
        }
        else {
            return true;
        }
    }
}
