<select class="form__select"
        (change)="internalOnChange($event)"
        [class.read-only]="readonly"
        [class.required]="touched && invalid"
        [(ngModel)]="value"
        [class]="cssClasses"
        [class.hasAppend]="append.children.length > 0"
        #dropdown>
    <option [value]="placeholderValue" disabled *ngIf="placeholder">{{placeholder}}</option>
    <option *ngFor="let option of options" [value]="option.value">{{option.label}}</option>
</select>
<div #append class="form__input-group-append" [class.required]="invalid && touched" [hidden]="append.innerHTML.length === 0">
    <ng-content select="[append]"></ng-content>
</div>
