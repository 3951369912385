import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {ManageOrganisationService} from "./manage-organisation.service";
import {RightmoveAuthenticationInformation} from "../models/rightmove/rightmove-authentication-information.interface";
import {
    RightmoveAuthenticationInformationPost
} from "../models/rightmove/rightmove-authentication-information-post.interface";
import {RightmoveProperty} from "../models/rightmove/rightmove-property.interface";

@Injectable()
export class RightmoveService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private manageOrganisationService: ManageOrganisationService) {
    }

    getAuthenticationInformation() {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/right-move/auth`
        return this.http.get<RightmoveAuthenticationInformation[]>(url);
    }

    postAuthenticationInformation(model: RightmoveAuthenticationInformationPost) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/right-move/auth`
        return this.http.post<RightmoveAuthenticationInformation[]>(url, model);
    }

    patchAuthenticationInformation(guid: string, model: RightmoveAuthenticationInformationPost) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/right-move/auth/${guid}`
        return this.http.patch<RightmoveAuthenticationInformation[]>(url, model);
    }

    deleteAuthenticationInformation(guid: string) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/right-move/auth/${guid}`
        return this.http.delete<RightmoveAuthenticationInformation[]>(url);
    }

    getBranchPropertyList(guid: string) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/right-move/auth/${guid}/get-property-list`
        return this.http.get<{ propertyList: string[] }>(url);
    }

    sendRoom(guid: string, roomReference: string) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/right-move/auth/${guid}/rooms/${roomReference}`
        return this.http.post<RightmoveProperty>(url, null);
    }

    sendProperty(guid: string, propertyReference: string) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/right-move/auth/${guid}/properties/${propertyReference}`
        return this.http.post<RightmoveProperty>(url, null);
    }

    removeProperty(guid: string, agentRef: string) {
        const organisationReference = this.manageOrganisationService.getOrganisationReference();
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/right-move/auth/${guid}/right-move-properties/${agentRef}`
        return this.http.delete(url);
    }
}
