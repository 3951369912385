export enum COHOFeedbackQuestionTypes
{
    TEXT = 1,
    BOOLEAN = 2,
    SCORE_1_TO_3 = 3,
    SCORE_1_TO_5 = 4,
    SCORE_1_TO_10 = 5,
    MEH_OK_GREAT = 6,
    OPTIONS = 7,
    YES_LINK = 8
}
