import { AccountService } from './account.service';
import { AddressService } from './address.service';
import { ArticleService } from './article.service';
import { AnalyticsService } from './analytics.service';
import { AppLoaderService } from './app-loader.service';
import { AuthorisedAuthGuard } from './authorised.auth-guard';
import { AuthorisedRegistrationIncompleteAuthGuard } from './authorised-registration-incomplete.auth-guard';
import { BoilerServiceService } from './boiler-service.service';
import { ConfigService } from './config.service';
import { ConversationsService } from './conversations.service';
import { DomService } from './dom.service';
import { DepositManagementService } from './deposit-management-service.service';
import { ElectricalInstallationConditionReportService } from './electrical-installation-condition-report.service';
import { EnergyPerformanceCertificateService } from './energy-performance-certificate.service';
import { FireSafetyService } from './fire-safety.service';
import { GasSafetyCertificateService } from './gas-safety-certificate.service';
import { HealthAndSafetyService } from './health-and-safety.service';
import { HMOLicenceService } from './hmo-licence.service';
import { HomeService } from './home.service';
import { HomesService } from './homes.service';
import { ImageService } from './image.service';
import { InfoBoxService } from './info-box.service';
import { InspectionService } from './inspection.service';
import { InvestmentPropertyService } from './investment-property.service';
import { LayoutService } from './layout.service';
import { LazyLoaderService } from './lazy-loader.service';
import { LoaderService } from './loader.service';
import { LookupService } from './lookup.service';
import { ManageOrganisationService } from './manage-organisation.service';
import { ManagePropertyService } from './manage-property.service';
import { ManageRoomService } from './manage-room.service';
import { ModalService } from './modal.service';
import { MoveOutService } from './move-out.service';
import { MyHomeService } from './my-home.service';
import { PortableApplianceTestService } from './portable-appliance-test.service';
import { PropertyAccessService } from './property-access.service';
import { PropertyServiceService } from './property-service.service';
import { PropertyOwnershipService } from './property-ownership.service';
import { RentCollectionService } from './rent-collection.service';
import { MaintenanceService } from './maintenance.service';
import { RightToRentService } from './right-to-rent.service';
import { TenancyAgreementService } from './tenancy-agreement.service';
import { TenantFindService } from './tenant-find.service';
import { TitleService } from './title.service';
import { UnauthorisedAuthGuard } from './unauthorised.auth-guard';
import { UnauthorisedOrUnverifiedAuthGuard } from './unauthorised-or-unverified.auth-guard';
import { UserService } from './user.service';
import { UtilitiesService } from './utilities.service';
import { FormService } from "./form.service";
import { FileService } from "./file.service";
import { ErrorService } from "./error.service";
import { DeviceService } from "./device.service";
import { NavigateService } from "./navigate.service";
import { CanDeactivateGuard } from "./can-deactivate.auth-guard";
import { MortgageRenewalService } from './mortgage-renewal.service';
import { BuildingInsuranceService } from './building-insurance.service';
import { TrackingService } from "./tracking.service";
import { CompliancyService } from './compliancy.service';
import { SearchService } from './search.service';
import { DropdownService } from './dropdown.service';
import { PropertyMarketingService } from './property-marketing.service';
import { AccountingService } from "./accounting.service";
import { PageSectionStylesService } from "./page-section-styles.service";
import { ManageOrganisationUsersService } from './manage-organisation-users.service';
import { RegistrationAuthGuard } from './registraton.auth-guard';
import { ReportService } from './report.service';
import { AppUpdateService } from './app-update.service';
import { NotDemoAuthGuard } from './not-demo.auth-guard';
import { IsDemoAuthGuard } from './is-demo.auth-guard';
import { UserLogoutAuthGuard } from './user-logout.auth.guard';
import { MobileAppService } from "./mobile-app.service";
import { OnboardingService } from './onboarding.service';
import { HistoryService } from './history.service';
import { NotMobileAppAuthGuard } from './not-mobile-app.auth-guard';
import { PerformanceService } from './performance.service';
import { AdminAuthGuard } from './admin.auth-guard';
import { FinanceService } from './finance.service';
import { WebsocketNotificationService } from './websocket-notifications.service';
import { AffiliateService } from './affiliate.service';
import { ReferralService } from './referral.service';
import { TenantAuthGuard } from './tenant.auth-guard';
import { ManagerAuthGuard } from './manager.auth-guard';
import { AgeRangeService } from './age-range.service';
import { OrganisationSuppliersService } from './organisation-suppliers.service';
import { AutomapperService } from './automapper.service';
import { StripeService } from './stripe.service';
import { OwnerAuthGuard } from './owner.auth-guard';
import { OwnerService } from './owner.service';
import { SupplierService } from './supplier.service';
import {CalendarService} from "./calendar.service";
import { TransactionService } from './transaction.service';
import {TenantFeedbackService} from "./tenant-feedback.service";
import {TenancyRecurringTransactionService} from "./tenancy-recurring-transaction.service";
import { DateService } from "./date-service";
import { SupplierAuthGuard } from './supplier.auth-guard';
import { OpenBankingService } from "./open-banking.service";
import {ManageTagService} from "./manage-tag.service";
import {InventoryService} from "./inventory.service";
import { VabooService } from './vaboo-service';
import {ActionService} from "./action.service";
import {WindowService} from "./window.service";
import {COHOFeedbackService} from "./coho-feedback.service";
import {RentReviewService} from "./rent-review.service";
import {AuthorisationService} from "./authorisation.service";
import {FocusOverlayService} from "./focus-overlay.service";
import {MyViewingsAuthGuard} from "./my-viewings.auth-guard";
import {ApplicationFormService} from "./application-form.service";
import {TdsService} from "./tds.service";
import { ManageBuildingService } from './manage-building.service';
import {GoCardlessService} from "./go-cardless.service";
import {RightmoveService} from "./rightmove.service";
import {OrganisationDashboardService} from "./organisation-dashboard.service";
import {StringService} from "./string.service";
import {AutomaticAssignmentService} from "./automatic-assignment.service";
import { OrderByService } from "./order-by.service";

export const services = [
    AccountService,
    AccountingService,
    ActionService,
    AddressService,
    AffiliateService,
    AgeRangeService,
    ArticleService,
    AnalyticsService,
    AppLoaderService,
    AuthorisedAuthGuard,
    AuthorisedRegistrationIncompleteAuthGuard,
    BoilerServiceService,
    BuildingInsuranceService,
    CanDeactivateGuard,
    COHOFeedbackService,
    CompliancyService,
    ConfigService,
    ConversationsService,
    WebsocketNotificationService,
    IsDemoAuthGuard,
    AdminAuthGuard,
    NotDemoAuthGuard,
    UserLogoutAuthGuard,
    DeviceService,
    DomService,
    DepositManagementService,
    ElectricalInstallationConditionReportService,
    EnergyPerformanceCertificateService,
    ErrorService,
    FileService,
    FireSafetyService,
    FormService,
    FinanceService,
    GasSafetyCertificateService,
    HealthAndSafetyService,
    HistoryService,
    HMOLicenceService,
    InspectionService,
    InventoryService,
    InvestmentPropertyService,
    HomeService,
    HomesService,
    ImageService,
    InfoBoxService,
    LayoutService,
    LazyLoaderService,
    LoaderService,
    LookupService,
    ManageOrganisationService,
    ManageOrganisationUsersService,
    ManagePropertyService,
    ManageRoomService,
    ModalService,
    MortgageRenewalService,
    MoveOutService,
    MyHomeService,
    NavigateService,
    NotDemoAuthGuard,
    PerformanceService,
    OnboardingService,
    OrganisationSuppliersService,
    CalendarService,
    PortableApplianceTestService,
    PropertyAccessService,
    PropertyMarketingService,
    PropertyOwnershipService,
    PropertyServiceService,
    RentCollectionService,
    ReferralService,
    OrganisationDashboardService,
    MaintenanceService,
    RentReviewService,
    ReportService,
    RightToRentService,
    TenancyAgreementService,
    TenancyRecurringTransactionService,
    ManageTagService,
    TenantFindService,
    TitleService,
    TrackingService,
    UnauthorisedAuthGuard,
    UnauthorisedOrUnverifiedAuthGuard,
    RegistrationAuthGuard,
    UserService,
    OwnerService,
    SupplierService,
    UtilitiesService,
    SearchService,
    DropdownService,
    TenantFeedbackService,
    VabooService,
    PageSectionStylesService,
    AppUpdateService,
    MobileAppService,
    NotMobileAppAuthGuard,
    TenantAuthGuard,
    OwnerAuthGuard,
    SupplierAuthGuard,
    ManagerAuthGuard,
    AutomapperService,
    StripeService,
    DateService,
    FocusOverlayService,
    TransactionService,
    OpenBankingService,
    WindowService,
    AuthorisationService,
    MyViewingsAuthGuard,
    ApplicationFormService,
    TdsService,
    ManageBuildingService,
    GoCardlessService,
    RightmoveService,
    StringService,
    AutomaticAssignmentService,
    OrderByService
];
