import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HistoryItem } from '../models/history/history-item';
import { HistoryItemPatch } from '../models/history/history-item-patch';
import {HistoryItemPost, TenancyNotesItem} from '../models/history/history-item-post';
import { ConfigService } from './config.service';
import {BaseEntityTypes} from "../models/base-entity-types";
import {ManualNotesSummaryInterface} from "../models/history/manual-notes-summary.interface";
import {HistoriesFilters} from "../models/history/histories-filters";

@Injectable()
export class HistoryService {


    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getPropertyHistoryItems(propertyReference: string, model?: HistoriesFilters, take?: number): Observable<HistoryItem[]> {
        const url = `${this.configService.baseUrl}/history/property/${propertyReference}`
        let params = new HttpParams();
        if(take){
            params = params.append('take', take)
        }
        if(model){
            Object.keys(model).forEach(key => {
                if (model[key] !== null && model[key] !== undefined) {
                    params = params.append(key, model[key].toString());
                }
            });
        }
        return this.http.get<HistoryItem[]>(url, {params});
    }

    getRoomHistoryItemsByProperty(propertyReference: string): Observable<HistoryItem[]> {
        return this.http.get<HistoryItem[]>(`${this.configService.baseUrl}/history/property/${propertyReference}/all-rooms`);
    }

    getMaintenanceHistoryItems(maintenanceIssueGuid: string, historyTypeIds: number | number[] | string | string[] | null = null): Observable<HistoryItem[]> {
        let params = new HttpParams();

        if (historyTypeIds) {
            if (!Array.isArray(historyTypeIds)) historyTypeIds = [historyTypeIds.toString()];

            historyTypeIds.forEach(typeId => params = params.append('historyTypeIds', typeId));
        }

        return this.http.get<HistoryItem[]>(`${this.configService.baseUrl}/history/maintenance/${maintenanceIssueGuid}`, { params });
    }

    addMaintenanceHistoryItem(maintenanceIssueGuid: string, model: HistoryItemPost): Observable<HistoryItem> {
        return this.http.post<HistoryItem>(`${this.configService.baseUrl}/history/maintenance/${maintenanceIssueGuid}`, model);
    }

    getViewingHistoryItems(viewingGuid: string, historyTypeIds: number | number[] | string | string[] | null = null): Observable<HistoryItem[]> {
        const url = `${this.configService.baseUrl}/history/viewing/${viewingGuid}`
        let params = new HttpParams();
        if (historyTypeIds) {
            if (!Array.isArray(historyTypeIds)) historyTypeIds = [historyTypeIds.toString()];
            historyTypeIds.forEach(typeId => params = params.append('historyTypeIds', typeId));
        }
        return this.http.get<HistoryItem[]>(url, {params});
    }

    addViewingHistoryItem(viewingGuid: string, model: HistoryItemPost): Observable<HistoryItem> {
        return this.http.post<HistoryItem>(`${this.configService.baseUrl}/history/viewing/${viewingGuid}`, model);
    }

    getTenancyRecurringTransactionHistoryItems(trtGuid: string): Observable<HistoryItem[]> {
        return this.http.get<HistoryItem[]>(`${this.configService.baseUrl}/history/tenancy-recurring-transaction/${trtGuid}`);
    }

    getCreditControlTenancyHistoryItems(tenancyGuid: string): Observable<HistoryItem[]> {
        return this.http.get<HistoryItem[]>(`${this.configService.baseUrl}/history/credit-control-tenancy/${tenancyGuid}`);
    }

    addCreditControlTenancyHistoryItem(tenancyGuid: string, model: HistoryItemPost): Observable<HistoryItem> {
        return this.http.post<HistoryItem>(`${this.configService.baseUrl}/history/credit-control-tenancy/${tenancyGuid}`, model);
    }

    addBaseEntityHistoryItem(organisationReference: string, entityType: BaseEntityTypes, referenceOrGuid: string, model: HistoryItemPost): Observable<HistoryItem> {
        return this.http.post<HistoryItem>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/history/identifier/${entityType}/${referenceOrGuid}`, model);
    }

    getLeadHistoryItems(leadGuid: string): Observable<HistoryItem[]> {
        return this.http.get<HistoryItem[]>(`${this.configService.baseUrl}/history/lead/${leadGuid}`);
    }

    addLeadHistoryItem(leadGuid: string, model: HistoryItemPost): Observable<HistoryItem> {
        return this.http.post<HistoryItem>(`${this.configService.baseUrl}/history/lead/${leadGuid}`, model);
    }

    getOnboardingHistoryItems(onboardingGuid: string): Observable<HistoryItem[]> {
        return this.http.get<HistoryItem[]>(`${this.configService.baseUrl}/history/onboarding/${onboardingGuid}`);
    }

    addOnboardingHistoryItem(onboardingGuid: string, model: HistoryItemPost): Observable<HistoryItem> {
        return this.http.post<HistoryItem>(`${this.configService.baseUrl}/history/onboarding/${onboardingGuid}`, model);
    }

    getRentDueRecordHistoryItems(rentDueRecordGuid: string): Observable<HistoryItem[]> {
        return this.http.get<HistoryItem[]>(`${this.configService.baseUrl}/history/rent-due-record/${rentDueRecordGuid}`);
    }

    addRentDueRecordHistoryItem(rentDueRecordGuid: string, model: HistoryItemPost): Observable<HistoryItem> {
        return this.http.post<HistoryItem>(`${this.configService.baseUrl}/history/rent-due-record/${rentDueRecordGuid}`, model);
    }

    patchHistoryItem(historyItemGuid: string, model: HistoryItemPatch): Observable<HistoryItem> {
        return this.http.patch<HistoryItem>(`${this.configService.baseUrl}/history/${historyItemGuid}`, model);
    }

    deleteHistoryItem(historyItemGuid: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/history/${historyItemGuid}`);
    }

    addManualNote(model: TenancyNotesItem, scope: 'tenancy' | 'room' | 'property' | 'organisation' = 'tenancy'){
        const url = `${this.configService.baseUrl}/history/${scope}-manual-note/`
        return this.http.post<HistoryItem>(url, model);
    }

    getTenancyNotes(propertyReference: string, roomReference: string, tenancyGuid: string, tenancyTenantGuid: string = null, model: HistoriesFilters){
        const url = `${this.configService.baseUrl}/history/property/${propertyReference}/room/${roomReference}/tenancy/${tenancyGuid}`
        let params = new HttpParams();
        if(tenancyTenantGuid) {
            params = params.append('tenancyTenantGuid', tenancyTenantGuid)
        }
        // Append properties of the model to the params
        Object.keys(model).forEach(key => {
            if (model[key] !== null && model[key] !== undefined) {
                params = params.append(key, model[key].toString());
            }
        });
        return this.http.get<HistoryItem[]>(url, {params});
    }

    getRoomHistoryItems(propertyReference: string, roomReference: string, model: HistoriesFilters){
        const url = `${this.configService.baseUrl}/history/property/${propertyReference}/room/${roomReference}`
        let params = new HttpParams();
        Object.keys(model).forEach(key => {
            if (model[key] !== null && model[key] !== undefined) {
                params = params.append(key, model[key].toString());
            }
        });
        return this.http.get<HistoryItem[]>(url, {params});
    }

    getManualNotesSummary(organisationReference: string, propertyReference?: string, roomReference?:string, tenancyGuid?: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/history/manual-notes-summary`
        let params = new HttpParams();
        if(propertyReference){
            params = params.append('propertyReference', propertyReference)
        }
        if(roomReference){
            params = params.append('roomReference', roomReference)
        }
        if(tenancyGuid){
            params = params.append('tenancyGuid', tenancyGuid)
        }
        return this.http.get<ManualNotesSummaryInterface>(url, {params});
    }

    getOrganisationHistoryItems(organisationReference: string, model: HistoriesFilters){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/history`
        let params = new HttpParams();
        Object.keys(model).forEach(key => {
            if (model[key] !== null && model[key] !== undefined) {
                params = params.append(key, model[key].toString());
            }
        });
        return this.http.get<HistoryItem[]>(url, {params});
    }

    getConversationHistoryItems(conversationReference: string) {
        const url = `${this.configService.baseUrl}/history/conversation/${conversationReference}`
        return this.http.get<HistoryItem[]>(url);
    }

    addConversationHistoryItem(conversationReference: string, model: HistoryItemPost) {
        const url = `${this.configService.baseUrl}/history/conversation/${conversationReference}`
        return this.http.post<HistoryItem>(url, model);
    }

    getTransactionItemHistoryItems(organisationReference: string, transactionItemGuid: string){
        const url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/history/transactions/${transactionItemGuid}`
        return this.http.get<HistoryItem[]>(url);
    }

    addTransactionItemHistoryItem(organisationReference: string, transactionItemGuid: string, model: HistoryItemPost): Observable<HistoryItem> {
        return this.http.post<HistoryItem>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/history/transactions/${transactionItemGuid}`, model);
    }
}
