import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DropdownComponent } from "./dropdown.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode'}),
        FormsModule
    ],
    declarations: [
        DropdownComponent
    ],
    exports: [
        DropdownComponent
    ]
})

export class DropdownModule {
}
