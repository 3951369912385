import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeEmpty',
    pure: true
})
export class RemoveEmptyPipe implements PipeTransform {
    transform(input:Array<string|number>): Array<string|number> {
        if (!input) return [];

        // Filter out empty values (null, '', ' ', undefined)
        return input.filter(m => (m || m === 0 || m === '0') && m !== ' ' ? m : false);
    }
}
