import {Input, OnInit, Directive, Optional} from "@angular/core";
import {AbstractControl, ControlContainer, ControlValueAccessor, FormControl, UntypedFormArray, UntypedFormGroup, ValidationErrors} from '@angular/forms';

import { UtilitiesService } from '../../services/utilities.service';

@Directive()
export class BaseControlValueAccessor<T> implements ControlValueAccessor, OnInit {
    @Input() formArrayName: string;
    @Input() formControlName: string;
    @Input() formControl: FormControl;

    @Input() set disabled(value: boolean) {
        this._disabled = value;
    }
    @Input() set readonly(value: boolean) {
        this._readonly = value;
    }

    private _value: T;
    protected _readonly: boolean = false;
    protected _disabled: boolean = false;

    get disabled(): boolean {
        return this._disabled;
    }

    get readonly(): boolean {
        return this._readonly;
    }

    get value(): T {
        return this._value;
    }

    set value(value: T) {
        this._value = value;
    }

    protected control: AbstractControl;

    constructor(@Optional()protected controlContainer: ControlContainer, protected utilitiesService: UtilitiesService) {
    }

    get status(): string {
        return this.control && this.control.status;
    }

    get valid(): boolean {
        return this.control && this.control.valid;
    }

    get invalid(): boolean {
        return this.control && this.control.invalid;
    }

    get errors(): ValidationErrors | null {
        return this.control && this.control.errors;
    }

    get touched(): boolean {
        return this.control && this.control.touched;
    }

    ngOnInit(): void {
        if (this.formControl) {
            this.control = this.formControl;
        }
        else if (this.controlContainer.control instanceof UntypedFormGroup) {
            this.control = this.controlContainer.control.get(this.formControlName);
        } else if (this.controlContainer.control instanceof UntypedFormArray) {
            this.control = this.controlContainer.control.at(parseInt(this.formControlName));
        }
    }

    onChange(newVal: T) {
    }

    onTouched(_?: any) {
    }

    writeValue(value: T): void {
        this._value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }
}
