
import * as _ from 'lodash';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {AccountService} from "./account.service";
import {Building, BuildingPartial} from "../models/building/building";
import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import {ConvertSelectablePartialToSelectableOptions, SelectablePartial, SelectablePartialAsOptions} from "../models/selectable-partial";
import {BuildingEditorPatchModel, BuildingEditorPostModel} from "../pages/manage/components/building-editor/building-editor-form";
import {PropertySummary} from "../models/manage-property/property-summary";
import {BuildingTier} from "../models/building/building-tier";
import {BuildingPatchPropertiesModel} from "../pages/manage/components/building-editor/building-patch-properties-form";

@Injectable()
export class ManageBuildingService {
    private _buildings: Building[];
    private _buildingsSubject: Subject<Building[]>;
    private _buildingsTopLevel: Building[];
    private _buildingsTopLevelSubject: Subject<Building[]>;
    private _buildingsLookup: { [p: string]: Building };
    private _buildingsLookupSubject: Subject<{ [p: string]: Building }>;
    private _buildingsOptions: SelectablePartialAsOptions;
    private _buildingsOptionsSubject: Subject<SelectablePartialAsOptions>;

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private accountService: AccountService) {
    }

    clearCaches() {
        this._buildings = null;
        if (this._buildingsSubject) this._buildingsSubject.unsubscribe()
        this._buildingsSubject = null;
        this._buildingsTopLevel = null;
        if (this._buildingsTopLevelSubject) this._buildingsTopLevelSubject.unsubscribe()
        this._buildingsTopLevelSubject = null;
        this._buildingsLookup = null;
        if (this._buildingsLookupSubject) this._buildingsLookupSubject.unsubscribe()
        this._buildingsLookupSubject = null;
        this._buildingsOptions = null;
        if (this._buildingsOptionsSubject) this._buildingsOptionsSubject.unsubscribe()
        this._buildingsOptionsSubject = null;
    }
    getBuildingsSelectableOptions(organisationReference: string): Observable<SelectablePartialAsOptions> {
        if (this._buildingsOptions) return new BehaviorSubject(this._buildingsOptions);
        if (this._buildingsOptionsSubject) return this._buildingsOptionsSubject;

        this._buildingsOptionsSubject = new Subject<SelectablePartialAsOptions>();

        this.http.get<{buildingSelectables: SelectablePartial[]}>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/building/get-as-selectables`)
            .subscribe(data => {
                this._buildingsOptions = ConvertSelectablePartialToSelectableOptions(data.buildingSelectables);
                this._buildingsOptionsSubject.next(this._buildingsOptions);
            },
        error => this._buildingsOptionsSubject.error(error),
        () => this._buildingsOptionsSubject.complete()
        );

        return this._buildingsOptionsSubject;
    }

    getBuildingsCached(organisationReference: string, propertySummaries: PropertySummary[]): Observable<Building[]> {
        if (this._buildings) return new BehaviorSubject(this._buildings);
        if (this._buildingsSubject) return this._buildingsSubject;

        // Load our cache and extract out only the data we need to serve as our response
        this._buildingsSubject = new Subject<Building[]>();
        this.loadBuildingsCache(organisationReference, propertySummaries)
            .subscribe(
    done => this._buildingsSubject.next(this._buildings),
            error => this._buildingsSubject.error(error),
                () => this._buildingsSubject.complete()
        );

        return this._buildingsSubject;
    }


    getTopLevelBuildingsCached(organisationReference: string, propertySummaries: PropertySummary[]): Observable<Building[]> {
        if (this._buildingsTopLevel) {
           // console.log("Returning cached in memory value via behaviour subject");
           // console.log(this._buildingsTopLevel);
            return new BehaviorSubject(this._buildingsTopLevel);
        }
        if (this._buildingsTopLevelSubject && this._buildingsTopLevelSubject.closed == false) {
           // console.log("Returning subject for response");
            return this._buildingsTopLevelSubject;
        }

        // Load our cache and extract out only the data we need to serve as our response
        this._buildingsTopLevelSubject = new Subject<Building[]>();
        this.loadBuildingsCache(organisationReference, propertySummaries)
            .subscribe(data => {
                    this._buildingsTopLevelSubject.next(this._buildingsTopLevel)
                    this._buildingsTopLevelSubject.complete();
                },
                error => this._buildingsTopLevelSubject.error(error),
                () => this._buildingsTopLevelSubject.complete()
            );

        return this._buildingsTopLevelSubject;
    }


    getBuildingsLookupCached(organisationReference: string, propertySummaries: PropertySummary[]): Observable<{ [p:string]: Building }> {
        if (this._buildingsLookup) return new BehaviorSubject(this._buildingsLookup);
        if (this._buildingsLookupSubject) return this._buildingsLookupSubject;

        // Load our cache and extract out only the data we need to serve as our response
        this._buildingsLookupSubject = new Subject<{ [p:string]: Building }>();
        this.loadBuildingsCache(organisationReference, propertySummaries)
            .subscribe(
                done => this._buildingsLookupSubject.next(this._buildingsLookup),
                error => this._buildingsLookupSubject.error(error),
                () => this._buildingsLookupSubject.complete()
            );

        return this._buildingsLookupSubject;
    }

    loadBuildingsCache(organisationReference: string, propertySummaries: PropertySummary[]): Observable<boolean> {
        const responseSubject = new Subject<boolean>();

        this.http.get<BuildingPartial[]>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/building/get-partials`)
            .subscribe(buildingPartials => {
                // Prepare our top level buildings
                const topLevelBuildings: {[p: string]: Building} = {};
                buildingPartials.filter(m => m.tier == BuildingTier.TOP_LEVEL).forEach(topLevelBuilding => {
                    topLevelBuildings[topLevelBuilding.guid] = Building.fromPartial(topLevelBuilding);
                });

                // Prepare our child level buildings
                const buildingsLookup = {...topLevelBuildings};
                buildingPartials.filter(m => m.tier == BuildingTier.LEAF_LEVEL).forEach(childBuildingPartial => {
                    // Load the child and parent objects
                    let parentBuilding: Building = topLevelBuildings[childBuildingPartial.parentGuid] || Building.unloadedBuilding(childBuildingPartial.parentGuid);
                    const childBuilding = Building.fromPartial(childBuildingPartial, parentBuilding);

                    // Update all our lookups
                    buildingsLookup[childBuilding.guid] = childBuilding;
                    buildingsLookup[parentBuilding.guid] = parentBuilding;
                    topLevelBuildings[parentBuilding.guid] = parentBuilding;

                    // Add the child to all the parent stuff
                    parentBuilding.childBuildings ||= [];
                    parentBuilding.childBuildingGuids ||= [];
                    parentBuilding.childBuildings.push(childBuilding);
                    parentBuilding.childBuildingGuids.push(childBuilding.guid);
                });

                this._buildings = Object.values(buildingsLookup);
                this._buildingsTopLevel = Object.values(topLevelBuildings);
                this._buildingsLookup = buildingsLookup;

                // Sort out buildings
                this._buildings =  _.orderBy(this._buildings, ['name']);
                this._buildingsTopLevel =  _.orderBy(this._buildingsTopLevel, ['name']);
                    this._buildingsTopLevel.forEach(parentBuilding => parentBuilding.childBuildings = _.orderBy(parentBuilding.childBuildings, ['name']));

                // Inject our property summaries into our buildings
                this.injectProperties(propertySummaries);

                responseSubject.next(true);
            },
        error => responseSubject.error(error),
    () => responseSubject.complete(),
        );

        return responseSubject;
    }

    injectProperties(propertySummaries: PropertySummary[]) {
        if (!propertySummaries || propertySummaries.length == 0) return;

        propertySummaries.forEach(property => {
            if (property.buildingGuid) {
                if (this._buildingsLookup[property.buildingGuid]) this._buildingsLookup[property.buildingGuid].properties.push(property);
                else {
                    // The building pointed to is not in loaded so create an unloaded building placeholder
                    this._buildingsLookup[property.buildingGuid] = Building.unloadedBuilding(property.buildingGuid);
                }
            }
        });
    }


    addBuilding(organisationReference: string, model: BuildingEditorPostModel) {
        return this.http.post<{ newBuildingGuid: string }>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/building`, model);
    }

    editBuilding(organisationReference: string, buildingGuid: string, model: BuildingEditorPatchModel) {
        return this.http.patch<boolean>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/building/${buildingGuid}`, model);
    }

    deleteBuilding(organisationReference: string, buildingGuid: string) {
        return this.http.delete<boolean>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/building/${buildingGuid}`);
    }


    patchBuildingProperties(organisationReference: string, buildingGuid: string, model: BuildingPatchPropertiesModel) {
        return this.http.patch<boolean>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/building/${buildingGuid}/set-properties`, model);
    }

    // Used for editing buildings & perhaps in the future for the manage-buildings page
    getBuildingFull(organisationReference: string, buildingGuid: string) {
        return this.http.get<Building>(`${ this.configService.baseUrl }/manage/organisation/${organisationReference}/building/${buildingGuid}/get-building-full`);
    }

}
