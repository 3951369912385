<div class="dropdown dropdown__feedback" [class.open]="feedbackMenuOpen" #feedbackDropdown>
    <button class="header__options-button dropdown__button" (click)="toggleFeedbackMenu()" [class.alert]="feedbackQuestion != null">
        <div class="profile__image header__profile-image" (click)="toggleFeedbackMenu()">
            <svg-icon role="img" class="icon icon-happy-outline icon--white" name="icon-happy-outline"></svg-icon>
        </div>
        <div class="badge pulse" *ngIf="feedbackQuestion != null && !questionSubmittedResponse">!</div>
    </button>

    <div class="feedback__menu feedback__menu--right">
        <ul class="feedback__menu-main">


            <loading-indicator *ngIf="loadingFeedback"></loading-indicator>
            <ng-container *ngIf="!loadingFeedback">

                <li class="highlight" *ngIf="feedbackQuestion != null">
                    <span class="dropdown__menu-title">A question for you, {{currentUser.person.forename}}</span>
    <!--                <a class="pointer" (click)="dismissQuestion()"><svg-icon class="icon icon-cross icon&#45;&#45;grey" name="icon-cross" role="img"></svg-icon></a>-->
                    <ul>
                        <li>
                            <div class="dropdown__menu-item feedback__holder">
                                <p class="u-text--small" *ngIf="feedbackQuestion.heading != null && feedbackQuestion.heading.length > 0"><strong>{{feedbackQuestion.heading}}</strong></p>
                                <p class="u-text--small" [innerHTML]="feedbackQuestion.question | safeHtml "></p>
                                <p class="u-text--tiny u-text-color--grey" *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.SCORE_1_TO_5">Where 1 is {{feedbackQuestion.lowLabel}} and 5 is {{feedbackQuestion.highLabel}}</p>
                                <p class="u-text--tiny u-text-color--grey" *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.SCORE_1_TO_10">Where 1 is {{feedbackQuestion.lowLabel}} and 10 is {{feedbackQuestion.highLabel}}</p>
                                <p *ngIf="questionSubmittedResponse">Thanks for answering!</p>
                                <form [formGroup]="formQuestion" *ngIf="questionSubmittedResponse != true">

                                    <ng-container *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.BOOLEAN">

                                        <div class="form__grid form__grid-2">

                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="1" [label]="feedbackQuestion.highLabel" (click)="setQuestionScore(1, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="0" [label]="feedbackQuestion.lowLabel" (click)="setQuestionScore(0, $event)"></radio-button>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.SCORE_1_TO_3">

                                        <div class="form__grid form__grid-3">

                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="1" [label]="feedbackQuestion.lowLabel" description="1" (click)="setQuestionScore(1, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="2" description="2" (click)="setQuestionScore(2, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="3" [label]="feedbackQuestion.highLabel" description="3" (click)="setQuestionScore(2, $event)"></radio-button>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.OPTIONS">
                                        <div class="form__grid form__grid-3">

                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="1" [label]="feedbackQuestion.highLabel" [description]="feedbackQuestion.highLabel" (click)="setQuestionScore(1, $event)"></radio-button>

                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="2" [label]="feedbackQuestion.lowLabel" [description]="feedbackQuestion.lowLabel" (click)="setQuestionScore(2, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element" *ngIf="feedbackQuestion.altLabel != null && feedbackQuestion.altLabel.length > 0">
                                                <radio-button name="score" formControlName="score" [internalValue]="3" [label]="feedbackQuestion.altLabel" [description]="feedbackQuestion.altLabel" (click)="setQuestionScore(3, $event)"></radio-button>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.SCORE_1_TO_5">

                                        <div class="form__grid form__grid-5 feedback__5">

                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="1" [label]="1" description="1" (click)="setQuestionScore(1, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="2" [label]="2" description="2" (click)="setQuestionScore(2, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="3" [label]="3" description="3" (click)="setQuestionScore(3, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="4" [label]="4" description="4" (click)="setQuestionScore(4, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="5" [label]="5" description="5" (click)="setQuestionScore(5, $event)"></radio-button>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.SCORE_1_TO_10">

                                        <div class="form__grid form__grid-10 feedback__10">

                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="1" [label]="1" description="1" (click)="setQuestionScore(1, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="2" [label]="2" description="2" (click)="setQuestionScore(2, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="3" [label]="3" description="3" (click)="setQuestionScore(3, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="4" [label]="4" description="4" (click)="setQuestionScore(4, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="5" [label]="5" description="5" (click)="setQuestionScore(5, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="6" [label]="6" description="6" (click)="setQuestionScore(6, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="7" [label]="7" description="7" (click)="setQuestionScore(7, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="8" [label]="8" description="8" (click)="setQuestionScore(8, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="9" [label]="9" description="9" (click)="setQuestionScore(9, $event)"></radio-button>
                                            </div>
                                            <div class="form__grid-element">
                                                <radio-button name="score" formControlName="score" [internalValue]="10" [label]="10" description="10" (click)="setQuestionScore(10, $event)"></radio-button>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.MEH_OK_GREAT">
                                        <div class="feedback--icons">
                                            <div class="feedback--icons-holder unhappy" (click)="setQuestionScore(1, $event)" [class.active]="formQuestion.get('score').value == 1">
                                                <svg-icon applycss="true" class="icon icon-unhappy icon--" name="icon-unhappy" role="img"></svg-icon>
                                                <p>Meh</p>
                                            </div>
                                            <div class="feedback--icons-holder happy" (click)="setQuestionScore(2, $event)" [class.active]="formQuestion.get('score').value == 2">
                                                <svg-icon applycss="true" class="icon icon-happy icon--" name="icon-happy" role="img"></svg-icon>
                                                <p>OK</p>
                                            </div>
                                            <div class="feedback--icons-holder great" (click)="setQuestionScore(3, $event)" [class.active]="formQuestion.get('score').value == 3">
                                                <svg-icon applycss="true" class="icon icon-great icon--" name="icon-great" role="img"></svg-icon>
                                                <p>Great!</p>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.YES_LINK">
                                        <div class="feedback--icons">
                                            <a [href]="feedbackQuestion.yesLink" target="_blank" class="feedback--icons-holder great" (click)="setQuestionScore(1, $event)" [class.active]="formQuestion.get('score').value == 1">
                                                <svg-icon applycss="true" class="icon icon-great icon--" name="icon-great" role="img"></svg-icon>
                                                <p>{{feedbackQuestion.highLabel}}</p>
                                            </a>
                                            <div class="feedback--icons-holder unhappy" (click)="setQuestionScore(0, $event)" [class.active]="formQuestion.get('score').value == 0">
                                                <svg-icon applycss="true" class="icon icon-unhappy icon--" name="icon-unhappy" role="img"></svg-icon>
                                                <p>{{feedbackQuestion.lowLabel}}</p>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <multiline-textbox *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.TEXT" formControlName="message" rows="3" [maxLength]="2000"></multiline-textbox>

                                    <error-summary [errors]="errors"></error-summary>

                                    <loader-button
                                        *ngIf="feedbackQuestion.type === COHOFeedbackQuestionTypes.TEXT"
                                        cssClasses="button--small"
                                        (click)="submitQuestionAnswer()"
                                        [loading]="questionAnswerSubmitting">
                                        Send
                                    </loader-button>

                                    <p style="text-align:right;font-size:smaller">
                                        <a href="javascript:void(0)" class="pointer" (click)="dismissQuestion()">Dismiss</a>
                                    </p>
                                </form>


                            </div>
                        </li>
                    </ul>
                </li>

                <li>
                    <span class="dropdown__menu-title">Feedback &amp; Suggestions</span>
                    <ul>
                        <li>
                            <div class="dropdown__menu-item">
                                
                                <form [formGroup]="formFeedback">
                                    <label class="form__labelform__label--small">We'd love to hear your feedback</label>
                                    <multiline-textbox formControlName="message" rows="3" [maxLength]="2000"></multiline-textbox>
                                    <div class="form__input-help"><p class="u-text--tiny">We don't actively monitor your feedback as a support channel, issues should be reported in the usual way.</p></div>
                                    <error-summary [errors]="errorsFeedback"></error-summary>

                                    <div class="button__group">
                                        <div class="button__group-item">
                                            <loader-button
                                                cssClasses="button--feedback button--small button--nomargin"
                                                (click)="submitFeedback()"
                                                [loading]="feedbackSubmitting">
                                                Send
                                            </loader-button>
                                        </div>
                                    </div>
                                </form>

                                <p class="u-margin-top--10" *ngIf="feedbackSubmittedResponse && formFeedback.controls['type'].value == COHOFeedbackTypes.FEEDBACK">Thanks for your feedback!</p>
                            </div>
                        </li>
                    </ul>
                </li>
            </ng-container>

        </ul>
    </div>
</div>
