import { Component, ViewEncapsulation, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user/user';
import {COHOFeedbackService} from "../../services/coho-feedback.service";
import {COHOFeedbackQuestion} from "../../models/coho-feedback/coho-feedback-question";
import {COHOFeedbackQuestionTypes} from "../../models/coho-feedback/coho-feedback-question-types";
import {FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "../../services/form.service";
import {COHOFeedbackTypes} from "../../models/coho-feedback/coho-feedback-types";
import {COHOFeedbackPost} from "../../models/coho-feedback/coho-feedback-post";
import {ErrorSummary} from "../../models/error/error-summary";
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'coho-feedback',
    templateUrl: './coho-feedback.component.html',
    encapsulation: ViewEncapsulation.None,
    host: {
        '(document:click)': 'onClick($event)'
    }
})

export class COHOFeedbackComponent implements OnInit {

    @ViewChild('feedbackDropdown') feedbackDropdown: ElementRef;

    feedbackMenuOpen: boolean = false;
    currentUser: User;
    loadingFeedback: boolean = false;

    feedbackQuestion: COHOFeedbackQuestion;
    formFeedback: FormGroup;
    formQuestion: FormGroup;
    questionAnswerSubmitting: boolean = false;
    feedbackSubmitting: boolean = false;
    questionSubmittedResponse: boolean = false;
    feedbackSubmittedResponse: boolean = false;
    COHOFeedbackQuestionTypes = COHOFeedbackQuestionTypes;
    COHOFeedbackTypes = COHOFeedbackTypes;
    errors: ErrorSummary;
    errorsFeedback: ErrorSummary;
    staticWebUrl: string;

    constructor(private cohoFeedbackService: COHOFeedbackService, private accountService: AccountService, private formService: FormService, private formBuilder: UntypedFormBuilder,private configService: ConfigService) {

        accountService.watchCurrentUser()
            .subscribe(user => {
                this.currentUser = user;
            });

        this.formFeedback = this.formBuilder.group({
            message: [null, Validators.required],
            type: [COHOFeedbackTypes.FEEDBACK],
        });

        this.formQuestion = this.formBuilder.group({
            message: [null],
            score: [null],
            type: [COHOFeedbackTypes.ANSWER],
            questionGuid: [null]
        });

        this.staticWebUrl = this.configService.staticWebUrl;
    }

    ngOnInit(): void {

        this.accountService.watchIsLoggedIn()
            .subscribe(isLoggedIn => {
                if (this.currentUser != null && this.currentUser.emailConfirmed && this.currentUser.registrationJourneyCompleted) {
                    this.getFeedback();
                }
            });
    }

    toggleFeedbackMenu(open?: boolean) {
        if (open === false) {
            this.feedbackMenuOpen = false;
        } else if (open === true) {
            this.feedbackMenuOpen = true;
        } else {
            if (this.feedbackMenuOpen) {
                setTimeout(() => this.feedbackMenuOpen = false);
            } else {
                setTimeout(() => this.feedbackMenuOpen = true);
            }
        }
    }


    onClick(event: Event): void {
        if (this.feedbackMenuOpen && this.feedbackDropdown && this.feedbackDropdown.nativeElement) {
            if (!this.feedbackDropdown.nativeElement.contains(event.target)) {
                this.feedbackMenuOpen = false;

                if (this.questionSubmittedResponse) {
                    this.feedbackQuestion = null;
                    this.questionSubmittedResponse = false;
                }
            }
        }
    }

    private getFeedback() {
        this.cohoFeedbackService.getQuestion().subscribe(data => {
            this.feedbackQuestion = data;
            this.loadingFeedback = false;

            if (this.feedbackQuestion != null) {
                this.formQuestion.controls['questionGuid'].setValue(this.feedbackQuestion.guid);

                switch(this.feedbackQuestion.type) {
                    case COHOFeedbackQuestionTypes.TEXT: this.formQuestion.get('message').setValidators([Validators.required]);
                }
            }
        });
    }

    submitQuestionAnswer() {
        this.errors = null;
        const formFields = {
            'Message': this.formQuestion.get('message'),
        };

        this.errors = this.formService.checkAllControlsAreValid(formFields);
        if (this.errors) return;

        this.questionAnswerSubmitting = true;
        let model = <COHOFeedbackPost>this.formQuestion.value;

        this.cohoFeedbackService.postFeedback(model).subscribe(data => {
            this.questionAnswerSubmitting = false;
            this.formQuestion.controls['message'].setValue('');
            this.formQuestion.controls['score'].setValue('');
            this.formQuestion.controls['questionGuid'].setValue('');
            this.questionSubmittedResponse = true;
        });

        this.feedbackMenuOpen = true;
    }

    submitFeedback() {
        this.errorsFeedback = null;
        const formFields = {
            'Message': this.formFeedback.get('message'),
        };

        this.errorsFeedback = this.formService.checkAllControlsAreValid(formFields);
        if (this.errorsFeedback) return;

        this.feedbackSubmitting = true;

        let model = <COHOFeedbackPost>this.formFeedback.value;

        this.cohoFeedbackService.postFeedback(model).subscribe(data => {
            this.feedbackSubmitting = false;
            this.formFeedback.controls['message'].setValue('');
            this.feedbackSubmittedResponse = true;
        });
    }

    setQuestionScore(score: number, e: MouseEvent = null) {
        if (e != null) {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
        }

        setTimeout(() => {
            this.formQuestion.controls['score'].setValue(score);
            this.submitQuestionAnswer();
            this.questionSubmittedResponse = true;
        }, 1);
    }

    dismissQuestion() {
        this.feedbackQuestion = null;

        if (this.feedbackSubmittedResponse == false) {

            let model = <COHOFeedbackPost>this.formQuestion.value;
            model.score = -1;

            this.cohoFeedbackService.postFeedback(model).subscribe(data => {
                this.questionAnswerSubmitting = false;
                this.formQuestion.controls['message'].setValue('');
                this.formQuestion.controls['score'].setValue('');
                this.formQuestion.controls['questionGuid'].setValue('');
                this.questionSubmittedResponse = false;
            });

            this.feedbackMenuOpen = true;
        }
    }
}
