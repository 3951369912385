import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MobileAppService } from './mobile-app.service';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export class NotMobileAppAuthGuard implements CanActivate {

    constructor(private mobileAppService: MobileAppService, private _router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (this.mobileAppService.isMobileApp()) {
            return from(this._router.navigate(['/login']))
                .pipe(map(() => false));
        }

        return true;
    }
}
