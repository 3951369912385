import {Observable, ReplaySubject, Subject, Subscription} from "rxjs";
import {isModelStateErrorsPostCommit, responseExtractModelStateErrors} from "../services/form.service";
import {LoadingStates} from "./loading-states";
import {HttpEvent, HttpEventType} from "@angular/common/http";
import {filter, last, tap} from "rxjs/operators";
import {ChangeDetectorRef} from "@angular/core";


export class LoadingState {
    private _state : LoadingStates = LoadingStates.NOT_STARTED;
    private _hasLoadedOnce : boolean = false;

    // Getters are via direct variables so Angular can update on the front-end
    public loading : boolean = false;       // Most recent API call is in progress
    public loadingProgress : number = 0;    // File upload progress
    public loadingFirst: boolean = false;   // Current in-progress API call is also the 1st API call
    public started : boolean = false;       // At least 1 API call was been made (it may not have returned yet...)
    public loaded : boolean = false;        // Most recent API call was successful and has finished
    public hasData : boolean = false;       // This really means 'hasLoadedDataOnce' and we need to find a better name for it
    public error : boolean = false;         // Most Recent API call failed (errored)

    private subject: Subject<any> | null = null;
    private internalSubject: Subscription;
    private changeDetector: ChangeDetectorRef | null;

    // errorObject & errorMessage will be null or contain the last failed call's details
    public responseErrors : any | null = null;
    public errorMessage : string | null = null;
    public errorIsPostCommit : boolean = false;

    constructor(changeDetector: ChangeDetectorRef | null = null) {
        // Construct our defaults
        this.reset(false);
        this.changeDetector = changeDetector;
    }

    public reset(updateChangeDetector: boolean = true) {
        this._state = LoadingStates.NOT_STARTED;
        this._hasLoadedOnce = false;

        this.started = false;
        this.loading = false;
        this.loadingProgress = 0;
        this.loaded = false;
        this.error = false;
        this.hasData = false;
        this.loadingFirst = false;
        this.responseErrors = null;
        this.errorMessage = null;
        this.errorIsPostCommit = false;

        if (updateChangeDetector && this.changeDetector) this.changeDetector.markForCheck();
    }

    // Setters used to interact with the state, there are only three options
    // * You set that you are loading - this would be right before kicking-off the job to load the data
    // * Then you either successfully load, or you failed (error).
    // * --> So you either call setLoaded() to indicate success
    // * --> Or you call setError() to indicate failure
    public setLoading(updateChangeDetector: boolean = true) {
        this._state = LoadingStates.BUSY_LOADING;
        this.loadingProgress = 0;
        this.updateGetters(updateChangeDetector);
    }

    public cancelPrevious() {
        if (!this.loading) return this;

        if (this.internalSubject) {
            this.internalSubject.unsubscribe();
            this.setLoading(false);
        }

        return this;
    }

    public fromLast<T>(observable : Observable<T>) : Observable<T> {
        return this.cancelPrevious().fromObservable(observable);
    }

    // Set's the state from the observable and captures the error
    public fromObservable<T>(observable : Observable<T>) : Observable<T> {
        this.setLoading(false);
        this.clearError(true);

        this.subject = new ReplaySubject<T>(1);

        // We need to queue the triggering of the observable to run after we have returned our subject to the caller
        //   because there is a chance the `observable` is a BehaviourSubject that will immediately return a result before the caller
        //   has been able to subscribe our observable. This meant with behaviour subjects the output result was often missed.
        //   So here I use a setTimeout to basically queue this code to run after the current thread has finished.
        //setTimeout(_ => {
            this.internalSubject = observable.subscribe(
                data => {
                    try {
                        this.setLoaded(false);
                        this.subject.next(data);
                    } finally {
                        if (this.changeDetector) {
                            //console.log("Marking change detection due to result");
                            this.changeDetector.markForCheck();
                        }
                    }
                },
                error => {
                    try {
                        this.setError(error, false);
                        this.subject.error(error);
                    } finally {
                        if (this.changeDetector) {
                            //console.log("Marking change detection due to error");
                            this.changeDetector.markForCheck();
                        }
                    }
                },
                () => this.subject.complete()
            );
        //});

        return this.subject.asObservable();
    }



    public fromObservableWithProgress<T>(observable : Observable<HttpEvent<T>>, trackProgress: boolean = true) : Observable<HttpEvent<T>> {


        const obs = observable.pipe(
            tap(event => {
                if (trackProgress) return;
                this.loadingProgress = this.processHttpUploadProgressEvent(event);

                if (this.changeDetector) this.changeDetector.markForCheck();
            }),
            filter(event => event.type == HttpEventType.Response),
            last()
        );

        return this.fromObservable(obs);
    }

    processHttpUploadProgressEvent(event: HttpEvent<any>): number {
        if (event.type === HttpEventType.UploadProgress) {
            return Math.round(100 * event.loaded / event.total);
        }
    }

    public setLoaded(updateChangeDetector: boolean = true) {
        this._state = LoadingStates.LAST_LOADING_OK;
        this._hasLoadedOnce = true;
        this.updateGetters(updateChangeDetector);
    }

    public setErrorFromString(errorString: string, updateChangeDetector: boolean = true)
    {
        this._state = LoadingStates.LAST_LOADING_ERROR;
        this.responseErrors = {"error": [errorString]};
        this.errorMessage = errorString;

        this.updateGetters(updateChangeDetector);

        return this;
    }

    public setErrorFromStrings(errorStrings: string[], updateChangeDetector: boolean = true)
    {
        this._state = LoadingStates.LAST_LOADING_ERROR;
        this.responseErrors = {"error": errorStrings};
        this.errorMessage = errorStrings.join("\n");

        this.updateGetters(updateChangeDetector);

        return this;
    }

    public setErrorIsPostCommit()
    {
        this.errorIsPostCommit = true;

        return this;
    }

    public setError(error : any, updateChangeDetector: boolean = true) {
        this.errorIsPostCommit = isModelStateErrorsPostCommit(error);
        this.errorMessage = error ? error.toString() : "Empty error";
        this.setErrorFromString(this.errorMessage);
        this.responseErrors = responseExtractModelStateErrors(error);
        this._state = LoadingStates.LAST_LOADING_ERROR;
        this.updateGetters(updateChangeDetector);
    }

    public setAbort(updateChangeDetector: boolean = true)
    {
        this._state = LoadingStates.NOT_STARTED;
        this.subject.complete();
        this.internalSubject.unsubscribe();

        this.updateGetters(updateChangeDetector);
    }

    // Clear error is useful to call if you have a 'Retry Button' command and want the error message to go away even before the new result
    // comes in (especially if you are doing a if this.errorMessage) check
    public clearError(updateChangeDetector: boolean = true) {
        if (this._state == LoadingStates.LAST_LOADING_ERROR && this._hasLoadedOnce) this._state = LoadingStates.LAST_LOADING_OK;
        if (this._state == LoadingStates.LAST_LOADING_ERROR && !this._hasLoadedOnce) this._state = LoadingStates.NOT_STARTED;
        this.responseErrors = null;
        this.errorMessage = null;
        this.errorIsPostCommit = false;

        this.updateGetters(updateChangeDetector);
    }

    public updateGetters(updateChangeDetector: boolean = true) : void {
        this.started = this._state != LoadingStates.NOT_STARTED;
        this.loading = this._state == LoadingStates.BUSY_LOADING;
        this.loaded = this._state == LoadingStates.LAST_LOADING_OK;
        this.error = this._state == LoadingStates.LAST_LOADING_ERROR;
        this.hasData = this._hasLoadedOnce;
        this.loadingFirst = !this._hasLoadedOnce && this._state == LoadingStates.BUSY_LOADING;

        if (updateChangeDetector && this.changeDetector) this.changeDetector.markForCheck();
    }

    cancel(updateChangeDetector: boolean = true) {
        this.subject?.unsubscribe();
        this.internalSubject?.unsubscribe();
        this.reset(updateChangeDetector);
    }
}
