<modal [type]="ModalTypes.CONFIRM_ITEM" [closeOnBlur]="false" [callback]="callbackSubscriber">
    <ng-container modal-header>{{title}}</ng-container>
    <ng-container modal-body>

        <p class="u-text-line-breaks" [innerHTML]="message"></p>

    </ng-container>

    <ng-container modal-footer>
        
        <div class="button__group">
            <div class="button__group-item">
                <button class="button button--primary" (click)="confirm()">{{yesLabel}}</button>
            </div>
            <div class="button__group-item">
                <button class="button button--ghost" type="button" (click)="closeModal()">{{noLabel}}</button>
            </div>
        </div>
        
    </ng-container>
</modal>
