import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'conversationDate' })
export class ConversationDatePipe implements PipeTransform {
    transform(value: any, fullDate: boolean = false): string {
        const val = DateTime.fromISO(value); // Automatically uses Settings.defaultZone
        const now = DateTime.now(); //now, but in UK
        let conversationDate = '';

        if (fullDate) {
            if (val.hasSame(now, 'day')) {
                conversationDate = `Today at ${val.toFormat("HH:mm")}`;
            } else if (val >= now.minus({ days: 1 }).startOf('day') && val < now.startOf('day')) {
                conversationDate = `Yesterday at ${val.toFormat("HH:mm")}`;
            } else if (val >= now.minus({ years: 1 })) {
                conversationDate = `${val.toFormat("dd LLL")} at ${val.toFormat("HH:mm")}`;
            } else {
                conversationDate = `${val.toFormat("dd LLL yyyy")} at ${val.toFormat("HH:mm")}`;
            }
        } else {
            if (val.hasSame(now, 'day')) {
                conversationDate = val.toFormat("HH:mm");
            } else if (val >= now.minus({ days: 1 }).startOf('day') && val < now.startOf('day')) {
                conversationDate = 'Yesterday';
            } else if (val >= now.minus({ years: 1 })) {
                conversationDate = val.toFormat("dd LLL");
            } else {
                conversationDate = val.toFormat("dd LLL yyyy");
            }
        }
        return conversationDate;
    }
}
