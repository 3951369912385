import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { OwnerDashboardOverview } from '../models/owner/owner-dashboard-overview';
import { OwnerSummary } from '../models/owner/owner-summary';
import { ActionOverview } from '../models/action/action-overview';
import { OwnerPropertyOverview } from '../models/owner/owner-property-overview';
import { NotificationSummary } from '../models/manage-organisation/notification-summary';
import {ActionSnoozePostFull} from "../models/action/action-snooze-post";

@Injectable()
export class OwnerService {
    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    getOwnerDashboardOverview(): Observable<OwnerDashboardOverview> {
        return this.http.get<OwnerDashboardOverview>(`${this.configService.baseUrl}/owner/overview`);
    }

    getOwnerSummary(): Observable<OwnerSummary> {
        return this.http.get<OwnerSummary>(`${this.configService.baseUrl}/owner/summary`);
    }

    getOwnerPropertyOverview(propertyReference: string): Observable<OwnerPropertyOverview> {
        return this.http.get<OwnerPropertyOverview>(`${this.configService.baseUrl}/owner/property/${propertyReference}/overview`);
    }

    getActionsOverview(): Observable<ActionOverview> {
        return this.http.get<ActionOverview>(`${this.configService.baseUrl}/owner/actions/overview`);
    }

    getActions(propertyReference: string): Observable<ActionOverview> {
        return this.http.get<ActionOverview>(`${this.configService.baseUrl}/owner/property/${propertyReference}/actions`);
    }

    getPropertyNotifications(propertyReference: string): Observable<NotificationSummary> {
        return this.http.get<NotificationSummary>(`${this.configService.baseUrl}/owner/property/${propertyReference}/notifications`);
    }

    getNotifications(): Observable<NotificationSummary> {
        return this.http.get<NotificationSummary>(`${this.configService.baseUrl}/owner/notifications`);
    }

    snoozeAction(actionReference: string, days: number, months: number, date: string = null): Observable<boolean> {
        let snoozeModel = new ActionSnoozePostFull();
        snoozeModel.date = date;
        snoozeModel.days = days;
        snoozeModel.months = months;

        return this.http.post<boolean>(`${this.configService.baseUrl}/owner/actions/${actionReference}/snooze`, snoozeModel);
    }

    completeAction(actionReference: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/owner/actions/${actionReference}/complete`, null);
    }
}

