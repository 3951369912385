import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PerformanceOverview} from '../models/performance/performance-overview';

import {ConfigService} from './config.service';
import {AccountService} from "./account.service";
import {PropertyFilterPresets} from "../models/manage-property/property-details";

@Injectable()
export class PerformanceService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private accountService: AccountService) {
    }

    getOverview(organisationReference: string, propertyReferences: string[] | string | null = null, propertyOwnerReference?: string, startingFromDate?: string): Observable<PerformanceOverview> {
        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences instanceof Array && propertyReferences.length) {
                propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            }
            else if (propertyReferences.length) {
                params = params.append('propertyReferences', propertyReferences.toString());
            } else {
                params = params.append('propertyReferences', PropertyFilterPresets.NONE);
            }
        }

        if (propertyOwnerReference) {
            params = params.append('ownerReference', propertyOwnerReference.toString());
        }

        if(startingFromDate){
            params = params.append('startingFromDateString', startingFromDate.toString())
        }

        return this.http.get<PerformanceOverview>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/performance/overview`, {params});
    }

    getOverviewAsCsvUrl(organisationReference: string, propertyReferences: string[] | null = null) {
        let params = new HttpParams();

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        params = params.set('access_token', this.accountService.accessToken.bearerToken);

        return `${this.configService.baseUrl}/manage/organisation/${organisationReference}/performance/overview/as-csv/true?${params.toString()}`;
    }
}
