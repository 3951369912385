import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'svg-icon',
    templateUrl: './svg-icon.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush         // Tested by DPJ and working
})

export class SvgIconComponent {
    @Input() name: string;
    @Input() color: string;

}
