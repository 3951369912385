import {Component, ViewEncapsulation, Input, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SpriteImage } from '../../models/image/sprite-image';
import { LookupService } from '../../services/lookup.service';

@Component({
    selector: 'sprite-image',
    templateUrl: './sprite-image.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush         // Converted by DPJ and tested
})

export class SpriteImageComponent {

    @Input() reference: string;
    @Input() placeholder: string;
    @Input() defaultImage: string;
    @Input() alt: string;
    @Input() classes: string;

    imagePath: SafeResourceUrl;
    loading: boolean = false;
    error: boolean = false;
    constructor(private _sanitizer: DomSanitizer, private _lookupService: LookupService, private cd: ChangeDetectorRef) {

    }

    ngOnInit() {
        this.imagePath = this.defaultImage;

        let spriteImage = this._lookupService.getSpriteImage(this.reference);

        if (spriteImage != null) {
            this.setImage(spriteImage);
        }
        else {
            this.loading = true;
            this.error = false;

            // The loading call will ring us back to inform us the sprite image is now loaded. Then we retrieve it from the lookup service again
            this._lookupService.loadSpriteImage(this.reference).subscribe(ready => {
                spriteImage = this._lookupService.getSpriteImage(this.reference);
                if (spriteImage == null) {
                    this.error = true;
                    this.loading = false;
                }
                else {
                    this.setImage(spriteImage);
                }

                this.cd.detectChanges();
            });
        }
    }

    setImage(spriteImage: SpriteImage) {
        let mime = 'data:image/png;base64';
        if (spriteImage != null && spriteImage.m != null && spriteImage.m.length) {
            mime = spriteImage.m;
        }
        this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(mime + ',' + spriteImage.d);
        this.loading = false;
        this.error = false;
    }

}
