import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { AuthorisedAuthGuard } from './services/authorised.auth-guard';
import { TenantAuthGuard } from './services/tenant.auth-guard';
import { ManagerAuthGuard } from './services/manager.auth-guard';
import { OwnerAuthGuard } from './services/owner.auth-guard';
import { ManageOrganisationFeaturesResolver } from './resolvers/manage-organisation-features.resolver';
import { SupplierAuthGuard } from './services/supplier.auth-guard';
import {AuthorisationResolver} from "./resolvers/authorisation.resolver";
import {MyViewingsAuthGuard} from "./services/my-viewings.auth-guard";

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/login' },
    { path: 'authorisation', /*,data: { preload: true },*/  loadChildren: () => import('./pages/authorisation-error/authorisation-error.page.module').then(m => m.AuthorisationErrorModule)},
    { path: 'university', loadChildren: () => import('./pages/university/university.page.module').then(m => m.UniversityPageModule) },
    { path: 'login', loadChildren: () => import('./pages/login/login.page.module').then(m => m.LoginPageModule) },
    { path: 'logout', loadChildren: () => import('./pages/logout/logout.page.module').then(m => m.LogoutPageModule) },
    { path: 'forgotten-password', loadChildren: () => import('./pages/forgotten-password/forgotten-password.page.module').then(m => m.ForgottenPasswordPageModule) },
    { path: 'register', loadChildren: () => import('./pages/register/register.page.module').then(m => m.RegisterPageModule) },
    { path: 'account', loadChildren: () => import('./pages/account/account.page.module').then(m => m.AccountPageModule) },
    { path: 'unsubscribe', loadChildren: () => import('./pages/account/account.page.module').then(m => m.AccountPageModule) },
    { path: 'release-notes', loadChildren: () => import('./pages/news/news.page.module').then(m => m.NewsPageModule) },
    { path: 'messages', loadChildren: () => import('./pages/messages-redirect/messages-redirect.page.module').then(m => m.MessagesRedirectPageModule) },
    { path: 'manage', loadChildren: () => import('./pages/manage/manage.page.module').then(m => m.ManagePageModule), canActivateChild: [AuthorisedAuthGuard, ManagerAuthGuard], runGuardsAndResolvers: 'always', resolve: { organisationFeatures: ManageOrganisationFeaturesResolver, authorisation: AuthorisationResolver } },
    { path: 'owner', loadChildren: () => import('./pages/owner/owner.page.module').then(m => m.OwnerPageModule), canActivateChild: [AuthorisedAuthGuard, OwnerAuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'supplier', loadChildren: () => import('./pages/supplier/supplier.page.module').then(m => m.SupplierPageModule), canActivateChild: [AuthorisedAuthGuard, SupplierAuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'my-home', loadChildren: () => import('./pages/my-home/my-home.page.module').then(m => m.MyHomePageModule), canActivateChild: [AuthorisedAuthGuard, TenantAuthGuard] },
    { path: 'find-a-home/company', loadChildren: () => import('./pages/organisation-homes/organisation-homes.page.module').then(m => m.OrganisationHomesPageModule) },
    { path: 'find-a-home', loadChildren: () => import('./pages/browse/browse.page.module').then(m => m.BrowsePageModule) },
    { path: 'find-a-home/:location', loadChildren: () => import('./pages/homes/homes.page.module').then(m => m.HomesPageModule) },
    { path: 'find-a-home/:location/:reference', loadChildren: () => import('./pages/home/home.page.module').then(m => m.HomePageModule) },
    { path: 'investment-properties', loadChildren: () => import('./pages/investment-properties/investment-properties.page.module').then(m => m.InvestmentPropertiesPageModule) },
    { path: 'investment-properties/:location', loadChildren: () => import('./pages/investment-property/investment-property.page.module').then(m => m.InvestmentPropertyPageModule) },
    { path: 'i', loadChildren: () => import('./pages/invite/invite.page.module').then(m => m.InvitePageModule) },
    { path: 'g', loadChildren: () => import('./pages/guarantor/guarantor.page.module').then(m => m.GuarantorPageModule) },
    { path: 'gw', loadChildren: () => import('./pages/gateway/gateway.page.module').then(m => m.GatewayPageModule) },
    { path: 'invoice', loadChildren: () => import('./pages/invoice/invoice.page.module').then(m => m.InvoicePageModule) },
    { path: 'report', loadChildren: () => import('./pages/report/report.page.module').then(m => m.ReportPageModule) },
    { path: 'widget', loadChildren: () => import('./pages/widget/widget.page.module').then(m => m.WidgetPageModule) },
    { path: 'demo', loadChildren: () => import('./pages/demo/demo.page.module').then(m => m.DemoPageModule) },
    { path: 'admin', loadChildren: () => import('./pages/admin/admin.page.module').then(m => m.AdminPageModule) },
    { path: 'error-404', loadChildren: () => import('./pages/error-404/error-404.page.module').then(m => m.Error404PageModule) },
    { path: 'error-500', loadChildren: () => import('./pages/error-500/error-500.page.module').then(m => m.Error500PageModule) },
    { path: 'playground', loadChildren: () => import('./pages/playground/playground.page.module').then(m => m.PlaygroundPageModule) },
    { path: 'facebook-leads-upload', loadChildren: () => import('./pages/facebook-leads-upload/facebook-leads-upload.page.module').then(m => m.FacebookLeadsUploadPageModule) },
    { path: 'maintenance', loadChildren: () => import('./pages/maintenance/maintenance.page.module').then(m => m.MaintenancePageModule) },
    { path: 'welcome-pack', loadChildren: () => import('./pages/welcome-pack/welcome-pack.page.module').then(m => m.WelcomePackPageModule) },
    { path: 'inventory-templates', loadChildren: () => import('./pages/inventory-templates/inventory-template.page.module').then(m => m.InventoryTemplatePageModule) },
    { path: 'my-viewings', loadChildren: () => import('./pages/my-viewings/my-viewings.page.module').then(m => m.MyViewingsPageModule), canActivateChild: [AuthorisedAuthGuard, MyViewingsAuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'viewing', loadChildren: () => import('./pages/viewing/viewing.page.module').then(m => m.ViewingPageModule) },
    // This should always be the last one
    { path: '**', loadChildren: () => import('./pages/error-404/error-404.page.module').then(m => m.Error404PageModule) }
];

// export class CustomPreloader implements PreloadingStrategy {
//     preload(route: Route, load: Function): Observable<any> {
//         return route.data && route.data.preload ? load() : of(null);
//     }
// }

/* preloadingStrategy: CustomPreloader, */
@NgModule({
    imports: [RouterModule.forRoot(routes,
        {   scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload'
        }
    )],
    exports: [RouterModule]
})

export class AppRouting {
}



