import {FilePost} from "../file/file-post";
import {HouseShareFile} from "../file/file";

export class UserPatch {
    email?: string;
    mobileNumber?: string;
    password?: string;
    forename?: string;
    surname?: string;
    image?: HouseShareFile | FilePost;
    updateImage?: boolean;
    acceptedCookieWarning?: boolean;
    dismissedAppNotice?: boolean;
    notificationsOffOverride?: boolean;
    darkMode?: boolean;
    privateProfile?: boolean;
}
