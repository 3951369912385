

// BuildingSvgIcons are just graphical options for the client to choose from that loosely which do not signify anything
//  related to the data
import {DropdownOption} from "../../components/dropdown/dropdown-option";

export enum BuildingSvgIcon {
    MIXED_USE = 1,
    DOOR = 2,
    HOUSE = 3,
    CALENDAR = 4,
    BLOCK = 5,
    SHOP = 6,
    OFFICE = 7,
    PARKING_SPACE = 8,
    PARKING_LOT = 9,
    RESIDENTIAL_ESTATE = 10,
    COMMERCIAL_OFFICES = 11,
    MALL = 12,
    BOAT = 13,
    PORT = 14,
    AIRPORT = 15,
    GARAGE = 16,
}

export const BuildingSvgIconReverse = {
    "1": "Mixed use",
    "2": "Door",
    "3": "House",
    "4": "Calendar",
    "5": "Block",
    "6": "Shop",
    "7": "Office",
    "8": "Parking spaces",
    "9": "Parking lot",
    "10": "Residential estate",
    "11": "Commercial offices",
    "12": "Mall",
    "13": "Boat",
    "14": "Port",
    "15": "Airport",
    "16": "Garage",
}

export const BuildingSvgIconNameReverse = {
    "1": "mixed-use",
    "2": "room",
    "3": "house",
    "4": "calendar",
    "5": "block",
    "6": "shop",
    "7": "professional",
    "8": "parking",
    "9": "off-road-parking",
    "10": "estate",
    "11": "commercial",
    "12": "cart",
    "13": "boat",
    "14": "port",
    "15": "airport",
    "16": "garage",
}



export const BuildingSvgDropdownOptions = [
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.MIXED_USE], BuildingSvgIcon.MIXED_USE),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.DOOR], BuildingSvgIcon.DOOR),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.HOUSE], BuildingSvgIcon.HOUSE),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.CALENDAR], BuildingSvgIcon.CALENDAR),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.BLOCK], BuildingSvgIcon.BLOCK),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.SHOP], BuildingSvgIcon.SHOP),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.OFFICE], BuildingSvgIcon.OFFICE),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.GARAGE], BuildingSvgIcon.GARAGE),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.PARKING_SPACE], BuildingSvgIcon.PARKING_SPACE),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.PARKING_LOT], BuildingSvgIcon.PARKING_LOT),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.RESIDENTIAL_ESTATE], BuildingSvgIcon.RESIDENTIAL_ESTATE),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.COMMERCIAL_OFFICES], BuildingSvgIcon.COMMERCIAL_OFFICES),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.MALL], BuildingSvgIcon.MALL),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.BOAT], BuildingSvgIcon.BOAT),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.PORT], BuildingSvgIcon.PORT),
    new DropdownOption(BuildingSvgIconReverse[BuildingSvgIcon.AIRPORT], BuildingSvgIcon.AIRPORT),
]
