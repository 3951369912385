import {HttpClient, HttpEvent, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { Inspection } from '../models/inspection/inspection';
import { InspectionPost } from '../models/inspection/inspection-post';
import { InspectionOverview } from '../models/inspection/inspection-overview';
import { FileService } from "./file.service";
import moment from "moment/moment";
import {PropertyFilterPresets} from "../models/manage-property/property-details";
import {InspectionsOverview} from "../models/inspection/inspections-overview";
import {PropertyServicesSummary} from "../models/property-services/property-services-summary.interface";
import {mergeMap, tap} from "rxjs/operators";


@Injectable()
export class InspectionService {
    constructor(private http: HttpClient, private configService: ConfigService, private fileService: FileService) {
    }

    getOrganisationOverview(organisationReference: string): Observable<InspectionsOverview> {

        var url= `${this.configService.baseUrl}/manage/organisation/${organisationReference}/inspections/overview`;

        return this.http.get<InspectionsOverview>(url);
    }

    getOverview(organisationReference: string, propertyReference: string, roomReference: string): Observable<InspectionOverview> {

        var url= `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/`;

        if (roomReference) {
            url += `room/${roomReference}/`;
        }

        url += 'inspection/overview';

        return this.http.get<InspectionOverview>(url);
    }

    getList(organisationReference: string, propertyReferences: string[] | null = null, roomReference: string, dateFrom: Date = null, dateTo: Date = null): Observable<Inspection[]> {

        let params = new HttpParams();
        if (dateFrom != null) {
            params = params.set('dateFrom', moment(dateFrom).toISOString());
        }

        if (dateTo != null) {
            params = params.set('dateTo', moment(dateTo).toISOString());
        }

        if (propertyReferences) {
            if (propertyReferences.length) propertyReferences.forEach(reference => params = params.append('propertyReferences', reference));
            else params = params.append('propertyReferences', PropertyFilterPresets.NONE);
        }

        var url= `${this.configService.baseUrl}/manage/organisation/${organisationReference}/inspections`;


        if (roomReference) {
            params = params.set('roomReference', roomReference);
        }

        return this.http.get<Inspection[]>(url, { params: params });
    }

    add(organisationReference: string,propertyReference: string, roomReference: string, model: InspectionPost): Observable<HttpEvent<Inspection>> {
        let url = `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/`;
        if (roomReference) {
            url += `room/${roomReference}/`;
        }
        url += 'inspection';

        return this.fileService.uploadFiles(model.inspectionFiles)
            .pipe(tap(_ => model.inspectionFiles = this.fileService.prepareFilePosts(model.inspectionFiles)),
                mergeMap(_ => this.http.post<Inspection>(url, model, {
                    reportProgress: true, observe: 'events'
                })));
    }

    remove(organisationReference: string, propertyReference: string, item: Inspection): Observable<boolean> {
        return this.http.delete<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/inspection/${item.guid}`);
    }

    update(itemGuid: string, model: InspectionPost) {
        return this.fileService.uploadFiles(model.inspectionFiles)
            .pipe(tap(_ => model.inspectionFiles = this.fileService.prepareFilePosts(model.inspectionFiles)),
                mergeMap(_ => this.http.patch<Inspection>(`${this.configService.baseUrl}/manage/inspections/${itemGuid}`, model)));
    }

    getSummary(organisationReference: string, propertyReference: string) {
        const url= `${this.configService.baseUrl}/manage/organisation/${organisationReference}/property/${propertyReference}/inspection/summary`;
        return this.http.get<PropertyServicesSummary>(url);
    }
}
