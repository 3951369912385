import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PerformanceOverview} from '../models/performance/performance-overview';

import {ConfigService} from './config.service';
import {AccountService} from "./account.service";
import {PropertyFilterPresets} from "../models/manage-property/property-details";
import {OrganisationDashboard} from "../models/organisation-dashboard/organisation-dashboard";

@Injectable()
export class OrganisationDashboardService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService) {
    }

    getOverview(organisationReference: string, refresh: boolean = false): Observable<OrganisationDashboard> {
        let params = new HttpParams();

        if (refresh) {
            params = params.append('refresh', refresh);
        }


        return this.http.get<OrganisationDashboard>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/dashboard/overview`, {params});
    }

    dismissDailyTip(organisationReference: string, dailyTipId: number): Observable<boolean> {
        return this.http.post<boolean>(`${this.configService.baseUrl}/manage/organisation/${organisationReference}/dashboard/daily-tip/${dailyTipId}/dismiss`, null);
    }

}
