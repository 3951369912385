import { Injectable } from '@angular/core';
import { DeviceDetectorService, OS } from 'ngx-device-detector';

@Injectable()
export class MobileAppService {

    constructor(private deviceService: DeviceDetectorService) {
    }

    token(): string {
        return localStorage.getItem('MOBILE_TOKEN');
    }

    isMobileApp(): boolean {
        return !!localStorage.getItem('ON_MOBILE');
    }

    isIOS(): boolean {
        return this.deviceService.os === OS.IOS;
    }

    login(bearerToken: string) {
        if (!this.isMobileApp()) {
            return;
        }

        this.doAction('login', `bearer=${ bearerToken }`);
    }

    logout() {
        if (!this.isMobileApp()) {
            return;
        }

        this.doAction('logout');
    }

    history(href: string) {
        if (!this.isMobileApp() || this.isIOS()) {
            return;
        }

        this.doAction('history', `href=${ href }`);
    }

    private doAction(action: string, args: string = '') {
        window.location.href = `action.html?action=${ action }&${ args }`;
    }
}
