import { Injector, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { MakePluralStringPipe } from 'ngx-pipes';

@Pipe({name: 'daysAgo'})
export class DaysAgoPipe implements PipeTransform {

    makePluralStringPipe: MakePluralStringPipe;

    constructor(private injector: Injector) {
        this.makePluralStringPipe = new MakePluralStringPipe();
    }

    transform(daysAgoDate: Date, textAgo: string = ' ago'): any {
        const daysAgo = moment().diff(moment(daysAgoDate), 'days');

        let str = '';
        if (daysAgo > 0) {
            str = `${ daysAgo } ${ this.makePluralStringPipe.transform('day', daysAgo) } ` + textAgo;
        } else if (daysAgo === 0) {
            str = 'Today';
        } else {
            str = `In ${ (daysAgo * -1) } ${ this.makePluralStringPipe.transform('day', daysAgo) }`;
        }

        return str;
    }
}
