import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {Observable, of} from 'rxjs';
import {mergeMap, tap} from "rxjs/operators";

import { ConfigService } from './config.service';
import { RegisterAbout } from '../pages/register/about/register-about';
import { UserNotifications } from '../models/user/user-notifications';
import { User } from "../models/user/user";
import { AccountService } from "./account.service";
import { UserPatch } from '../models/user/user-patch';
import { UserAccountSettings } from '../models/account/user-account-settings';
import { UserNotificationSettings } from '../models/account/user-notification-settings';
import { NotificationSettingsPatch } from '../models/account/notification-settings-patch';
import { NotificationMediumPatch } from '../models/account/notification-medium-patch';
import { UserProfile } from "../models/user/user-profile";
import { Housemate } from "../models/user/housemate";
import { RoomSearchPatch } from '../models/search/room-search-patch';
import { TenantPatch } from '../models/user/tenant-patch';
import { RoomSearchProfile } from "../models/homes/room-search-profile";
import { RequestVerifyIdentity } from '../models/user/request-verify-identity';
import { VerifyIdentity } from '../models/user/verify-identity';
import {FileService} from "./file.service";
import {InviteReferenceCheck} from "../models/tenancy/invite-reference-check-response.interface";

@Injectable()
export class UserService {

    private _accountService: AccountService = null;

    get accountService(): AccountService {
        if (!this._accountService) {
            this._accountService = this.injector.get(AccountService);
        }

        return this._accountService;
    }

    constructor(private http: HttpClient, private configService: ConfigService, private injector: Injector, private fileService: FileService) {
    }

    registerAbout(model: RegisterAbout): Observable<HttpEvent<User>> {
        let obs: Observable<any> = of(null);

        if (model.person?.image) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFile(model.person.image)),
                tap(_ => model.person.image = this.fileService.prepareFilePost(model.person.image)));
        }
        if (model.organisation?.organisationImage) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFile(model.organisation.organisationImage)),
                tap(_ => model.organisation.organisationImage = this.fileService.prepareFilePost(model.organisation.organisationImage)));
        }
        if (model.organisation?.individualImage) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFile(model.organisation.individualImage)),
                tap(_ => model.organisation.individualImage = this.fileService.prepareFilePost(model.organisation.individualImage)));
        }
        if (model.owner?.ownerImage) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFile(model.owner.ownerImage)),
                tap(_ => model.owner.ownerImage = this.fileService.prepareFilePost(model.owner.ownerImage)));
        }

        const url = `${this.configService.baseUrl}/user/register-about`;

        return obs.pipe(mergeMap(_ => this.http.post<User>(url, model, {
            reportProgress: true, observe: 'events'
        })));
    }

    checkPersonExistsByInviteReference(inviteReference: string) {
        return this.http.get<InviteReferenceCheck>(`${this.configService.baseUrl}/user/invite/${inviteReference}`)
    }

    checkOwnerExistsByInviteReference(inviteReference: string): Observable<string> {
        return this.http.get<string>(`${this.configService.baseUrl}/user/owner/invite/${inviteReference}`)
    }

    userNotifications(): Observable<UserNotifications> {
        return this.http.get<UserNotifications>(`${this.configService.baseUrl}/user/notifications`)
    }

    getMe(): Observable<User> {
        console.log('Getting me');
        return this.http.get<User>(`${this.configService.baseUrl}/user/me`);
    }

    getProfile(): Observable<UserProfile> {
        return this.http.get<UserProfile>(`${this.configService.baseUrl}/user/profile`);
    }

    getUserAccountSettings(notificationGuid: string): Observable<UserAccountSettings> {
        let params = new HttpParams();
        if (notificationGuid) {
            params = params.set("notificationGuid", notificationGuid);
        }

        return this.http.get<UserAccountSettings>(`${this.configService.baseUrl}/user/settings`, { params })
    }

    patchUserNotificationSettings(notificationGuid: string, model: NotificationSettingsPatch): Observable<UserNotificationSettings> {
        let params = new HttpParams();
        if (notificationGuid) {
            params = params.set("notificationGuid", notificationGuid);
        }

        return this.http.patch<UserNotificationSettings>(`${this.configService.baseUrl}/user/settings/notifications`, model, { params })
    }

    patchUserAccountMediumSettings(notificationGuid: string, model: NotificationMediumPatch): Observable<UserNotificationSettings> {
        let params = new HttpParams();
        if (notificationGuid) {
            params = params.set("notificationGuid", notificationGuid);
        }

        return this.http.patch<UserNotificationSettings>(`${this.configService.baseUrl}/user/settings/notifications/mediums`, model, { params })
    }

    patchUser(model: UserPatch): Observable<User> {
        let obs: Observable<any> = of(null);
        if (model.image) {
            obs = obs.pipe(
                mergeMap(_ => this.fileService.uploadFile(model.image)),
                tap(_ => model.image = this.fileService.prepareFilePost(model.image)));
        }

        const url = `${this.configService.baseUrl}/user/me`;
        return obs.pipe(
            mergeMap(_ => this.http.patch<User>(url, model)),
            tap(user => this.accountService.setCurrentUser(user))
        );
    }

    patchUserProfile(model: TenantPatch) {
        return this.http.patch<Housemate>(`${this.configService.baseUrl}/user/profile`, model, {
            reportProgress: true, observe: 'events'
        });
    }

    patchRoomSearch(model: RoomSearchPatch) {
        return this.http.patch<RoomSearchProfile>(`${this.configService.baseUrl}/user/room-search`, model);

    }

    requestVerifyIdentity(model: RequestVerifyIdentity): Observable<any> {
        return this.http.post(`${this.configService.baseUrl}/user/request-verify-identity`, model)
    }

    verifyIdentity(model: VerifyIdentity): Observable<string> {
        return this.http.post<string>(`${this.configService.baseUrl}/user/verify-identity`, model)
    }

    admin(): Observable<boolean> {
        return this.http.get<boolean>(`${this.configService.baseUrl}/user/admin`);
    }

    getOrganisationReferenceForCurrentManager() {
        return this.http.get<string>(`${this.configService.baseUrl}/user/get-organisation-reference-for-manager`);
    }

}
