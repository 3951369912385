import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { FocusOverlayComponent } from './focus-overlay.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        LoadingIndicatorModule,
        SvgIconModule,
        RouterModule
    ],
    declarations: [
        FocusOverlayComponent
    ],
    exports: [
        FocusOverlayComponent
    ]
})

export class FocusOverlayModule {
}
