import { User } from '../user/user';
import { LoginResponseStatus } from './login-response-status';

export class LoginResponse {
    bearerToken: string;
    refreshToken: string;
    user: User;
    loginResponseStatus: LoginResponseStatus; //This is generated directly within the account.service.ts response
    errorMessage: string;
}
