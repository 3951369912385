import {DropdownOption} from "../components/dropdown/dropdown-option";
import * as _ from 'lodash';

export interface SelectablePartial {
    identifier: string;
    displayText: string;
    displayHtml: string;
    orderByValue: string;

    grouped: boolean;
    groupIdentifier: string;
    groupDisplayText: string;
    groupDisplayHtml: string;
    groupOrderByValue: string;
}

export class SelectablePartialAsOptions {
    options: DropdownOption[];
    groupTranslation: {[p: string]: string};
    isGrouped: boolean;
    reset() {
        this.options = [];
        this.groupTranslation = {};
        this.isGrouped = false;
    }
    constructor(options: DropdownOption[] | null = null, groupTranslation: {[p: string]: string} | null = null, isGrouped: boolean | null = null) {
        if (options) this.options = options;
        else this.options = [];

        if (groupTranslation) this.groupTranslation = groupTranslation;
        else this.groupTranslation = {};

        if (isGrouped) this.isGrouped = isGrouped;
        else this.isGrouped = false;
    }

    static create(fields: {options?: DropdownOption[], groupTranslation?: {[p: string]: string}, isGrouped?: boolean}) {
        return new SelectablePartialAsOptions(fields.options, fields.groupTranslation, fields.isGrouped);
    }
}

export function ConvertLookupToSelectableOptions(lookup: {}) : SelectablePartialAsOptions
{
    const keys = Object.keys(lookup);
    const items = keys.map(key => {
        return {
            'identifier':  key,
            'displayText': lookup[key],
        }
    });

    return ConvertSelectablePartialToSelectableOptions(items as SelectablePartial[]);
}

export function ConvertSelectablePartialToSelectableOptions(items: SelectablePartial[], options:  {sort?: boolean} = {}) : SelectablePartialAsOptions
{
    let isGrouped: boolean = false;

    // Go through all items making sure any missing keys are filled in as null or something so we don't get unexpected/undefined errors. Also determine whether we are showing any grouping
    items.forEach(i => {
        if (i.grouped) isGrouped = true;
        else i.grouped = false;

        if (!i.identifier) i.identifier = null;
        if (!i.displayText) i.displayText = "- OPTION TEXT -";
        if (!i.displayHtml) i.displayHtml = _.escape(i.displayText);
        if (!i.orderByValue) i.orderByValue = i.displayText;

        if (i.grouped) {
            if (!i.groupIdentifier) i.groupIdentifier = null;
            if (!i.groupDisplayText) i.groupDisplayText = "- GROUP TEXT SUPPLIED -";
            if (!i.groupDisplayHtml) i.groupDisplayHtml = _.escape(i.groupDisplayHtml);
            if (!i.groupOrderByValue) i.groupOrderByValue = i.groupDisplayText;
        }
        else
        {
            i.groupIdentifier = null;
            i.groupDisplayText = null;
            i.groupDisplayHtml = null;
            i.groupOrderByValue = null;
        }
    })

    if (isGrouped) {
        const sorted = [...items];

        if (options.sort) {
            sorted.sort((a, b) => a.orderByValue.localeCompare(b.orderByValue));

            // Resort by group second so items are placed in group order and then by their previous order which was by item order
            sorted.sort((a, b) => a.groupOrderByValue.localeCompare(b.groupOrderByValue));
        }

        const translation = {};
        items.forEach(item => translation[item.groupIdentifier] = item.groupDisplayText);

        return SelectablePartialAsOptions.create({
            options: sorted.map(m => new DropdownOption(m.displayText, m.identifier, m.groupIdentifier)),
            groupTranslation: translation,
            isGrouped: isGrouped,
        });
    } else {
        const sorted = [...items];

        if (options.sort) {
            sorted.sort((a, b) => a.orderByValue.localeCompare(b.orderByValue));
        }

        return SelectablePartialAsOptions.create({
            options: sorted.map(m => new DropdownOption(m.displayText, m.identifier)),
            groupTranslation: {},
            isGrouped: isGrouped,
        });
    }


}
